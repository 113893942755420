import { PublishingCampaignSchedulingCampaignInfoWithMediaSchedules } from '@zetadisplay/engage-api-client';
import { PendingPromiseMessageType } from '@zetadisplay/engage-components/hooks/use-pending-promise';
import { AxiosResponse } from 'axios';

import { PlaylistSetupFormValues } from './createPlaylistFormValues';

const createMessagesCallbackFactory = (edit: boolean) => {
    return (
        messageType: PendingPromiseMessageType,
        formValues: PlaylistSetupFormValues,
        response?: AxiosResponse<PublishingCampaignSchedulingCampaignInfoWithMediaSchedules>
    ) => {
        if (messageType === 'pending') {
            return undefined;
        }

        return {
            message: edit ? 'engage.notification.edit.playlist.success' : 'engage.notification.create.playlist.success',
            params: [response?.data.name || formValues.name],
        };
    };
};

export default createMessagesCallbackFactory;
