import { ConfigModelsChannelPlayerRelations } from '@zetadisplay/engage-api-client';

import { LabelValuePlayerType, LabelValueType } from 'src/views/LabelSetupView';
import createChannelAttributes from 'src/views/LabelSetupView/Utils/createChannelAttributes';

const updatePlayerChannels = (
    attributeValue: LabelValueType,
    player: LabelValuePlayerType,
    channels?: ConfigModelsChannelPlayerRelations[] | null
) => {
    if (channels === undefined || channels === null) {
        return undefined;
    }

    return channels.map((channel) => ({
        ...channel,
        attributes: createChannelAttributes(attributeValue, player, channel.attributes),
    }));
};

export default updatePlayerChannels;
