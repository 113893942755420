import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useOidc } from '@axa-fr/react-oidc';
import { AuthInfo } from '@zetadisplay/engage-components/modules/auth';
import { Theme } from '@zetadisplay/zeta-ui-components';
import queryString, { ParsedQuery } from 'query-string';

import { DEFAULT_OIDC_CONFIGURATION_NAME } from 'src/config/appConfig';

type EngageErrorString = 'engage.auth.login.error' | 'engage.auth.unauthorized.access';
type OktaCallbackErrorString =
    | 'The resource owner or authorization server denied the request.'
    | 'User is not assigned to the client application.';
type SearchQueryParams = ParsedQuery & {
    error?: 'access_denied';
    error_description?: OktaCallbackErrorString;
    state?: string;
};

const EngageErrorActionStrings: Record<EngageErrorString, string | undefined> = {
    'engage.auth.login.error': 'common.action.login',
    'engage.auth.unauthorized.access': undefined,
};

const OktaCallbackErrors: Record<OktaCallbackErrorString, EngageErrorString> = {
    'The resource owner or authorization server denied the request.': 'engage.auth.unauthorized.access',
    'User is not assigned to the client application.': 'engage.auth.unauthorized.access',
};

const getErrorMessage = (errorDescription: OktaCallbackErrorString | undefined): EngageErrorString => {
    if (errorDescription) {
        return OktaCallbackErrors[errorDescription] || 'engage.auth.login.error';
    }

    return 'engage.auth.login.error';
};

const CallbackErrorComponent = () => {
    const location = useLocation();
    const { login } = useOidc(DEFAULT_OIDC_CONFIGURATION_NAME);

    const errorActions = useMemo<Record<EngageErrorString, (() => void) | undefined>>(
        () => ({
            'engage.auth.login.error': () => login(),
            'engage.auth.unauthorized.access': undefined,
        }),
        [login]
    );

    const queries = queryString.parse(location.search) as SearchQueryParams;
    const error = getErrorMessage(queries.error_description);

    return (
        <Theme>
            <AuthInfo action={errorActions[error]} actionText={EngageErrorActionStrings[error]} text={error} />
        </Theme>
    );
};

export default CallbackErrorComponent;
