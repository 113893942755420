import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { usePendingPromise } from '@zetadisplay/engage-components/hooks';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { createDefaultButtons, Modal } from '@zetadisplay/engage-components/modules/modal/components';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { convertScheduleToEngage } from '@zetadisplay/engage-components/utils/scheduling';
import { Schedule } from '@zetadisplay/zeta-ui-components';

import DefaultMediaScheduleForm from 'src/components/Forms/DefaultMediaScheduleForm';

export type ScheduleMediaFileFields = {
    schedules: Schedule[];
    selectedPlaylists?: number[];
};

export type ScheduleMediaFileFormProps = {
    media: DiscriminatedEntity<LibraryModelsMedia>;
} & NiceModalHocProps;

const ScheduleMediaFileForm = NiceModal.create<ScheduleMediaFileFormProps>(({ media }) => {
    const api = useApi();
    const methods = useForm<ScheduleMediaFileFields>();
    const modal = useModal();
    const { workspace } = useWorkspace();

    const submitAction = usePendingPromise(
        async (formValues: ScheduleMediaFileFields) => {
            const response = await api.media.setMediaDefaultSchedules({
                body: formValues.schedules.map((schedule) => convertScheduleToEngage(schedule)),
                mediaid: media.id,
                workspaceid: workspace.id,
            });

            if (formValues.selectedPlaylists?.length) {
                await api.media.applyMediaSchedulesToPlaylists({
                    body: formValues.selectedPlaylists,
                    mediaid: media.id,
                    workspaceid: workspace.id,
                });
            }

            return response.data;
        },
        { success: { message: 'engage.notification.schedule.media.success', params: [media.name] } }
    );

    const onSubmit = useCallback(
        async (formValues: ScheduleMediaFileFields) => {
            const response = await submitAction(formValues);
            if (response === undefined) {
                return;
            }

            modal.resolve(response);
            modal.hide();
        },
        [modal, submitAction]
    );

    return (
        <FormProvider {...methods}>
            <Modal
                actions={{
                    buttons: createDefaultButtons({
                        cancel: {
                            disabled: methods.formState.isSubmitting,
                            onClick: modal.hide,
                        },
                        submit: {
                            busy: methods.formState.isSubmitting,
                            disabled: methods.formState.isSubmitting,
                            onClick: methods.handleSubmit(onSubmit),
                        },
                    }),
                }}
                dark
                title={{ label: 'engage.modal.schedule.media.title' }}
            >
                <DefaultMediaScheduleForm media={media} />
            </Modal>
        </FormProvider>
    );
});

export default ScheduleMediaFileForm;
