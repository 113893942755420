import { useCallback } from 'react';
import { useAsyncAbortable } from 'react-async-hook';
import { NetworkModelsPlayerInformation } from '@zetadisplay/engage-api-client';
import { usePaginatedResults } from '@zetadisplay/engage-components/hooks';
import getResultData from '@zetadisplay/engage-components/hooks/utils/get-result-data';
import { EntityDiscriminators } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { getOrderByProperties, useSorting } from '@zetadisplay/engage-components/modules/options';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import objectHash from 'object-hash';

const usePlayersByAttributeValue = (attributeId?: number, attributeValueId?: number) => {
    const api = useApi();
    const sorting = useSorting();
    const { workspace } = useWorkspace();

    const { results, setResults, total } = usePaginatedResults<NetworkModelsPlayerInformation>(
        objectHash({ attributeId, attributeValueId }),
        EntityDiscriminators.Player,
        undefined,
        getOrderByProperties('Player', sorting.sort)
    );

    // On API error, set and return current data to avoid erroneous UX state
    // i.e. don't allow infinite loading to get stuck
    const onErrorHandler = useCallback(() => {
        const data = getResultData<NetworkModelsPlayerInformation>(results.current);
        return setResults(data, data.length);
    }, [results, setResults]);

    const players = useAsyncAbortable(
        async (signal) => {
            if (attributeId === undefined || attributeValueId === undefined) {
                return [];
            }

            return api.players
                .findByAttribute(
                    {
                        attributeid: attributeId,
                        attributevalueid: attributeValueId,
                        workspaceid: workspace.id,
                    },
                    { signal }
                )
                .then((response) => setResults(response.data.items, response.data.total))
                .catch(onErrorHandler);
        },
        [api.players, attributeId, attributeValueId, onErrorHandler, setResults, workspace.id]
    );

    return {
        data: (results.current && [...(results.current.values() || [])]) || [],
        isLoading: players.loading,
        total: total.current,
    };
};

export default usePlayersByAttributeValue;
