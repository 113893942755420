import React from 'react';
import { useAsyncAbortable } from 'react-async-hook';
import env from '@beam-australia/react-env';
import { Grid, Paper, Typography } from '@mui/material';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';
import htmlParser from 'html-react-parser';
import { DateTime } from 'luxon';
import sanitizeHtml from 'sanitize-html';

import Explosion from 'src/views/DashboardView/Components/DasboardFeed/Assets/explosion.jpg';
import Eye from 'src/views/DashboardView/Components/DasboardFeed/Assets/eye.jpg';
import Face from 'src/views/DashboardView/Components/DasboardFeed/Assets/face.jpg';

type NewsFeedType = {
    body?: string;
    iconType?: string;
    title?: string;
    UpdateDate?: string;
};

const useStyles = makeStyles()(() => ({
    announcement: {
        borderRadius: 2,
    },
    bodyContainer: {
        marginBottom: 20,
        padding: '10px 10px 12px;',
        '& p': {
            margin: 2,
        },
    },
    titleContainer: {
        backgroundColor: '#000',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '60px',
        paddingLeft: 80,
    },
    'titleContainer--info': {
        background: `url(${Face}) no-repeat left center`,
        backgroundColor: '#000',
    },
    'titleContainer--maintenance': {
        background: `url(${Eye}) no-repeat left center`,
        backgroundColor: '#000',
        paddingLeft: 100,
    },
    'titleContainer--warning': {
        background: `url(${Explosion}) no-repeat left center`,
        backgroundColor: '#000',
    },
    titleTypography: {
        color: themeOptions.colors.WHITE,
    },
    timestamp: {
        color: themeOptions.colors.WHITE,
        fontSize: 12,
        lineHeight: '18px',
        letterSpacing: 0,
    },
}));

// TODO: This exists to skip rendering feed currently during tests because we need to figure out a way to hook into that single fetch
type Props = {
    isDisabled?: boolean;
};

const dashboardFeedUrl = env('DASHBOARD_FEED_URL') || 'https://zetadisplaytest.gauddi.com/en-us/home/engage/news/';

const DashboardFeed = ({ isDisabled }: Props) => {
    const { classes, cx } = useStyles();
    const t = useTranslation();

    // TODO: Loading feed is currently huge hack as the structure is interesting and should be addressed asap
    const newsFeed = useAsyncAbortable(
        async (signal) => {
            if (isDisabled) {
                return undefined;
            }

            return fetch(dashboardFeedUrl, {
                signal,
            })
                .then((response) => response.json())
                .then((response) => {
                    const values = response?.Children?.zetadisplay?.Children;
                    if (values === undefined) {
                        return undefined;
                    }

                    return Object.values(values) as NewsFeedType[];
                })
                .then((response) => {
                    if (response === undefined) {
                        return undefined;
                    }

                    return response[0];
                });
        },

        [isDisabled]
    );

    const feed = newsFeed.result;
    if (isDisabled || feed === undefined) {
        return null;
    }

    const titleClasses = cx({
        [classes.titleContainer]: true,
        [classes['titleContainer--info']]: feed.iconType === 'Info',
        [classes['titleContainer--warning']]: feed.iconType === 'Warning',
        [classes['titleContainer--maintenance']]: feed.iconType === 'Maintenance',
    });

    return (
        <Grid container spacing={1} data-testid="feed">
            <Grid item xs={12}>
                <Typography variant="h2">{t.trans('engage.dashboard.feed.title')}</Typography>
            </Grid>

            <Grid item xs={12}>
                <Paper className={cx(classes.announcement)} elevation={3}>
                    <div className={titleClasses}>
                        <Typography className={cx(classes.titleTypography)} variant="h4">
                            {feed?.title || ''}
                        </Typography>

                        <Typography className={cx(classes.timestamp)} variant="h3">
                            {(feed?.UpdateDate && DateTime.fromISO(feed?.UpdateDate).toFormat('d.M.yyyy HH:mm')) || ''}
                        </Typography>
                    </div>

                    <div className={cx(classes.bodyContainer)}>
                        {feed?.body &&
                            htmlParser(
                                sanitizeHtml(feed.body, {
                                    allowedTags: ['em', 'p', 'a', 'strong', 'ul', 'ol', 'li', 'br'],
                                })
                            )}
                    </div>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default DashboardFeed;
