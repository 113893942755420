import React, { memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import { Typography } from '@mui/material';
import {
    ConfigModelsGroupInfoWithChildren,
    NetworkModelsLifelineConnectionStatusEnum,
} from '@zetadisplay/engage-api-client';
import { ItemsView, SkeletonItemsView } from '@zetadisplay/engage-components';
import { DiscriminatedEntity, isGroup, isPlayer } from '@zetadisplay/engage-components/models';
import { useViewMode } from '@zetadisplay/engage-components/modules/options';
import { getIconType } from '@zetadisplay/engage-components/modules/view/utils';
import { generateRouteFromItem, RouteNode } from '@zetadisplay/engage-components/utils/route/route';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ActionGroup, ItemProps } from '@zetadisplay/zeta-ui-components';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

import GroupItemSubtitle from 'src/components/Subtitles/Group/GroupItemSubtitle';
import PlayerItemSubtitle from 'src/components/Subtitles/Player/PlayerItemSubtitle';
import { PLAYERS_BASE_PATH } from 'src/constants/Paths';
import PlayerDetailsSidekick, { PlayerDetailsSidekickProps } from 'src/modules/player/sidekick/PlayerDetailsSidekick';
import { PlayersItemType } from 'src/views/PlayersView';
import PlayerItemStatus from 'src/views/PlayersView/Components/PlayerItemStatus';
import usePlayersListGroupActions from 'src/views/PlayersView/Hooks/usePlayersListGroupActions';
import usePlayersViewListPlayerActions from 'src/views/PlayersView/Hooks/usePlayersViewListPlayerActions';

const { BLACK } = themeOptions.colors;

type Props = {
    infiniteScrolling?: boolean;
    items: PlayersItemType[];
    loading: boolean;
    onInfiniteScroll?: () => void;
    path: RouteNode[];
};

const useStyles = makeStyles()(() => ({
    root: {
        flex: 1,
    },
    section: {
        borderTop: '1px solid',
        borderColor: '#D9D9D9',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
    },
    container: {
        height: '100%',
        flexGrow: 1,
    },
    info: {
        color: BLACK,
        padding: 48,
    },
}));

const PlayersViewList = ({ infiniteScrolling = false, items, loading, onInfiniteScroll, path }: Props) => {
    const groupActions = usePlayersListGroupActions();
    const playerActions = usePlayersViewListPlayerActions();
    const { classes, cx } = useStyles();
    const history = useNavigate();
    const t = useTranslation();
    const { viewMode } = useViewMode();

    const generateRoute = useCallback(
        (item: DiscriminatedEntity<ConfigModelsGroupInfoWithChildren>) => {
            return generateRouteFromItem(PLAYERS_BASE_PATH, item, path);
        },
        [path]
    );

    const getThumbnailUrl = (item: PlayersItemType) => {
        if (isPlayer(item)) {
            return item.thumbUrl;
        }

        return null;
    };

    const renderStatus = useCallback((item: PlayersItemType) => {
        if (isPlayer(item)) {
            return (
                <PlayerItemStatus
                    status={
                        item.onlineState !== undefined
                            ? item.onlineState
                            : NetworkModelsLifelineConnectionStatusEnum.OFFLINE
                    }
                />
            );
        }

        return null;
    }, []);

    const renderSubtitle = (item: PlayersItemType) => {
        if (isPlayer(item)) {
            return <PlayerItemSubtitle item={item} />;
        }

        return <GroupItemSubtitle item={item} />;
    };

    const renderTitle = (item: PlayersItemType) => {
        if (isPlayer(item)) {
            return item.friendlyName;
        }

        return item.name;
    };

    const handleItemClick = useCallback(
        (item: PlayersItemType) => {
            if (isGroup(item)) {
                return history(generateRoute(item));
            }
            return NiceModal.show<void, PlayerDetailsSidekickProps>(PlayerDetailsSidekick, { item });
        },
        [generateRoute, history]
    );

    const itemProps: ItemProps<PlayersItemType> = useMemo(
        () => ({
            actions: [...groupActions, ...playerActions] as ActionGroup<PlayersItemType>[],
            clickable: (item) => isGroup(item) || isPlayer(item),
            getItemIconType: getIconType,
            getThumbnailUrl: (item) => getThumbnailUrl(item) ?? '',
            inViewThreshold: 0, // a fairly small value is required for our expanding components
            onItemClick: handleItemClick,
            renderStatus,
            renderSubtitle,
            renderTitle,
            showThumbnail: isPlayer,
        }),
        [groupActions, handleItemClick, playerActions, renderStatus]
    );

    const renderItemsView = () => {
        if (loading && items.length === 0) {
            return <SkeletonItemsView actions={[]} viewMode={viewMode} />;
        }

        if (!items || items.length === 0) {
            return (
                <Typography align="center" className={cx(classes.info)}>
                    {t.trans('engage.players.content.no_players')}
                </Typography>
            );
        }

        return (
            <ItemsView
                infiniteScrolling={infiniteScrolling}
                isLoadingMore={loading && items.length > 0}
                items={items}
                ItemProps={itemProps}
                onInfiniteScroll={onInfiniteScroll}
                viewMode={viewMode}
            />
        );
    };

    return (
        <div data-testid="players-list" id="playersList" className={cx(classes.root)}>
            <section className={cx(classes.section)}>
                <div className={cx(classes.container)}>{renderItemsView()}</div>
            </section>
        </div>
    );
};

export default memo(PlayersViewList);
