import { useMemo } from 'react';
import { useOidc } from '@axa-fr/react-oidc';
import env from '@beam-australia/react-env';
import NiceModal from '@ebay/nice-modal-react';
import { UserModelsUser } from '@zetadisplay/engage-api-client';
import { DropdownMenuItem } from '@zetadisplay/engage-components';
import { useUserInformation, useUserLanguages } from '@zetadisplay/engage-components/modules/auth';

import pkg from 'src/../package.json';
import ProfileModal, { ProfileModalProps } from 'src/components/Modals/Profile/ProfileModal';
import { DEFAULT_OIDC_CONFIGURATION_NAME } from 'src/config/appConfig';

const commitHash = env('COMMIT_HASH')?.slice(0, 6) || '000000';
const release = env('APP_VERSION') || pkg.version;
const version = process.env.NODE_ENV === 'development' ? '.DEV' : `${release} (${commitHash})`;

const useUserMenu = () => {
    const { logout } = useOidc(DEFAULT_OIDC_CONFIGURATION_NAME);
    const { user, reloadUser } = useUserInformation();
    const { changeInterfaceLanguage } = useUserLanguages();

    return useMemo(
        (): DropdownMenuItem[] => [
            {
                label: 'engage.profile.title',
                name: 'profile',
                // TODO: Replace then with actual event trigger
                callback: () => {
                    NiceModal.show<UserModelsUser, ProfileModalProps>(ProfileModal, { user }).then((response) => {
                        if (response.profile?.languageId) {
                            changeInterfaceLanguage(response.profile?.languageId);
                        }

                        reloadUser();
                    });
                },
            },
            {
                callback: () => logout('/'),
                label: 'common.action.logout',
                name: 'logout',
            },
            {
                disabled: true,
                label: `v${version}`,
                name: 'version',
            },
        ],
        [changeInterfaceLanguage, logout, reloadUser, user]
    );
};

export default useUserMenu;
