import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { formatDuration } from '@zetadisplay/engage-components/utils/media';
import { ZetaTheme } from '@zetadisplay/zeta-ui-components/utils/theme';

type Props = {
    item: DiscriminatedEntity<LibraryModelsMedia>;
};

const PlaylistPreviewContentItemInformation = ({ item }: Props) => {
    const theme: ZetaTheme = useTheme();

    return (
        <Box sx={{ flexGrow: 1, overflow: 'hidden', marginRight: '8px' }}>
            <Typography
                noWrap
                sx={{ fontSize: 14, fontWeight: 400, lineHeight: '20px', letterSpacing: '0.47px' }}
                title={item.name}
            >
                {item.name}
            </Typography>

            <Typography sx={{ fontSize: '12px', color: theme.palette.text.disabled }}>
                {formatDuration(item.duration)}
            </Typography>
        </Box>
    );
};

export default PlaylistPreviewContentItemInformation;
