import React from 'react';
import { useFormState } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SwitchInput } from '@zetadisplay/engage-components';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { PowerScheduleSchedule } from 'src/views/PowerScheduleSetupView/Utils/createPowerScheduleSchedule';

const useStyles = makeStyles()(() => ({
    container: {
        alignItems: 'baseline',
        padding: '5px 10px',
    },
    timePicker: {
        maxWidth: '186px',
    },
    weekdayTypography: {
        width: '88px',
    },
}));

export type PrimaryPowerScheduleFormScheduleProps = {
    onEndTimeChange: (key: string, date: Date | null) => void;
    onStartTimeChange: (key: string, date: Date | null) => void;
    schedule: PowerScheduleSchedule;
    weekday: string;
};

const PrimaryPowerScheduleFormSchedule = ({
    onEndTimeChange,
    onStartTimeChange,
    schedule,
    weekday,
}: PrimaryPowerScheduleFormScheduleProps) => {
    const { classes } = useStyles();
    const t = useTranslation();
    const { errors } = useFormState();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore suppress ts error here because setValue doesn't work well with generated strings as value resolves to never
    const scheduleEndTimeError = errors?.primaryPowerSchedule?.schedules?.[weekday]?.endTime;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore suppress ts error here because setValue doesn't work well with generated strings as value resolves to never
    const scheduleStartTimeError = errors?.primaryPowerSchedule?.schedules?.[weekday]?.startTime;

    return (
        <Stack
            className={classes.container}
            data-testid={`primaryPowerSchedule.schedules.${weekday}`}
            direction="row"
            key={weekday}
            spacing={1}
        >
            <SwitchInput name={`primaryPowerSchedule.schedules.${weekday}.enabled`} />
            <Typography className={classes.weekdayTypography}>{t.trans(`common.datetime.${weekday}.long`)}</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                    ampm={false}
                    disabled={!schedule.enabled}
                    label={t.trans(scheduleStartTimeError?.message || 'common.scheduling.times.start_time')}
                    onChange={(date) => onStartTimeChange(weekday, date)}
                    slotProps={{
                        textField: {
                            className: classes.timePicker,
                            InputLabelProps: { shrink: true },
                            error: !!scheduleStartTimeError,
                        },
                    }}
                    value={schedule.startTime}
                />
                <TimePicker
                    ampm={false}
                    disabled={!schedule.enabled}
                    label={t.trans(scheduleEndTimeError?.message || 'common.scheduling.times.end_time')}
                    onChange={(date) => onEndTimeChange(weekday, date)}
                    slotProps={{
                        textField: {
                            className: classes.timePicker,
                            InputLabelProps: { shrink: true },
                            error: !!scheduleEndTimeError,
                        },
                    }}
                    value={schedule.endTime}
                />
            </LocalizationProvider>
        </Stack>
    );
};

export default PrimaryPowerScheduleFormSchedule;
