export type PlayersViewParams = {
    attributeId?: string;
    attributeValueId?: string;
    groupId?: string;
};

export enum PlayerView {
    GROUPS = 'GROUPS',
    LABELS = 'LABELS',
    LABEL_SETUP = 'LABEL_SETUP',
    PLAYERS = 'PLAYERS',
}

export type PlayerViewType = keyof typeof PlayerView;
