import { LibraryModelsMedia, PublishingCampaignSchedulingCreateCampaignModel } from '@zetadisplay/engage-api-client';
import { ApiInterface } from '@zetadisplay/engage-components/modules/api';
import pLimit from 'p-limit';

import { PlaylistSetupFormValues } from 'src/modules/playlist/utils/createPlaylistFormValues';
import createPlaylistPayload from 'src/modules/playlist/utils/createPlaylistPayload';

const limit = pLimit(1);

const playlistCreateOrUpdatePromiseFactory = ({
    api,
    playlistId,
    workspaceid,
}: {
    api: ApiInterface;
    playlistId?: number;
    workspaceid: string;
}) => {
    return async (formValues: PlaylistSetupFormValues) => {
        const modifiedMediaPromises = formValues.modifiedMedia
            ? Array.from(formValues.modifiedMedia, ([key, value]) => {
                  return limit(async () => {
                      const response = await api.media.updateMedia({
                          body: {
                              duration: value.data?.duration || value.object.duration,
                              name: value.data?.name || value.object.name,
                              url: value.data?.url || value.object.url,
                              urlInternal: value.data?.urlInternal || value.object.urlInternal,
                              folderId: value.object.folderId,
                              type: value.object.type,
                          },
                          mediaid: key,
                          workspaceid,
                      });
                      return [key, response.data];
                  }) as Promise<[string, LibraryModelsMedia]>;
              })
            : [];

        let updatedFormValues = formValues;
        if (modifiedMediaPromises.length > 0) {
            const modifiedMediaResponses = new Map<string, LibraryModelsMedia>(
                await Promise.all(modifiedMediaPromises)
            );
            // The next block will handle each modified media to update the mediaId in the form values, since changed media items might well have gotten a new media id
            updatedFormValues = {
                ...formValues,
                playlistMediaCollection: formValues.playlistMediaCollection.map((media) => {
                    const modifiedMedia = (media.mediaId && modifiedMediaResponses.get(media.mediaId)) || undefined;
                    return {
                        ...media,
                        mediaId: modifiedMedia?.id || media.mediaId,
                    };
                }),
            };
        }

        // Create new playlist
        if (playlistId === undefined) {
            return api.playlists.createCampaign({
                body: createPlaylistPayload(
                    updatedFormValues,
                    workspaceid
                ) as PublishingCampaignSchedulingCreateCampaignModel,
                workspaceid,
            });
        }

        return api.playlists.editCampaign({
            body: createPlaylistPayload(updatedFormValues) as PublishingCampaignSchedulingCreateCampaignModel,
            campaignid: playlistId,
            workspaceid,
        });
    };
};

export default playlistCreateOrUpdatePromiseFactory;
