import React, { useCallback, useMemo } from 'react';
import { useAsyncAbortable } from 'react-async-hook';
import NiceModal, { NiceModalHocProps } from '@ebay/nice-modal-react';
import { LibraryModelsFolder, LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useUserInformation } from '@zetadisplay/engage-components/modules/auth';
import {
    CreateMediaFolderForm,
    CreateMediaFolderFormProps,
    CreateURLMediaFileForm,
    UploadMediaFileForm,
} from '@zetadisplay/engage-components/modules/library/components';
import {
    emitOnMediaFileCreated,
    emitOnMediaFolderCreated,
} from '@zetadisplay/engage-components/modules/library/events';
import { useCurrentMediaFolder } from '@zetadisplay/engage-components/modules/library/hooks';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Icon } from '@zetadisplay/zeta-ui-components';

import TemplateLibrary from 'src/components/Modals/Library/TemplateLibrary/TemplateLibrary';
import ViewActions from 'src/components/ViewActions';

const LibraryViewActions = () => {
    const api = useApi();
    const currentMediaFolderId = useCurrentMediaFolder();
    const t = useTranslation();
    const { hasRole } = useUserInformation();
    const { workspace } = useWorkspace();

    const { result, loading } = useAsyncAbortable(
        (signal) => {
            return api.folders
                .getMediaFolder({ folderid: currentMediaFolderId, workspaceid: workspace.id }, { signal })
                .then((response) => response.data);
        },
        [api.folders, currentMediaFolderId, workspace.id]
    );

    const isDisabledAction = useCallback(
        (roles: string[]) => {
            if (loading || result === undefined || result.sharedFrom) {
                return true;
            }

            return !hasRole(roles);
        },
        [hasRole, loading, result]
    );

    const actions = useMemo(
        () => [
            {
                disabled: isDisabledAction(['ENGAGE_LIBRARY_CONTENT_UPLOAD']),
                icon: <Icon type="ADD_MEDIA" color="#fff" />,
                name: t.trans('engage.action.upload.file'),
                onClick: () => {
                    NiceModal.show<DiscriminatedEntity<LibraryModelsMedia>[], NiceModalHocProps>(
                        UploadMediaFileForm
                    ).then((response) => response.forEach(emitOnMediaFileCreated));
                },
            },
            {
                disabled: isDisabledAction(['ENGAGE_LIBRARY_FOLDER_CREATE']),
                icon: <Icon type="ADD_MEDIA_FOLDER" color="#fff" />,
                name: t.trans('engage.action.create.folder'),
                onClick: () => {
                    NiceModal.show<DiscriminatedEntity<LibraryModelsFolder>, CreateMediaFolderFormProps>(
                        CreateMediaFolderForm
                    ).then(emitOnMediaFolderCreated);
                },
            },
            {
                disabled: isDisabledAction(['ENGAGE_LIBRARY_CONTENT_CREATE_URL']),
                icon: <Icon type="ADD_URL_CONTENT" color="#fff" />,
                name: t.trans('engage.action.create.url'),
                onClick: () => {
                    NiceModal.show<DiscriminatedEntity<LibraryModelsMedia>, NiceModalHocProps>(
                        CreateURLMediaFileForm
                    ).then(emitOnMediaFileCreated);
                },
            },
            {
                disabled: isDisabledAction(['ENGAGE_LIBRARY_TEMPLATE_VIEW']),
                icon: <Icon type="URL" color="#fff" />,
                name: t.trans('engage.action.open.template-library'),
                onClick: () => {
                    NiceModal.show(TemplateLibrary);
                },
            },
        ],
        [isDisabledAction, t]
    );

    return <ViewActions actions={actions} label="library-actions" />;
};

export default LibraryViewActions;
