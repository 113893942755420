import { useCallback } from 'react';
import NiceModal, { NiceModalHocProps } from '@ebay/nice-modal-react';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Icon } from '@zetadisplay/zeta-ui-components';

import ConfirmPrompt from 'src/components/Modals/ConfirmPrompt';

import { AssignedPowerScheduleType } from '../../../views/PowerScheduleSetupView';

export type RemoveAssignedPowerSchedulePromptProps = {
    powerSchedule: AssignedPowerScheduleType;
} & NiceModalHocProps;

const RemoveAssignedPowerSchedulePrompt = NiceModal.create<RemoveAssignedPowerSchedulePromptProps>(
    ({ powerSchedule }) => {
        const t = useTranslation();
        const submitAction = useCallback(async () => powerSchedule, [powerSchedule]);

        return (
            <ConfirmPrompt
                description={t.trans('engage.modal.delete.content', [powerSchedule.name])}
                title={{
                    icon: <Icon type="OPENING_HOURS" />,
                    label: 'engage.modal.remove.assigned.power_schedule.title',
                }}
                submit={{
                    label: 'common.action.remove',
                    name: 'remove',
                }}
                submitAction={submitAction}
            />
        );
    }
);

export default RemoveAssignedPowerSchedulePrompt;
