import { FieldArrayWithId, UseFieldArrayMove } from 'react-hook-form';
import { DragEndEvent } from '@dnd-kit/core/dist/types';

import { PlaylistSetupFormValues } from './createPlaylistFormValues';

const movePlaylistContentItemOnSort =
    (
        fields: FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>[],
        move: UseFieldArrayMove
    ) =>
    (event: DragEndEvent) => {
        const { active, over } = event;

        if (!over || !active.data.current || !over.data.current || active.id === over.id) {
            return;
        }

        const oldIndex = fields.findIndex((field) => field.fieldArrayId === active.id);
        const newIndex = fields.findIndex((field) => field.fieldArrayId === over.id);

        move(oldIndex, newIndex);
    };

export default movePlaylistContentItemOnSort;
