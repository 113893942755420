import React from 'react';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import PlayerItemDetails, { PlaylistItem } from './PlayerItemDetails';

const useStyles = makeStyles()(() => ({
    container: {
        paddingTop: 4,
    },
}));

type Props = { playlist: DiscriminatedEntity<PlaylistItem> };

const PlayerItemDetailsPlaylistDetails = ({ playlist }: Props) => {
    const { classes } = useStyles();

    return (
        <div className={classes.container} data-testid={`player-playlist-details-${playlist.id}`}>
            <PlayerItemDetails item={playlist} />
        </div>
    );
};

export default PlayerItemDetailsPlaylistDetails;
