import React, { useCallback, useState } from 'react';
import { Box, FormControlLabel, ListItemButton, ListItemIcon, ListItemText, Switch, Typography } from '@mui/material';
import { PublishingCampaignSchedulingCampaignInfoWithMediaSchedules } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Checkbox } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()(() => ({
    root: {
        marginBottom: 12,
        marginTop: 12,
    },
    listItem: {
        paddingBottom: 0,
        paddingLeft: 0,
        paddingTop: 0,
    },
}));

type Props = {
    onChange: (entries: number[]) => void;
    playlists: DiscriminatedEntity<PublishingCampaignSchedulingCampaignInfoWithMediaSchedules>[];
    showApplyToEveryPlaylist?: boolean;
};

const PlaylistSelection = ({ onChange, playlists, showApplyToEveryPlaylist = true }: Props) => {
    const { classes } = useStyles();
    const t = useTranslation();

    /**
     * Selected playlists are internally stored in this array which is also passed as form value or call via callback
     */
    const [applyToEveryPlaylist, setApplyToEveryPlaylist] = useState(showApplyToEveryPlaylist);
    const [selectedPlaylists, setSelectedPlaylists] = useState(playlists.map((playlist) => playlist.id));
    const isSelectedPlaylist = useCallback(
        (id: number) => !!selectedPlaylists.find((pid) => pid === id),
        [selectedPlaylists]
    );

    const togglePlaylist = (id: number) => {
        const nextSelectedPlaylists = isSelectedPlaylist(id)
            ? selectedPlaylists.filter((pid) => pid !== id)
            : [...selectedPlaylists, id];

        setSelectedPlaylists(nextSelectedPlaylists);
        onChange(nextSelectedPlaylists);
    };

    const renderPlaylistSelection = () => {
        if (applyToEveryPlaylist) {
            return null;
        }

        return (
            <Box>
                <Typography variant="subtitle1">{t.trans('engage.playlists.title')}</Typography>
                {playlists.map((playlist) => {
                    const selected = isSelectedPlaylist(playlist.id);

                    return (
                        <div data-testid={`playlist-checklist-item-${playlist.name}`} key={playlist.id}>
                            <ListItemButton
                                className={classes.listItem}
                                dense
                                onClick={() => togglePlaylist(playlist.id)}
                                data-testid={`playlist-${playlist.name}`}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        checked={selected}
                                        data-testid={`playlist-checkbox-${playlist.name}--${
                                            selected ? 'checked' : 'unchecked'
                                        }`}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={playlist.name} />
                            </ListItemButton>
                        </div>
                    );
                })}
            </Box>
        );
    };

    return (
        <div className={classes.root} data-testid="playlists-checklist">
            {showApplyToEveryPlaylist && (
                <FormControlLabel
                    control={
                        <Switch
                            aria-label="apply-to-every-playlist-switch"
                            checked={applyToEveryPlaylist}
                            data-testid="apply-to-every-playlist-switch"
                            onChange={(_, val) => setApplyToEveryPlaylist(val)}
                        />
                    }
                    label={t.trans('engage.playlist.select.switch.label')}
                />
            )}
            {renderPlaylistSelection()}
        </div>
    );
};

export default PlaylistSelection;
