import React, { useCallback, useMemo } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useFormContext } from 'react-hook-form';
import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { MediaSubtitle, PreviewDialog } from '@zetadisplay/engage-components';
import { DiscriminatedEntity, EntityDiscriminators, isMediaFolder } from '@zetadisplay/engage-components/models';
import { useApi, useApiConfiguration } from '@zetadisplay/engage-components/modules/api';
import { LibraryItemType } from '@zetadisplay/engage-components/modules/library';
import { LibraryWidget } from '@zetadisplay/engage-components/modules/library/components';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { getPreviewUrl, isPreviewable } from '@zetadisplay/engage-components/utils/media';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button, DragConfig, DragObject } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { PlaylistSetupFormValues } from 'src/modules/playlist/utils/createPlaylistFormValues';
import { emitOnPlaylistMediaAdded } from 'src/views/PlaylistSetupView/Events/onPlaylistMediaAddedEvent';
import { createPlaylistMedia } from 'src/views/PlaylistView/Utils/withMediaResolver';

import getPlaylistMediaDefaultSchedules from '../../../modules/playlist/api/get-playlist-media-default-schedules';

const useStyles = makeStyles()(() => ({
    actionButton: {
        fontSize: 12,
        marginLeft: 4,
        letterSpacing: '0.4px',
        lineHeight: '20px',
        paddingLeft: 0,
        textTransform: 'uppercase',
    },
    root: {
        height: '100%',
    },
}));

type AsyncPrimaryActionProps = {
    mediaFile: DiscriminatedEntity<LibraryModelsMedia>;
};

const AsyncPrimaryAction = ({ mediaFile }: AsyncPrimaryActionProps) => {
    const api = useApi();
    const { classes } = useStyles();
    const t = useTranslation();
    const { workspace } = useWorkspace();

    const action = useAsyncCallback(async () => {
        const playlistMedia = createPlaylistMedia(mediaFile);
        if (mediaFile.scheduleIds?.length) {
            playlistMedia.mediaSchedules = await getPlaylistMediaDefaultSchedules(mediaFile.id, api, workspace.id);
        }

        emitOnPlaylistMediaAdded(playlistMedia);
    });

    return (
        <Button
            busy={action.loading}
            className={classes.actionButton}
            disabled={action.loading}
            name={`async-primary-action-${mediaFile.name}`}
            onClick={action.execute}
        >
            + {t.trans('common.action.add')}
        </Button>
    );
};

const PlaylistSetupLibrary = () => {
    const { classes } = useStyles();
    const apiConfig = useApiConfiguration();

    const { watch } = useFormContext<PlaylistSetupFormValues>();
    const playlistMediaCollection = watch('playlistMediaCollection') || [];

    /**
     * Folders are not draggable at the moment at least
     */
    const dragConfig: DragConfig<LibraryItemType> = useMemo(
        () => ({
            type: EntityDiscriminators.MediaFile,
            setDragItem: (item): DragObject<LibraryItemType> => ({
                id: new Date().getTime(),
                index: playlistMediaCollection.length,
                data: item,
                type: item.discriminatorType,
            }),
        }),
        [playlistMediaCollection.length]
    );

    const renderPrimaryAction = useCallback((item: LibraryItemType) => {
        if (isMediaFolder(item)) {
            return null;
        }

        return <AsyncPrimaryAction mediaFile={item} />;
    }, []);

    const renderPreview = useCallback(
        (item: LibraryItemType) => {
            if (isMediaFolder(item)) {
                return null;
            }

            return (
                <PreviewDialog
                    media={item}
                    previewable={isPreviewable(item)}
                    previewUrl={getPreviewUrl(item, false, apiConfig.basePath)}
                    renderSubtitle={(arg) => <MediaSubtitle item={arg} />}
                />
            );
        },
        [apiConfig.basePath]
    );

    return (
        <div className={classes.root} data-testid="playlist-library-list">
            <LibraryWidget
                dragConfig={dragConfig}
                renderPrimaryAction={renderPrimaryAction}
                renderPreview={renderPreview}
                scope="Playlist Settings Library"
                uploadAction
                urlAction
            />
        </div>
    );
};

export default PlaylistSetupLibrary;
