import React, { useCallback } from 'react';
import { ProductFruits } from 'react-product-fruits';
import env from '@beam-australia/react-env';
import { useUserInformation } from '@zetadisplay/engage-components/modules/auth';
import { useTranslation } from '@zetadisplay/zeta-localization';

import getLocaleLanguage from '../GetLocaleLanguage';

const ProductFruitsWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { language } = useTranslation();
    const { user } = useUserInformation();
    const productFruitsWorkspaceCode = env('PRODUCT_FRUITS_WORKSPACE');

    const renderProductFruits = useCallback(() => {
        if (!productFruitsWorkspaceCode || !language || !user?.profile?.email) {
            return null;
        }

        return (
            <ProductFruits
                language={getLocaleLanguage(language)}
                user={{
                    email: user?.profile?.email,
                    firstname: user?.profile?.firstName ?? undefined,
                    lastname: user?.profile?.lastName ?? undefined,
                    username: user?.profile?.email,
                    props: {
                        permissions: user?.roles || [],
                    },
                }}
                workspaceCode={productFruitsWorkspaceCode}
            />
        );
    }, [
        language,
        productFruitsWorkspaceCode,
        user?.profile?.email,
        user?.profile?.firstName,
        user?.profile?.lastName,
        user?.roles,
    ]);
    return (
        <>
            {children}
            {renderProductFruits()}
        </>
    );
};

export default ProductFruitsWrapper;
