import { useCallback } from 'react';
import { LibraryModelsFolderActions, LibraryModelsMediaActions } from '@zetadisplay/engage-api-client';
import { useUserInformation } from '@zetadisplay/engage-components/modules/auth';

import { LibraryItemsType } from 'src/views/LibraryView';

const useIsDisabledLibraryAction = (defaultRoles: string[] = []) => {
    const { hasRole } = useUserInformation();

    return useCallback(
        (
            item: LibraryItemsType,
            actionFlag: LibraryModelsFolderActions | LibraryModelsMediaActions,
            roles: string[]
        ) => {
            return !hasRole(defaultRoles.concat(roles)) || !(item.actionFlags & actionFlag);
        },
        [defaultRoles, hasRole]
    );
};

export default useIsDisabledLibraryAction;
