import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ConfigModelsGroupInfoWithChildren, NetworkModelsPlayerInformation } from '@zetadisplay/engage-api-client';
import { ViewHeader } from '@zetadisplay/engage-components';
import { useBreadcrumbs, useToolbarOptions } from '@zetadisplay/engage-components/hooks';
import { DiscriminatedEntity, SortableSort } from '@zetadisplay/engage-components/models';
import { createStatusFilter, FilterFields, keywordFilter } from '@zetadisplay/engage-components/modules/search';
import { emitOnSearch, SearchEventScope } from '@zetadisplay/engage-components/modules/search/events';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import {
    buildUuidNamePathNode,
    getRoutePathNodes,
    idNamePathNodeRegex,
} from '@zetadisplay/engage-components/utils/route';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { SearchFilters, SearchState } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import ViewToolbar from 'src/components/ViewToolbar';
import { PLAYERS_BASE_PATH } from 'src/constants/Paths';
import { statuses, statusStrings } from 'src/constants/Players/PlayerStatus';
import useResetSearchOnLocationChange from 'src/hooks/Search/useResetSearchOnLocationChange';
import useViewTitle from 'src/hooks/useViewTitle';
import createRootNode from 'src/utils/breadcrumbs/createRootNode';
import withDefaultLayout from 'src/utils/Layout/withDefaultLayout';
import PlayersViewActions from 'src/views/PlayersView/Components/PlayersViewActions';
import PlayersViewList from 'src/views/PlayersView/Components/PlayersViewList';
import usePlayersContent from 'src/views/PlayersView/Hooks/usePlayersContent';

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',

        [theme.breakpoints.up('lg')]: {
            paddingLeft: 148,
            paddingRight: 152,
        },
    },
}));

const getPlayersViewScope = (showPlayersOnly: boolean) => {
    return !showPlayersOnly ? 'engage.players.toolbar.view.toggle.players' : 'engage.players.toolbar.view.toggle.mixed';
};

export type PlayersItemType =
    | DiscriminatedEntity<ConfigModelsGroupInfoWithChildren>
    | DiscriminatedEntity<NetworkModelsPlayerInformation>;

const PlayersView = () => {
    useViewTitle('engage.players.title');

    const { classes } = useStyles();
    const location = useLocation();
    const t = useTranslation();
    const { workspace } = useWorkspace();

    // Search and filter states
    const searchRef = useResetSearchOnLocationChange();
    const searchQueryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

    const [showPlayersOnly, setShowPlayersOnly] = useState(Boolean(searchQueryParams.get('players')));
    const toolbarOptions = useToolbarOptions(
        {
            sorting: {
                defaultSort: SortableSort.NAME,
                filter: [{ name: SortableSort.MODIFIED }],
            },
        },
        [
            {
                active: showPlayersOnly,
                label: getPlayersViewScope(showPlayersOnly),
                name: 'togglePlayers',
                onClick: () => setShowPlayersOnly((prevState) => !prevState),
            },
        ]
    );

    const { groups, players, getNextResultPage, total } = usePlayersContent(showPlayersOnly);
    const rootNode = useMemo(
        () => createRootNode(workspace.id, workspace.name, PLAYERS_BASE_PATH),
        [workspace.id, workspace.name]
    );

    const pathNodes = getRoutePathNodes(location?.pathname, idNamePathNodeRegex, buildUuidNamePathNode);
    const breadcrumbs = useBreadcrumbs(pathNodes, PLAYERS_BASE_PATH, rootNode, true);

    const items = useMemo(() => {
        return ((groups?.data || []) as PlayersItemType[]).concat(players.data);
    }, [groups?.data, players.data]);

    // Handle default filters
    useEffect(() => {
        const value: SearchState<SearchFilters> = {};
        if (searchQueryParams.has('keyword')) {
            value.keyword = searchQueryParams.get('keyword') as string;
        }

        if (searchQueryParams.has('status')) {
            value.status = searchQueryParams.get('status') as string;
        }

        emitOnSearch({ scope: SearchEventScope.PLAYER | SearchEventScope.GROUP, value });
        // TODO What is the reason for this?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <main className={classes.root}>
            <PlayersViewActions />

            <ViewHeader title="engage.players.title" />

            <ViewToolbar
                breadcrumbs={breadcrumbs}
                options={toolbarOptions}
                ref={searchRef}
                renderReturnButton
                searchOptions={{
                    filters: {
                        [FilterFields.KEYWORD]: {
                            ...keywordFilter,
                            defaultValue: searchQueryParams.get('keyword') || '',
                        },
                        [FilterFields.STATUS]: {
                            ...createStatusFilter(
                                t.trans('common.detail.status'),
                                statuses.map((status) => ({
                                    id: status.key,
                                    name: t.trans(statusStrings[status.key]),
                                    value: status.literal,
                                }))
                            ),
                            defaultValue: searchQueryParams.get('status') || '',
                        },
                    },
                    onChangeCallback: (value) => {
                        emitOnSearch({ scope: SearchEventScope.PLAYER | SearchEventScope.GROUP, value });
                    },
                }}
            />

            <PlayersViewList
                infiniteScrolling={!total || total > items.length}
                items={items}
                loading={groups.isLoading || players.isLoading}
                onInfiniteScroll={getNextResultPage}
                path={pathNodes}
            />
        </main>
    );
};

export default withDefaultLayout(PlayersView);
