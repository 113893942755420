import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ConfigModelsAttributeDetails, ConfigModelsAttributeValueBasic } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { handleResponseError } from '@zetadisplay/engage-components/utils/response';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button } from '@zetadisplay/zeta-ui-components';
import { useNotify } from '@zetadisplay/zeta-ui-components/hooks';
import { AxiosError } from 'axios';

import { PLAYERS_BASE_PATH } from 'src/constants/Paths';
import { LabelSetupFormData } from 'src/views/LabelSetupView';
import createAttributeValues from 'src/views/LabelSetupView/Actions/createAttributeValues';
import createOrUpdateAttribute from 'src/views/LabelSetupView/Actions/createOrUpdateAttribute';
import deleteOrUpdateAttributeValues from 'src/views/LabelSetupView/Actions/deleteOrUpdateAttributeValues';
import updatePlayerRelations from 'src/views/LabelSetupView/Actions/updatePlayerRelations';

type Props = {
    attribute?: DiscriminatedEntity<ConfigModelsAttributeDetails>;
    attributeValues: DiscriminatedEntity<ConfigModelsAttributeValueBasic>[];
    isLoading?: boolean;
    onUpdatedAttribute: (attribute: DiscriminatedEntity<ConfigModelsAttributeDetails>) => void;
    onClearForm: () => void;
};

const LabelSetupActions = ({
    attribute,
    attributeValues,
    isLoading = false,
    onUpdatedAttribute,
    onClearForm,
}: Props) => {
    const api = useApi();
    const history = useNavigate();
    const t = useTranslation();
    const notify = useNotify();
    const { workspace } = useWorkspace();

    const { formState, handleSubmit } = useFormContext<LabelSetupFormData>();
    const { isSubmitting, isValid } = formState;

    const isExistingLabel = attribute !== undefined;
    const onCancel = useCallback(() => history(PLAYERS_BASE_PATH), [history]);

    const onSubmit = async (data: LabelSetupFormData) => {
        try {
            const { values } = data;
            const response = await createOrUpdateAttribute(api, attribute, data, workspace.id, notify);
            const createdAttributeValues = await createAttributeValues(api, response, values, workspace.id, notify);
            const mappedAttributeValues = values.map((value) => {
                const createdAttributeValue = createdAttributeValues.find((v) => v.value === value.value);
                if (createdAttributeValue === undefined) {
                    return value;
                }

                return { ...value, ...createdAttributeValue };
            });

            await updatePlayerRelations(api, mappedAttributeValues, workspace.id, notify);
            await deleteOrUpdateAttributeValues(api, response, attributeValues, values, workspace.id, notify);

            return onUpdatedAttribute(response);
        } catch (e) {
            if (!(e instanceof AxiosError)) {
                throw e;
            }

            return notify({ message: handleResponseError(e), variant: 'error' });
        }
    };

    return (
        <div>
            <Button kind="secondaryAction" label={t.trans('common.action.cancel')} onClick={onCancel} />
            <Button
                kind="secondaryAction"
                disabled={isLoading}
                label={t.trans('common.action.clear')}
                onClick={onClearForm}
            />
            <Button
                disabled={isSubmitting || !isValid || isLoading}
                kind="primaryAction"
                label={t.trans(isExistingLabel ? 'common.action.save' : 'common.action.create')}
                name={isExistingLabel ? 'save' : 'create'}
                onClick={handleSubmit(onSubmit)}
            />
        </div>
    );
};

export default LabelSetupActions;
