import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { SearchControls } from '@zetadisplay/zeta-ui-components';
import { usePrevious } from '@zetadisplay/zeta-ui-components/hooks';

const useResetSearchOnLocationChange = () => {
    const location = useLocation();
    const prevLocation = usePrevious(location);
    const searchRef = useRef<SearchControls>(null);

    useEffect(() => {
        if (searchRef.current === null || prevLocation === undefined || location.pathname === prevLocation.pathname) {
            return;
        }

        searchRef.current.reset();
    }, [location.pathname, prevLocation, prevLocation?.pathname]);

    return searchRef;
};

export default useResetSearchOnLocationChange;
