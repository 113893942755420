import React, { useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { LibraryModelsMedia, LibraryModelsTemplateRelations } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import { filesize } from 'filesize';

import LibraryMediaDetailsDetail from 'src/modules/library/sidekick/LibraryMediaDetailsSidekick/components/LibraryMediaDetails/LibraryMediaDetailsDetail';

const useStyles = makeStyles()(() => ({
    root: {
        paddingTop: 10,
    },
    title: {
        marginBottom: 2,
    },
}));

type LibraryListMediaDetailsInformationProps = {
    item: DiscriminatedEntity<LibraryModelsMedia>;
    template?: LibraryModelsTemplateRelations | undefined;
};

const LibraryMediaDetailsInformation: React.FC<LibraryListMediaDetailsInformationProps> = ({ item, template }) => {
    const { classes } = useStyles();
    const { trans } = useTranslation();

    const getFileSize = useCallback(() => {
        // If we have template, and it is liveTemplate then size is unavailable
        if (template?.liveTemplate) {
            return trans('common.detail.unavailable');
        }

        return item.fileSize !== undefined ? filesize(item.fileSize).toString() : trans('common.detail.unavailable');
    }, [item.fileSize, template?.liveTemplate, trans]);

    return (
        <Box className={classes.root}>
            <Typography className={classes.title} variant="h5">
                {trans('engage.library.media.details.title')}
            </Typography>
            {item.templateId !== null && (
                <LibraryMediaDetailsDetail
                    loading={template === undefined}
                    name="type"
                    subject="common.detail.type"
                    value={
                        template?.liveTemplate
                            ? trans('engage.templates.type.live')
                            : trans('engage.templates.type.transcoded')
                    }
                />
            )}
            <LibraryMediaDetailsDetail
                name="created"
                subject="common.detail.imported"
                value={new Date(item.created).toLocaleString()}
            />
            <LibraryMediaDetailsDetail
                name="modified"
                subject="common.detail.updated"
                value={new Date(item.modified).toLocaleString()}
            />
            <LibraryMediaDetailsDetail name="fileSize" subject="common.detail.filesize" value={getFileSize()} />
        </Box>
    );
};

export default LibraryMediaDetailsInformation;
