import React, { useCallback, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { ConfigModelsAttributeDetails } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useAttributes } from '@zetadisplay/engage-components/modules/players/hooks';
import { FilterFields, keywordFilter, useSearchFilters } from '@zetadisplay/engage-components/modules/search';
import { emitOnSearch, SearchEventScope } from '@zetadisplay/engage-components/modules/search/events';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Search } from '@zetadisplay/zeta-ui-components';
import { createButtonClickEvent, pushToDataLayer } from '@zetadisplay/zeta-ui-components/utils/data-layer';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

import { GROUP_SETUP_VIEW } from 'src/constants/Views';
import { emitOnAttributeConstraintAdded } from 'src/modules/groups/setup/events/onAttributeConstraintAddedEvent';
import LabelsLibraryList from 'src/modules/Labels/LabelsLibrary/Components/LabelsLibraryList';

const useStyles = makeStyles()(() => ({
    root: {
        height: '100%',
    },
    container: {
        height: 'calc(100vh - 210px)',
    },
    tab: {
        fontWeight: themeOptions.BOLD,
        fontSize: 12,
        lineHeight: '20px',
        letterSpacing: '0.4px',
        maxWidth: '50%',
        minWidth: 120,
        textTransform: 'uppercase',
        opacity: 0.5,
    },
    tabs: {
        marginBottom: 20,
    },
    tabIndicator: {
        opacity: 0.75,
    },
    search: {
        marginBottom: 20,
    },
}));

const LabelsLibrary = () => {
    const { classes } = useStyles();
    const t = useTranslation();

    const [searchFilters, searchFiltersKey] = useSearchFilters([SearchEventScope.LABEL]);
    const attributes = useAttributes({ key: GROUP_SETUP_VIEW, searchFilters, searchFiltersKey });

    const [tab, setTab] = useState(0);
    const changeTab = (newValue: number) => {
        setTab(newValue);
    };

    const handleAddLabel = useCallback((item: DiscriminatedEntity<ConfigModelsAttributeDetails>) => {
        emitOnAttributeConstraintAdded(item);
        pushToDataLayer(createButtonClickEvent('Group Settings', 'Add Label'));
    }, []);

    return (
        <div className={classes.root}>
            <Tabs
                className={classes.tabs}
                classes={{
                    indicator: classes.tabIndicator,
                }}
                id="tabs"
                onChange={(_, value) => changeTab(value)}
                textColor="secondary"
                value={tab}
                variant="fullWidth"
            >
                <Tab label={t.trans('engage.players.labels.library.title')} className={classes.tab} />
            </Tabs>

            {tab === 0 && (
                <>
                    <div className={classes.search}>
                        <Search
                            filters={{ [FilterFields.KEYWORD]: keywordFilter }}
                            fullWidth
                            onChangeCallback={(state) => emitOnSearch({ scope: SearchEventScope.LABEL, value: state })}
                        />
                    </div>
                    <div className={classes.container}>
                        <LabelsLibraryList
                            items={attributes?.data || []}
                            loading={attributes?.isLoading}
                            primaryAction={(item) => {
                                return {
                                    disabled: false,
                                    handler: () => handleAddLabel(item),
                                    label: `+ ${t.trans('common.action.add')}`,
                                };
                            }}
                            type="compact"
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default LabelsLibrary;
