import { useCallback } from 'react';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';

// This list should be coming from some Enum in the API client
export const availableFeatures = {
    playlistPriority: 'priority',
} as const;

export type AvailableFeatureKeys = keyof typeof availableFeatures;
export type AvailableFeatureType = (typeof availableFeatures)[AvailableFeatureKeys];

const useIsFeatureEnabled = () => {
    const { workspaceSettings } = useWorkspace();

    return useCallback(
        (feature: AvailableFeatureType) => {
            if (workspaceSettings?.featureSettings?.enabledFeatures) {
                return workspaceSettings.featureSettings.enabledFeatures.includes(feature);
            }

            return false;
        },
        [workspaceSettings?.featureSettings?.enabledFeatures]
    );
};

export default useIsFeatureEnabled;
