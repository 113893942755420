import React, { useEffect } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button, ComponentLoader } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import AddLabelValuePlayersModal, {
    PlayersSelectModalProps,
} from 'src/components/Modals/LabelSetup/AddLabelValuePlayersModal';
import { LabelValuePlayerState, LabelValuePlayerType, LabelValueState, LabelValueType } from 'src/views/LabelSetupView';
import LabelSetupValuePlayer from 'src/views/LabelSetupView/Components/LabelSetupMainForm/LabelSetupValues/LabelSetupValue/LabelSetupValuePlayers/LabelSetupValuePlayer';
import usePlayersByAttributeValue from 'src/views/LabelSetupView/Hooks/usePlayersByAttributeValue';

const useStyles = makeStyles()((theme) => ({
    addPlayersAction: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'right',
        marginBottom: 6,
    },
    content: {
        backgroundColor: theme.palette.background.contentSecondary,
        display: 'block',
        marginBottom: 8,
        padding: '8px 20px 12px',
    },
    subtitle: {
        fontSize: 12,
        lineHeight: '30px',
        marginLeft: 20,
    },
}));

type LabelSetupValuePlayersProps = {
    labelValue: LabelValueType;
    onAddLabelValuePlayers: (players: LabelValuePlayerType[]) => void;
    onLoadLabelValuePlayers: (players: LabelValuePlayerType[]) => void;
    onRemoveLabelValuePlayer: (player: LabelValuePlayerType) => void;
    onRestoreLabelValuePlayer: (player: LabelValuePlayerType) => void;
    removedPlayersFromAttributeValues: LabelValuePlayerType[];
    usedPlayersByAttributeValues: LabelValuePlayerType[];
};

const LabelSetupValuePlayers = ({
    labelValue,
    onAddLabelValuePlayers,
    onLoadLabelValuePlayers,
    onRemoveLabelValuePlayer,
    onRestoreLabelValuePlayer,
    removedPlayersFromAttributeValues,
    usedPlayersByAttributeValues,
}: LabelSetupValuePlayersProps) => {
    const { classes } = useStyles();
    const t = useTranslation();

    const existingPlayers = usePlayersByAttributeValue(
        labelValue.labelId,
        labelValue.state !== LabelValueState.NEW ? labelValue.id : undefined
    );

    const renderPlayers = () => {
        if (existingPlayers.isLoading) {
            return <ComponentLoader />;
        }

        if (labelValue.players.length === 0) {
            return <Typography>{t.trans('engage.players.content.no_players')}</Typography>;
        }

        return labelValue.players.map((player) => (
            <LabelSetupValuePlayer
                key={player.id}
                labelValue={labelValue}
                onRemoveLabelValuePlayer={onRemoveLabelValuePlayer}
                onRestoreLabelValuePlayer={onRestoreLabelValuePlayer}
                player={player}
                usedPlayersByAttributeValues={usedPlayersByAttributeValues}
            />
        ));
    };

    const showAddLabelValuePlayersModal = () => {
        NiceModal.show<LabelValuePlayerType[], PlayersSelectModalProps>(AddLabelValuePlayersModal, {
            allowPlayersWithMultipleChannels: false,
            allowPlayersWithoutChannel: false,
            allowSharedPlayers: false,
            attributeId: labelValue.labelId,
            currentPlayers: labelValue.players.filter((p) => p.state !== LabelValuePlayerState.DELETED),
            removedPlayersFromAttributeValues,
            usedPlayersByAttributeValues,
        }).then(onAddLabelValuePlayers);
    };

    useEffect(() => {
        if (
            labelValue.players.length !== 0 ||
            existingPlayers.isLoading ||
            existingPlayers.data === undefined ||
            existingPlayers.data.length === 0
        ) {
            return;
        }

        onLoadLabelValuePlayers(existingPlayers.data);
    }, [
        existingPlayers.data,
        existingPlayers.isLoading,
        labelValue,
        labelValue.players.length,
        onLoadLabelValuePlayers,
    ]);

    return (
        <Box>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <Typography variant="h5" className={classes.subtitle}>
                        {t.trans('engage.players.title')}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.addPlayersAction}>
                        <Button
                            label={`+ ${t.trans('engage.players.add_player')}`}
                            name="add-player"
                            onClick={showAddLabelValuePlayersModal}
                        />
                    </div>
                </Grid>
            </Grid>
            <div className={classes.content}>{renderPlayers()}</div>
        </Box>
    );
};

export default LabelSetupValuePlayers;
