import * as z from 'zod';
import { ZodType } from 'zod';

import {
    AssignedPowerScheduleType,
    SpecialDayData,
    SpecialPowerScheduleFormData,
} from 'src/views/PowerScheduleSetupView';
import {
    PowerScheduleAssignedPlayerState,
    SpecialDayState,
} from 'src/views/PowerScheduleSetupView/Utils/powerScheduleFormEnums';

const scheduleDaysSchema: ZodType<SpecialDayData> = z
    .object({
        name: z.string(),
        date: z.date().nullable(),
        startTime: z.date().nullable(),
        endTime: z.date().nullable(),
        enabled: z.boolean(),
        state: z.enum([SpecialDayState.NEW, SpecialDayState.EXISTING]),
    })
    .refine(
        (data) => {
            // CASE1: if date, startTime and endTime is null
            const { date, startTime, endTime } = data;
            return !(date === null && startTime === null && endTime === null);
        },
        { message: 'common.validation.required', path: ['date'] }
    )
    .refine(
        (data) => {
            // CASE1: if start time is empty but end time is not empty
            const { date, startTime, endTime } = data;
            if (date !== null && startTime === null && endTime === null) {
                return true;
            }
            return !(startTime === null && endTime !== null);
        },
        { message: 'common.validation.required', path: ['startTime'] }
    )
    .refine(
        (data) => {
            const { date, startTime, endTime } = data;
            // CASE2: if start time is not empty but end time is empty
            if (date !== null && startTime === null && endTime === null) {
                return true;
            }
            return !(startTime !== null && endTime === null);
        },
        { message: 'common.validation.required', path: ['endTime'] }
    );

const powerScheduleSchema: ZodType<AssignedPowerScheduleType> = z.object({
    id: z.number(),
    name: z.string().min(1, { message: 'Required' }),
    state: z.enum([PowerScheduleAssignedPlayerState.NEW, PowerScheduleAssignedPlayerState.DELETED]).optional(),

    startTimeMon: z.string().nullable(),
    endTimeMon: z.string().nullable(),

    startTimeTue: z.string().nullable(),
    endTimeTue: z.string().nullable(),

    startTimeWed: z.string().nullable(),
    endTimeWed: z.string().nullable(),

    startTimeThu: z.string().nullable(),
    endTimeThu: z.string().nullable(),

    startTimeFri: z.string().nullable(),
    endTimeFri: z.string().nullable(),

    startTimeSat: z.string().nullable(),
    endTimeSat: z.string().nullable(),

    startTimeSun: z.string().nullable(),
    endTimeSun: z.string().nullable(),

    discriminatorType: z.literal('PowerSchedule'),
});

const SpecialPowerScheduleSchema: ZodType<SpecialPowerScheduleFormData> = z.object({
    id: z.number(),
    name: z.string().min(1, { message: 'Required' }),
    specialDays: z.array(scheduleDaysSchema).optional(),
    powerSchedules: z.array(powerScheduleSchema).optional(),
});

export default SpecialPowerScheduleSchema;
