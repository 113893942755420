import { FieldArrayWithId, UseFieldArrayUpdate } from 'react-hook-form';

import { PlaylistSetupFormValues } from './createPlaylistFormValues';

const undoRemoveContentFromPlaylist =
    (
        fields: FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>[],
        update: UseFieldArrayUpdate<PlaylistSetupFormValues, 'playlistMediaCollection'>
    ) =>
    (item: FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>) => {
        const index = fields.findIndex((field) => field.id === item.id);

        update(index, { ...item, state: undefined });
    };

export default undoRemoveContentFromPlaylist;
