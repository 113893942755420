import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { handleResponseError } from '@zetadisplay/engage-components/utils/response';
import useNotify from '@zetadisplay/zeta-ui-components/hooks/use-notify';
import { AxiosError } from 'axios';

import { SpecialPowerScheduleFormData, SpecialPowerScheduleType } from 'src/views/PowerScheduleSetupView';

import createOrUpdateSpecialDayPowerSchedule from '../Actions/createOrUpdateSpecialDayPowerSchedule';

export type OnSubmittedActionCallback = (specialSchedule: SpecialPowerScheduleType) => void;

const useSpecialScheduleSubmitAction = (onSubmitted?: OnSubmittedActionCallback) => {
    const api = useApi();
    const notify = useNotify();
    const { workspace } = useWorkspace();
    const { reset } = useFormContext<SpecialPowerScheduleFormData>();

    return useCallback(
        async (data: SpecialPowerScheduleFormData) => {
            try {
                const specialPowerSchedule = await createOrUpdateSpecialDayPowerSchedule(
                    api,
                    data,
                    workspace.id,
                    notify
                );

                if (onSubmitted) {
                    onSubmitted(specialPowerSchedule);
                }

                reset();
            } catch (e) {
                if (!(e instanceof AxiosError)) {
                    throw e;
                }

                notify({ message: handleResponseError(e), variant: 'error' });
            }
        },
        [api, notify, onSubmitted, reset, workspace.id]
    );
};

export default useSpecialScheduleSubmitAction;
