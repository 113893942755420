import { useAsyncAbortable } from 'react-async-hook';
import { createDiscriminatedEntity, EntityDiscriminators } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';

const useMediaRelations = (mediaId: string) => {
    const api = useApi();
    const { workspace } = useWorkspace();

    return useAsyncAbortable(
        async (signal) => {
            return api.media
                .getMediaRelations(
                    {
                        mediaid: mediaId,
                        workspaceid: workspace.id,
                    },
                    { signal }
                )
                .then((response) => createDiscriminatedEntity(EntityDiscriminators.MediaFile, response.data));
        },
        [api.media, mediaId, workspace.id]
    );
};

export default useMediaRelations;
