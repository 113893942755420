import React, { useMemo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import {
    ConfigModelsGroupInfo,
    PublishingModelsCampaignCampaignPriorityEnum,
    PublishingModelsCampaignCampaignRules,
    PublishingModelsCampaignCampaignStatus,
} from '@zetadisplay/engage-api-client';
import { SelectInput } from '@zetadisplay/engage-components';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { TreeNode } from '@zetadisplay/engage-components/utils/tree-builder';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ComponentLoader, disabledDefaultSchedule, Schedule, Scheduling } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import FeatureFlag from 'src/components/FeatureFlag';
import Targeting from 'src/components/Group/Targeting';
import { availableFeatures } from 'src/hooks/useIsFeatureEnabled';
import { PlaylistSetupFormValues } from 'src/modules/playlist/utils/createPlaylistFormValues';
import createSchedulesFromPlaylist from 'src/modules/playlist/utils/createSchedulesFromPlaylist';
import { emitOnPlaylistScheduled } from 'src/views/PlaylistSetupView/Events/onPlaylistScheduledEvent';
import { emitOnPlaylistTargeted } from 'src/views/PlaylistSetupView/Events/onPlaylistTargetedEvent';
import usePlaylistModeOptions from 'src/views/PlaylistSetupView/Hooks/usePlaylistModeOptions';
import usePlaylistPriorityOptions from 'src/views/PlaylistSetupView/Hooks/usePlaylistPriorityOptions';

const useStyles = makeStyles()(() => ({
    loaderContainer: {
        display: 'flex',
        height: 'calc(100% - 72px)',
        justifyContent: 'center',
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflow: 'auto',
        maxHeight: '830px',
        paddingRight: '10px',
    },
    title: {
        margin: '12px 0',
    },
}));

type PlaylistSettingsValues = {
    playlistMode?: PublishingModelsCampaignCampaignRules;
    playlistPriority?: PublishingModelsCampaignCampaignPriorityEnum;
    groups?: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfo>, number>[];
    schedules?: Schedule[];
};

type Props = {
    isNewPlaylist: boolean;
};

const PlaylistSetupSettingsForm = ({ isNewPlaylist }: Props) => {
    const { classes, cx } = useStyles();
    const { getValues, watch } = useFormContext<PlaylistSetupFormValues>();
    const { isLoading } = useFormState<PlaylistSettingsValues>();
    const t = useTranslation();

    const playlistModeOptions = usePlaylistModeOptions();
    const playlistPriorityOptions = usePlaylistPriorityOptions();
    const formSchedules = watch('schedules');
    const groups = watch('groups');

    const schedules = useMemo(() => {
        const status = getValues('status');
        const convertedSchedules = createSchedulesFromPlaylist(formSchedules);

        // If playlist is "disabled", append a disabled schedule to visualise
        // the status to user. This may be removed later if we'll implement
        // explicit setting of the playlist status
        if (
            status === PublishingModelsCampaignCampaignStatus.New ||
            status === PublishingModelsCampaignCampaignStatus.Expired
        ) {
            convertedSchedules.push({ ...disabledDefaultSchedule });
        }

        return convertedSchedules;
    }, [formSchedules, getValues]);

    if (isLoading) {
        return (
            <div className={cx(classes.loaderContainer)}>
                <ComponentLoader />
            </div>
        );
    }

    return (
        <Box className={cx(classes.root)} data-testid="playlist-settings">
            <Box>
                <Typography className={cx(classes.title)} variant="h5">
                    {t.trans('engage.playlist.setup.scheduling.title')}
                </Typography>
                <Scheduling
                    disablePastDates={isNewPlaylist}
                    initialSchedules={(schedules && [...schedules.values()]) || undefined}
                    onChange={emitOnPlaylistScheduled}
                    multiple
                    showPlayingTimes
                    showWeekdays
                    useDateTime
                />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography className={cx(classes.title)} variant="h5">
                    {t.trans('engage.playlist.setup.targeting.title')}
                </Typography>
                <Targeting initialGroups={groups} onChange={emitOnPlaylistTargeted} />
            </Box>

            <FeatureFlag feature={availableFeatures.playlistPriority}>
                <Box>
                    <Typography className={cx(classes.title)} variant="h5">
                        {t.trans('engage.playlist.setup.priority.title')}
                    </Typography>
                    <SelectInput name="priority" options={playlistPriorityOptions} />
                </Box>
            </FeatureFlag>
            <Box>
                <Typography className={cx(classes.title)} variant="h5">
                    {t.trans('engage.playlist.setup.mode.title')}
                </Typography>
                <SelectInput name="mixingMode" options={playlistModeOptions} />
            </Box>
        </Box>
    );
};

export default PlaylistSetupSettingsForm;
