import { useCallback } from 'react';
import NiceModal, { NiceModalHocProps } from '@ebay/nice-modal-react';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Icon } from '@zetadisplay/zeta-ui-components';

import ConfirmPrompt from 'src/components/Modals/ConfirmPrompt';
import { SpecialDayData } from 'src/views/PowerScheduleSetupView';
import { SpecialDayState } from 'src/views/PowerScheduleSetupView/Utils/powerScheduleFormEnums';

export type RemoveSpecialScheduleValuePromptProps = {
    specialDay: SpecialDayData;
} & NiceModalHocProps;

const RemoveSpecialScheduleValuePrompt = NiceModal.create<RemoveSpecialScheduleValuePromptProps>(({ specialDay }) => {
    const t = useTranslation();

    const submitAction = useCallback(async () => {
        if (specialDay.state === SpecialDayState.NEW) {
            return specialDay;
        }
        return { ...specialDay };
    }, [specialDay]);

    return (
        <ConfirmPrompt
            description={t.trans('engage.modal.special_schedule.remove-special-day-value.description')}
            title={{
                icon: <Icon type="VALUE" />,
                label: 'engage.modal.special_schedule.remove-special-day-value.title',
            }}
            submit={{
                label: 'common.action.remove',
                name: 'remove',
            }}
            submitAction={submitAction}
        />
    );
});

export default RemoveSpecialScheduleValuePrompt;
