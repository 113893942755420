import { useMemo } from 'react';
import { SharedModelsLayoutRelations, SharedModelsLayoutZoneInfo } from '@zetadisplay/engage-api-client';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';

const usePlaylistLayout = (layoutZoneId?: number) => {
    const { workspaceLayouts } = useWorkspace();

    const layout: SharedModelsLayoutRelations | undefined = useMemo(() => {
        if (workspaceLayouts && workspaceLayouts?.length > 0 && layoutZoneId) {
            return workspaceLayouts?.filter((workspaceLayout) =>
                workspaceLayout.zones.some((zone) => zone.id === layoutZoneId)
            )[0];
        }

        return undefined;
    }, [layoutZoneId, workspaceLayouts]);

    const zone: SharedModelsLayoutZoneInfo | undefined = useMemo(() => {
        if (layout) {
            return layout?.zones?.find((layoutZone) => layoutZone.id === layoutZoneId);
        }

        return undefined;
    }, [layout, layoutZoneId]);

    return { layout, zone };
};

export default usePlaylistLayout;
