import React, { memo, useState } from 'react';
import { FabProps, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { Add, Edit as EditIcon } from '@styled-icons/material';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()((theme) => ({
    speedDial: {
        color: '#fff',
        right: 25,
        position: 'absolute',
        top: -15,
        zIndex: 1200,
        [theme.breakpoints.up('md')]: {
            top: -70,
            right: 220,
        },
        [theme.breakpoints.up('lg')]: {
            right: 152,
        },
    },
    'speedDial--template-library': {
        top: 172,
        right: 40,
        [theme.breakpoints.up('md')]: {
            right: 80,
        },
    },
    fab: {
        alignItems: 'center',
        display: 'inherit',
        justifyContent: 'center',
        opacity: '1.0 !important',
        '& > span': {
            width: '100%',
            display: 'inherit',
            alignItems: 'inherit',
            justifyContent: 'inherit',
        },
    },
}));

export type ViewAction = {
    disabled?: boolean;
    icon: React.ReactNode;
    label?: string;
    name: string;
    onClick: () => void;
};

type Props = {
    actions: ViewAction[];
    buttonProps?: Partial<FabProps>;
    context?: 'view' | 'template-library';
    label: string;
};

const ViewActions = ({ actions, buttonProps, context = 'view', label }: Props) => {
    const { classes, cx } = useStyles();
    const [menuOpen, setMenuOpen] = useState(false);

    const speedDialClasses = cx({
        [classes.speedDial]: true,
        [classes['speedDial--template-library']]: context === 'template-library',
    });

    return (
        <SpeedDial
            ariaLabel={label}
            className={speedDialClasses}
            data-testid="view-actions"
            direction="left"
            FabProps={{ ...buttonProps, disabled: actions.length === 0 }}
            icon={
                <SpeedDialIcon
                    color="#fff"
                    icon={<Add size={36} color="#fff" />}
                    openIcon={<EditIcon size={24} color="#fff" />}
                />
            }
            onBlur={() => setMenuOpen(false)}
            onClick={() => setMenuOpen(!menuOpen)}
            onClose={() => setMenuOpen(false)}
            onFocus={() => setMenuOpen(true)}
            onMouseEnter={() => setMenuOpen(true)}
            onMouseLeave={() => setMenuOpen(false)}
            open={menuOpen}
            transitionDuration={0}
        >
            {actions
                .filter((action) => action !== null)
                .map((action) => (
                    <SpeedDialAction
                        data-testid={`view-action-${action.name}`}
                        FabProps={{
                            classes: { root: classes.fab },
                            disabled: action.disabled,
                            name: action.name || action.label,
                        }}
                        key={action.name}
                        icon={action.icon}
                        onClick={() => action.onClick && action.onClick()}
                        title={action.label || action.name}
                        tooltipTitle={action.name}
                        tooltipPlacement="bottom"
                    />
                ))}
        </SpeedDial>
    );
};

export default memo(ViewActions);
