import React from 'react';
import { FilterFields, keywordFilter } from '@zetadisplay/engage-components/modules/search';
import { emitOnSearch, SearchEventScope } from '@zetadisplay/engage-components/modules/search/events';
import { Search } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import ColorExplosion from 'src/assets/images/ZD_PATTERN_2D_GREY_RGB.webp';

const useStyles = makeStyles()((theme) => ({
    root: {
        background: `url(${ColorExplosion}) no-repeat center center`,
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: 200,
        padding: '0 20%',
        width: '100%',
    },
    searchContainer: {
        backgroundColor: theme.palette.background.main,
    },
}));

const TemplateLibraryHeader = () => {
    const { classes } = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.searchContainer}>
                <Search
                    filters={{ [FilterFields.KEYWORD]: keywordFilter }}
                    fullWidth
                    onChangeCallback={(value) => {
                        emitOnSearch({ scope: SearchEventScope.TEMPLATE, value });
                    }}
                />
            </div>
        </div>
    );
};

export default TemplateLibraryHeader;
