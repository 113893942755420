import { EndpointsRootNetworkModelsPowerSchedulePowerScheduleInfo } from '@zetadisplay/engage-api-client';
import { ApiInterface } from '@zetadisplay/engage-components/modules/api';
import { NotifyFunctionType } from '@zetadisplay/zeta-ui-components/hooks/use-notify';

import handlePendingPromise, { PendingPromiseMessages } from 'src/utils/HandlePendingPromise';
import { PrimaryPowerScheduleFormData } from 'src/views/PowerScheduleSetupView';
import { PowerScheduleAssignedPlayerState } from 'src/views/PowerScheduleSetupView/Utils/powerScheduleFormEnums';

const assignPlayersToPowerSchedulePromise = (
    api: ApiInterface,
    data: PrimaryPowerScheduleFormData,
    powerSchedule: EndpointsRootNetworkModelsPowerSchedulePowerScheduleInfo,
    workspaceId: string
) => {
    const assignedPlayers = data.assignedPlayers || [];
    const players = assignedPlayers
        .filter((player) => player.state !== PowerScheduleAssignedPlayerState.DELETED)
        .map((player) => player.guid);

    return api.powerSchedules
        .assignPowerScheduleToPlayers({
            body: players,
            powerscheduleid: powerSchedule.id,
            workspaceid: workspaceId,
        })
        .then((response) => response.data);
};

const assignPlayersToPowerSchedule = (
    api: ApiInterface,
    data: PrimaryPowerScheduleFormData,
    powerSchedule: EndpointsRootNetworkModelsPowerSchedulePowerScheduleInfo,
    workspaceId: string,
    notify: NotifyFunctionType
) => {
    const messages: PendingPromiseMessages = {
        pending: {
            message: 'engage.notification.assign.power_schedule.info',
            params: [data.name],
        },
        success: {
            message: 'engage.notification.assign.power_schedule.success',
            params: [data.name],
        },
    };

    return handlePendingPromise(
        assignPlayersToPowerSchedulePromise(api, data, powerSchedule, workspaceId),
        messages,
        notify
    );
};

export default assignPlayersToPowerSchedule;
