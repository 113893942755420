import { useMemo } from 'react';
import { useAsyncAbortable } from 'react-async-hook';
import { ConfigModelsGroupInfo, NetworkModelsPlayerInformation } from '@zetadisplay/engage-api-client';
import { usePaginatedRequests, usePaginatedResults } from '@zetadisplay/engage-components/hooks';
import getResultData from '@zetadisplay/engage-components/hooks/utils/get-result-data';
import {
    createDiscriminatedEntity,
    DiscriminatedEntity,
    EntityDiscriminators,
} from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useSorting } from '@zetadisplay/engage-components/modules/options';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { TreeNode } from '@zetadisplay/engage-components/utils/tree-builder';

const usePlayersByGroups = (groups: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfo>, number>[], pageSize = 100) => {
    const api = useApi();
    const sorting = useSorting();
    const { workspace } = useWorkspace();

    const paginatedGroupsKey = useMemo(() => groups.map((group) => group.id).join(';'), [groups]);

    const { $top, $skip, getNextResultPage } = usePaginatedRequests(
        paginatedGroupsKey,
        undefined,
        sorting.sort,
        pageSize
    );

    const { results, removeResult, setResult, setResults, total } = usePaginatedResults<NetworkModelsPlayerInformation>(
        paginatedGroupsKey,
        EntityDiscriminators.Player,
        undefined
    );

    const players = useAsyncAbortable(
        async (signal) =>
            api.players
                .getPlayersByGroups(
                    {
                        ids: groups.map((group) => group.id),
                        workspaceid: workspace.id,
                        $top,
                        $skip,
                    },
                    { signal }
                )
                .then((response) =>
                    setResults(
                        response.data.items.map((item) => createDiscriminatedEntity(EntityDiscriminators.Player, item)),
                        response.data.total
                    )
                )
                .catch(() => {
                    // On API error, set and return current data to avoid erroneous UX state
                    // i.e. don't allow infinite loading to get stuck
                    const data = getResultData<NetworkModelsPlayerInformation>(results.current);
                    return setResults(data, data.length);
                }),
        [$skip, $top, api, groups, results, setResults, workspace.id],
        {
            setLoading: (state) => {
                return { ...state, result: results.current && [...results.current.values()], loading: true };
            },
        }
    );

    return {
        getNextResultPage,
        data: getResultData<NetworkModelsPlayerInformation>(results.current),
        isLoading: players.loading,
        removeResult,
        setResult,
        total: total.current,
    };
};

export default usePlayersByGroups;
