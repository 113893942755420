import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ViewHeader } from '@zetadisplay/engage-components';
import { useBreadcrumbs } from '@zetadisplay/engage-components/hooks';
import { useCurrentPlaylistFolder, usePlaylistContent } from '@zetadisplay/engage-components/modules/playlist';
import { createStatusFilter, FilterFields, keywordFilter } from '@zetadisplay/engage-components/modules/search';
import { emitOnSearch, SearchEventScope } from '@zetadisplay/engage-components/modules/search/events';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import {
    buildUuidNamePathNode,
    getRoutePathNodes,
    uuidNamePathNodeRegex,
} from '@zetadisplay/engage-components/utils/route';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import queryString, { ParsedQuery } from 'query-string';

import ViewToolbar from 'src/components/ViewToolbar';
import { MAX_ITEMS_PER_PAGE } from 'src/config/appConfig';
import { PLAYLISTS_BASE_PATH } from 'src/constants/Paths';
import useResetSearchOnLocationChange from 'src/hooks/Search/useResetSearchOnLocationChange';
import useViewTitle from 'src/hooks/useViewTitle';
import createRootNode from 'src/utils/breadcrumbs/createRootNode';
import withDefaultLayout from 'src/utils/Layout/withDefaultLayout';
import PlaylistViewActions from 'src/views/PlaylistView/Components/PlaylistViewActions';
import PlaylistViewList, { PlaylistViewListItems } from 'src/views/PlaylistView/Components/PlaylistViewList';
import usePlaylistViewEvents from 'src/views/PlaylistView/Hooks/usePlaylistViewEvents';
import { isEngagePlaylistStatus, statuses } from 'src/views/PlaylistView/Utils/contants';

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',

        [theme.breakpoints.up('lg')]: {
            paddingLeft: 148,
            paddingRight: 152,
        },
    },
}));

const engagePlaylistStatuses = statuses.filter((status) => isEngagePlaylistStatus(status.key));

export type PlaylistSearchQueryParams = ParsedQuery & {
    keyword?: string;
    status?: string;
};

const PlaylistView = () => {
    useViewTitle('engage.playlists.title');

    const { classes } = useStyles();
    const currentFolderId = useCurrentPlaylistFolder();
    const location = useLocation();
    const searchRef = useResetSearchOnLocationChange();
    const t = useTranslation();
    const { workspace } = useWorkspace();

    const searchQueryParams = useMemo(() => {
        return queryString.parse(location.search) as PlaylistSearchQueryParams;
    }, [location.search]);

    const { folders, playlists, getNextResultPage, total } = usePlaylistContent(currentFolderId, MAX_ITEMS_PER_PAGE);
    const rootNode = useMemo(
        () => createRootNode(workspace.id, workspace.name, PLAYLISTS_BASE_PATH),
        [workspace.id, workspace.name]
    );

    const pathNodes = getRoutePathNodes(location.pathname, uuidNamePathNodeRegex, buildUuidNamePathNode);
    const breadcrumbs = useBreadcrumbs(pathNodes, PLAYLISTS_BASE_PATH, rootNode, true);

    const items = useMemo(() => {
        return ((folders?.data || []) as PlaylistViewListItems).concat(playlists.data);
    }, [folders?.data, playlists.data]);

    usePlaylistViewEvents(folders, playlists);

    return (
        <main className={classes.root}>
            <PlaylistViewActions />
            <ViewHeader title="engage.playlists.title" />
            <ViewToolbar
                breadcrumbs={breadcrumbs}
                options={[]}
                ref={searchRef}
                renderReturnButton
                searchOptions={{
                    filters: {
                        [FilterFields.KEYWORD]: { ...keywordFilter, defaultValue: searchQueryParams.keyword },
                        [FilterFields.STATUS]: {
                            ...createStatusFilter(
                                t.trans('common.detail.status'),
                                engagePlaylistStatuses.map((status) => ({
                                    id: status.key,
                                    name: t.trans(status.label),
                                    value: status.literal,
                                }))
                            ),
                            defaultValue: searchQueryParams.status,
                        },
                    },
                    onChangeCallback: (value) => {
                        emitOnSearch({ scope: SearchEventScope.PLAYLIST | SearchEventScope.PLAYLIST_FOLDER, value });
                    },
                }}
            />
            <PlaylistViewList
                infiniteScrolling={!total || total > items.length}
                items={items}
                loading={folders.isLoading || playlists.isLoading}
                onInfiniteScroll={getNextResultPage}
                path={pathNodes}
            />
        </main>
    );
};

export default withDefaultLayout(PlaylistView);
