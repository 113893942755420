import React, { useCallback } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Box, Link, Typography } from '@mui/material';
import { LibraryModelsMedia, PublishingModelsCampaignCampaignBasic } from '@zetadisplay/engage-api-client';
import {
    createDiscriminatedEntity,
    DiscriminatedEntity,
    EntityDiscriminators,
} from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Icon } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import PlaylistPreviewSidekick from 'src/modules/playlist/sidekick/playlist-preview-sidekick/playlist-preview-sidekick';

import LibraryMediaDetailsSidekick from '../../../LibraryMediaDetailsSidekick';

const useStyles = makeStyles()(() => ({
    playlist: {
        display: 'flex',
        flex: 1,
    },
    root: {
        paddingTop: 10,
    },
    title: {
        marginBottom: 2,
    },
}));

type ExpandedDetailsPlaylistsProps = {
    playlists: PublishingModelsCampaignCampaignBasic[];
    media: DiscriminatedEntity<LibraryModelsMedia>;
};

const LibraryMediaDetailsPlaylists: React.FC<ExpandedDetailsPlaylistsProps> = ({ playlists, media }) => {
    const { classes } = useStyles();
    const { trans } = useTranslation();
    const { workspace } = useWorkspace();
    const api = useApi();
    const libraryMediaModal = useModal(LibraryMediaDetailsSidekick);
    const playlistPreviewModal = useModal(PlaylistPreviewSidekick);

    const onClosePlaylist = useCallback(() => libraryMediaModal.show({ item: media }), [libraryMediaModal, media]);

    const onClickPlaylist = useCallback(
        async (playlistId: number) => {
            const response = await api.playlists.getCampaignById({
                campaignid: playlistId,
                workspaceid: workspace.id,
            });
            const playlist = createDiscriminatedEntity(EntityDiscriminators.PlaylistFolder, response.data);
            libraryMediaModal.remove();
            await playlistPreviewModal.show({
                playlist,
                onClosePlaylist,
            });
        },
        [api.playlists, workspace.id, libraryMediaModal, playlistPreviewModal, onClosePlaylist]
    );

    const renderPlaylists = useCallback(() => {
        if (playlists.length === 0) {
            return <Typography>{trans('engage.players.details.playlist.no_playlists')}</Typography>;
        }

        return playlists.map((playlist) => (
            <Box className={classes.playlist} key={playlist.id}>
                <Icon type="PLAYLIST" />
                {playlist.workspaceId === workspace.id ? (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <Link data-testid="playlist-edit-link" onClick={() => onClickPlaylist(playlist.id)}>
                        {playlist.name}
                    </Link>
                ) : (
                    playlist.name
                )}
            </Box>
        ));
    }, [classes.playlist, playlists, trans, workspace.id, onClickPlaylist]);

    return (
        <Box className={classes.root}>
            <Typography className={classes.title} variant="h5">
                {trans('engage.library.media.details.usage.title')}
            </Typography>
            {renderPlaylists()}
        </Box>
    );
};

export default LibraryMediaDetailsPlaylists;
