import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { IconButton } from '@mui/material';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Icon } from '@zetadisplay/zeta-ui-components';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

import { PowerScheduleAssignedPlayerType } from 'src/views/PowerScheduleSetupView';
import { PowerScheduleAssignedPlayerState } from 'src/views/PowerScheduleSetupView/Utils/powerScheduleFormEnums';

import RemovePowerSchedulePlayerPrompt, {
    RemovePowerSchedulePlayerPromptProps,
} from '../../Modals/PowerScheduleSetup/RemovePowerSchedulePlayerPrompt';
import Tag from '../../Tag';

const { ORANGE, DARKGRAY } = themeOptions.colors;

const useStyles = makeStyles()(() => ({
    actions: {
        paddingLeft: 6,
    },
    tags: {
        display: 'flex',
    },
}));

type Props = {
    onRemovePowerSchedulePlayer: (player: PowerScheduleAssignedPlayerType) => void;
    onRestorePowerSchedulePlayer: (player: PowerScheduleAssignedPlayerType) => void;
    player: PowerScheduleAssignedPlayerType;
};

const PlayerTargetsPrimaryAction = ({ player, onRestorePowerSchedulePlayer, onRemovePowerSchedulePlayer }: Props) => {
    const { classes } = useStyles();
    const { trans } = useTranslation();

    const showRemovePlayerPrompt = () => {
        NiceModal.show<PowerScheduleAssignedPlayerType, RemovePowerSchedulePlayerPromptProps>(
            RemovePowerSchedulePlayerPrompt,
            {
                player,
            }
        ).then(onRemovePowerSchedulePlayer);
    };

    const renderStateTag = () => {
        if (player.state === undefined) {
            return null;
        }

        return player.state === PowerScheduleAssignedPlayerState.DELETED ? (
            <Tag type="RED" text={trans('engage.misc.deleted')} />
        ) : (
            <Tag type="DEFAULT" text={trans('engage.misc.new')} />
        );
    };

    const renderActionButton = () => {
        if (player.state !== PowerScheduleAssignedPlayerState.DELETED) {
            return (
                <IconButton data-testid={`remove-player-${player.id}`} onClick={showRemovePlayerPrompt} size="small">
                    <Icon type="DELETE" size={16} color={ORANGE} />
                </IconButton>
            );
        }

        return (
            <IconButton
                data-testid={`enable-player-${player.id}`}
                onClick={() => onRestorePowerSchedulePlayer(player)}
                size="small"
            >
                <Icon type="DELETE_RESTORE" size={16} color={DARKGRAY} />
            </IconButton>
        );
    };

    return (
        <div className={classes.tags} data-testid={`player-targets-item-actions-${player.name}`}>
            {renderStateTag()}
            <div className={classes.actions}>{renderActionButton()}</div>
        </div>
    );
};

export default PlayerTargetsPrimaryAction;
