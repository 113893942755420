import React from 'react';
import { CloseSharp } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

export type SidekickCloseProps = {
    onClose: () => void;
};

const SidekickClose: React.FC<SidekickCloseProps> = ({ onClose }) => {
    return (
        <IconButton
            onClick={onClose}
            data-testid="sidekick-close"
            sx={{
                '&:hover': {
                    backgroundColor: themeOptions.colors.CONTENT_DARK_HOVER,
                },
                backgroundColor: themeOptions.colors.CONTENT_DARK,
                marginRight: '10px',
            }}
        >
            <CloseSharp sx={{ color: themeOptions.colors.WHITE, fontSize: 16 }} />
        </IconButton>
    );
};

export default SidekickClose;
