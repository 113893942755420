import React from 'react';
import { FieldArrayWithId } from 'react-hook-form';
import { Box } from '@mui/material';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Tag } from '@zetadisplay/zeta-ui-components';

import { PlaylistSetupFormValues } from '../../utils/createPlaylistFormValues';

type Props = {
    item: FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>;
};

const PlaylistPreviewContentItemState = ({ item }: Props) => {
    const { trans } = useTranslation();

    return (
        <Box sx={{ display: 'flex', mr: 1 }}>
            {item.state === 'added' && <Tag text={trans('engage.misc.new')} type="GREEN" />}
            {item.state === 'removed' && <Tag text={trans('engage.misc.removed')} type="RED" />}
            {item.state === 'modified' && <Tag text={trans('common.detail.updated')} type="YELLOW" />}
        </Box>
    );
};

export default PlaylistPreviewContentItemState;
