import { useCallback } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { Edit, RestartAlt } from '@styled-icons/material-sharp';
import {
    NetworkModelsLifelineConnectionStatusEnum,
    NetworkModelsPlayerInformation,
    NetworkModelsPlayersPlayerRelations,
} from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity, isPlayer } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useUserInformation } from '@zetadisplay/engage-components/modules/auth';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ActionGroup } from '@zetadisplay/zeta-ui-components';
import { useNotify } from '@zetadisplay/zeta-ui-components/hooks';

import { emitOnPlayerSettingsUpdate } from 'src/events/player/onPlayerSettingsUpdateEvent';
import PlayerSettingsSidekick, {
    PlayerSettingsSidekickProps,
} from 'src/modules/player/sidekick/PlayerSettingsSidekick';
import getErrorMessage from 'src/utils/Response/GetErrorMessage';

const usePlayersViewListPlayerActions = (): ActionGroup<DiscriminatedEntity<NetworkModelsPlayerInformation>>[] => {
    const api = useApi();
    const notify = useNotify();
    const t = useTranslation();
    const { hasRole } = useUserInformation();
    const { workspace } = useWorkspace();

    const isSharedPlayer = useCallback(
        (player: DiscriminatedEntity<NetworkModelsPlayerInformation>) => workspace.id !== player.workspaceId,
        [workspace.id]
    );

    const handleEditAction = (player: DiscriminatedEntity<NetworkModelsPlayerInformation>) => {
        NiceModal.show<DiscriminatedEntity<NetworkModelsPlayersPlayerRelations>, PlayerSettingsSidekickProps>(
            PlayerSettingsSidekick,
            { player }
        ).then((response) => emitOnPlayerSettingsUpdate({ ...player, ...response }));
    };

    const handleRestartAction = (player: DiscriminatedEntity<NetworkModelsPlayerInformation>) => {
        api.remote
            .reboot({ playerid: player.id, workspaceid: workspace.id })
            .then(() =>
                notify({
                    message: 'engage.notification.player.reboot',
                    params: [player.friendlyName],
                    variant: 'info',
                })
            )
            .catch((e) => {
                notify({ message: getErrorMessage(e), variant: 'error' });
            });
    };

    return [
        {
            actions: [
                {
                    handler: handleEditAction,
                    icon: Edit,
                    isDisabled: (player) => {
                        return (
                            !hasRole(['ENGAGE_PLAYER_EDIT']) ||
                            isSharedPlayer(player) ||
                            player.channelIds?.length === 0
                        );
                    },
                    isVisible: isPlayer,
                    label: t.trans('common.action.edit'),
                    name: 'edit',
                },
                {
                    handler: handleRestartAction,
                    icon: RestartAlt,
                    isDisabled: (player) => {
                        return (
                            !hasRole(['ENGAGE_PLAYER_REBOOT']) ||
                            player.onlineState !== NetworkModelsLifelineConnectionStatusEnum.ONLINE
                        );
                    },
                    isVisible: isPlayer,
                    label: t.trans('engage.players.context.menu.reboot'),
                    name: 'reboot',
                },
            ],
            name: 'players-list-player-actions',
        },
    ];
};

export default usePlayersViewListPlayerActions;
