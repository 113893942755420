import React, { useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import {
    ConfigModelsGroupInfo,
    NetworkModelsLifelineConnectionStatusEnum,
    NetworkModelsPlayerInformation,
} from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { getIconType } from '@zetadisplay/engage-components/modules/view/utils';
import { TreeNode } from '@zetadisplay/engage-components/utils/tree-builder';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button, ItemList } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import PlayerItemStatus from 'src/views/PlayersView/Components/PlayerItemStatus';
import usePlayersByGroups from 'src/views/PlayersView/Hooks/usePlayersByGroups';

const COMPACT_HEIGHT = 54;
const MAX_VISIBLE_PLAYERS = 5;

type UseStylesProps = {
    playerCount: number;
};

const useStyles = makeStyles<UseStylesProps>()((theme, { playerCount }) => ({
    action: {
        marginLeft: 20,
    },
    subtitle: {
        height: 24,
        lineHeight: '19px',
    },
    subtitleText: {
        color: theme.palette.background.disabledText,
    },
    players: {
        height: playerCount * COMPACT_HEIGHT,
        maxHeight: MAX_VISIBLE_PLAYERS * COMPACT_HEIGHT,
        width: '100%',
    },
    player: {
        marginBottom: 4,
    },
    info: {
        fontSize: 12,
    },
}));

type Props = {
    groups: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfo>, number>[];
};

const TargetingResults = ({ groups }: Props) => {
    const players = usePlayersByGroups(groups);
    const t = useTranslation();
    const { classes } = useStyles({ playerCount: players.data.length });

    // Internal states
    const [open, setOpen] = useState(false);

    const renderPlayerStatus = useCallback((item: NetworkModelsPlayerInformation) => {
        return (
            <PlayerItemStatus
                status={
                    item.onlineState !== undefined
                        ? item.onlineState
                        : NetworkModelsLifelineConnectionStatusEnum.OFFLINE
                }
            />
        );
    }, []);

    const renderTargetingResults = () => {
        if (groups.length === 0) {
            return <Typography>{t.trans('engage.players.targeting.select_groups_to_see_results')}</Typography>;
        }

        if (players.data.length === 0) {
            return <Typography>{t.trans('engage.players.targeting.no_results')}</Typography>;
        }

        return null;
    };

    const renderTargetingMatches = () => {
        if (players.data.length === 0) {
            return null;
        }

        return (
            <Typography variant="body2" className={classes.subtitle}>
                {t.trans(
                    'engage.players.targeting.matches.info',
                    [players.total?.toString() || ''],
                    Boolean(players.total && players.total > 1)
                )}
                <Button onClick={() => setOpen(!open)} className={classes.action}>
                    {t.trans(open ? 'common.action.hide' : 'common.action.show')}
                </Button>
            </Typography>
        );
    };

    const renderTargetedPlayers = () => {
        if (players.data.length === 0 || !open) {
            return false;
        }

        return (
            <div className={classes.players}>
                <ItemList
                    infiniteScrolling={!players.total || players.total > players.data.length}
                    initialItemCount={0}
                    isLoadingMore={players.isLoading}
                    items={players.data || []}
                    ItemProps={{
                        clickable: false,
                        getItemIconType: getIconType,
                        renderStatus: renderPlayerStatus,
                        renderSubtitle: (item) => (
                            <Typography className={classes.subtitleText} noWrap title={item.name} variant="subtitle1">
                                {item.name}
                            </Typography>
                        ),
                        renderTitle: (item) => item.friendlyName,
                        showThumbnail: false,
                        showStatus: true,
                        type: 'compact',
                    }}
                    onInfiniteScroll={players.getNextResultPage}
                    overscan={0}
                />
            </div>
        );
    };

    return (
        <div>
            <Typography variant="h6">{t.trans('engage.players.title')}</Typography>
            {renderTargetingResults()}
            <Box sx={{ flexGrow: 1 }}>
                {renderTargetingMatches()}
                {renderTargetedPlayers()}
            </Box>
        </div>
    );
};

export default TargetingResults;
