import { createButtonClickEvent } from '@zetadisplay/zeta-ui-components/utils/data-layer';
import { DataLayerObject } from '@zetadisplay/zeta-ui-components/utils/data-layer/push-to-data-layer';

import { getPlayingModeIdentifier, getScheduleModeIdentifier } from '../../../utils/Analytics/analyticsUtils';
import { PlaylistSetupFormValues } from './createPlaylistFormValues';

const createButtonEvents = ({ schedules, status, mixingMode }: PlaylistSetupFormValues): DataLayerObject[] => {
    const schedule = schedules?.[0];

    return [
        createButtonClickEvent('Playlist Settings', 'Save'),
        createButtonClickEvent(
            'Playlist Settings',
            `Scheduling Type Chosen: ${getScheduleModeIdentifier(schedule, status)}`
        ),
        createButtonClickEvent('Playlist Settings', `Playlist Mode Chosen: ${getPlayingModeIdentifier(mixingMode)}`),
    ];
};

export default createButtonEvents;
