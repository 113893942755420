import {
    EndpointsRootPublishingModelsCampaignDaysOfWeek,
    PublishingModelsCampaignCampaignRules,
    PublishingModelsCampaignCampaignStatus,
} from '@zetadisplay/engage-api-client';
import { PublishingCampaignSchedulingSchedule } from '@zetadisplay/engage-api-client/models/publishing-campaign-scheduling-schedule';
import createDateFromPlayingTime from '@zetadisplay/engage-components/utils/scheduling/create-date-from-playing-time';

const getScheduleMode = (
    schedule: PublishingCampaignSchedulingSchedule | undefined,
    status: PublishingModelsCampaignCampaignStatus | undefined
): number => {
    if (status === PublishingModelsCampaignCampaignStatus.New) {
        return 0;
    }

    const endDate = schedule?.endDate ? new Date(schedule?.endDate) : null;
    const startDate = schedule?.startDate ? new Date(schedule?.startDate) : null;
    const endTime = schedule?.segment?.end ? createDateFromPlayingTime(schedule?.segment?.end) : null;
    const startTime = schedule?.segment?.start ? createDateFromPlayingTime(schedule?.segment?.start) : null;
    const daysOfWeek = schedule?.segment?.daysOfWeek || EndpointsRootPublishingModelsCampaignDaysOfWeek.AllDays;

    if (
        endDate ||
        startDate ||
        startTime ||
        endTime ||
        daysOfWeek !== EndpointsRootPublishingModelsCampaignDaysOfWeek.AllDays
    ) {
        return 2;
    }

    return 1;
};
export const getScheduleModeIdentifier = (
    schedule: PublishingCampaignSchedulingSchedule | undefined,
    status: PublishingModelsCampaignCampaignStatus | undefined
): string => {
    const mode = getScheduleMode(schedule, status);
    switch (mode) {
        case 0:
            return 'Disabled';
        case 2:
            return 'Scheduled';
        case 1:
        default:
            return 'Always';
    }
};

export const getPlayingModeIdentifier = (mode: PublishingModelsCampaignCampaignRules | undefined): string => {
    switch (mode) {
        case 1:
            return 'Shuffled';
        case 3:
            return 'Exclusive';
        case 4:
            return 'On-demand';
        case 2:
        default:
            return 'Grouped';
    }
};
