import { createEvent } from 'react-event-hook';
import { FieldArrayWithId } from 'react-hook-form';
import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';

import { PlaylistSetupFormValues } from '../../../utils/createPlaylistFormValues';

export type OnPlaylistContentEditedEventPayload = {
    item: FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>;
    data: DiscriminatedEntity<LibraryModelsMedia>;
};

export const { useOnPlaylistContentEditedListener, emitOnPlaylistContentEdited } =
    createEvent('onPlaylistContentEdited')<OnPlaylistContentEditedEventPayload>();
