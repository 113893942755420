import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useModal } from '@ebay/nice-modal-react';
import { Typography } from '@mui/material';
import { TextInput } from '@zetadisplay/engage-components';
import { usePendingPromise } from '@zetadisplay/engage-components/hooks';
import { PendingPromiseMessages } from '@zetadisplay/engage-components/hooks/use-pending-promise';
import {
    createDefaultButtons,
    Modal,
    ModalActionButton,
    ModalTitleProps,
} from '@zetadisplay/engage-components/modules/modal/components';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { DataLayerObject } from '@zetadisplay/zeta-ui-components/utils/data-layer/push-to-data-layer';

import { isSameValue } from 'src/utils/Form/validators';

export enum ConfirmPromptActions {
    COPY = 'copy',
    DELETE = 'delete',
    MOVE = 'move',
}

type ConfirmPromptProps<T> = {
    description: string;
    submit?: Partial<Pick<ModalActionButton, 'label' | 'name'>>;
    submitAction: () => Promise<T | undefined>;
    submitActionDataLayerEvent?: DataLayerObject;
    submitActionMessages?: PendingPromiseMessages;
    title?: ModalTitleProps;
    validate?: { label: string; value: ConfirmPromptActions };
};

const ConfirmPrompt = <T,>({
    description,
    submit = {
        label: 'common.action.save',
        name: 'save',
    },
    submitAction,
    submitActionDataLayerEvent,
    submitActionMessages,
    title,
    validate,
}: ConfirmPromptProps<T>) => {
    const modal = useModal();
    const methods = useForm({
        defaultValues: {
            confirmation: '',
        },
    });
    const t = useTranslation();

    const pendingPromise = usePendingPromise(submitAction, submitActionMessages, submitActionDataLayerEvent);
    const onSubmit = useCallback(async () => {
        const response = await pendingPromise();
        if (response === undefined) {
            return;
        }

        modal.resolve(response);
        modal.hide();
    }, [modal, pendingPromise]);

    return (
        <Modal
            actions={{
                buttons: createDefaultButtons({
                    cancel: {
                        disabled: methods.formState.isSubmitting,
                        onClick: modal.hide,
                    },
                    submit: {
                        busy: methods.formState.isSubmitting,
                        disabled: !methods.formState.isValid || methods.formState.isSubmitting,
                        label: submit.label,
                        name: submit.name,
                        onClick: methods.handleSubmit(onSubmit),
                    },
                }),
            }}
            dark
            title={title}
        >
            <FormProvider {...methods}>
                <Typography sx={{ paddingBottom: validate ? '15px' : '0px' }}>{description}</Typography>
                {validate && (
                    <Typography sx={{ paddingBottom: validate ? '15px' : '0px' }}>
                        {t.trans('engage.modal.delete.content.confirmation', [
                            t.trans(`common.action.${validate.value}`).toUpperCase(),
                        ])}
                    </Typography>
                )}
                {validate && (
                    <form autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
                        <TextInput
                            defaultLabel={validate.label}
                            name="confirmation"
                            rules={{
                                required: 'common.validation.required',
                                validate: {
                                    isSameValue: isSameValue(t.trans(`common.action.${validate.value}`).toUpperCase()),
                                },
                            }}
                        />
                    </form>
                )}
            </FormProvider>
        </Modal>
    );
};

export default ConfirmPrompt;
