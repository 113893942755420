import React, { useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { NetworkModelsPlayerInformation } from '@zetadisplay/engage-api-client';
import { PlaylistPreview } from '@zetadisplay/engage-components';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { PlaylistMediaType } from '@zetadisplay/engage-components/modules/playlist';
import { createInternalMediaUuid } from '@zetadisplay/engage-components/utils/media';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import { colors } from '@zetadisplay/zeta-ui-components/utils/theme/theme-options';

import PlayerItemDetailsPlaylistDetails from 'src/views/PlayersView/Components/PlayerItemDetails/PlayerItemDetailsPlaylistDetails';
import PlayerItemDetailsPlaylistSkeleton from 'src/views/PlayersView/Components/PlayerItemDetails/PlayerItemDetailsPlaylistSkeleton';
import usePlayerPlaylist from 'src/views/PlayersView/Hooks/usePlayerPlaylist';

import useResolvePlaylistMediaCollection from '../../../../hooks/useResolvePlaylistMediaCollection';

const useStyles = makeStyles()(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 10,
        paddingTop: 10,
    },
    title: {
        marginBottom: 2,
    },
    text: {
        display: 'inline-block',
        fontSize: 12,
        lineHeight: '16px',
        marginBottom: 8,
    },
    previewContainer: {
        border: `1px solid ${colors.LIGHTGRAY}`,
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        position: 'relative',
    },
    skeleton: {
        paddingTop: 4,
        border: `1px solid ${colors.LIGHTGRAY}`,
        borderRadius: 8,
    },
}));

type Props = {
    item: DiscriminatedEntity<NetworkModelsPlayerInformation>;
};

const PlayerItemDetailsPlaylist = ({ item }: Props) => {
    const { classes } = useStyles();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const playlist = usePlayerPlaylist(item, selectedDate);
    const t = useTranslation();

    /**
     * Build campaignMedia compatible object that will be resolved in useResolvePlaylistMediaCollection since the media object
     * sent by backend is incomplete and missing properties.
     *
     * If backend gave us complete object we could just use that instead of resolving files 1 by 1 async.
     */
    const channelMedia = useMemo(() => {
        const channel = playlist.result?.channels?.[0];
        if (channel === undefined) {
            return undefined;
        }

        return channel.media?.map((entry, index) => ({
            id: createInternalMediaUuid(entry.id, index),
            mediaId: entry.id,
            mediaSchedules: [],
            model: undefined,
        }));
    }, [playlist.result?.channels]);

    const [playlistMediaCollection] = useResolvePlaylistMediaCollection(channelMedia);

    const renderPlayerPlaylists = () => {
        if (playlist.loading) {
            return (
                <div className={classes.skeleton}>
                    <PlayerItemDetailsPlaylistSkeleton />
                </div>
            );
        }

        if (
            playlist.result?.campaigns === null ||
            playlist.result?.campaigns === undefined ||
            playlist.result?.campaigns.length === 0
        ) {
            return (
                <Typography className={classes.text}>
                    {t.trans('engage.players.details.playlist.no_playlists')}
                </Typography>
            );
        }

        // TODO: Maybe instead of mapping campaigns to single details component render expandable list here?
        return playlist.result.campaigns.map((entry) => (
            <PlayerItemDetailsPlaylistDetails key={entry.id} playlist={entry} />
        ));
    };

    const renderTotalPlaylists = () => {
        if (!playlist.loading && playlist.result?.campaigns !== null && playlist.result?.campaigns !== undefined) {
            return (
                <Typography className={classes.title} variant="h5">
                    {t.trans('engage.players.details.playlist.title')} ({playlist.result?.campaigns.length})
                </Typography>
            );
        }
        return (
            <Typography className={classes.text}>{t.trans('engage.players.details.playlist.no_playlists')}</Typography>
        );
    };

    const handleAcceptDate = (date: Date | null) => {
        setSelectedDate(date ?? new Date());
    };

    return (
        <div className={classes.container} data-testid="player-playlists">
            <div className={classes.previewContainer} data-testid="player-currently-playing-content">
                <PlaylistPreview
                    loopPlaylist
                    initialMedia={playlistMediaCollection[0] as PlaylistMediaType}
                    isLoadingPlaylist={playlist.loading}
                    medias={playlistMediaCollection}
                    onAcceptValue={handleAcceptDate}
                    selectedDate={selectedDate}
                />
            </div>

            <div className={classes.container} data-testid="player-content">
                {renderTotalPlaylists()}
                {renderPlayerPlaylists()}
            </div>
        </div>
    );
};

export default PlayerItemDetailsPlaylist;
