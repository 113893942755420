import { Box, Skeleton } from '@mui/material';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button } from '@zetadisplay/zeta-ui-components';
import { themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

const PlayerSettingsChannelLabelsAppendLabel: React.FC = () => {
    const { trans } = useTranslation();
    return (
        <Box
            sx={{
                alignItems: 'center',
                backgroundColor: themeOptions.colors.WHITE,
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'space-between',
                margin: '10px 0 0 0',
                padding: '8px 16px',
            }}
        >
            <Skeleton variant="text" width="100px" />
            <Button disabled kind="primaryAction" label={trans('common.action.add')} sx={{ borderRadius: '8px' }} />
        </Box>
    );
};

export default PlayerSettingsChannelLabelsAppendLabel;
