import React from 'react';
import { Controller } from 'react-hook-form';
import { Schedule, Scheduling } from '@zetadisplay/zeta-ui-components';
import {
    MODE_NAME_ALWAYS,
    MODE_NAME_SCHEDULED,
} from '@zetadisplay/zeta-ui-components/components/scheduling/components/modes';

type Props = {
    mediaSchedules?: Schedule[];
};

const SchedulingForm = ({ mediaSchedules = [] }: Props) => {
    return (
        <Controller
            defaultValue={mediaSchedules}
            name="schedules"
            render={({ field: { onChange } }) => (
                <Scheduling
                    allowedModes={[MODE_NAME_ALWAYS, MODE_NAME_SCHEDULED]}
                    initialSchedules={mediaSchedules}
                    multiple
                    onChange={onChange}
                    showPlayingTimes
                    showWeekdays
                    useDateTime
                />
            )}
        />
    );
};

export default SchedulingForm;
