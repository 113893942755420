import { UseFieldArrayAppend } from 'react-hook-form';
import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { ApiInterface } from '@zetadisplay/engage-components/modules/api';
import { NotifyFunctionType } from '@zetadisplay/zeta-ui-components/hooks/use-notify';
import { createButtonClickEvent, pushToDataLayer } from '@zetadisplay/zeta-ui-components/utils/data-layer';
import pLimit from 'p-limit';

import { createPlaylistMedia } from '../../../views/PlaylistView/Utils/withMediaResolver';
import getPlaylistMediaDefaultSchedules from '../api/get-playlist-media-default-schedules';
import { PlaylistSetupFormValues } from './createPlaylistFormValues';

const limit = pLimit(4);

const appendContentOnPlaylist =
    (
        append: UseFieldArrayAppend<PlaylistSetupFormValues, 'playlistMediaCollection'>,
        api: ApiInterface,
        workspaceId: string,
        notify?: NotifyFunctionType
    ) =>
    async (content: DiscriminatedEntity<LibraryModelsMedia>[]) => {
        const mediaToAppend = await Promise.all(
            content.map((media) =>
                limit(async () => {
                    const playlistMedia = createPlaylistMedia(media);

                    if (media.scheduleIds?.length) {
                        playlistMedia.mediaSchedules = await getPlaylistMediaDefaultSchedules(
                            media.id,
                            api,
                            workspaceId,
                            undefined,
                            notify
                        );
                    }

                    return playlistMedia;
                })
            )
        );

        append(mediaToAppend);

        pushToDataLayer(createButtonClickEvent('Playlist Settings', 'Add Content'));
    };

export default appendContentOnPlaylist;
