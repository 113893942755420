import React from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import { TextInput } from '@zetadisplay/engage-components';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { PlaylistSetupFormValues } from 'src/modules/playlist/utils/createPlaylistFormValues';
import PlaylistSetupLayoutSelect from 'src/views/PlaylistSetupView/Components/PlaylistSetupInitializeForm/PlaylistSetupLayoutSelect';

const useStyles = makeStyles()((theme) => ({
    root: {
        height: '100%',
        paddingTop: 20,
        width: '100%',

        [theme.breakpoints.up('md')]: {
            width: '60%',
        },
    },
    subtitle: {
        fontSize: 15,
        textTransform: 'uppercase',
        textAlign: 'center',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 24,
    },
}));

type Props = {
    onSubmit: () => void;
    onCancel: () => void;
};

const PlaylistSetupInitializeForm = ({ onCancel, onSubmit }: Props) => {
    const { classes, cx } = useStyles();
    const t = useTranslation();
    const { getValues } = useFormContext<PlaylistSetupFormValues>();
    const { errors } = useFormState<PlaylistSetupFormValues>();

    return (
        <div className={cx(classes.root)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h3" className={cx(classes.subtitle)}>
                        {t.trans('engage.playlist.setup.initialize.title')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextInput
                        name="name"
                        defaultLabel="common.form.input.name"
                        error={errors?.name}
                        fullWidth
                        rules={{
                            required: 'common.validation.required',
                            validate: {
                                maxLength: (value: string) =>
                                    value.length <= 50 ? true : 'common.validation.maxLength',
                            },
                        }}
                    />
                </Grid>

                <PlaylistSetupLayoutSelect />

                <Grid item xs={12}>
                    <div className={cx(classes.buttons)}>
                        <Button kind="secondaryAction" label={t.trans('common.action.cancel')} onClick={onCancel} />
                        <Button
                            disabled={!getValues('layoutZoneId')}
                            kind="primaryAction"
                            label={t.trans('common.action.next')}
                            name="next"
                            onClick={onSubmit}
                            data-testid="next-button"
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default PlaylistSetupInitializeForm;
