import React, { useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Grid, Link, Typography } from '@mui/material';
import { usePlaylists } from '@zetadisplay/engage-components/modules/playlist';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import {
    buildUuidNamePathNode,
    getRoutePathNodes,
    uuidNamePathNodeRegex,
} from '@zetadisplay/engage-components/utils/route';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import PlaylistViewList, { PlaylistViewListItems } from 'src/views/PlaylistView/Components/PlaylistViewList';

const useStyles = makeStyles()((theme) => ({
    link: {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        color: theme.palette.text.primary,
        display: 'inline-block',
        fontSize: 14,
        fontWeight: 700,
        lineHeight: '18px',
        letterSpacing: '0.47px',
        paddingBottom: 4,
        textTransform: 'uppercase',
        verticalAlign: 'sub',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    linkGrid: {
        alignItems: 'center',
        textAlign: 'right',
    },
}));

const RecentlyModifiedPlaylists = () => {
    const { classes, cx } = useStyles();
    const t = useTranslation();
    const { workspace } = useWorkspace();

    // Use workspace id in place of folder id to reset pagination cache when switching workspaces
    const playlists = usePlaylists({ folderId: workspace.id, limit: 2, orderby: 'modified desc' });
    const location = useLocation();
    const pathNodes = getRoutePathNodes(location?.pathname, uuidNamePathNodeRegex, buildUuidNamePathNode);

    const items = useMemo(() => {
        const result: PlaylistViewListItems = [];
        if (!playlists.data) {
            return result;
        }

        return playlists.data;
    }, [playlists.data]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <Typography variant="h2" noWrap>
                    {t.trans('engage.playlist.recently_modified.title')}
                </Typography>
            </Grid>
            <Grid item xs={6} className={cx(classes.linkGrid)}>
                <Link className={cx(classes.link)} to="/playlists" component={RouterLink}>
                    {t.trans('engage.playlist.all_playlists')}
                </Link>
            </Grid>
            <Grid item xs={12}>
                <PlaylistViewList
                    calculateFixedHeight
                    items={items}
                    loading={playlists.isLoading}
                    path={pathNodes}
                    scrollingDisabled
                    skeletons={2}
                />
            </Grid>
        </Grid>
    );
};

export default RecentlyModifiedPlaylists;
