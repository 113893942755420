import React from 'react';
import { Grid, Typography } from '@mui/material';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import {
    NetworkModelsLifelineConnectionStatusEnum,
    NetworkModelsPlayerInformation,
} from '@zetadisplay/engage-api-client';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ComponentLoader } from '@zetadisplay/zeta-ui-components';
import color from 'color';

import { GOOGLE_MAPS_API_KEY } from 'src/config/appConfig';
import { statusColors } from 'src/constants/Players/PlayerStatus';

import usePlayerLocations from '../Hooks/usePlayerLocations';

const containerStyle = {
    width: '100%',
    height: '300px',
};

const center = {
    lat: 55.6,
    lng: 13.05,
};

export type PlayerLocation = {
    id: number;
    lat: number;
    lng: number;
    name: string;
    status: NetworkModelsLifelineConnectionStatusEnum;
};

const getPlayerOnlineState = (onlineState?: NetworkModelsLifelineConnectionStatusEnum) => {
    if (onlineState === undefined) {
        return NetworkModelsLifelineConnectionStatusEnum.NEW;
    }
    return onlineState;
};

const getLocationMarker = (location: PlayerLocation) => {
    const markerDotImage = 'assets/icons/spotlight/spotlight_pin_v4_dot-1-small.png';
    const markerOutlineImage = 'assets/icons/spotlight/spotlight_pin_v4_outline-1-small.png';
    const markerPinImage = 'assets/icons/spotlight/spotlight_pin_v4-1-small.png';

    const markerAccentColor = color(statusColors[location.status]).darken(0.6).hex();
    const markerPinColor = statusColors[location.status];

    return `https://mt.google.com/vt/icon/name=${[markerOutlineImage, markerPinImage, markerDotImage].join(
        ','
    )}&highlight=${[
        markerAccentColor.replace('#', ''),
        markerPinColor.replace('#', ''),
        markerAccentColor.replace('#', ''),
    ].join(',')}`;
};

type Props = {
    isLoading?: boolean;
    players: NetworkModelsPlayerInformation[];
};

const NetworkMap = ({ isLoading, players }: Props) => {
    const t = useTranslation();
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    });

    const locations = usePlayerLocations(players);

    const getCenterLocation = () => {
        const location = locations.result?.[0];

        return location ? { lat: location.lat as number, lng: location.lng as number } : center;
    };

    const renderGoogleMap = () => {
        if (isLoading || !isLoaded || locations.loading) {
            return <ComponentLoader />;
        }

        return (
            <GoogleMap
                center={getCenterLocation()}
                mapContainerStyle={containerStyle}
                options={{ fullscreenControl: false, mapTypeControl: false, streetViewControl: false }}
                zoom={6}
            >
                {locations.result &&
                    locations.result.map((location) => (
                        <Marker icon={getLocationMarker(location)} key={location.id} position={location} />
                    ))}
            </GoogleMap>
        );
    };

    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <Typography variant="h2">{t.trans('engage.dashboard.network.map.title')}</Typography>
            </Grid>
            <Grid item>{renderGoogleMap()}</Grid>
        </Grid>
    );
};

export default NetworkMap;
export { getPlayerOnlineState };
