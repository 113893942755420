import { useCallback, useMemo } from 'react';
import { PublishingModelsCampaignCampaignRules } from '@zetadisplay/engage-api-client';
import { SelectOption } from '@zetadisplay/engage-components';
import { useTranslation } from '@zetadisplay/zeta-localization';

import useIsFeatureEnabled, { availableFeatures } from 'src/hooks/useIsFeatureEnabled';

const usePlaylistModeOptions = () => {
    const isFeatureEnabled = useIsFeatureEnabled();
    const t = useTranslation();

    const optionFilter = useCallback(
        (option: SelectOption) => {
            if (option.id === PublishingModelsCampaignCampaignRules.Exclusive) {
                return !isFeatureEnabled(availableFeatures.playlistPriority);
            }

            return true;
        },
        [isFeatureEnabled]
    );

    return useMemo(() => {
        return [
            {
                id: PublishingModelsCampaignCampaignRules.Shuffled,
                name: t.trans('engage.playlist.setup.mode.option.shuffled'),
            },
            {
                id: PublishingModelsCampaignCampaignRules.Grouped,
                name: t.trans('engage.playlist.setup.mode.option.grouped'),
            },
            {
                id: PublishingModelsCampaignCampaignRules.Exclusive,
                name: t.trans('engage.playlist.setup.mode.option.exclusive'),
            },
            {
                id: PublishingModelsCampaignCampaignRules.OnDemand,
                name: t.trans('engage.playlist.setup.mode.option.on-demand'),
            },
        ].filter(optionFilter);
    }, [optionFilter, t]);
};

export default usePlaylistModeOptions;
