import {
    PublishingCampaignSchedulingCampaignInfoWithMediaSchedules,
    PublishingCampaignSchedulingCampaignMediaSchedule,
    PublishingCampaignSchedulingSchedule,
} from '@zetadisplay/engage-api-client';
import { createScheduleFromEngageSchedule } from '@zetadisplay/engage-components/modules/playlist';
import { Schedule } from '@zetadisplay/zeta-ui-components';

export type PublishingCampaignScheduleTypes =
    | PublishingCampaignSchedulingSchedule
    | PublishingCampaignSchedulingCampaignMediaSchedule
    | PublishingCampaignSchedulingCampaignInfoWithMediaSchedules;

/**
 * This function takes playlist(s) or Engage schedule(s) and returns an array of ZUIC compatible Schedules
 * TODO Consider renaming this function or separating the concerns (not mixing input types)
 * @param items
 */
const createSchedulesFromPlaylist = (
    items?: PublishingCampaignScheduleTypes | PublishingCampaignScheduleTypes[] | null
): Schedule[] => {
    if (items === null || items === undefined) {
        return [createScheduleFromEngageSchedule({ id: 0, endDate: null, startDate: null })];
    }

    const itemsArray = Array.isArray(items) ? items : [items];

    let schedulesArray: (PublishingCampaignSchedulingSchedule | PublishingCampaignSchedulingCampaignMediaSchedule)[] =
        [];

    itemsArray.forEach((item) => {
        if ('schedules' in item) {
            const { schedules } = item as PublishingCampaignSchedulingCampaignInfoWithMediaSchedules;

            if (schedules) {
                schedulesArray = schedulesArray.concat(schedules);
            }
        } else {
            schedulesArray.push(item);
        }
    });

    return schedulesArray.map(createScheduleFromEngageSchedule);
};

export default createSchedulesFromPlaylist;
