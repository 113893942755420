import { useMemo } from 'react';
import { FieldArrayWithId, useFieldArray, UseFieldArrayAppend, useWatch } from 'react-hook-form';
import { ConfigModelsGroupPersistModel } from '@zetadisplay/engage-api-client/models/config-models-group-persist-model';

type AttributesConstraintsPath = `attributesConstraints`;

const useAttributesConstraintsArray = (): [
    FieldArrayWithId<ConfigModelsGroupPersistModel, 'attributesConstraints', 'key'>[],
    UseFieldArrayAppend<ConfigModelsGroupPersistModel, 'attributesConstraints'>,
    (index?: number | number[]) => void
] => {
    const watchedFields = useWatch<ConfigModelsGroupPersistModel, AttributesConstraintsPath>({
        defaultValue: [],
        name: 'attributesConstraints',
    });

    const { fields, append, remove } = useFieldArray<ConfigModelsGroupPersistModel, AttributesConstraintsPath, 'key'>({
        name: 'attributesConstraints',
        keyName: 'key',
        rules: {
            required: false,
        },
    });

    const controlledFields = useMemo(
        () =>
            fields.map((field, index) => {
                if (watchedFields?.[index]) {
                    return { ...field, ...watchedFields[index] };
                }

                return field;
            }),
        [fields, watchedFields]
    );

    return [controlledFields, append, remove];
};

export default useAttributesConstraintsArray;
