import React, { useMemo } from 'react';
import NiceModal, { NiceModalHocProps } from '@ebay/nice-modal-react';
import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { Sidekick, SidekickProps } from '@zetadisplay/engage-components/modules/sidekick/components';

import SidekickClose from '../../../components/Sidekicks/SideKickClose';
import LibraryMediaDetails from './LibraryMediaDetailsSidekick/components/LibraryMediaDetails';
import LibraryMediaDetailsHeader from './LibraryMediaDetailsSidekick/components/LibraryMediaDetailsHeader';

export type LibraryMediaDetailsSidekickProps = {
    item: DiscriminatedEntity<LibraryModelsMedia>;
} & NiceModalHocProps;

const LibraryMediaDetailsSidekick = NiceModal.create<LibraryMediaDetailsSidekickProps>(({ item }) => {
    const sidekickHeader = useMemo(
        (): SidekickProps['header'] => ({
            renderHeader: () => <LibraryMediaDetailsHeader libraryMedia={item} />,
            renderClose: (onClose) => <SidekickClose onClose={onClose} />,
        }),
        [item]
    );

    return (
        <Sidekick header={sidekickHeader} dark={false} drawerWidth={700}>
            <LibraryMediaDetails item={item} />
        </Sidekick>
    );
});

export default LibraryMediaDetailsSidekick;
