import { capitalize } from 'lodash';

export enum PowerScheduleWeekdayLong {
    Monday = 'monday',
    Tuesday = 'tuesday',
    Wednesday = 'wednesday',
    Thursday = 'thursday',
    Friday = 'friday',
    Saturday = 'saturday',
    Sunday = 'sunday',
}

export type PowerScheduleWeekdayLongType = keyof typeof PowerScheduleWeekdayLong;

export enum PowerScheduleWeekdayShort {
    Monday = 'mon',
    Tuesday = 'tue',
    Wednesday = 'wed',
    Thursday = 'thu',
    Friday = 'fri',
    Saturday = 'sat',
    Sunday = 'sun',
}

export const getPowerScheduleWeekdayKey = (
    value: PowerScheduleWeekdayLong
): PowerScheduleWeekdayLongType | undefined => {
    return Object.entries(PowerScheduleWeekdayLong).find(
        ([, val]) => val === value
    )?.[0] as PowerScheduleWeekdayLongType;
};

export const getPowerScheduleCapitalWeekdayShort = (key: PowerScheduleWeekdayLongType) => {
    return capitalize(PowerScheduleWeekdayShort[key]) as Capitalize<PowerScheduleWeekdayShort>;
};
