import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import { NetworkModelsPlayersPlayerRelations } from '@zetadisplay/engage-api-client';

import PlayerSettingsChannelContext from './PlayerSettingsChannels/PlayerSettingsChannelContext';
import PlayerSettingsChannelForm from './PlayerSettingsChannels/PlayerSettingsChannelForm';

const PlayerSettingsChannels = () => {
    const { watch } = useFormContext<NetworkModelsPlayersPlayerRelations>();
    const [currentChannelIndex, setCurrentChannelIndex] = useState<number>(0);

    const channelContextValues = useMemo(() => ({ currentChannelIndex }), [currentChannelIndex]);
    const channels = watch('channels') || [];
    const shouldRenderTabs = channels && channels.length > 1;

    if (!shouldRenderTabs) {
        return (
            <PlayerSettingsChannelContext.Provider value={channelContextValues}>
                <PlayerSettingsChannelForm />
            </PlayerSettingsChannelContext.Provider>
        );
    }

    return (
        <PlayerSettingsChannelContext.Provider value={channelContextValues}>
            <TabContext value={currentChannelIndex.toString()}>
                <TabList
                    data-testid="player-channels"
                    onChange={(_, value) => setCurrentChannelIndex(value)}
                    variant="fullWidth"
                >
                    {channels.map((channel, idx) => (
                        <Tab
                            data-testid={`player-channel-tab-${channel.id}`}
                            key={channel.id}
                            label={channel.name}
                            value={idx.toString()}
                        />
                    ))}
                </TabList>
                {channels.map((channel, idx) => (
                    <TabPanel
                        data-testid={`player-channel-${channel.id}`}
                        key={channel.id}
                        sx={{ padding: 'unset' }}
                        value={idx.toString()}
                    >
                        <PlayerSettingsChannelForm />
                    </TabPanel>
                ))}
            </TabContext>
        </PlayerSettingsChannelContext.Provider>
    );
};

export default PlayerSettingsChannels;
