import { useState } from 'react';
import { useAsyncAbortable } from 'react-async-hook';
import { EndpointsRootNetworkModelsPowerScheduleSpecialDayGroupRelations } from '@zetadisplay/engage-api-client/models/endpoints-root-network-models-power-schedule-special-day-group-relations';
import { usePaginatedRequests, usePaginatedResults } from '@zetadisplay/engage-components/hooks';
import getResultData from '@zetadisplay/engage-components/hooks/utils/get-result-data';
import { EntityDiscriminators, SortableSort } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { getOrderByProperties } from '@zetadisplay/engage-components/modules/options';
import {
    hasSearchEventScope,
    SearchEventScope,
    useOnSearchListener,
} from '@zetadisplay/engage-components/modules/search/events';
import { getQueryFilter } from '@zetadisplay/engage-components/modules/search/query-filters';
import { createKeywordFilterQuery } from '@zetadisplay/engage-components/modules/search/utils';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { SearchFilters, SearchState } from '@zetadisplay/zeta-ui-components';
import objectHash from 'object-hash';

const useSpecialDayGroup = (pageSize?: number) => {
    const api = useApi();
    const { workspace } = useWorkspace();

    const [searchFilters, setSearchFilters] = useState<SearchState<SearchFilters>>({});
    const filter = getQueryFilter(createKeywordFilterQuery(searchFilters, ['name']));
    const filterHash = objectHash(searchFilters);

    const { $top, $skip, getNextResultPage } = usePaginatedRequests(
        EntityDiscriminators.SpecialPowerSchedule,
        filterHash,
        undefined,
        pageSize
    );

    const { removeResult, results, setResult, setResults, total } =
        usePaginatedResults<EndpointsRootNetworkModelsPowerScheduleSpecialDayGroupRelations>(
            EntityDiscriminators.SpecialPowerSchedule,
            EntityDiscriminators.SpecialPowerSchedule,
            filterHash,
            getOrderByProperties('PowerSchedule', SortableSort.NAME)
        );

    const powerSchedules = useAsyncAbortable(
        async (signal) => {
            return api.powerSchedules
                .getAllSpecialDayGroups({ workspaceid: workspace.id, $filter: filter, $top, $skip }, { signal })
                .then((response) => {
                    // TODO: It seems that the DTO is of wrong type here
                    const items = response.data
                        .items as unknown as EndpointsRootNetworkModelsPowerScheduleSpecialDayGroupRelations[];

                    return setResults(items, response.data.total);
                })
                .catch(() => {
                    const nestedData = getResultData(results.current);
                    return setResults(nestedData, nestedData.length);
                });
        },
        [$skip, $top, api, filter, results, setResults, workspace.id],
        {
            setLoading: (state) => {
                return { ...state, result: results.current && [...results.current.values()], loading: true };
            },
        }
    );

    useOnSearchListener((event) => {
        if (hasSearchEventScope(event, SearchEventScope.POWER_SCHEDULE)) {
            setSearchFilters(event.value);
        }
    });

    return {
        getNextResultPage,
        data: getResultData<EndpointsRootNetworkModelsPowerScheduleSpecialDayGroupRelations>(results.current),
        isLoading: powerSchedules.loading,
        removeResult,
        setResult,
        total: total.current,
    };
};

export default useSpecialDayGroup;
