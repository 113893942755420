import { useCallback, useMemo, useState } from 'react';
import { FieldArrayWithId } from 'react-hook-form';
import { Virtuoso } from 'react-virtuoso';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Box, Typography } from '@mui/material';
import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity, isMediaFolder } from '@zetadisplay/engage-components/models';
import { LibraryWidget } from '@zetadisplay/engage-components/modules/library/components';
import { LibraryWidgetProps } from '@zetadisplay/engage-components/modules/library/components/library-widget/library-widget';
import { LibraryItemType } from '@zetadisplay/engage-components/modules/library/library';
import { PlaylistSetupModifiedMediaType } from '@zetadisplay/engage-components/modules/playlist';
import { BottomsUp, BottomsUpProps } from '@zetadisplay/engage-components/modules/sidekick/components';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button, Checkbox, ComponentLoader, Theme } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { PlaylistSetupFormValues } from '../../utils/createPlaylistFormValues';
import { emitOnPlaylistContentAdded } from './events/on-playlist-content-added-event';
import { emitOnPlaylistContentItemDragEnd } from './events/on-playlist-content-item-drag-end-event';
import PlaylistPreviewContentItem from './playlist-preview-content-item';

const useStyles = makeStyles()((theme) => ({
    root: {
        borderTop: (theme.palette.dark && '1px solid rgba(255, 255, 255, 0.3)') || '1px solid rgba(0, 0, 0, 0.3)',
        height: 'calc(100% - 100px)',
        width: '100%',
        minHeight: 'calc(100% - 100px)',
    },
}));

type Props = {
    layoutZoneId?: number;
    loading: boolean;
    modifiedMedia: Map<string, PlaylistSetupModifiedMediaType>;
    playlistMediaCollection: FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>[];
    selectItem: (item: FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>) => void;
    selectedItems: Record<string, FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>>;
};

const Empty = () => {
    const { trans } = useTranslation();

    return (
        <Typography sx={{ lineHeight: '28px', textAlign: 'center' }}>
            {trans('engage.players.details.playlist.no_content')}
        </Typography>
    );
};

const PlaylistPreviewContentList = ({
    layoutZoneId,
    loading,
    modifiedMedia,
    playlistMediaCollection,
    selectItem,
    selectedItems,
}: Props) => {
    const { classes } = useStyles();
    const { trans } = useTranslation();

    const [addedMedia, setAddedMedia] = useState<DiscriminatedEntity<LibraryModelsMedia>[]>([]);

    const renderItem = useCallback(
        (
            _index: number,
            item: FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>
        ) => {
            const copies = playlistMediaCollection
                .map((copy) => (item.mediaId === copy.mediaId ? copy.id : undefined))
                .filter((value): value is string => value !== undefined);

            const modifiedData = (item.model && modifiedMedia.get(item.model.id)?.data) || undefined;

            return (
                <PlaylistPreviewContentItem
                    key={`${item.fieldArrayId}`}
                    copies={copies}
                    item={item}
                    layoutZoneId={layoutZoneId}
                    modifiedData={modifiedData}
                    selected={selectedItems[item.fieldArrayId] !== undefined}
                    selectItem={selectItem}
                />
            );
        },
        [layoutZoneId, modifiedMedia, playlistMediaCollection, selectItem, selectedItems]
    );

    const renderPrimaryAction = useCallback(
        (item: LibraryItemType) => {
            if (isMediaFolder(item)) {
                return null;
            }

            return (
                <Checkbox
                    checked={addedMedia.some((addedItem) => addedItem.id === item.id)}
                    onChange={() => {
                        if (addedMedia.some((addedItem) => addedItem.id === item.id)) {
                            setAddedMedia(addedMedia.filter((addedItem) => addedItem.id !== item.id));
                        } else {
                            setAddedMedia([...addedMedia, item]);
                        }
                    }}
                />
            );
        },
        [addedMedia]
    );

    const addMedia = useCallback(() => {
        emitOnPlaylistContentAdded(addedMedia);
        setAddedMedia([]);
    }, [addedMedia]);

    const components = useMemo(
        () => ({
            EmptyPlaceholder: Empty,
        }),
        []
    );

    const libraryWidgetProps: LibraryWidgetProps = useMemo(
        () => ({
            renderPrimaryAction,
        }),
        [renderPrimaryAction]
    );

    const bottomsUpProps = useMemo(
        (): BottomsUpProps => ({
            secondaryContent: (
                <Box sx={{ height: '80vh' }}>
                    <Theme dark nested>
                        <LibraryWidget {...libraryWidgetProps} />
                    </Theme>
                </Box>
            ),
            header: (
                <Theme dark nested>
                    <Box sx={{ display: 'inline-flex', flexDirection: 'row', flexGrow: 1, justifyContent: 'right' }}>
                        <Box>
                            <Button
                                disabled={addedMedia.length === 0}
                                fullWidth={false}
                                kind="primaryAction"
                                label={trans('common.action.add')}
                                name="add-new-content"
                                onClick={addMedia}
                                sx={{ borderRadius: '8px' }}
                            />
                        </Box>
                    </Box>
                </Theme>
            ),
            width: 700,
        }),
        [addMedia, addedMedia.length, libraryWidgetProps, trans]
    );

    return (
        <Box className={classes.root} data-testid="playlist-preview-content-list">
            {loading && (
                <Box sx={{ mb: 2, mt: 2 }}>
                    <ComponentLoader />
                </Box>
            )}

            {!loading && (
                <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={emitOnPlaylistContentItemDragEnd}>
                    <SortableContext
                        items={playlistMediaCollection.map((item) => `${item.fieldArrayId}`)}
                        strategy={verticalListSortingStrategy}
                    >
                        <Virtuoso<FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>>
                            components={components}
                            data={playlistMediaCollection}
                            itemContent={renderItem}
                            style={{ height: '100%' }}
                            totalCount={playlistMediaCollection.length}
                        />
                    </SortableContext>

                    <BottomsUp {...bottomsUpProps} />
                </DndContext>
            )}
        </Box>
    );
};

export default PlaylistPreviewContentList;
