import { OidcConfiguration } from '@axa-fr/react-oidc/dist/vanilla/oidc';
import env from '@beam-australia/react-env';

const clientRoot = env('CLIENT_ROOT') || 'https://app.engage.zetadisplay.local:3000';
const deploymentPath = process.env.PUBLIC_URL || '/';
// const environment = env('STAGE') || 'dev';
const redirectUri = `${deploymentPath}/signin-callback.html`.replace(/\/\/+/g, '/');
const serviceWorkerUrl = `${deploymentPath}/OidcServiceWorker.js`.replace(/\/\/+/g, '/');

// TODO: Currently disabled for all environment because svc worker does not support injecting accessToken into WebSocket
// const shouldEnableServiceWorker = [].includes(environment);
const shouldEnableServiceWorker = false;

const oidcConfiguration: OidcConfiguration = {
    authority: env('AUTH_AUTHORITY') || 'https://zetadisplay-test.okta.com/oauth2/aus3uzribu1uJtzga417',
    client_id: env('CLIENT_ID') || '0oa19v98wqsuIxj2A417',
    redirect_uri: `${clientRoot}${redirectUri}`,
    scope: 'email openid offline_access profile',
    service_worker_relative_url: shouldEnableServiceWorker ? serviceWorkerUrl : undefined,
    service_worker_only: false,
};

export { clientRoot };
export default oidcConfiguration;
