import React from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { FieldPath } from 'react-hook-form/dist/types';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { ConfigModelsAttributeValueBasic } from '@zetadisplay/engage-api-client';
import { ConfigModelsAttributeBasic } from '@zetadisplay/engage-api-client/models/config-models-attribute-basic';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useAttributeWithValues } from '@zetadisplay/engage-components/modules/players/hooks';
import { Autocomplete, TextFieldProps } from '@zetadisplay/zeta-ui-components';

type LabelValueInputProps<T extends FieldValues, N extends FieldPath<T> = FieldPath<T>> = {
    /**
     * React Hook Form control
     */
    control?: Control<T>;
    /**
     * Label
     */
    label: ConfigModelsAttributeBasic;
    /**
     * Name of the label value input. Will also act as a namespace for sub values
     * which will be queried from FormContext.
     * */
    name: N;
    /**
     * Rules for react-hook-form
     */
    rules?: Omit<RegisterOptions<T, N>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    /**
     * Variant of the input field
     */
    variant?: TextFieldProps['variant'];
};

const LabelValueInput = <T extends FieldValues>({ control, label, name, rules, variant }: LabelValueInputProps<T>) => {
    const data = useAttributeWithValues(label.id);
    const attributeValues = data.result?.attributeValues || [];

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value, ...props } }) => (
                <Autocomplete<DiscriminatedEntity<ConfigModelsAttributeValueBasic>, false, false, false>
                    {...props}
                    autoSelect={false}
                    blurOnSelect
                    fullWidth
                    getOptionLabel={(option) => option?.value}
                    isOptionEqualToValue={(option, _value) => option.id === _value.id}
                    label={label.name}
                    loading={data.loading}
                    name="attributeValueId"
                    onChange={(_, val) => onChange(val?.id || null)}
                    options={attributeValues}
                    placeholder="Select value"
                    ref={null}
                    variant={variant}
                    value={attributeValues.find((item) => item?.id === value) || null}
                />
            )}
            rules={rules}
        />
    );
};

export default LabelValueInput;
