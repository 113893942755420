import React, { memo, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NiceModal, { NiceModalHocProps } from '@ebay/nice-modal-react';
import { LibraryModelsFolder } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useUserInformation } from '@zetadisplay/engage-components/modules/auth';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { getCurrentNodeFromPath } from '@zetadisplay/engage-components/utils/route';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Icon } from '@zetadisplay/zeta-ui-components';

import CreatePlaylistFolderForm from 'src/components/Modals/Playlist/PlaylistFolder/CreatePlaylistFolderForm';
import ViewActions from 'src/components/ViewActions';
import { emitOnPlaylistFolderCreated } from 'src/views/PlaylistView/Events/onPlaylistFolderCreatedEvent';

const PlaylistViewActions = () => {
    const history = useNavigate();
    const location = useLocation();
    const t = useTranslation();
    const { hasRole } = useUserInformation();
    const { workspaceSettings } = useWorkspace();
    const currentFolderId = getCurrentNodeFromPath(location?.pathname) || workspaceSettings?.campaignRootFolder;

    const actions = useMemo(
        () => [
            {
                disabled: !hasRole(['ENGAGE_PLAYLIST_CREATE']),
                icon: <Icon type="ADD" color="#fff" />,
                label: t.trans('engage.action.create.playlist'),
                name: 'create-playlist',
                onClick: () => {
                    history(`/playlists/create?returnUrl=${location.pathname}`, { state: { currentFolderId } });
                },
            },
            {
                disabled: !hasRole(['ENGAGE_PLAYLIST_FOLDER_CREATE']),
                icon: <Icon type="ADD_WORKSPACE" color="#fff" />,
                label: t.trans('engage.action.create.playlist_folder'),
                name: 'create-playlist-folder',
                onClick: () => {
                    NiceModal.show<DiscriminatedEntity<LibraryModelsFolder>, NiceModalHocProps>(
                        CreatePlaylistFolderForm
                    ).then(emitOnPlaylistFolderCreated);
                },
            },
        ],
        [currentFolderId, hasRole, history, location.pathname, t]
    );

    return <ViewActions actions={actions} label="playlists-actions" />;
};

export default memo(PlaylistViewActions);
