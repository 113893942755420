import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { usePendingPromise } from '@zetadisplay/engage-components/hooks';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { createButtonClickEvent } from '@zetadisplay/zeta-ui-components/utils/data-layer';
import pLimit from 'p-limit';

import { DeleteMediaFileFields } from 'src/components/Modals/Library/MediaFile/DeleteMediaFilePrompt';

const limit = pLimit(1);

const useDeleteMediaFileSubmitAction = (media: DiscriminatedEntity<LibraryModelsMedia>) => {
    const api = useApi();
    const { workspace } = useWorkspace();

    return usePendingPromise(
        async (formValues: DeleteMediaFileFields) => {
            if (formValues.deleteSourceMedia) {
                return api.media
                    .deleteMedia({
                        force: true,
                        mediaid: media.id,
                        workspaceid: media.workspaceId,
                    })
                    .then(() => media);
            }

            return Promise.all(
                formValues.selectedPlaylists?.map((playlistId) =>
                    limit(async () => {
                        return api.playlists
                            .getCampaignById({
                                campaignid: playlistId,
                                workspaceid: workspace.id,
                            })
                            .then((response) => response.data)
                            .then((playlist) =>
                                api.playlists
                                    .editCampaign({
                                        body: {
                                            ...playlist,
                                            campaignMedia: playlist.campaignMedia?.filter(
                                                (campaignMedia) => campaignMedia.mediaId !== media.id
                                            ),
                                        },
                                        campaignid: playlist.id,
                                        workspaceid: workspace.id,
                                    })
                                    .then((response) => response.data)
                                    .catch(() => undefined)
                            )
                            .catch(() => undefined);
                    })
                ) || []
            );
        },
        { success: { message: 'engage.notification.delete.file.success', params: [media.name] } },
        createButtonClickEvent('Editing option', 'Delete')
    );
};

export default useDeleteMediaFileSubmitAction;
