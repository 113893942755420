import { useCallback } from 'react';
import {
    NetworkModelsPlayersPlayerRelations,
    NetworkModelsPlayersUpdatePlayerRelations,
} from '@zetadisplay/engage-api-client';
import { ConfigModelsAttributeValueRelations } from '@zetadisplay/engage-api-client/models/config-models-attribute-value-relations';
import { SharedModelsChannelAttributeValue } from '@zetadisplay/engage-api-client/models/shared-models-channel-attribute-value';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';

const mapChannelAttribute = (
    attribute?: ConfigModelsAttributeValueRelations
): SharedModelsChannelAttributeValue | undefined => {
    if (!attribute || attribute.attributeValue === undefined) {
        return undefined;
    }

    return {
        attributeId: attribute.attribute.id,
        attributeValueId: attribute.attributeValue.id,
    };
};

const createPlayerRelationUpdatePayload = (
    data: NetworkModelsPlayersPlayerRelations
): NetworkModelsPlayersUpdatePlayerRelations => {
    const playerLocation =
        data.playerLocation?.id || data.playerLocation?.address?.length !== 0 ? data.playerLocation : undefined;

    const channels = data.channels?.length
        ? data.channels.map((channel) => ({
              ...channel,
              attributes: channel.attributes?.length
                  ? channel.attributes
                        .map(mapChannelAttribute)
                        .filter((attribute): attribute is SharedModelsChannelAttributeValue => attribute !== undefined)
                  : undefined,
          }))
        : undefined;

    return { ...data, playerLocation, channels };
};

const useUpdatePlayerSettings = (player: NetworkModelsPlayersPlayerRelations) => {
    const api = useApi();
    const { workspace } = useWorkspace();

    return useCallback(
        async (data: NetworkModelsPlayersPlayerRelations) => {
            const payload = createPlayerRelationUpdatePayload(data);

            return api.players.updatePlayerRelations({
                body: payload,
                playerGuid: player.guid,
                workspaceid: workspace.id,
            });
        },
        [api.players, player.guid, workspace.id]
    );
};

export default useUpdatePlayerSettings;
