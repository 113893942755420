import { useAsyncAbortable } from 'react-async-hook';
import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { createScheduleFromEngageSchedule } from '@zetadisplay/engage-components/modules/playlist';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { Schedule } from '@zetadisplay/zeta-ui-components';

const useDefaultMediaSchedules = (media?: DiscriminatedEntity<LibraryModelsMedia>, enabled?: boolean) => {
    const api = useApi();
    const { workspace } = useWorkspace();

    return useAsyncAbortable(
        async (signal) => {
            if (enabled === false || media === undefined) {
                return undefined;
            }

            return api.media
                .getMediaDefaultSchedules({ mediaid: media.id, workspaceid: workspace.id }, { signal })
                .then((response) => response.data.map(createScheduleFromEngageSchedule) as Schedule[]);
        },
        [api, enabled, media, workspace.id]
    );
};

export default useDefaultMediaSchedules;
