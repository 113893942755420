import { useAsyncAbortable } from 'react-async-hook';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';

const useTemplate = (templateId: string | undefined | null) => {
    const api = useApi();
    const { workspace } = useWorkspace();
    return useAsyncAbortable(
        async (signal) => {
            if (templateId === undefined || templateId === null) {
                return undefined;
            }

            return api.templates
                .getTemplateById(
                    {
                        workspaceid: workspace.id,
                        templateid: templateId,
                    },
                    { signal }
                )
                .then((response) => response.data);
        },
        [api, templateId, workspace.id]
    );
};

export default useTemplate;
