import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { useDocumentTitle } from '@zetadisplay/zeta-ui-components/hooks';

import { APPLICATION_NAME } from 'src/config/appConfig';

const useViewTitle = (viewName: string) => {
    const t = useTranslation();
    const { workspace } = useWorkspace();

    useDocumentTitle(APPLICATION_NAME, [workspace.name, t.trans(viewName)]);
};

export default useViewTitle;
