import React, { useCallback, useMemo } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { useNavigate } from 'react-router-dom';
import { Grid, Skeleton, Typography } from '@mui/material';
import { Minus } from '@styled-icons/typicons';
import {
    NetworkModelsLifelineConnectionStatusEnum,
    NetworkModelsPlayerInformation,
} from '@zetadisplay/engage-api-client';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { statusColors, statusStrings } from 'src/constants/Players/PlayerStatus';

const useStyles = makeStyles()(() => ({
    legendIcon: {
        marginBottom: 3,
        marginRight: 4,
    },
    legendText: { height: 22 },
    legendTitle: {
        display: 'inline-block',
        lineHeight: '20px',
    },
}));

type Props = {
    isLoading?: boolean;
    players: NetworkModelsPlayerInformation[];
};

const NetworkStatus = ({ isLoading, players }: Props) => {
    const { classes } = useStyles();
    const history = useNavigate();
    const t = useTranslation();

    const playerStatuses = useMemo(
        () => [
            NetworkModelsLifelineConnectionStatusEnum.ONLINE,
            NetworkModelsLifelineConnectionStatusEnum.OFFLINE,
            NetworkModelsLifelineConnectionStatusEnum.IDLE,
            NetworkModelsLifelineConnectionStatusEnum.NEW,
        ],
        []
    );

    const createPlayerStatusEntry = useCallback(
        (state: NetworkModelsLifelineConnectionStatusEnum, value: number) => ({
            title: t.trans(statusStrings[state]),
            value,
            color: statusColors[state],
        }),
        [t]
    );

    const playerStatusEntries = useMemo(
        () =>
            playerStatuses.map((state) =>
                createPlayerStatusEntry(state, players.filter((player) => player.onlineState === state).length || 0)
            ),
        [createPlayerStatusEntry, playerStatuses, players]
    );

    const handlePieChartClick = (segment: number) => {
        history(`/players?players=true&status=${NetworkModelsLifelineConnectionStatusEnum[segment]}`);
    };

    const renderNetworkStatusChart = () => {
        if (isLoading) {
            return <Skeleton height={200} variant="circular" width={200} />;
        }

        return (
            <PieChart
                animate
                data={playerStatusEntries}
                labelPosition={112}
                onClick={(_, segment) => handlePieChartClick(segment)}
                radius={42}
                segmentsShift={1}
                segmentsStyle={{ cursor: 'pointer' }}
                startAngle={-90}
                style={{ width: '200px' }}
                totalValue={players.length || 0}
            />
        );
    };
    const renderNetworkStatusLegend = () => {
        if (isLoading) {
            return playerStatuses.map((status) => (
                <div key={status} className={classes.legendText}>
                    <Skeleton width={100} />
                </div>
            ));
        }

        return playerStatusEntries.map((entry) => (
            <div key={entry.title} className={classes.legendText}>
                <Minus color={entry.color} size={24} className={classes.legendIcon} />
                <Typography className={classes.legendTitle}>{`${entry.value} ${entry.title}`}</Typography>
            </div>
        ));
    };

    const renderNetworkStatus = () => {
        if (!isLoading && !players.length) {
            return <Typography align="center">{t.trans('engage.players.content.no_players')}</Typography>;
        }

        return (
            <Grid alignItems="center" container spacing={2}>
                <Grid item>{renderNetworkStatusChart()}</Grid>
                <Grid item>{renderNetworkStatusLegend()}</Grid>
            </Grid>
        );
    };

    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <Typography variant="h2">{t.trans('engage.dashboard.network.status.title')}</Typography>
            </Grid>
            <Grid item>{renderNetworkStatus()}</Grid>
        </Grid>
    );
};

export default NetworkStatus;
