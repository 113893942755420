import {
    PublishingCampaignSchedulingCampaignSchedule,
    PublishingCampaignSchedulingSchedule,
} from '@zetadisplay/engage-api-client';
import convertDateToUTC from '@zetadisplay/engage-components/utils/scheduling/convert-date-to-utc';
import getDateFromSchedule from '@zetadisplay/engage-components/utils/scheduling/get-date-from-schedule';
import { Schedule } from '@zetadisplay/zeta-ui-components';

import createSegmentFromSchedule from './create-segment-from-schedule';

const convertScheduleToEngage = (
    schedule: Schedule,
    playlistSchedules?: PublishingCampaignSchedulingSchedule[] | null | undefined
): PublishingCampaignSchedulingSchedule | PublishingCampaignSchedulingCampaignSchedule => {
    return {
        id: (schedule?.id && (schedule.id as number)) || 0,
        endDate: convertDateToUTC(getDateFromSchedule(schedule, 'endDate'))?.toISOString(),
        segment: schedule.mode === 2 ? createSegmentFromSchedule(schedule, playlistSchedules) : undefined,
        startDate: convertDateToUTC(getDateFromSchedule(schedule, 'startDate'))?.toISOString(),
    };
};

export default convertScheduleToEngage;
