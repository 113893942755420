import { useCallback, useEffect, useState } from 'react';
import { IdentifiableObject } from '@zetadisplay/zeta-ui-components/models';

type UseSidekickNavigationReturn<T> = {
    currentNode: T | undefined;
    updateCurrentNode: (item: T | undefined) => void;
};

const useSidekickNavigation = <T extends IdentifiableObject>(
    getTargetById: (id: string, targets: Map<string, T>) => T | undefined,
    targets: Map<string, T>
): UseSidekickNavigationReturn<T> => {
    const [currentNode, setCurrentNode] = useState<T | undefined>(undefined);

    const updateCurrentNode = useCallback(
        (node: T | undefined) =>
            setCurrentNode((state) => {
                if (node === undefined) {
                    return undefined;
                }

                const id = typeof node.id === 'string' ? node.id : node.id.toString();
                return getTargetById(id, targets) || state;
            }),
        [getTargetById, targets]
    );

    // Current node should be reset to initial state once targets change
    useEffect(() => {
        setCurrentNode(undefined);
    }, [targets]);

    return { currentNode, updateCurrentNode };
};

export default useSidekickNavigation;
