import React, { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
    EndpointsRootNetworkModelsPowerSchedulePowerScheduleDetails,
    NetworkModelsPlayersPlayerBasic,
} from '@zetadisplay/engage-api-client';
import { EndpointsRootNetworkModelsPowerSchedulePowerScheduleInfo } from '@zetadisplay/engage-api-client/models/endpoints-root-network-models-power-schedule-power-schedule-info';
import { EndpointsRootNetworkModelsPowerScheduleSpecialDayGroupRelations } from '@zetadisplay/engage-api-client/models/endpoints-root-network-models-power-schedule-special-day-group-relations';
import { ThreeColumnView } from '@zetadisplay/engage-components/layouts/components';
import {
    createDiscriminatedEntity,
    DiscriminatedEntity,
    EntityDiscriminators,
} from '@zetadisplay/engage-components/models';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import createDateFromPlayingTime from '@zetadisplay/engage-components/utils/scheduling/create-date-from-playing-time';

import getTimestampInMilliseconds from 'src/utils/GetTimestampInMilliseconds';
import withDarkLayout from 'src/utils/Layout/withDarkLayout';
import PowerScheduleForm from 'src/views/PowerScheduleSetupView/Components/PowerScheduleForm';
import PowerScheduleFormActions from 'src/views/PowerScheduleSetupView/Components/PowerScheduleFormActions';
import PowerScheduleLibrary from 'src/views/PowerScheduleSetupView/Components/PowerScheduleLibrary';
import PowerScheduleTargets from 'src/views/PowerScheduleSetupView/Components/PowerScheduleTargets';
import createPowerScheduleSchedules, {
    PowerScheduleSchedules,
} from 'src/views/PowerScheduleSetupView/Utils/createPowerScheduleSchedules';

import validateCurrentFormSchema from './PowerScheduleSetupView/Components/PowerScheduleForm/Schemas/PowerScheduleSchema';
import {
    PowerScheduleAssignedPlayerState,
    SpecialDayState,
} from './PowerScheduleSetupView/Utils/powerScheduleFormEnums';

export type PrimaryPowerScheduleType = DiscriminatedEntity<EndpointsRootNetworkModelsPowerSchedulePowerScheduleDetails>;
export type SpecialPowerScheduleType =
    DiscriminatedEntity<EndpointsRootNetworkModelsPowerScheduleSpecialDayGroupRelations>;

export type PowerScheduleAssignedPlayerType = DiscriminatedEntity<
    NetworkModelsPlayersPlayerBasic & { state?: PowerScheduleAssignedPlayerState }
>;

export type AssignedPowerScheduleType =
    DiscriminatedEntity<EndpointsRootNetworkModelsPowerSchedulePowerScheduleInfo> & {
        state?: PowerScheduleAssignedPlayerState;
    };

export type PrimaryPowerScheduleFormData = {
    id: number;
    name: string;
    schedules: PowerScheduleSchedules;
    assignedPlayers?: PowerScheduleAssignedPlayerType[];
    specialDayGroupIds?: number[];
    workspaceId: string;
};

export type SpecialDayData = {
    id?: number;
    name: string;
    date: Date | null;
    startTime: Date | null;
    endTime: Date | null;
    state?: SpecialDayState;
    enabled: boolean;
    timestamp?: number;
};

export type SpecialPowerScheduleFormData = {
    id: number;
    name: string;
    specialDays?: Array<SpecialDayData> | null;
    powerSchedules?: Array<AssignedPowerScheduleType> | null;
};

export type PowerScheduleFormData = {
    primaryPowerSchedule: PrimaryPowerScheduleFormData;
    specialPowerSchedule: SpecialPowerScheduleFormData;
};

const PowerScheduleSetupView = () => {
    const { workspace } = useWorkspace();
    const [currentTab, setCurrentTab] = useState(0);

    const methods = useForm<PowerScheduleFormData>({
        defaultValues: {
            primaryPowerSchedule: {
                assignedPlayers: [],
                id: 0,
                name: '',
                schedules: createPowerScheduleSchedules(),
                specialDayGroupIds: [],
                workspaceId: workspace.id,
            },
            specialPowerSchedule: {
                id: 0,
                name: '',
                powerSchedules: [],
                specialDays: [
                    {
                        date: null,
                        endTime: null,
                        enabled: true,
                        name: '',
                        startTime: null,
                        state: SpecialDayState.NEW,
                        timestamp: getTimestampInMilliseconds(),
                    },
                ],
            },
        },
        resolver: async (data, context, options) => {
            // if one structure of form is active then omit other structure from validation based on currentTab
            return zodResolver(
                validateCurrentFormSchema(currentTab !== 0 ? 'primaryPowerSchedule' : 'specialPowerSchedule')
            )(data, context, options);
        },
    });

    const handleSelectPowerSchedule = useCallback(
        (powerSchedule: PrimaryPowerScheduleType) => {
            const powerScheduleInfo = powerSchedule as PrimaryPowerScheduleType;
            methods.setValue('primaryPowerSchedule.id', powerScheduleInfo.id);
            methods.setValue('primaryPowerSchedule.name', powerScheduleInfo.name);
            methods.setValue('primaryPowerSchedule.schedules', createPowerScheduleSchedules(powerScheduleInfo));
            methods.setValue(
                'primaryPowerSchedule.assignedPlayers',
                powerScheduleInfo.assignedPlayers?.map((player) =>
                    createDiscriminatedEntity(EntityDiscriminators.Player, player)
                ) || []
            );
            methods.setValue('primaryPowerSchedule.specialDayGroupIds', powerScheduleInfo.specialDayGroupIds || []);
            methods.setValue('primaryPowerSchedule.workspaceId', powerScheduleInfo.workspaceId);

            // Validate the form manually after selecting the power schedule
            methods.trigger('primaryPowerSchedule');
        },
        [methods]
    );

    const handleSelectSpecialSchedule = useCallback(
        (specialSchedule: SpecialPowerScheduleType) => {
            const specialDays = specialSchedule.specialDays?.map((specialDay) => ({
                ...specialDay,
                date: new Date(specialDay.date),
                startTime: specialDay.startTime ? createDateFromPlayingTime(specialDay.startTime) : null,
                endTime: specialDay.endTime ? createDateFromPlayingTime(specialDay.endTime) : null,
                state: SpecialDayState.EXISTING,
                enabled: Boolean(specialDay.startTime && specialDay.endTime),
            }));

            methods.setValue('specialPowerSchedule.id', specialSchedule.id);
            methods.setValue('specialPowerSchedule.name', specialSchedule.name);
            methods.setValue('specialPowerSchedule.specialDays', specialDays);
            methods.setValue(
                'specialPowerSchedule.powerSchedules',
                specialSchedule.powerSchedules?.map((powerSchedule) =>
                    createDiscriminatedEntity(EntityDiscriminators.PowerSchedule, powerSchedule)
                )
            );

            // Validate the form manually after selecting the power schedule
            methods.trigger('specialPowerSchedule');
        },
        [methods]
    );

    const handleTabSelect = useCallback((tab: number) => {
        setCurrentTab(tab); // Update selected tab
    }, []);

    return (
        <FormProvider {...methods}>
            <ThreeColumnView
                actions={<PowerScheduleFormActions currentTab={currentTab} />}
                heading="engage.players.setup.power_schedule.title"
                center={<PowerScheduleForm currentTab={currentTab} />}
                icon="OPENING_HOURS"
                left={
                    <PowerScheduleLibrary
                        onTabSelect={handleTabSelect}
                        currentTab={currentTab}
                        onSelectPowerSchedule={handleSelectPowerSchedule}
                        onSelectSpecialSchedule={handleSelectSpecialSchedule}
                    />
                }
                right={<PowerScheduleTargets currentTab={currentTab} />}
            />
        </FormProvider>
    );
};

export default withDarkLayout(PowerScheduleSetupView, { fullWidthOnSmall: true });
