import { UsePlaylistFoldersReturnType, UsePlaylistsReturnType } from '@zetadisplay/engage-components/modules/playlist';

import { useOnPlaylistCopiedListener } from 'src/views/PlaylistView/Events/onPlaylistCopiedEvent';
import { useOnPlaylistDeletedListener } from 'src/views/PlaylistView/Events/onPlaylistDeletedEvent';
import { useOnPlaylistFolderCreatedListener } from 'src/views/PlaylistView/Events/onPlaylistFolderCreatedEvent';
import { useOnPlaylistFolderDeletedListener } from 'src/views/PlaylistView/Events/onPlaylistFolderDeletedEvent';
import { useOnPlaylistFolderUpdatedListener } from 'src/views/PlaylistView/Events/onPlaylistFolderUpdatedEvent';
import { useOnPlaylistUpdatedListener } from 'src/views/PlaylistView/Events/onPlaylistUpdatedEvent';

const usePlaylistViewEvents = (folders: UsePlaylistFoldersReturnType, playlists: UsePlaylistsReturnType) => {
    useOnPlaylistCopiedListener(playlists.setResult);
    useOnPlaylistDeletedListener(playlists.removeResult);
    useOnPlaylistUpdatedListener(playlists.setResult);
    useOnPlaylistFolderCreatedListener(folders.setResult);
    useOnPlaylistFolderDeletedListener(folders.removeResult);
    useOnPlaylistFolderUpdatedListener(folders.setResult);
};

export default usePlaylistViewEvents;
