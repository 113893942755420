import React, { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box, Grid, Typography } from '@mui/material';
import { createDiscriminatedEntity, EntityDiscriminators } from '@zetadisplay/engage-components/models';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button, ComponentLoader } from '@zetadisplay/zeta-ui-components';
import { createButtonClickEvent, pushToDataLayer } from '@zetadisplay/zeta-ui-components/utils/data-layer';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import GetTimestampInMilliseconds from 'src/utils/GetTimestampInMilliseconds';
import { LabelSetupFormData, LabelValueState, LabelValueType } from 'src/views/LabelSetupView';
import LabelForm from 'src/views/LabelSetupView/Components/LabelSetupMainForm/LabelForm';
import LabelSetupValues from 'src/views/LabelSetupView/Components/LabelSetupMainForm/LabelSetupValues';

const useStyles = makeStyles()(() => ({
    createValueAction: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'right',
        marginBottom: 6,
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    },
}));

type Props = {
    isLoading: boolean;
    labelId: number;
};

const LabelSetupMainForm = ({ isLoading, labelId }: Props) => {
    const t = useTranslation();
    const { classes } = useStyles();
    const { control, watch } = useFormContext<LabelSetupFormData>();
    const { append, fields, remove, update } = useFieldArray<LabelSetupFormData, 'values', 'key'>({
        control,
        keyName: 'key',
        name: 'values',
        shouldUnregister: false,
    });

    const watchedValues = watch('values');
    const values = fields.map((field, index) => ({ ...field, ...watchedValues[index] } as LabelValueType));

    const createLabelValue = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();

            append(
                createDiscriminatedEntity(EntityDiscriminators.AttributeValue, {
                    id: GetTimestampInMilliseconds(),
                    discriminatorType: 'AttributeValue',
                    labelId,
                    players: [],
                    state: LabelValueState.NEW,
                    value: '',
                }),
                { shouldFocus: true }
            );

            pushToDataLayer(createButtonClickEvent('Label Settings', 'Create new value'));
        },
        [append, labelId]
    );

    const onRemoveLabelValue = useCallback(
        (labelValue: LabelValueType) => {
            const labelValueIndex = values.findIndex((value) => value.id === labelValue.id);
            if (labelValue.state === LabelValueState.NEW) {
                remove(labelValueIndex);
            } else {
                update(labelValueIndex, { ...labelValue, state: LabelValueState.DELETED });
            }
        },
        [values, remove, update]
    );

    if (isLoading) {
        return <ComponentLoader />;
    }

    return (
        <div className={classes.root}>
            <Typography sx={{ margin: '12px 0' }} variant="h5">
                {t.trans('engage.players.labels.setup.general_settings.title')}
            </Typography>
            <LabelForm />
            <Box sx={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ margin: '12px 0' }} variant="h5">
                    {t.trans('engage.players.labels.setup.values.title')}
                </Typography>

                <div className={classes.createValueAction}>
                    <Button
                        label={`+ ${t.trans('engage.modal.create.label.value.title')}`}
                        name="add-new-label-value"
                        onClick={createLabelValue}
                    />
                </div>
            </Box>
            <Grid container spacing={0} sx={{ overflowY: 'auto' }}>
                {values.length === 0 ? (
                    <Grid item xs={12}>
                        <Typography data-testid="label-setup-no-values">
                            {t.trans('engage.players.labels.setup.values.no_values')}
                        </Typography>
                    </Grid>
                ) : (
                    <LabelSetupValues onRemoveLabelValue={onRemoveLabelValue} values={values} />
                )}
            </Grid>
        </div>
    );
};

export default LabelSetupMainForm;
