import {
    PublishingCampaignSchedulingCampaignMediaSchedule,
    PublishingCampaignSchedulingSchedule,
} from '@zetadisplay/engage-api-client';
import { Schedule } from '@zetadisplay/zeta-ui-components';

const findSegmentId = (
    schedule: Schedule,
    schedules?: (PublishingCampaignSchedulingSchedule | PublishingCampaignSchedulingCampaignMediaSchedule)[] | null
) => {
    if (schedule.id === undefined || schedules === undefined || schedules === null) {
        return 0;
    }

    return schedules.find((originalSchedule) => originalSchedule.id === schedule.id)?.segment?.id || 0;
};

export default findSegmentId;
