import React, { useCallback, useMemo } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { IconButton, Typography } from '@mui/material';
import {
    NetworkModelsLifelineConnectionStatusEnum,
    NetworkModelsPlayerInformation,
    NetworkModelsPlayersPlayerRelations,
} from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useUserInformation } from '@zetadisplay/engage-components/modules/auth';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { Icon } from '@zetadisplay/zeta-ui-components';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

import { emitOnPlayerSettingsUpdate } from 'src/events/player/onPlayerSettingsUpdateEvent';
import PlayerDetailsSidekick, { PlayerDetailsSidekickProps } from 'src/modules/player/sidekick/PlayerDetailsSidekick';
import PlayerSettingsSidekick, {
    PlayerSettingsSidekickProps,
} from 'src/modules/player/sidekick/PlayerSettingsSidekick';
import PlayerItemStatus from 'src/views/PlayersView/Components/PlayerItemStatus';

import getPlayer from '../../../api/get-player';

const useStyles = makeStyles()(() => ({
    button: {
        '&:hover': {
            backgroundColor: themeOptions.colors.CONTENT_DARK_HOVER,
        },
        backgroundColor: themeOptions.colors.CONTENT_DARK,
    },
    playerName: {
        fontSize: '24px',
        lineHeight: '24px',
        whiteSpace: 'initial',
    },
    status: {
        marginLeft: 'auto',
        marginTop: 4,
        display: 'flex',
        alignItems: 'center',
    },
}));

export type PlayerDetailsHeaderProps = {
    player: DiscriminatedEntity<NetworkModelsPlayerInformation>;
};

const PlayerDetailsHeader = ({ player }: PlayerDetailsHeaderProps) => {
    const { classes } = useStyles();
    const { hasRole } = useUserInformation();
    const { workspace } = useWorkspace();
    const api = useApi();

    const isSettingsDisabled = useMemo(() => {
        return (
            !hasRole('ENGAGE_PLAYER_EDIT') || player.workspaceId !== workspace?.id || player.channelIds?.length === 0
        );
    }, [hasRole, player.channelIds?.length, player.workspaceId, workspace?.id]);

    const status = useMemo(() => {
        return player.onlineState !== undefined
            ? player.onlineState
            : NetworkModelsLifelineConnectionStatusEnum.OFFLINE;
    }, [player.onlineState]);

    const openPlayerDetailsSidekick = useCallback(
        async (id: string) => {
            const item = await getPlayer(api, id, workspace.id);
            return NiceModal.show<void, PlayerDetailsSidekickProps>(PlayerDetailsSidekick, { item });
        },
        [api, workspace.id]
    );

    const showPlayerDetails = useCallback(() => {
        NiceModal.show<DiscriminatedEntity<NetworkModelsPlayersPlayerRelations>, PlayerSettingsSidekickProps>(
            PlayerSettingsSidekick,
            { openDetailsCallback: openPlayerDetailsSidekick, player }
        ).then((response) => {
            emitOnPlayerSettingsUpdate({ ...player, ...response });
        });

        // After we have opened new sidekick we can close this one, or maybe we should just chain them without increasing darkness on backdrop
        NiceModal.hide(PlayerDetailsSidekick);
    }, [openPlayerDetailsSidekick, player]);

    return (
        <>
            <Typography className={classes.playerName} variant="h1" title={player.friendlyName}>
                {player.friendlyName}
            </Typography>

            <span className={classes.status}>
                <PlayerItemStatus status={status} />

                <IconButton
                    className={classes.button}
                    data-testid="settings"
                    disabled={isSettingsDisabled}
                    onClick={showPlayerDetails}
                >
                    <Icon
                        color={isSettingsDisabled ? themeOptions.colors.DARKGRAY : themeOptions.colors.WHITE}
                        size={18}
                        type="SETTINGS"
                    />
                </IconButton>
            </span>
        </>
    );
};

export default PlayerDetailsHeader;
