import { createEvent } from 'react-event-hook';
import { PlaylistMediaType } from '@zetadisplay/engage-components/modules/playlist';

export type OnPlaylistMediaDraggedEventPayload = {
    dragIndex: number;
    hoverIndex: number;
    newItem: PlaylistMediaType | undefined;
};

export const { useOnPlaylistMediaDraggedListener, emitOnPlaylistMediaDragged } =
    createEvent('onPlaylistMediaDragged')<OnPlaylistMediaDraggedEventPayload>();
