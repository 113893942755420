import { useAsyncAbortable, UseAsyncReturn } from 'react-async-hook';
import { LibraryModelsFolder } from '@zetadisplay/engage-api-client';
import {
    createDiscriminatedEntity,
    DiscriminatedEntity,
    EntityDiscriminators,
} from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { getOrderByQuery, useSorting } from '@zetadisplay/engage-components/modules/options';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { TreeBuilder } from '@zetadisplay/engage-components/utils/tree-builder';

import { MoveMediaTargetType } from 'src/components/Sidekicks/Library/MoveLibraryItemSidekick';

type UseSidekickTargetsReturn = {
    rootNode: UseAsyncReturn<MoveMediaTargetType | undefined>;
    refresh: () => Promise<Map<string, MoveMediaTargetType>>;
    targets: UseAsyncReturn<Map<string, MoveMediaTargetType>>;
};

const useSidekickTargets = (): UseSidekickTargetsReturn => {
    const api = useApi();
    const sorting = useSorting();
    const { workspace, workspaceSettings } = useWorkspace();

    const targets = useAsyncAbortable(
        async (signal) => {
            return api.folders
                .getMediaFolders(
                    {
                        parentId: workspaceSettings?.mediaRootFolder,
                        recursive: true,
                        workspaceid: workspace.id,
                        $orderby: getOrderByQuery('MediaFolder', sorting.sort),
                    },
                    { signal }
                )
                .then((response) =>
                    TreeBuilder<DiscriminatedEntity<LibraryModelsFolder>, LibraryModelsFolder>(
                        response.data.items,
                        (node) => createDiscriminatedEntity(EntityDiscriminators.MediaFolder, node)
                    )
                );
        },
        [api, sorting.sort, workspace.id, workspaceSettings?.mediaRootFolder]
    );

    const rootNode = useAsyncAbortable(
        async (signal) => {
            if (!workspaceSettings?.mediaRootFolder) {
                return undefined;
            }

            return api.folders
                .getMediaFolder({ folderid: workspaceSettings.mediaRootFolder, workspaceid: workspace.id }, { signal })
                .then((response) => createDiscriminatedEntity(EntityDiscriminators.MediaFolder, response.data))
                .then((response): MoveMediaTargetType => ({ ...response, children: new Map() }));
        },
        [api, workspace.id, workspaceSettings?.mediaRootFolder]
    );

    return { rootNode, refresh: targets.execute, targets };
};

export default useSidekickTargets;
