import { EndpointsRootNetworkModelsPowerScheduleSpecialDayInfo } from '@zetadisplay/engage-api-client/models/endpoints-root-network-models-power-schedule-special-day-info';
import { createDiscriminatedEntity, EntityDiscriminators } from '@zetadisplay/engage-components/models';
import { ApiInterface } from '@zetadisplay/engage-components/modules/api';
import { createPlayingTimeFromDate } from '@zetadisplay/engage-components/utils/scheduling';
import convertDateToUTC from '@zetadisplay/engage-components/utils/scheduling/convert-date-to-utc';
import { NotifyFunctionType } from '@zetadisplay/zeta-ui-components/hooks/use-notify';

import handlePendingPromise, { PendingPromiseMessages } from 'src/utils/HandlePendingPromise';
import { SpecialPowerScheduleFormData } from 'src/views/PowerScheduleSetupView';
import {
    PowerScheduleAssignedPlayerState,
    SpecialDayState,
} from 'src/views/PowerScheduleSetupView/Utils/powerScheduleFormEnums';

const createSpecialDayPayload = (data: SpecialPowerScheduleFormData) => {
    const assignedPowerSchedules = data.powerSchedules || [];
    const powerScheduleIds = assignedPowerSchedules
        .filter((powerSchedule) => powerSchedule.state !== PowerScheduleAssignedPlayerState.DELETED)
        .map((powerSchedule) => powerSchedule.id);

    return {
        name: data.name,
        specialDays: data.specialDays?.map((specialDay) => {
            const date = convertDateToUTC(specialDay.date);

            return {
                name: `${data.name}-${date?.toISOString()}`,
                date: date?.toISOString(),
                startTime: specialDay.enabled ? createPlayingTimeFromDate(specialDay.startTime) : null,
                endTime: specialDay.enabled ? createPlayingTimeFromDate(specialDay.endTime) : null,
            } as EndpointsRootNetworkModelsPowerScheduleSpecialDayInfo;
        }),
        powerScheduleIds,
    };
};

const createOrUpdateSpecialDayPowerSchedulePromise = (
    api: ApiInterface,
    data: SpecialPowerScheduleFormData,
    workspaceId: string
) => {
    // Update existing SpecialDayGroup
    if (data.id !== 0) {
        return api.powerSchedules
            .updateSpecialDayGroup({
                body: { id: data.id, ...createSpecialDayPayload(data) },
                specialdaygroupid: data.id,
                workspaceid: workspaceId,
            })
            .then((response) => createDiscriminatedEntity(EntityDiscriminators.SpecialPowerSchedule, response.data));
    }

    return api.powerSchedules
        .createSpecialDayGroup({
            body: createSpecialDayPayload(data),
            workspaceid: workspaceId,
        })
        .then((response) => createDiscriminatedEntity(EntityDiscriminators.SpecialPowerSchedule, response.data));
};

const createOrUpdateSpecialDayPowerSchedule = (
    api: ApiInterface,
    data: SpecialPowerScheduleFormData,
    workspaceId: string,
    notify: NotifyFunctionType
) => {
    const isExistingSchedule = data.specialDays?.some((i) => i.state === SpecialDayState.EXISTING);
    const messages: PendingPromiseMessages = {
        pending: {
            message: isExistingSchedule
                ? 'engage.notification.edit.power_schedule.info'
                : 'engage.notification.create.power_schedule.info',
            params: [data.name],
        },
        success: {
            message: isExistingSchedule
                ? 'engage.notification.edit.power_schedule.success'
                : 'engage.notification.create.power_schedule.success',
            params: [data.name],
        },
    };

    return handlePendingPromise(createOrUpdateSpecialDayPowerSchedulePromise(api, data, workspaceId), messages, notify);
};

export default createOrUpdateSpecialDayPowerSchedule;
