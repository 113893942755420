import { useAsyncAbortable } from 'react-async-hook';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';

const usePlayersByAttribute = (attributeId?: number) => {
    const api = useApi();
    const { workspace } = useWorkspace();

    return useAsyncAbortable(
        async (signal) => {
            if (attributeId === undefined) {
                return [];
            }

            return api.players
                .getPlayersByAttribute({ attributeid: attributeId, workspaceid: workspace.id }, { signal })
                .then((response) => response.data);
        },
        [api, attributeId, workspace.id]
    );
};

export default usePlayersByAttribute;
