import React, { useMemo } from 'react';
import NiceModal, { NiceModalHocProps } from '@ebay/nice-modal-react';
import { NetworkModelsPlayerInformation } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { Sidekick, SidekickProps } from '@zetadisplay/engage-components/modules/sidekick/components';

import SidekickClose from 'src/components/Sidekicks/SideKickClose';
import PlayerDetailsHeader from 'src/modules/player/sidekick/PlayerDetailsSidekick/components/PlayerDetailsHeader';
import PlayerItemDetailsPlaylist from 'src/views/PlayersView/Components/PlayerItemDetails/PlayerItemDetailsPlaylist';

export type PlayerDetailsSidekickProps = {
    item: DiscriminatedEntity<NetworkModelsPlayerInformation>;
} & NiceModalHocProps;

const PlayerDetailsSidekick = NiceModal.create<PlayerDetailsSidekickProps>(({ item }) => {
    const sidekickHeader = useMemo(
        (): SidekickProps['header'] => ({
            renderHeader: () => <PlayerDetailsHeader player={item} />,
            renderClose: (onClose) => <SidekickClose onClose={onClose} />,
        }),
        [item]
    );

    return (
        <Sidekick header={sidekickHeader} dark={false} drawerWidth={700}>
            <PlayerItemDetailsPlaylist item={item} />
        </Sidekick>
    );
});

export default PlayerDetailsSidekick;
