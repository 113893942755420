import React, { useCallback, useMemo, useState } from 'react';
import { InView } from 'react-intersection-observer';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { NetworkModelsPlayerInformation } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { createDefaultButtons, Modal } from '@zetadisplay/engage-components/modules/modal/components';
import { FilterFields, keywordFilter } from '@zetadisplay/engage-components/modules/search';
import { emitOnSearch, SearchEventScope } from '@zetadisplay/engage-components/modules/search/events';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import {
    Checkbox,
    ComponentLoader,
    Icon,
    InViewTrigger,
    ItemListItemSkeleton,
    Search,
} from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { LabelValuePlayerState, LabelValuePlayerType } from 'src/views/LabelSetupView';
import usePlayers from 'src/views/LabelSetupView/Hooks/usePlayers';
import usePlayersByAttribute from 'src/views/LabelSetupView/Hooks/usePlayersByAttribute';

const useStyles = makeStyles()(() => ({
    root: {
        height: 'calc(80vh - 138px)',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
    },
    container: {
        display: 'flex',
        flexGrow: 1,
    },
    isLoadingMore: {
        height: 40,
        marginTop: 12,
    },
    listItem: {
        paddingBottom: 0,
        paddingLeft: 0,
        paddingTop: 0,
    },
    noItems: {
        padding: 6,
        textAlign: 'center',
    },
    playerSubtitle: {
        fontSize: 12,
    },
    searchContainer: {
        marginBottom: 6,
    },
}));

export type PlayersSelectModalProps = {
    allowPlayersWithMultipleChannels?: boolean;
    allowPlayersWithoutChannel?: boolean;
    allowSharedPlayers?: boolean;
    attributeId?: number;
    currentPlayers: LabelValuePlayerType[];
    removedPlayersFromAttributeValues: LabelValuePlayerType[];
    usedPlayersByAttributeValues: LabelValuePlayerType[];
    searchScope?: string;
} & NiceModalHocProps;

// TODO: Refactor this to use ItemList component for rendering selectable players
const AddLabelValuePlayersModal = NiceModal.create<PlayersSelectModalProps>(
    ({
        allowPlayersWithMultipleChannels,
        allowPlayersWithoutChannel,
        allowSharedPlayers,
        attributeId,
        currentPlayers,
        removedPlayersFromAttributeValues,
        usedPlayersByAttributeValues,
    }) => {
        const modal = useModal();
        const t = useTranslation();
        const { classes } = useStyles();
        const { workspace } = useWorkspace();

        const players = usePlayers();
        const usedPlayersByAttribute = usePlayersByAttribute(attributeId);

        const [selectedPlayers, setSelectedPlayers] = useState(currentPlayers);
        const usedPlayers = useMemo(
            () =>
                (usedPlayersByAttribute.result || [])
                    .filter(
                        (usedPlayer) =>
                            removedPlayersFromAttributeValues.find(
                                (removedPlayer) => usedPlayer.id === removedPlayer.id
                            ) === undefined
                    )
                    .concat(usedPlayersByAttributeValues),
            [removedPlayersFromAttributeValues, usedPlayersByAttribute.result, usedPlayersByAttributeValues]
        );

        const isDisabledPlayer = useCallback(
            (player: DiscriminatedEntity<NetworkModelsPlayerInformation>) => {
                if (currentPlayers.some((p) => p.id === player.id)) {
                    return false;
                }

                return usedPlayers.some((p) => p.id === player.id);
            },
            [currentPlayers, usedPlayers]
        );
        const isLoadingMore = players.isLoading;
        const infiniteScrolling = (players.total && players.total > players.data.length) || false;

        const toggleSelectedPlayer = useCallback((player: DiscriminatedEntity<NetworkModelsPlayerInformation>) => {
            setSelectedPlayers((prevState) => {
                return prevState.some((p) => p.id === player.id)
                    ? prevState.filter((p) => p.id !== player.id)
                    : [...prevState, { ...player, state: LabelValuePlayerState.NEW }];
            });
        }, []);

        const renderPlayerItem = (inView: boolean, player: DiscriminatedEntity<NetworkModelsPlayerInformation>) => {
            if (!inView) {
                return <ItemListItemSkeleton type="compact" />;
            }

            return (
                <ListItemButton
                    className={classes.listItem}
                    dense
                    disabled={
                        isDisabledPlayer(player) ||
                        (!allowSharedPlayers && player.workspaceId !== workspace.id) ||
                        (!allowPlayersWithoutChannel && player.channelIds && player.channelIds.length < 1) ||
                        (!allowPlayersWithMultipleChannels && player.channelIds && player.channelIds.length > 1) ||
                        false
                    }
                    onClick={() => toggleSelectedPlayer(player)}
                    data-testid={`player-${player.name}`}
                >
                    <ListItemIcon>
                        <Checkbox
                            checked={selectedPlayers.some((p) => p.id === player.id)}
                            data-testid={`player-checkbox-${player.name}`}
                            disabled={isDisabledPlayer(player)}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={player.friendlyName}
                        secondary={
                            <Typography variant="body2" className={classes.playerSubtitle}>
                                <span>#{player.id}</span> <span>{player.name}</span>
                            </Typography>
                        }
                    />
                </ListItemButton>
            );
        };

        return (
            <Modal
                actions={{
                    buttons: createDefaultButtons({
                        cancel: { onClick: modal.hide },
                        submit: {
                            label: 'common.action.add',
                            onClick: () => {
                                modal.resolve(selectedPlayers);
                                modal.hide();
                            },
                        },
                    }),
                }}
                dark
                title={{
                    icon: <Icon type="PLAYER_GROUP" />,
                    label: 'engage.modal.players.labels.setup.add-players.title',
                }}
            >
                <div className={classes.searchContainer}>
                    <Search
                        filters={{ [FilterFields.KEYWORD]: keywordFilter }}
                        fullWidth
                        onChangeCallback={(state) => emitOnSearch({ scope: SearchEventScope.PLAYER, value: state })}
                    />
                </div>
                <div className={classes.root} data-testid="players-checklist">
                    {!isLoadingMore && players.data.length === 0 && (
                        <Typography className={classes.noItems} data-testid="no-players-info">
                            {t.trans('engage.players.content.no_players')}
                        </Typography>
                    )}
                    {players.data.map((player) => (
                        <InView key={player.id} threshold={0}>
                            {({ inView, ref }) => (
                                <div ref={ref} data-testid={`player-checklist-item-${player.name}`}>
                                    {renderPlayerItem(inView, player)}
                                </div>
                            )}
                        </InView>
                    ))}
                    {isLoadingMore && (
                        <div className={classes.isLoadingMore}>
                            <ComponentLoader />
                        </div>
                    )}
                    <InViewTrigger
                        key={players.data.length}
                        callback={players.getNextResultPage}
                        enabled={(!isLoadingMore && infiniteScrolling) || false}
                    />
                </div>
            </Modal>
        );
    }
);

export default AddLabelValuePlayersModal;
