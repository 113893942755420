import React from 'react';
import { useAsyncAbortable } from 'react-async-hook';
import { Grid } from '@mui/material';
import { ViewHeader } from '@zetadisplay/engage-components';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useUserInformation } from '@zetadisplay/engage-components/modules/auth';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import useViewTitle from 'src/hooks/useViewTitle';
import withDefaultLayout from 'src/utils/Layout/withDefaultLayout';
import DashboardFeed from 'src/views/DashboardView/Components/DashboardFeed';
import NetworkMap from 'src/views/DashboardView/Components/NetworkMap';
import NetworkStatus from 'src/views/DashboardView/Components/NetworkStatus';
import RecentlyModifiedPlaylists from 'src/views/DashboardView/Components/RecentlyModifiedPlaylists';
import Support from 'src/views/DashboardView/Components/Support';

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',

        [theme.breakpoints.up('lg')]: {
            paddingLeft: 148,
            paddingRight: 152,
        },
    },
}));

// TODO: This exists to skip rendering feed currently during tests because we need to figure out a way to hook into that single fetch
type Props = {
    renderFeed?: boolean;
};

const DashboardView = ({ renderFeed = true }: Props) => {
    useViewTitle('engage.dashboard.view.title');

    const api = useApi();
    const { classes } = useStyles();
    const { user } = useUserInformation();
    const { workspace } = useWorkspace();

    const players = useAsyncAbortable(
        async (signal) =>
            api.players
                .getPlayers(
                    {
                        workspaceid: workspace.id,
                    },
                    { signal }
                )
                .then((response) => response.data),
        [api, workspace.id]
    );

    return (
        <main className={classes.root}>
            <ViewHeader title="engage.dashboard.title" titleParams={[user?.profile?.firstName || 'User']} />
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <DashboardFeed isDisabled={!renderFeed} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <NetworkStatus isLoading={players.loading} players={players.result?.items || []} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <NetworkMap isLoading={players.loading} players={players.result?.items || []} />
                </Grid>
                <Grid item xs={12}>
                    <RecentlyModifiedPlaylists />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Support />
                </Grid>
            </Grid>
        </main>
    );
};

export default withDefaultLayout(DashboardView);
