import { ApiInterface } from '@zetadisplay/engage-components/modules/api';
import { createScheduleFromEngageSchedule } from '@zetadisplay/engage-components/modules/playlist';
import { Schedule } from '@zetadisplay/zeta-ui-components';
import { NotifyFunctionType } from '@zetadisplay/zeta-ui-components/hooks/use-notify';
import { AxiosError } from 'axios';

import getErrorMessage from '../../../utils/Response/GetErrorMessage';

/**
 * Get media default schedules when adding media to playlist.
 *
 * Returns an empty array if request fails, notifies user about the error if notify function is provided.
 *
 * @param id
 * @param api
 * @param workspaceId
 * @param signal
 * @param notify
 */
const getPlaylistMediaDefaultSchedules = async (
    id: string,
    api: ApiInterface,
    workspaceId: string,
    signal?: AbortSignal,
    notify?: NotifyFunctionType
): Promise<Schedule[]> =>
    api.media
        .getMediaDefaultSchedules({ mediaid: id, workspaceid: workspaceId }, { signal })
        .then((response) => response.data.map(createScheduleFromEngageSchedule) as Schedule[])
        .catch((error: AxiosError) => {
            if (notify) {
                notify({
                    message: getErrorMessage(error),
                    params: [],
                    variant: 'error',
                });
            }

            return [];
        });

export default getPlaylistMediaDefaultSchedules;
