import React from 'react';
import { Typography } from '@mui/material';
import {
    NetworkModelsPlayerInformation,
    NetworkModelsPlayersPlayerBasic,
    NetworkModelsPlayerTypeEnum,
} from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()((theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        overflow: 'hidden',
    },
    text: {
        color: theme.palette.background.disabledText,
        display: 'inline-block',
        '&:first-letter': {
            textTransform: 'uppercase',
        },
        '&:not(:last-of-type)::after': {
            content: '" • "',
            marginRight: 4,
        },
    },
}));

export const getPlayerTypeString = (type: NetworkModelsPlayerTypeEnum) => {
    return Object.entries(NetworkModelsPlayerTypeEnum).filter((entry) => {
        return entry[1] === type;
    })[0][0];
};

type PlayerTargetSubtitleProps = {
    player: DiscriminatedEntity<NetworkModelsPlayersPlayerBasic> | DiscriminatedEntity<NetworkModelsPlayerInformation>;
    showType?: boolean;
    showShare?: boolean;
};

const PlayerTargetsSubtitle: React.FC<PlayerTargetSubtitleProps> = ({ player, showShare = true, showType = true }) => {
    const { classes, cx } = useStyles();
    const t = useTranslation();
    const { workspace } = useWorkspace();

    const renderSharedInformation = () => {
        if (!('workspaceId' in player) || player.workspaceId === workspace.id || !showShare) {
            return null;
        }

        return (
            <Typography variant="subtitle1" noWrap className={cx(classes.text)}>
                {t.trans('engage.model.common.shared')}
            </Typography>
        );
    };

    const renderTypeInformation = () => {
        if (!('type' in player) || !showType) {
            return null;
        }

        return (
            <Typography
                variant="subtitle1"
                noWrap
                className={classes.text}
                data-testid={`player-item-subtitle-type-${player.id}`}
            >
                {getPlayerTypeString(player.type)}
            </Typography>
        );
    };

    return (
        <div className={cx(classes.root)} data-testid={`player-item-subtitle-${player.id}`}>
            <Typography variant="subtitle1" noWrap className={cx(classes.text)}>
                {player.name}
            </Typography>
            {renderTypeInformation()}
            {renderSharedInformation()}
        </div>
    );
};

export default PlayerTargetsSubtitle;
