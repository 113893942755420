import { createEvent } from 'react-event-hook';
import { PlaylistMediaType } from '@zetadisplay/engage-components/modules/playlist';

import { ScheduleMediaFileFields } from 'src/components/Modals/PlaylistSetup/SchedulePlaylistMediaForm';

export type PlaylistMediaScheduledEventPayload = {
    playlistMedia: PlaylistMediaType;
    data: ScheduleMediaFileFields;
};

export const { useOnPlaylistMediaScheduledListener, emitOnPlaylistMediaScheduled } =
    createEvent('onPlaylistMediaScheduled')<PlaylistMediaScheduledEventPayload>();
