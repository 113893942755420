import React, { useCallback, useState } from 'react';
import { ConfigModelsGroupInfo, ConfigModelsGroupInfoWithChildren } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { TreeNode } from '@zetadisplay/engage-components/utils/tree-builder';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import TargetingGroupsList from 'src/components/Group/Targeting/TargetingGroupsList';
import TargetingResults from 'src/components/Group/Targeting/TargetingResults';
import TargetingSelectGroups from 'src/components/Group/Targeting/TargetingSelectGroups';

const useStyles = makeStyles()(() => ({
    root: {
        width: '100%',
    },
}));

type Props = {
    initialGroups?: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfo>, number>[];
    onChange: (arg: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfo>, number>[]) => void;
};

const Targeting = ({ initialGroups = [], onChange }: Props) => {
    const { classes, cx } = useStyles();

    // TODO: Refactor this to listen for watch instead
    const [selectedGroups, setSelectedGroups] =
        useState<TreeNode<DiscriminatedEntity<ConfigModelsGroupInfoWithChildren>, number>[]>(initialGroups);

    // TODO: Refactor to pass data directly to callback
    const handleSelectedGroups = useCallback(
        (data: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfoWithChildren>, number>[]) => {
            setSelectedGroups(data);
            if (onChange) {
                onChange(data);
            }
        },
        [onChange]
    );

    return (
        <div className={cx(classes.root)}>
            <TargetingGroupsList groups={selectedGroups} onSelectGroups={handleSelectedGroups} />
            <TargetingSelectGroups onSelectGroups={handleSelectedGroups} initialSelectedGroups={selectedGroups} />
            <TargetingResults groups={selectedGroups} />
        </div>
    );
};

export default Targeting;
