import { useAsyncAbortable } from 'react-async-hook';
import { createDiscriminatedEntity, EntityDiscriminators } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';

const useGroup = (groupId?: number) => {
    const api = useApi();
    const { workspace } = useWorkspace();

    return useAsyncAbortable(
        async (signal) => {
            if (groupId === undefined) {
                return undefined;
            }

            return api.publishGroups
                .getGroup(
                    {
                        groupid: groupId,
                        workspaceid: workspace.id,
                    },
                    { signal }
                )
                .then((response) => createDiscriminatedEntity(EntityDiscriminators.Group, response.data));
        },
        [api, groupId, workspace.id]
    );
};

export default useGroup;
