import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { ConfigModelsGroupInfo } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { TreeNode } from '@zetadisplay/engage-components/utils/tree-builder';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import GroupSelectionModal, { GroupSelectionModalProps } from 'src/components/Modals/Group/GroupSelectionModal';

const useStyles = makeStyles()(() => ({
    root: {
        marginBottom: 12,
    },
}));

type Props = {
    onSelectGroups: (arg: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfo>, number>[]) => void;
    initialSelectedGroups: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfo>, number>[];
};

const TargetingSelectGroups = ({ onSelectGroups, initialSelectedGroups }: Props) => {
    const { classes, cx } = useStyles();
    const t = useTranslation();

    return (
        <div className={cx(classes.root)}>
            <Button
                label={`+ ${t.trans('engage.players.targeting.groups.action.add_groups')}`}
                onClick={() =>
                    NiceModal.show<
                        TreeNode<DiscriminatedEntity<ConfigModelsGroupInfo>, number>[],
                        GroupSelectionModalProps
                    >(GroupSelectionModal, { initialSelectedGroups }).then(onSelectGroups)
                }
            />
        </div>
    );
};

export default TargetingSelectGroups;
