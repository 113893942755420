import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import 'react-app-polyfill/ie11';
import 'core-js';
import 'es6-promise/auto';
import './index.css';
import App from './App';
import unregisterServiceWorker from './unregisterServiceWorker';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <Router basename={process.env.PUBLIC_URL}>
            <App />
        </Router>
    </React.StrictMode>
);

unregisterServiceWorker();
