import { useAsyncAbortable } from 'react-async-hook';
import { ConfigModelsGroupInfoWithChildren } from '@zetadisplay/engage-api-client';
import {
    createDiscriminatedEntity,
    DiscriminatedEntity,
    EntityDiscriminators,
} from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { TreeBuilder, TreeNode } from '@zetadisplay/engage-components/utils/tree-builder';

const findIfSharedGroupHasParentPresent = (id: number, groups: ConfigModelsGroupInfoWithChildren[]) =>
    groups.find((group) => group.id === id);

export type UseGroupsReturnType = {
    data: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfoWithChildren>, number>[];
    isLoading: boolean;
    refresh: () => Promise<
        Map<number, TreeNode<DiscriminatedEntity<ConfigModelsGroupInfoWithChildren>, number>> | undefined
    >;
    total: number | undefined;
};

const useGroups = (enabled: boolean = true): UseGroupsReturnType => {
    const api = useApi();
    const { workspace } = useWorkspace();

    const groups = useAsyncAbortable(
        async (signal) => {
            if (!enabled) {
                return undefined;
            }

            return api.publishGroups
                .getGroups(
                    {
                        recursive: true,
                        workspaceid: workspace.id,
                    },
                    { signal }
                )
                .then((response) => {
                    const rootGroup = response.data.items.find((group) => !group.parent && group.isShared === false);

                    if (rootGroup === undefined) {
                        throw new Error('Failed to build tree structure for groups. Parent not found!');
                    }

                    return response.data.items.map((group, _, self) =>
                        group.isShared
                            ? {
                                  ...group,
                                  parent:
                                      group.parent && findIfSharedGroupHasParentPresent(group.parent, self)
                                          ? group.parent
                                          : rootGroup.id,
                              }
                            : group
                    );
                })
                .then((response) =>
                    TreeBuilder<
                        DiscriminatedEntity<ConfigModelsGroupInfoWithChildren>,
                        ConfigModelsGroupInfoWithChildren,
                        number
                    >(
                        response,
                        (group) => createDiscriminatedEntity(EntityDiscriminators.Group, group),
                        undefined,
                        'parent'
                    )
                )
                .catch(() => undefined);
        },
        [api, enabled, workspace.id]
    );

    return {
        data: (groups.result && [...groups.result.values()]) || [],
        isLoading: groups.loading,
        refresh: groups.execute,
        total: (groups.result && [...groups.result.values()].length) || 0,
    };
};

export default useGroups;
