import { useCallback } from 'react';
import NiceModal, { NiceModalHocProps } from '@ebay/nice-modal-react';
import { EndpointsRootNetworkModelsPowerScheduleSpecialDayGroupRelations } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Icon } from '@zetadisplay/zeta-ui-components';

import ConfirmPrompt from 'src/components/Modals/ConfirmPrompt';

export type RemoveSpecialPowerSchedulePromptProps = {
    specialPowerSchedule: DiscriminatedEntity<EndpointsRootNetworkModelsPowerScheduleSpecialDayGroupRelations>;
} & NiceModalHocProps;

const RemoveSpecialPowerSchedulePrompt = NiceModal.create<RemoveSpecialPowerSchedulePromptProps>(
    ({ specialPowerSchedule }) => {
        const api = useApi();
        const t = useTranslation();
        const { workspace } = useWorkspace();

        const submitAction = useCallback(async () => {
            return api.powerSchedules
                .deleteSpecialDayGroup({
                    specialdaygroupid: specialPowerSchedule.id,
                    workspaceid: workspace.id,
                })
                .then(() => specialPowerSchedule);
        }, [api, specialPowerSchedule, workspace.id]);

        return (
            <ConfirmPrompt
                description={t.trans('engage.modal.delete.content', [specialPowerSchedule.name])}
                title={{
                    icon: <Icon type="OPENING_HOURS" />,
                    label: 'engage.modal.delete.special_schedule.title',
                }}
                submit={{ label: 'common.action.delete', name: 'delete' }}
                submitAction={submitAction}
            />
        );
    }
);

export default RemoveSpecialPowerSchedulePrompt;
