import React, { ReactNode, useMemo } from 'react';
import { useAsyncAbortable } from 'react-async-hook';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Grid, Link, Skeleton, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import {
    ConfigModelsGroupInfo,
    PublishingCampaignSchedulingCampaignInfoWithMediaSchedules,
} from '@zetadisplay/engage-api-client';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';
import {
    createDiscriminatedEntity,
    DiscriminatedEntity,
    EntityDiscriminators,
} from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ActionGroup, ContextMenu, Schedule, ScheduleToString } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import { DateTime } from 'luxon';

import FeatureFlag from 'src/components/FeatureFlag';
import { availableFeatures } from 'src/hooks/useIsFeatureEnabled';
import createSchedulesFromPlaylist from 'src/modules/playlist/utils/createSchedulesFromPlaylist';
import { statuses } from 'src/views/PlaylistView/Utils/contants';

import usePlaylistLayout from '../../Hooks/usePlaylistLayout';
import { getPlaybackModeTranslation, getPlaybackPriorityTranslation } from '../../Utils/PlaylistPlaybackDetails';

const useStyles = makeStyles()((theme) => ({
    detailSubject: {
        fontSize: 13,
        fontWeight: 600,
        marginRight: theme.spacing(1),
    },
    title: {
        marginBottom: 6,
    },
    text: {
        display: 'inline-block',
        fontSize: 13,
        lineHeight: '16px',
    },
    group: {
        display: 'inline-block',
        marginRight: 8,
        ':not(:last-of-type)&::after': {
            content: '", "',
        },
    },
    actions: {
        textAlign: 'right',
    },
    schedules: {
        'p > span': {
            display: 'block',
        },
    },
    row: {
        display: 'flex',
        marginBottom: 4,
    },
}));

type Props = {
    actions?: ActionGroup<DiscriminatedEntity<PublishingCampaignSchedulingCampaignInfoWithMediaSchedules>>[];
    item: DiscriminatedEntity<PublishingCampaignSchedulingCampaignInfoWithMediaSchedules>;
    showName?: boolean;
    showStatus?: boolean;
    showTitle?: boolean;
    sx?: SxProps;
};

const PlaylistItemDetailsInformation = ({
    actions,
    item,
    showName = false,
    showStatus = true,
    showTitle = true,
    sx,
}: Props) => {
    const api = useApi();
    const { classes, cx } = useStyles();
    const t = useTranslation();
    const snackbar = useTranslatedSnackbar();
    const { workspace } = useWorkspace();
    const { layout, zone } = usePlaylistLayout(item.layoutZoneId ?? undefined);

    const timezone = DateTime.local().zoneName;

    const groups = useAsyncAbortable(
        (signal) =>
            Promise.all(
                item.publishGroups?.map((group) =>
                    api.publishGroups
                        .getGroup(
                            {
                                groupid: group,
                                workspaceid: workspace.id,
                            },
                            { signal }
                        )
                        .then((response) => createDiscriminatedEntity(EntityDiscriminators.Group, response.data))
                        .catch(() => undefined)
                ) || []
            ).then((resolvedGroups) => {
                const hasInvalidGroups = resolvedGroups.some((group) => group === undefined);
                if (hasInvalidGroups) {
                    snackbar('engage.playlist.inaccessible.groups', [], 'warning');
                }

                return resolvedGroups.filter(
                    (group): group is DiscriminatedEntity<ConfigModelsGroupInfo> => group !== undefined
                );
            }),
        [api, item.publishGroups, snackbar, workspace.id]
    );

    const schedules = useMemo(() => {
        return createSchedulesFromPlaylist(item);
    }, [item]);

    const renderInformationDetail = (detailSubject: string, detailValue: ReactNode, isLoading?: boolean) => (
        <>
            <Typography className={cx(classes.detailSubject, classes.text)}>{detailSubject}:</Typography>
            <Typography className={cx(classes.text)}>
                {isLoading ? <Skeleton data-testid="skeleton" width={40} /> : detailValue || '-'}
            </Typography>
        </>
    );

    return (
        <Box sx={{ marginBottom: '10px', maxHeight: '240px', overflow: 'auto', ...sx }}>
            {showTitle && (
                <Typography className={cx(classes.title)} variant="h5">
                    {t.trans('engage.playlist.details.title')}
                </Typography>
            )}

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    {showName && (
                        <div data-testid="name" className={classes.row}>
                            {renderInformationDetail(t.trans('common.form.input.name'), item.name)}
                        </div>
                    )}

                    {showStatus && (
                        <div data-testid="status" className={classes.row}>
                            {renderInformationDetail(
                                t.trans('common.detail.status'),
                                t.trans(
                                    (item.status && statuses.find((status) => status.key === item.status)?.label) ||
                                        'engage.playlist.status.disabled'
                                )
                            )}
                        </div>
                    )}

                    <div data-testid="modified" className={classes.row}>
                        {renderInformationDetail(
                            t.trans('common.detail.updated'),
                            item.modified && new Date(item.modified).toLocaleString()
                        )}
                    </div>

                    <div data-testid="start" className={classes.row}>
                        {renderInformationDetail(
                            t.trans('engage.playlist.details.start'),
                            item.start && new Date(item.start).toLocaleString()
                        )}
                    </div>

                    <div data-testid="end" className={classes.row}>
                        {renderInformationDetail(
                            t.trans('engage.playlist.details.end'),
                            item.end && new Date(item.end).toLocaleString()
                        )}
                    </div>
                </Grid>

                <Grid item xs={(actions && 5) || 6}>
                    <FeatureFlag feature={availableFeatures.playlistPriority}>
                        {item.priority && (
                            <div data-testid="playback-priority" className={classes.row}>
                                {renderInformationDetail(
                                    t.trans('engage.playlist.details.playback-priority'),
                                    t.trans(getPlaybackPriorityTranslation(item.priority))
                                )}
                            </div>
                        )}
                    </FeatureFlag>

                    <div data-testid="playback-mode" className={classes.row}>
                        {renderInformationDetail(
                            t.trans('engage.playlist.details.playback-mode'),
                            t.trans(getPlaybackModeTranslation(item.mixingMode))
                        )}
                    </div>

                    <div data-testid="layout" className={classes.row}>
                        {renderInformationDetail(
                            t.trans('engage.playlist.details.layout'),
                            (layout && `${layout.name} ${zone && ` / ${zone.name}`}`) || '-'
                        )}
                    </div>
                </Grid>

                {actions && (
                    <Grid item xs={1} className={cx(classes.actions)}>
                        <ContextMenu actions={actions} item={item} />
                    </Grid>
                )}
            </Grid>

            <Grid item xs={(actions && 11) || 12}>
                <div className={classes.row}>
                    {renderInformationDetail(
                        t.trans('engage.playlist.details.groups'),
                        groups.result &&
                            groups.result.length > 0 &&
                            groups.result?.map((group) => (
                                <Link
                                    className={cx(classes.group)}
                                    key={group.id}
                                    component={RouterLink}
                                    to={`/players/${group.id}/${group.name}`}
                                >
                                    {group.name}
                                </Link>
                            )),
                        groups.loading
                    )}
                </div>

                <div data-testid="scheduling" className={cx(classes.row, classes.schedules)}>
                    {renderInformationDetail(
                        t.trans('engage.playlist.setup.scheduling.title'),
                        schedules?.map((schedule: Schedule) => (
                            <ScheduleToString key={schedule.id} schedule={schedule} timezone={timezone} />
                        ))
                    )}
                </div>
            </Grid>
        </Box>
    );
};

export default PlaylistItemDetailsInformation;
