import { version as uuidVersion } from 'uuid';

import convertScheduleToEngage from './convert-schedule-to-engage';
import { ExtendedPlaylistMediaType } from './createPlaylistFormValues';

const createCampaignMediaFromPlaylistMedia = (playlistMedia: Partial<ExtendedPlaylistMediaType>) => {
    // Copy playlistMedia into new property that we will manipulate instead of original
    const editablePlaylistMedia = { ...playlistMedia };

    // Strip id from media if it was created by engage form
    if (typeof playlistMedia.id === 'string' && uuidVersion(playlistMedia.id) === 1) {
        delete editablePlaylistMedia.id;
    }

    // Strip any model information because this can be injected by form
    if (playlistMedia.model !== undefined) {
        delete editablePlaylistMedia.model;
    }

    // Strip any media state management information
    if (playlistMedia.state !== undefined) {
        delete editablePlaylistMedia.state;
    }

    if (playlistMedia.fieldArrayId !== undefined) {
        delete editablePlaylistMedia.fieldArrayId;
    }

    return {
        ...editablePlaylistMedia,
        mediaSchedules: playlistMedia.mediaSchedules?.map((schedule) => convertScheduleToEngage(schedule)),
    };
};

export default createCampaignMediaFromPlaylistMedia;
