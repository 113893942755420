import React from 'react';
import { Typography } from '@mui/material';
import { NetworkModelsPlayerInformation, NetworkModelsPlayerTypeEnum } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()((theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
    },
    text: {
        color: theme.palette.background.disabledText,
        display: 'inline-block',
        '&:first-letter': {
            textTransform: 'uppercase',
        },
        '&:not(:last-of-type)::after': {
            content: '" • "',
            marginRight: 4,
        },
    },
}));

type Props = {
    item: DiscriminatedEntity<NetworkModelsPlayerInformation>;
    showType?: boolean;
    showShare?: boolean;
};

const PlayerItemSubtitle = ({ item, showShare = true, showType = true }: Props) => {
    const { classes, cx } = useStyles();
    const t = useTranslation();
    const { workspace } = useWorkspace();

    const type = Object.entries(NetworkModelsPlayerTypeEnum).filter((entry) => {
        return entry[1] === item.type;
    })[0][0];

    return (
        <div className={cx(classes.root)} data-testid={`player-item-subtitle-${item.id}`}>
            <Typography variant="subtitle1" noWrap className={cx(classes.text)}>
                {item.name}
            </Typography>
            {showType && (
                <Typography
                    variant="subtitle1"
                    noWrap
                    className={classes.text}
                    data-testid={`player-item-subtitle-type-${item.id}`}
                >
                    {type}
                </Typography>
            )}
            {item.workspaceId !== workspace.id && showShare && (
                <Typography variant="subtitle1" noWrap className={cx(classes.text)}>
                    {t.trans('engage.model.common.shared')}
                </Typography>
            )}
        </div>
    );
};

export default PlayerItemSubtitle;
