import React from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { IconButton } from '@mui/material';
import { createDiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { EntityDiscriminators } from '@zetadisplay/engage-components/models/entity-discriminator';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button, Icon } from '@zetadisplay/zeta-ui-components';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

import { SpecialPowerScheduleType } from 'src/views/PowerScheduleSetupView';

const { ORANGE } = themeOptions.colors;

const useStyles = makeStyles()(() => ({
    delete: {
        marginLeft: '0 !important',
    },
}));

type Props = {
    onDeletePowerSchedule: (arg: SpecialPowerScheduleType) => void;
    onSelectPowerSchedule: (arg: SpecialPowerScheduleType) => void;
    powerSchedule: SpecialPowerScheduleType;
};

const SpecialPowerScheduleLibraryItemActions = ({
    onDeletePowerSchedule,
    onSelectPowerSchedule,
    powerSchedule,
}: Props) => {
    const api = useApi();
    const { classes } = useStyles();
    const t = useTranslation();
    const { workspace } = useWorkspace();

    const handleSelectPowerSchedule = useAsyncCallback(async () => {
        const details = await api.powerSchedules
            .getSpecialDayGroupData({ specialdaygroupid: powerSchedule.id, workspaceid: workspace.id })
            .then((response) => createDiscriminatedEntity(EntityDiscriminators.SpecialPowerSchedule, response.data));

        onSelectPowerSchedule(details);
    });

    return (
        <div data-testid={`special-power-schedule-setup-library-item-actions-${powerSchedule.name}`}>
            <Button
                busy={handleSelectPowerSchedule.loading}
                onClick={() => handleSelectPowerSchedule.execute()}
                label={t.trans('common.action.edit').toUpperCase()}
            />
            <IconButton
                className={classes.delete}
                data-testid="delete-special-power-schedule"
                onClick={() => onDeletePowerSchedule(powerSchedule)}
                size="small"
            >
                <Icon type="DELETE" size={16} color={ORANGE} />
            </IconButton>
        </div>
    );
};

export default SpecialPowerScheduleLibraryItemActions;
