import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import { ContentCopy, Delete, Edit } from '@styled-icons/material-sharp';
import { ConfigModelsGroupInfoWithChildren } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity, isGroup } from '@zetadisplay/engage-components/models';
import { useUserInformation } from '@zetadisplay/engage-components/modules/auth';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ActionGroup } from '@zetadisplay/zeta-ui-components';
import queryString from 'query-string';

import DeleteGroupPrompt, { DeleteGroupPromptProps } from 'src/components/Modals/Group/DeleteGroupPrompt';
import useCurrentGroup from 'src/hooks/useCurrentGroup';
import { emitOnGroupDeleted } from 'src/views/PlayersView/Events/onGroupDeleted';

const usePlayersListGroupActions = (): ActionGroup<DiscriminatedEntity<ConfigModelsGroupInfoWithChildren>>[] => {
    const currentGroupId = useCurrentGroup();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const t = useTranslation();
    const { hasRole } = useUserInformation();

    const handleCopyAction = (group: DiscriminatedEntity<DiscriminatedEntity<ConfigModelsGroupInfoWithChildren>>) => {
        navigate(
            {
                pathname: '/players/group/create',
                search: queryString.stringify({ groupId: group.id, returnUrl: pathname, copy: true }),
            },
            { state: { currentGroupId } }
        );
    };

    const handleEditAction = (group: DiscriminatedEntity<DiscriminatedEntity<ConfigModelsGroupInfoWithChildren>>) => {
        navigate({
            pathname: '/players/group/edit',
            search: queryString.stringify({ groupId: group.id, returnUrl: pathname }),
        });
    };

    const handleDeleteAction = (group: DiscriminatedEntity<DiscriminatedEntity<ConfigModelsGroupInfoWithChildren>>) => {
        NiceModal.show<DiscriminatedEntity<ConfigModelsGroupInfoWithChildren>, DeleteGroupPromptProps>(
            DeleteGroupPrompt,
            {
                group,
            }
        ).then(emitOnGroupDeleted);
    };

    const shouldDisableAction = useCallback(
        (
            group: DiscriminatedEntity<DiscriminatedEntity<ConfigModelsGroupInfoWithChildren>>,
            roles: string | string[]
        ) => {
            return Boolean(group.isShared) || !hasRole(roles);
        },
        [hasRole]
    );

    return [
        {
            actions: [
                {
                    handler: handleEditAction,
                    icon: Edit,
                    isDisabled: (group) => {
                        return shouldDisableAction(group, ['ENGAGE_LABEL_MANAGEMENT']);
                    },
                    isVisible: isGroup,
                    label: t.trans('common.action.edit'),
                    name: 'edit',
                },
                {
                    handler: handleCopyAction,
                    icon: ContentCopy,
                    isDisabled: (group) => {
                        return shouldDisableAction(group, ['ENGAGE_LABEL_MANAGEMENT']);
                    },
                    isVisible: isGroup,
                    label: t.trans('common.action.copy'),
                    name: 'copy',
                },
                {
                    handler: handleDeleteAction,
                    icon: Delete,
                    isDisabled: (group) => {
                        return shouldDisableAction(group, ['ENGAGE_LABEL_MANAGEMENT']);
                    },
                    isVisible: isGroup,
                    label: t.trans('common.action.delete'),
                    name: 'delete',
                },
            ],
            name: 'players-list-groups-actions',
        },
    ];
};

export default usePlayersListGroupActions;
