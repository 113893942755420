import React, { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import { useUserInformation } from '@zetadisplay/engage-components/modules/auth';

import DisplayError from 'src/routing/Components/DisplayError';

type PermissionProps = {
    Component?: React.ComponentType;
    roles: string[];
};

const Permission: React.FC<PropsWithChildren<PermissionProps>> = ({ Component, roles }) => {
    const { hasRole } = useUserInformation();

    if (!hasRole(roles)) {
        return (
            <DisplayError
                title="Forbidden"
                description="Oops! You don't seem to have an access to this page. Please contact ZetaDisplay support."
            />
        );
    }

    if (Component !== undefined) {
        return <Component />;
    }

    return <Outlet />;
};

export default Permission;
