import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { usePlaylists } from '@zetadisplay/engage-components/modules/playlist';
import { FilterFields } from '@zetadisplay/engage-components/modules/search';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ComponentLoader } from '@zetadisplay/zeta-ui-components';

import SchedulingForm from 'src/components/Forms/SchedulingForm';
import PlaylistSelection from 'src/components/PlaylistSelection';
import useDefaultMediaSchedules from 'src/hooks/Library/useDefaultMediaSchedules';

type SchedulingFormProps = {
    media: DiscriminatedEntity<LibraryModelsMedia>;
};

const DefaultMediaScheduleForm = ({ media }: SchedulingFormProps) => {
    const t = useTranslation();

    const shouldSelectPlaylists = useMemo(
        () => !!(media.campaignIds && media.campaignIds.length !== 0),
        [media.campaignIds]
    );

    // Fetch default medias if media has any
    const defaultMediaSchedules = useDefaultMediaSchedules(
        media,
        Boolean(media.scheduleIds && media.scheduleIds.length !== 0)
    );

    const searchFilters = useMemo(() => ({ [FilterFields.MEDIA_ID]: media.id }), [media.id]);

    // Fetch playlists if media has any
    const playlists = usePlaylists({
        enabled: shouldSelectPlaylists,
        folderId: '',
        searchFilters,
    });

    if (defaultMediaSchedules.loading || playlists.isLoading) {
        return <ComponentLoader />;
    }

    return (
        <Box>
            <SchedulingForm mediaSchedules={defaultMediaSchedules.result || []} />
            {shouldSelectPlaylists && (
                <Box>
                    <Typography sx={{ paddingTop: 3 }} variant="h5">
                        {t.trans('engage.modal.schedule.media.content.usage')}
                    </Typography>
                    <Typography>{t.trans('engage.playlist.select.info')}</Typography>
                    <Controller
                        defaultValue={playlists.data.map((playlist) => playlist.id) || []}
                        name="selectedPlaylists"
                        render={({ field: { onChange } }) => (
                            <PlaylistSelection onChange={onChange} playlists={playlists.data} />
                        )}
                    />
                </Box>
            )}
        </Box>
    );
};

export default DefaultMediaScheduleForm;
