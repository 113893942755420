import React from 'react';
import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { Preview } from '@zetadisplay/engage-components';
import CurrentMediaInfo from '@zetadisplay/engage-components/components/preview/components/current-media-info';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useApiConfiguration } from '@zetadisplay/engage-components/modules/api';
import { getPreviewUrl } from '@zetadisplay/engage-components/utils/media';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import { colors } from '@zetadisplay/zeta-ui-components/utils/theme/theme-options';

import useMediaRelations from 'src/components/Modals/Library/MediaFile/Hooks/useMediaRelations';
import useDefaultMediaSchedules from 'src/hooks/Library/useDefaultMediaSchedules';
import useTemplate from 'src/views/LibraryView/Hooks/useTemplate';

import LibraryMediaDetailsInformation from './LibraryMediaDetails/LibraryMediaDetailsInformation';
import LibraryMediaDetailsPlaylists from './LibraryMediaDetails/LibraryMediaDetailsPlaylists';
import LibraryMediaDetailsScheduling from './LibraryMediaDetails/LibraryMediaDetailsScheduling';

const useStyles = makeStyles()(() => ({
    detailsPreview: {
        border: `1px solid ${colors.LIGHTGRAY}`,
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        position: 'relative',
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 10,
        paddingTop: 10,
    },
}));

type LibraryListMediaDetailsProps = {
    item: DiscriminatedEntity<LibraryModelsMedia>;
};

const LibraryMediaDetails = ({ item }: LibraryListMediaDetailsProps) => {
    const scheduling = useDefaultMediaSchedules(item, item.scheduleIds?.length !== 0);
    const details = useMediaRelations(item.id);
    const { classes } = useStyles();
    const apiConfig = useApiConfiguration();

    // TODO: We need to most likely refactor backend to expose template object in media object or refactor media object to include template live property
    const template = useTemplate(item.templateId);

    return (
        <div className={classes.root} data-testid="library-media-details">
            <div className={classes.detailsPreview} data-testid="library-media-details-preview">
                <Preview
                    autoplay
                    media={item}
                    previewUrl={getPreviewUrl(item, false, apiConfig.basePath)}
                    renderDatePicker={false}
                    renderSubtitle={(media) => <CurrentMediaInfo item={media} />}
                />
            </div>
            <div className={classes.root} data-testid="library-media-details-information">
                <LibraryMediaDetailsInformation item={item} template={template.result} />
                <LibraryMediaDetailsScheduling schedules={scheduling.result || []} />
                <LibraryMediaDetailsPlaylists
                    media={item}
                    playlists={
                        details.result?.campaigns
                            ?.filter((playlist, index, self) => {
                                return index === self.findIndex((p) => p.id === playlist.id);
                            })
                            .sort((left, right) => left.name.localeCompare(right.name)) || []
                    }
                />
            </div>
        </div>
    );
};

export default LibraryMediaDetails;
