import React, { memo, useMemo } from 'react';
import { Typography } from '@mui/material';
import { ConfigModelsAttributeDetails } from '@zetadisplay/engage-api-client';
import { ItemsView, SkeletonItemsView } from '@zetadisplay/engage-components';
import { DiscriminatedEntity, isAttributeValue } from '@zetadisplay/engage-components/models';
import { ViewMode } from '@zetadisplay/engage-components/modules/options';
import { getIconType } from '@zetadisplay/engage-components/modules/view/utils';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ItemInformationType, ItemProps } from '@zetadisplay/zeta-ui-components';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

const { BLACK, LIGHTGRAY, MEDIUMGRAY } = themeOptions.colors;

const useStyles = makeStyles()((theme) => ({
    root: {
        height: 'inherit',
        flex: 1,
    },
    section: {
        borderTop: '1px solid',
        borderColor: theme.palette.dark ? `${MEDIUMGRAY}66` : LIGHTGRAY,
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
    },
    container: {
        height: '100%',
        flexGrow: 1,
    },
    info: {
        color: BLACK,
        padding: 48,
    },
}));

type Props = {
    infiniteScrolling?: boolean;
    items: DiscriminatedEntity<ConfigModelsAttributeDetails>[];
    loading: boolean;
    onInfiniteScroll?: () => void;
    onItemClick?: (item: DiscriminatedEntity<ConfigModelsAttributeDetails>) => void;
    primaryAction?: (item: DiscriminatedEntity<ConfigModelsAttributeDetails>) => {
        label: string;
        handler: () => void;
        disabled: boolean;
    };
    type?: ItemInformationType;
};

const LabelsLibraryList = ({
    infiniteScrolling = false,
    items,
    loading,
    onInfiniteScroll,
    onItemClick,
    primaryAction = undefined,
    type = 'normal',
}: Props) => {
    const { classes } = useStyles();
    const t = useTranslation();

    const itemProps: ItemProps<DiscriminatedEntity<ConfigModelsAttributeDetails>> = useMemo(
        () => ({
            getItemIconType: getIconType,
            onItemClick,
            primaryAction,
            renderTitle: (item) => (isAttributeValue(item) ? item.value : item.name),
            showThumbnail: false,
            type,
        }),
        [onItemClick, primaryAction, type]
    );

    const renderItemsView = () => {
        if (loading && items.length === 0) {
            return <SkeletonItemsView actions={[]} viewMode={ViewMode.ROWS} />;
        }

        if (!items || items.length === 0) {
            return (
                <Typography align="center" className={classes.info}>
                    {t.trans('engage.players.content.no_labels')}
                </Typography>
            );
        }

        return (
            <ItemsView
                infiniteScrolling={infiniteScrolling}
                isLoadingMore={infiniteScrolling && loading && items.length > 0}
                ItemProps={itemProps}
                items={items}
                onInfiniteScroll={onInfiniteScroll}
                viewMode={ViewMode.ROWS}
            />
        );
    };

    return (
        <div id="labelsList" className={classes.root} data-testid="labelsList">
            <section className={classes.section}>
                <div className={classes.container}>{renderItemsView()}</div>
            </section>
        </div>
    );
};

export default memo(LabelsLibraryList);
