import { useCallback, useState } from 'react';
import { useAsyncAbortable } from 'react-async-hook';
import { NetworkModelsPlayerInformation } from '@zetadisplay/engage-api-client';
import { usePaginatedRequests, usePaginatedResults } from '@zetadisplay/engage-components/hooks';
import getResultData from '@zetadisplay/engage-components/hooks/utils/get-result-data';
import { DiscriminatedEntity, EntityDiscriminators } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { getOrderByProperties, getOrderByQuery, useSorting } from '@zetadisplay/engage-components/modules/options';
import { QueryOperation } from '@zetadisplay/engage-components/modules/search';
import {
    hasSearchEventScope,
    SearchEventScope,
    useOnSearchListener,
} from '@zetadisplay/engage-components/modules/search/events';
import { getQueryFilter } from '@zetadisplay/engage-components/modules/search/query-filters';
import { QueryField } from '@zetadisplay/engage-components/modules/search/search';
import { createKeywordFilterQuery } from '@zetadisplay/engage-components/modules/search/utils';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { uuidRegex } from '@zetadisplay/engage-components/utils/route';
import { SearchFilters, SearchState } from '@zetadisplay/zeta-ui-components';
import objectHash from 'object-hash';

import { PlayerView } from 'src/views/PlayersView/Hooks/usePlayersView';

const getPlayersSearchTermFields = (): (string | QueryField)[] => {
    return [
        { condition: (term: string) => uuidRegex.test(term), operation: QueryOperation.Eq, property: 'guid' },
        'name',
        'friendlyName',
    ];
};

export type UsePlayersReturnType = {
    data: DiscriminatedEntity<NetworkModelsPlayerInformation>[];
    getNextResultPage: () => void;
    isLoading: boolean;
    total: number | undefined;
};

const usePlayers = (): UsePlayersReturnType => {
    const api = useApi();
    const sorting = useSorting();
    const { workspace } = useWorkspace();

    const [searchFilters, setSearchFilters] = useState<SearchState<SearchFilters>>({});
    const filter = getQueryFilter([createKeywordFilterQuery(searchFilters, getPlayersSearchTermFields())]);
    const filterHash = objectHash(searchFilters);

    const { $top, $skip, getNextResultPage } = usePaginatedRequests(PlayerView.PLAYERS, filterHash);
    const { results, setResults, total } = usePaginatedResults<NetworkModelsPlayerInformation>(
        PlayerView.PLAYERS,
        EntityDiscriminators.Player,
        filterHash,
        getOrderByProperties('Player', sorting.sort)
    );

    // On API error, set and return current data to avoid erroneous UX state
    // i.e. don't allow infinite loading to get stuck
    const onErrorHandler = useCallback(() => {
        const data = getResultData<NetworkModelsPlayerInformation>(results.current);
        return setResults(data, data.length);
    }, [results, setResults]);

    const players = useAsyncAbortable(
        async (signal) => {
            return api.players
                .getPlayers(
                    {
                        workspaceid: workspace.id,
                        $filter: filter,
                        $orderby: getOrderByQuery('Player', sorting.sort),
                        $top,
                        $skip,
                    },
                    { signal }
                )
                .then((response) => setResults(response.data.items, response.data.total))
                .catch(onErrorHandler);
        },
        [$skip, $top, api.players, filter, onErrorHandler, setResults, sorting.sort, workspace.id],
        {
            setLoading: (state) => {
                return { ...state, result: results.current && [...results.current.values()], loading: true };
            },
        }
    );

    useOnSearchListener((event) => {
        if (hasSearchEventScope(event, SearchEventScope.PLAYER)) {
            setSearchFilters(event.value);
        }
    });

    return {
        data: (results.current && [...(results.current.values() || [])]) || [],
        getNextResultPage,
        isLoading: players.loading,
        total: total.current,
    };
};

export default usePlayers;
