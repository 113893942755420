import { useCallback, useMemo } from 'react';
import { FieldArrayWithId } from 'react-hook-form';
import NiceModal from '@ebay/nice-modal-react';
import { LibraryModelsMedia, LibraryModelsMediaActions } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity, isMediaFile } from '@zetadisplay/engage-components/models';
import { useUserInformation } from '@zetadisplay/engage-components/modules/auth';
import { EditMediaFileFields } from '@zetadisplay/engage-components/modules/library';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ActionGroup } from '@zetadisplay/zeta-ui-components';

import EditMediaFileForm, {
    UpdateMediaFileFormProps,
} from '../../../../../components/Modals/PlaylistSetup/EditMediaFileForm';
import RemovePlaylistMediaPrompt, {
    RemovePlaylistMediaProps,
} from '../../../../../components/Modals/PlaylistSetup/RemovePlaylistMediaPrompt';
import SchedulePlaylistMediaForm, {
    ScheduleMediaFileFields,
    ScheduleMediaFileFormProps,
} from '../../../../../components/Modals/PlaylistSetup/SchedulePlaylistMediaForm';
import { isVisibleForZetaCastTemplate } from '../../../../../views/LibraryView/Utils/TemplateUtils';
import { emitOnPlaylistMediaRemoved } from '../../../../../views/PlaylistSetupView/Events/onPlaylistMediaRemovedEvent';
import { PlaylistSetupFormValues } from '../../../utils/createPlaylistFormValues';
import { emitOnPlaylistContentEdited } from '../events/on-playlist-content-edited-event';
import { emitOnPlaylistContentRemoveUndo } from '../events/on-playlist-content-remove-undo-event';
import { emitOnPlaylistContentScheduled } from '../events/on-playlist-content-scheduled-event';

const usePlaylistContentListItemActions = <
    T extends FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>
>(
    copies: string[],
    modifiedData: undefined | EditMediaFileFields
): ActionGroup<T>[] => {
    const { hasRole } = useUserInformation();
    const t = useTranslation();

    const handleEditPlaylistMedia = useCallback(
        (item: T) => {
            if (item.model) {
                NiceModal.show<DiscriminatedEntity<LibraryModelsMedia>, UpdateMediaFileFormProps>(EditMediaFileForm, {
                    media: { ...item.model, ...modifiedData },
                }).then((data) => emitOnPlaylistContentEdited({ item, data }));
            }
        },
        [modifiedData]
    );

    const handleRemovePlaylistMedia = useCallback(
        (item: T) => {
            NiceModal.show<string[], RemovePlaylistMediaProps>(RemovePlaylistMediaPrompt, {
                playlistMedia: item,
                copies,
            }).then(emitOnPlaylistMediaRemoved);
        },
        [copies]
    );

    const handleSchedulePlaylistMedia = useCallback((item: T) => {
        NiceModal.show<ScheduleMediaFileFields, ScheduleMediaFileFormProps>(SchedulePlaylistMediaForm, {
            mediaSchedules: item.mediaSchedules || [],
        }).then((data) => emitOnPlaylistContentScheduled({ item, data }));
    }, []);

    const isVisibleForMediaFile = useCallback((item: T) => {
        return (item.model && isMediaFile(item.model) && !isVisibleForZetaCastTemplate(item.model)) || false;
    }, []);

    return useMemo(
        () => [
            {
                actions: [
                    {
                        handler: handleEditPlaylistMedia,
                        isVisible: isVisibleForMediaFile,
                        isDisabled: (item: T) => {
                            return (
                                !item.model ||
                                !hasRole(['ENGAGE_LIBRARY_CONTENT_EDIT']) ||
                                !(item.model.actionFlags & LibraryModelsMediaActions.Edit)
                            );
                        },
                        label: t.trans('common.action.edit'),
                        name: 'edit-item',
                    },
                    {
                        handler: handleRemovePlaylistMedia,
                        label: t.trans('common.action.remove'),
                        name: 'remove-item',
                        isVisible: (item) => item.state !== 'removed',
                    },
                    {
                        handler: emitOnPlaylistContentRemoveUndo,
                        label: t.trans('engage.misc.undo_removal'),
                        name: 'undo-remove-item',
                        isVisible: (item) => item.state === 'removed',
                    },
                    {
                        handler: handleSchedulePlaylistMedia,
                        label: t.trans('engage.action.schedule'),
                        name: 'schedule-item',
                    },
                ],
                name: 'playlist-content-list-item-actions',
            },
        ],
        [
            handleEditPlaylistMedia,
            handleRemovePlaylistMedia,
            handleSchedulePlaylistMedia,
            hasRole,
            isVisibleForMediaFile,
            t,
        ]
    );
};

export default usePlaylistContentListItemActions;
