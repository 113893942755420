import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
    ConfigModelsAttributeDetails,
    ConfigModelsAttributeValueBasic,
    NetworkModelsPlayersPlayerBasic,
} from '@zetadisplay/engage-api-client';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';
import { ThreeColumnView } from '@zetadisplay/engage-components/layouts/components';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useAttributes, useAttributeWithValues } from '@zetadisplay/engage-components/modules/players/hooks';
import { useSearchFilters } from '@zetadisplay/engage-components/modules/search';
import { SearchEventScope } from '@zetadisplay/engage-components/modules/search/events';
import { createButtonClickEvent, pushToDataLayer } from '@zetadisplay/zeta-ui-components/utils/data-layer';

import { LABEL_SETUP_VIEW } from 'src/constants/Views';
import withDarkLayout from 'src/utils/Layout/withDarkLayout';
import LabelSetupActions from 'src/views/LabelSetupView/Components/LabelSetupActions';
import LabelSetupLibrary from 'src/views/LabelSetupView/Components/LabelSetupLibrary';
import LabelSetupMainForm from 'src/views/LabelSetupView/Components/LabelSetupMainForm';

export enum LabelValueState {
    DELETED = 'deleted',
    NEW = 'new',
}

export enum LabelValuePlayerState {
    DELETED = 'deleted',
    NEW = 'new',
}

export type LabelValuePlayerType = DiscriminatedEntity<
    NetworkModelsPlayersPlayerBasic & { state?: LabelValuePlayerState }
>;

export type LabelValueType = DiscriminatedEntity<
    ConfigModelsAttributeValueBasic & {
        labelId: number;
        players: LabelValuePlayerType[];
        state?: LabelValueState;
    }
>;

export type LabelSetupFormData = {
    description: string;
    name: string;
    values: LabelValueType[];
};

const getDefaultSelectedLabelId = (id: string | undefined) => {
    const selectedLabelId = Number(id);
    return Number.isNaN(selectedLabelId) ? 0 : selectedLabelId;
};

const LabelSetupView = () => {
    const { attributeId } = useParams<'attributeId'>();
    const methods = useForm<LabelSetupFormData>({
        defaultValues: { description: '', name: '', values: [] },
        mode: 'onChange',
        shouldUnregister: false,
    });
    const notify = useTranslatedSnackbar();

    // Selected state, default state will be parsed from params
    const [selectedLabelId, setSelectedLabelId] = useState(getDefaultSelectedLabelId(attributeId));
    const [searchFilters, searchFiltersKey] = useSearchFilters([SearchEventScope.LABEL]);

    // LabelSetup states & values
    const labels = useAttributes({ key: LABEL_SETUP_VIEW, searchFilters, searchFiltersKey });
    const labelWithValues = useAttributeWithValues(selectedLabelId !== 0 ? selectedLabelId : undefined);

    /**
     * Clear form to empty state
     */
    const handleClearForm = useCallback(() => {
        // We should reset selected id first
        setSelectedLabelId(0);

        // Now reset the form, maybe not the best way, but it should in theory default to defaultValues
        methods.reset();
        methods.clearErrors();
    }, [methods]);

    const onClearForm = useCallback(() => {
        handleClearForm();
        pushToDataLayer(createButtonClickEvent('Label Settings', 'Clear Label'));
    }, [handleClearForm]);

    const onRemoveLabel = useCallback(
        (label: DiscriminatedEntity<ConfigModelsAttributeDetails>) => {
            // If removed label is same as selected make sure we clear form first
            if (selectedLabelId === label.id) {
                handleClearForm();
            }

            labels.removeResult(label);
            notify('engage.notification.delete.label.success', [label.name], 'success');
            pushToDataLayer(createButtonClickEvent('Editing option', 'Delete'));
        },
        [handleClearForm, labels, notify, selectedLabelId]
    );

    const onUpdatedAttribute = useCallback(
        (attribute: DiscriminatedEntity<ConfigModelsAttributeDetails>) => {
            handleClearForm();
            labels.setResult(attribute);
            pushToDataLayer(createButtonClickEvent('Label Settings', 'Save Label'));
        },
        [handleClearForm, labels]
    );

    /**
     * Register form fields on first render
     */
    useEffect(() => {
        methods.register('values', {
            value: [],
        });
    }, [methods]);

    /**
     * When label with values has been loaded, set the form data3
     */
    useEffect(() => {
        if (selectedLabelId === 0 || labelWithValues.loading || labelWithValues.result === undefined) {
            return;
        }

        const { name, description, attributeValues } = labelWithValues.result;
        const values = attributeValues?.map(
            (attributeValue): LabelValueType => ({
                ...attributeValue,
                labelId: selectedLabelId,
                players: [],
            })
        );

        methods.setValue('name', name);
        methods.setValue('description', description ?? '');
        methods.setValue('values', values || []);
    }, [labelWithValues.loading, labelWithValues.result, methods, selectedLabelId]);

    return (
        <FormProvider {...methods}>
            <ThreeColumnView
                actions={
                    <LabelSetupActions
                        attribute={labels.data.find((label) => label.id === selectedLabelId)}
                        attributeValues={labelWithValues.result?.attributeValues || []}
                        isLoading={labelWithValues.loading}
                        onClearForm={onClearForm}
                        onUpdatedAttribute={onUpdatedAttribute}
                    />
                }
                center={<LabelSetupMainForm isLoading={labelWithValues.loading} labelId={selectedLabelId} />}
                heading="engage.players.labels.setup.title"
                icon="LABELS"
                left={
                    <LabelSetupLibrary
                        labels={labels}
                        onRemoveLabel={onRemoveLabel}
                        onSelectLabel={setSelectedLabelId}
                    />
                }
            />
        </FormProvider>
    );
};

export default withDarkLayout(LabelSetupView);
