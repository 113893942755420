import {
    ConfigModelsAttributeDetails,
    ConfigModelsAttributeRelations,
    ConfigModelsAttributeValueBasic,
} from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { ApiInterface } from '@zetadisplay/engage-components/modules/api';
import { NotifyFunctionType } from '@zetadisplay/zeta-ui-components/hooks/use-notify';
import { createButtonClickEvent, pushToDataLayer } from '@zetadisplay/zeta-ui-components/utils/data-layer';
import pLimit from 'p-limit';

import handlePendingPromise, { PendingPromiseMessages } from 'src/utils/HandlePendingPromise';
import { LabelValueState, LabelValueType } from 'src/views/LabelSetupView';

const limit = pLimit(1);

const createAttributeValuePromises = (
    api: ApiInterface,
    attribute: ConfigModelsAttributeRelations,
    data: LabelValueType[],
    workspaceId: string
): Promise<ConfigModelsAttributeValueBasic[]> => {
    return Promise.all(
        data.map((attributeValue) => {
            return limit(async () => {
                return api.attributes
                    .createAttributeValue({
                        body: { value: attributeValue.value },
                        attributeid: attribute.id,
                        workspaceid: workspaceId,
                    })
                    .then((createdValue) => ({ ...attributeValue, ...createdValue.data, labelId: attribute.id }));
            });
        })
    );
};

const createAttributeValues = (
    api: ApiInterface,
    attribute: DiscriminatedEntity<ConfigModelsAttributeDetails>,
    data: LabelValueType[],
    workspaceId: string,
    notify: NotifyFunctionType
) => {
    const pendingValues = data.filter((value) => value.state === LabelValueState.NEW);
    if (pendingValues.length === 0) {
        return [];
    }

    const hasMultipleValues = pendingValues.length > 1;
    const messages: PendingPromiseMessages = {
        pending: {
            message: 'engage.notification.create.label.value.info',
            params: [hasMultipleValues ? pendingValues.length.toString() : pendingValues[0].value],
            plural: hasMultipleValues,
        },
        success: {
            message: 'engage.notification.create.label.value.success',
            params: [hasMultipleValues ? pendingValues.length.toString() : pendingValues[0].value],
            plural: hasMultipleValues,
        },
    };

    return handlePendingPromise(
        createAttributeValuePromises(api, attribute, pendingValues, workspaceId),
        messages,
        notify
    ).finally(() => {
        pushToDataLayer(createButtonClickEvent('Label Settings', 'Create Label'));
    });
};

export default createAttributeValues;
