import {
    PublishingCampaignSchedulingCampaignMediaSchedule,
    PublishingCampaignSchedulingCampaignSegment,
    PublishingCampaignSchedulingSchedule,
} from '@zetadisplay/engage-api-client';
import createBinaryValueFromWeekdays from '@zetadisplay/engage-components/utils/scheduling/create-binary-value-from-weekdays';
import getDateFromSchedule from '@zetadisplay/engage-components/utils/scheduling/get-date-from-schedule';
import { Schedule } from '@zetadisplay/zeta-ui-components';

import findSegmentId from './find-segment-id';

const createSegmentFromSchedule = (
    schedule: Schedule,
    schedules?: (PublishingCampaignSchedulingSchedule | PublishingCampaignSchedulingCampaignMediaSchedule)[] | null
): PublishingCampaignSchedulingCampaignSegment => {
    const endTime = getDateFromSchedule(schedule, 'endTime');
    const startTime = getDateFromSchedule(schedule, 'startTime');

    return {
        id: findSegmentId(schedule, schedules),
        end:
            (endTime &&
                `${String(endTime.getHours()).padStart(2, '0')}:${String(endTime.getMinutes()).padStart(2, '0')}`) ||
            null,
        daysOfWeek: createBinaryValueFromWeekdays(schedule),
        start:
            (startTime &&
                `${String(startTime.getHours()).padStart(2, '0')}:${String(startTime.getMinutes()).padStart(
                    2,
                    '0'
                )}`) ||
            null,
    };
};

export default createSegmentFromSchedule;
