import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import { Typography } from '@mui/material';
import { PublishingCampaignSchedulingCampaignInfoWithMediaSchedules } from '@zetadisplay/engage-api-client';
import { ItemsView, SkeletonItemsView } from '@zetadisplay/engage-components';
import { DiscriminatedEntity, isCampaign, isPlaylistFolder } from '@zetadisplay/engage-components/models';
import { ViewMode } from '@zetadisplay/engage-components/modules/options';
import { PlaylistsItemType } from '@zetadisplay/engage-components/modules/playlist';
import { getIconType } from '@zetadisplay/engage-components/modules/view/utils';
import { generateRouteFromItem, RouteNode } from '@zetadisplay/engage-components/utils/route/route';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ActionGroup, ItemProps } from '@zetadisplay/zeta-ui-components';
import { useExpandableItems } from '@zetadisplay/zeta-ui-components/hooks';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

import PlaylistItemDetails from 'src/views/PlaylistView/Components/PlaylistItemDetails';
import PlaylistItemSubtitle from 'src/views/PlaylistView/Components/PlaylistItemSubtitle';
import usePlaylistViewListFolderActions from 'src/views/PlaylistView/Hooks/usePlaylistViewListFolderActions';
import usePlaylistViewListPlaylistActions from 'src/views/PlaylistView/Hooks/usePlaylistViewListPlaylistActions';

import PlaylistPreviewSidekick, {
    PlaylistPreviewSidekickProps,
} from '../../../modules/playlist/sidekick/playlist-preview-sidekick/playlist-preview-sidekick';

const { BLACK } = themeOptions.colors;

const useStyles = makeStyles<{ count: number }>()((_, { count }) => ({
    calculatedPlaylistHeight: {
        height: count * 71,
        maxHeight: 141,
    },
    info: {
        color: BLACK,
        padding: 48,
    },
    root: {
        flexGrow: 1,
    },
    section: {
        borderTop: '1px solid',
        borderColor: '#D9D9D9',
        height: '100%',
        width: '100%',
    },
}));

export type PlaylistViewListItems = PlaylistsItemType[];

type Props = {
    basePath?: string;
    calculateFixedHeight?: boolean;
    infiniteScrolling?: boolean;
    items: PlaylistViewListItems;
    loading?: boolean;
    onInfiniteScroll?: () => void;
    path: RouteNode[];
    scrollingDisabled?: boolean;
    skeletons?: number;
};

const PlaylistViewList = ({
    basePath = '/playlists',
    calculateFixedHeight = false,
    infiniteScrolling = false,
    items,
    loading,
    onInfiniteScroll,
    path,
    scrollingDisabled = false,
    skeletons = 4,
}: Props) => {
    const { classes, cx } = useStyles({ count: items.length });
    const { expandedItems, expandItem } = useExpandableItems<PlaylistsItemType>(items);
    const navigate = useNavigate();
    const playlistActions = usePlaylistViewListPlaylistActions();
    const playlistFolderActions = usePlaylistViewListFolderActions();
    const t = useTranslation();

    // TODO: following height and maxHeight are just hacks to make the items render since grid + flex doesn't work
    // This is required to render RecentlyModifiedPlaylists properly
    const sectionClasses = cx({
        [classes.section]: true,
        [classes.calculatedPlaylistHeight]: calculateFixedHeight,
    });

    const generateFolderRoute = useCallback(
        (item: PlaylistsItemType) => {
            return generateRouteFromItem(`${basePath}/folders`, item, path);
        },
        [basePath, path]
    );

    const renderPlaylistSubtitle = (
        playlist: DiscriminatedEntity<PublishingCampaignSchedulingCampaignInfoWithMediaSchedules>
    ) => {
        return <PlaylistItemSubtitle playlist={playlist} />;
    };

    const handleItemClick = useCallback(
        (item: PlaylistsItemType) => {
            if (isPlaylistFolder(item)) {
                return navigate(generateFolderRoute(item));
            }

            return NiceModal.show<void, PlaylistPreviewSidekickProps>(PlaylistPreviewSidekick, { playlist: item });
        },
        [generateFolderRoute, navigate]
    );

    const itemProps: ItemProps<PlaylistsItemType> = useMemo(
        () => ({
            actions: [...playlistActions, ...playlistFolderActions] as ActionGroup<PlaylistsItemType>[],
            clickable: (item) => isPlaylistFolder(item) || isCampaign(item),
            expandable: (item) => !calculateFixedHeight && isCampaign(item),
            getItemIconType: getIconType,
            inViewThreshold: 0,
            onItemClick: handleItemClick,
            renderExpandedDetails: (item) => (isCampaign(item) ? <PlaylistItemDetails item={item} /> : null),
            renderSubtitle: (item) => (isCampaign(item) && renderPlaylistSubtitle(item)) || null,
            showThumbnail: false,
        }),
        [calculateFixedHeight, handleItemClick, playlistActions, playlistFolderActions]
    );

    const renderItemsView = useCallback(() => {
        if (loading && items.length === 0) {
            return <SkeletonItemsView actions={[]} items={skeletons} viewMode={ViewMode.ROWS} />;
        }

        if (!items || items.length === 0) {
            return (
                <Typography align="center" className={classes.info}>
                    {t.trans('engage.library.content.no_content')}
                </Typography>
            );
        }

        return (
            <ItemsView
                expandedItems={expandedItems}
                infiniteScrolling={infiniteScrolling}
                isLoadingMore={(loading && items.length > 0) || false}
                ItemProps={itemProps}
                items={items}
                onExpandItemToggle={expandItem}
                onInfiniteScroll={onInfiniteScroll}
                scrollingDisabled={scrollingDisabled}
                viewMode={ViewMode.ROWS}
            />
        );
    }, [
        classes.info,
        expandItem,
        expandedItems,
        infiniteScrolling,
        itemProps,
        items,
        loading,
        onInfiniteScroll,
        scrollingDisabled,
        skeletons,
        t,
    ]);

    return (
        <div id="playlistsList" className={classes.root} data-testid="playlistsList">
            <section className={sectionClasses}>{renderItemsView()}</section>
        </div>
    );
};

export default PlaylistViewList;
