import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { handleResponseError } from '@zetadisplay/engage-components/utils/response';
import useNotify from '@zetadisplay/zeta-ui-components/hooks/use-notify';
import { AxiosError } from 'axios';

import { PrimaryPowerScheduleFormData, PrimaryPowerScheduleType } from 'src/views/PowerScheduleSetupView';
import assignPlayersToPowerSchedule from 'src/views/PowerScheduleSetupView/Actions/assignPlayersToPowerSchedule';
import createOrUpdatePowerSchedule from 'src/views/PowerScheduleSetupView/Actions/createOrUpdatePowerSchedule';

export type OnSubmittedActionCallback = (powerSchedule: PrimaryPowerScheduleType) => void;

const usePowerScheduleSubmitAction = (onSubmitted?: OnSubmittedActionCallback) => {
    const api = useApi();
    const notify = useNotify();
    const { workspace } = useWorkspace();
    const { reset } = useFormContext<PrimaryPowerScheduleFormData>();

    return useCallback(
        async (data: PrimaryPowerScheduleFormData) => {
            try {
                const powerSchedule = await createOrUpdatePowerSchedule(api, data, workspace.id, notify);

                // TODO: Refactor assignedPlayers with state to update them only if we have new or removed players
                await assignPlayersToPowerSchedule(api, data, powerSchedule, workspace.id, notify);

                if (onSubmitted) {
                    onSubmitted({ ...powerSchedule, workspaceId: workspace.id });
                }

                reset();
            } catch (e) {
                if (!(e instanceof AxiosError)) {
                    throw e;
                }

                notify({ message: handleResponseError(e), variant: 'error' });
            }
        },
        [api, notify, onSubmitted, reset, workspace.id]
    );
};

export default usePowerScheduleSubmitAction;
