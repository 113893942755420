import React, { useCallback, useMemo } from 'react';
import { FieldArrayWithId } from 'react-hook-form';
import NiceModal from '@ebay/nice-modal-react';
import { Box, Typography } from '@mui/material';
import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { PlaylistSetupModifiedMediaType } from '@zetadisplay/engage-components/modules/playlist';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { SplitButton, SplitButtonOption } from '@zetadisplay/zeta-ui-components';

import { emitOnPlaylistMediaRemoved } from '../../../../views/PlaylistSetupView/Events/onPlaylistMediaRemovedEvent';
import ScheduleMultiplePlaylistItemsModal, {
    ScheduleMultiplePlaylistItemsModalProps,
} from '../../components/schedule-multiple-playlist-items-modal/schedule-multiple-playlist-items-modal';
import { PlaylistSetupFormValues } from '../../utils/createPlaylistFormValues';
import getPlaylistTotalDuration from '../../utils/get-playlist-total-duration';
import {
    emitOnPlaylistContentScheduled,
    OnPlaylistContentScheduledEventPayload,
} from './events/on-playlist-content-scheduled-event';
import PlaylistPreviewConfirmBulkRemovePrompt, {
    PlaylistPreviewConfirmBulkRemovePromptProps,
} from './playlist-preview-confirm-bulk-remove-prompt';

type Props = {
    loading: boolean;
    modifiedMedia: Map<string, PlaylistSetupModifiedMediaType>;
    playlistMediaCollection: FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>[];
    selectAllItems: (
        items: FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>[],
        selectAll: boolean
    ) => void;
    selectedItems: Record<string, FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>>;
};

const PlaylistPreviewContentHeader = ({
    loading,
    modifiedMedia,
    playlistMediaCollection,
    selectAllItems,
    selectedItems,
}: Props) => {
    const { trans } = useTranslation();

    const selectedItemsArray = useMemo(() => Object.values(selectedItems), [selectedItems]);

    const showScheduleMultipleMediaModal = useCallback(() => {
        NiceModal.show<OnPlaylistContentScheduledEventPayload, ScheduleMultiplePlaylistItemsModalProps>(
            ScheduleMultiplePlaylistItemsModal,
            { items: selectedItemsArray }
        ).then(({ item, data }) => emitOnPlaylistContentScheduled({ item, data }));
    }, [selectedItemsArray]);

    const confirmBulkDelete = useCallback(() => {
        NiceModal.show<
            FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>[],
            PlaylistPreviewConfirmBulkRemovePromptProps
        >(PlaylistPreviewConfirmBulkRemovePrompt, { items: selectedItemsArray }).then((items) => {
            emitOnPlaylistMediaRemoved(items.map((item) => item.id).filter((item) => item !== undefined) as string[]);
        });
    }, [selectedItemsArray]);

    const bulkActions = useMemo(
        (): SplitButtonOption[] => [
            {
                key: 'bulk-schedule',
                disabled: selectedItemsArray.length === 0 || loading,
                label: trans('engage.action.schedule'),
                onClick: showScheduleMultipleMediaModal,
            },
            {
                key: 'bulk-delete',
                disabled: selectedItemsArray.length === 0 || loading,
                label: trans('common.action.remove'),
                onClick: confirmBulkDelete,
            },
        ],
        [confirmBulkDelete, loading, selectedItemsArray.length, showScheduleMultipleMediaModal, trans]
    );

    const items = playlistMediaCollection
        .filter((media) => media.state !== 'removed')
        .map((media) => {
            const modifiedData = (media.model && modifiedMedia.get(media.model.id)?.data) || undefined;

            // TODO Check typing, seems that modifiedData is object not fields??
            return {
                ...media,
                model: (modifiedData as DiscriminatedEntity<LibraryModelsMedia>) || media.model,
            };
        });

    return (
        <Box
            data-testid="playlist-preview-content-header"
            sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}
        >
            <Box sx={{ flexGrow: 1 }}>
                <SplitButton
                    fillPrimaryAction={() =>
                        !!(selectedItemsArray.length && selectedItemsArray.length === items?.length)
                    }
                    fillSecondaryAction={() => selectedItemsArray.length !== 0}
                    onPrimaryActionClick={() => selectAllItems(items, items.length !== selectedItemsArray.length)}
                    options={bulkActions}
                    primaryActionText={trans('common.action.select_all')}
                    popperSx={{
                        zIndex: 2000,
                    }}
                    size="small"
                    variant="outlined"
                />
            </Box>

            {!loading && (
                <>
                    <Typography sx={{ marginRight: '6px' }}>
                        {`${items.length} `}

                        <Typography component="span" sx={{ fontWeight: 600 }}>
                            {trans('engage.playlist.content.items')}
                        </Typography>
                    </Typography>

                    <Typography sx={{ fontWeight: 600, marginRight: '4px' }}>{`${trans(
                        'engage.playlist.details.duration'
                    )}:`}</Typography>

                    <Typography>{getPlaylistTotalDuration(items)}</Typography>
                </>
            )}
        </Box>
    );
};

export default PlaylistPreviewContentHeader;
