import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()(() => ({
    header: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        width: '100%',
    },
}));

export type PlayerSettingsHeaderProps = {
    isSubmitBusy: boolean;
    isSubmitDisabled: boolean;
    onSubmitClick: (e?: React.BaseSyntheticEvent) => Promise<void>;
};

const PlayerSettingsHeader: React.FC<PlayerSettingsHeaderProps> = ({
    isSubmitBusy,
    isSubmitDisabled,
    onSubmitClick,
}) => {
    const { classes } = useStyles();
    const { trans } = useTranslation();

    return (
        <Box className={classes.header}>
            <Typography noWrap variant="h1">
                {trans('engage.players.settings.title')}
            </Typography>
            <Button
                busy={isSubmitBusy}
                disabled={isSubmitDisabled}
                kind="primaryAction"
                label={trans('common.action.update')}
                name="update"
                onClick={onSubmitClick}
                sx={{ borderRadius: '8px' }}
            />
        </Box>
    );
};

export default PlayerSettingsHeader;
