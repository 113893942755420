import React from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()((theme) => ({
    detailSubject: {
        flex: '0 0 20%',
        fontSize: 13,
        marginRight: theme.spacing(1),
    },
    detailValue: {
        flexGrow: '0 0 80%',
        fontWeight: 600,
    },
    row: {
        display: 'flex',
        margin: '4px 0',
    },
    text: {
        display: 'inline-block',
        fontSize: 12,
        lineHeight: '16px',
    },
}));

type ItemDetailsProps = {
    loading?: boolean;
    name: string;
    subject: string;
    value: string;
};

const LibraryMediaDetailsDetail = ({ loading, name, subject, value }: ItemDetailsProps) => {
    const { classes, cx } = useStyles();
    const { trans } = useTranslation();

    return (
        <Box data-testid={`expanded-details-detail-${name}`} className={classes.row}>
            <Typography
                className={cx(classes.detailSubject, classes.text)}
                data-testid={`expanded-details-detail-${name}-subject`}
            >
                {trans(subject)}
            </Typography>
            {loading ? (
                <Skeleton
                    variant="text"
                    width={100}
                    height={16}
                    className={classes.detailValue}
                    data-testid={`expanded-details-detail-${name}-value`}
                />
            ) : (
                <Typography
                    className={cx(classes.detailValue, classes.text)}
                    data-testid={`expanded-details-detail-${name}-value`}
                >
                    {value}
                </Typography>
            )}
        </Box>
    );
};

export default LibraryMediaDetailsDetail;
