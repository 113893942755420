import React, { useMemo } from 'react';
import { FieldArrayWithId } from 'react-hook-form';
import { NcastContractsEntitiesConstraintTypes } from '@zetadisplay/engage-api-client';
import { ConfigModelsAttributeConstraintRef } from '@zetadisplay/engage-api-client/models/config-models-attribute-constraint-ref';
import { ConfigModelsGroupPersistModel } from '@zetadisplay/engage-api-client/models/config-models-group-persist-model';
import { SelectInput } from '@zetadisplay/engage-components';
import { useTranslation } from '@zetadisplay/zeta-localization';

import { determinePossibleConstraintsOptions } from 'src/views/PlayersView/Utils/Constraints';

type Props = {
    attributeConstraint: ConfigModelsAttributeConstraintRef;
    attributesConstraints: FieldArrayWithId<ConfigModelsGroupPersistModel, 'attributesConstraints', 'key'>[];
    disabled?: boolean;
    index: number;
};

const AttributeConstraintSelect = ({ attributeConstraint, attributesConstraints, disabled, index }: Props) => {
    const t = useTranslation();

    /**
     * Determine which constraints are available
     */
    const constraints = useMemo(() => {
        const options = [
            {
                disabled: false,
                id: NcastContractsEntitiesConstraintTypes.CAN_BE,
                name: t.trans('engage.players.labels.constraint.can_be.title'),
            },
            {
                disabled: false,
                id: NcastContractsEntitiesConstraintTypes.CAN_NOT_BE,
                name: t.trans('engage.players.labels.constraint.can_not_be.title'),
            },
            {
                disabled: false,
                id: NcastContractsEntitiesConstraintTypes.CAN_BE_ANY,
                name: t.trans('engage.players.labels.constraint.can_be_any.title'),
            },
        ];

        const allowedConstraints = determinePossibleConstraintsOptions(
            attributeConstraint.attributeId,
            index,
            attributesConstraints
        );

        // Disable options which are not allowed to keep UI consistent and
        // avoid issues with Select component when options change all together
        return options.map((option) => ({ ...option, disabled: !allowedConstraints.includes(option.id) }));
    }, [index, attributeConstraint.attributeId, attributesConstraints, t]);

    const constraintDisabled = attributesConstraints.some(
        (value, idx) =>
            index < idx &&
            value.attributeId === attributeConstraint.attributeId &&
            attributeConstraint.constraintType === NcastContractsEntitiesConstraintTypes.CAN_BE_ANY &&
            value.constraintType === NcastContractsEntitiesConstraintTypes.CAN_NOT_BE
    );

    return (
        <SelectInput
            defaultValue={attributeConstraint.constraintType}
            disabled={disabled || constraintDisabled}
            maxWidth={150}
            name={`attributesConstraints.${index}.constraintType`}
            options={constraints}
            rules={{
                required: 'common.validation.required',
            }}
            testId="constraintType"
            variant="standard"
        />
    );
};

export default AttributeConstraintSelect;
