import React, { useMemo } from 'react';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { Box, Stack, Typography } from '@mui/material';
import {
    NetworkModelsCountryRef,
    NetworkModelsPlayerSettingOrientation,
    NetworkModelsPlayersPlayerLocation,
    NetworkModelsPlayersPlayerRelations,
    NetworkModelsTimeZoneRef,
} from '@zetadisplay/engage-api-client';
import { SelectInput, SelectOption, TextInput } from '@zetadisplay/engage-components';
import { useCountries, useTimeZones } from '@zetadisplay/engage-components/hooks';
import { LocationInput } from '@zetadisplay/engage-components/modules/players/components';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Autocomplete } from '@zetadisplay/zeta-ui-components';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

const timezoneSort = (left: NetworkModelsTimeZoneRef, right: NetworkModelsTimeZoneRef) => {
    if (left.bias < right.bias) {
        return 1;
    }

    if (left.bias > right.bias) {
        return -1;
    }

    return 0;
};

const useStyles = makeStyles()(() => ({
    addressSettingsContainer: {
        paddingTop: '15px',
    },
    container: {
        border: `1px solid ${themeOptions.colors.DARKGRAY}`,
        borderRadius: '8px',
        padding: '10px',
    },
    generalSettingsContainer: {
        paddingBottom: '10px',
        paddingTop: '15px',
    },
}));

type PlayerSettingsDetailsFormProps = {
    supportsOrientation: boolean;
};

const PlayerSettingsDetailsForm = ({ supportsOrientation }: PlayerSettingsDetailsFormProps) => {
    const { classes } = useStyles();
    const { trans } = useTranslation();
    const { setValue } = useFormContext<NetworkModelsPlayersPlayerRelations>();
    const { isLoading } = useFormState();

    const countries = useCountries();
    const timezones = useTimeZones();

    const countryOptions = useMemo(() => {
        return countries?.result?.items || [];
    }, [countries?.result?.items]);

    const timezoneOptions = useMemo(() => {
        return timezones?.result?.items.sort(timezoneSort) || [];
    }, [timezones?.result?.items]);

    const updatePlayerLocationInputs = (playerLocationData: NetworkModelsPlayersPlayerLocation) => {
        setValue('playerLocation.city', playerLocationData.city, { shouldDirty: true });
        setValue('playerLocation.countryId', playerLocationData.countryId, { shouldDirty: true });
        setValue('playerLocation.zipcode', playerLocationData.zipcode, { shouldDirty: true });
    };

    const orientationOptions: SelectOption[] = useMemo(() => {
        return [
            {
                id: NetworkModelsPlayerSettingOrientation.Off,
                name: trans('common.generic.off'),
            },
            {
                id: NetworkModelsPlayerSettingOrientation.Portrait90,
                name: `${NetworkModelsPlayerSettingOrientation.Portrait90}`,
                value: `${NetworkModelsPlayerSettingOrientation.Portrait90}`,
            },
            {
                id: NetworkModelsPlayerSettingOrientation.PortraitNegative90,
                name: `${NetworkModelsPlayerSettingOrientation.PortraitNegative90}`,
                value: `${NetworkModelsPlayerSettingOrientation.PortraitNegative90}`,
            },
        ];
    }, [trans]);

    return (
        <Box className={classes.container}>
            <Typography variant="h5">General Player Settings</Typography>

            <Stack className={classes.generalSettingsContainer} direction="row" spacing={1}>
                <TextInput
                    defaultLabel="common.form.input.name"
                    loading={isLoading}
                    name="friendlyName"
                    variant="filled"
                />
                <TextInput
                    defaultLabel="common.form.input.player_id"
                    loading={isLoading}
                    disabled
                    name="name"
                    variant="filled"
                />

                {supportsOrientation && (
                    <SelectInput
                        fullWidth
                        loading={isLoading}
                        label={trans('common.form.input.screen_rotation')}
                        name="orientation"
                        options={orientationOptions}
                        renderSelectItem
                        variant="filled"
                    />
                )}
            </Stack>

            <Typography variant="h5">Address</Typography>

            <Stack className={classes.addressSettingsContainer} direction="column" spacing={1}>
                <Stack direction="row" spacing={2}>
                    <LocationInput
                        disableClearable
                        name="playerLocation"
                        onChangeCallback={updatePlayerLocationInputs}
                        variant="filled"
                    />
                    <TextInput
                        defaultLabel="common.form.input.city"
                        loading={isLoading}
                        name="playerLocation.city"
                        variant="filled"
                    />
                    <TextInput
                        defaultLabel="common.form.input.zipcode"
                        loading={isLoading}
                        name="playerLocation.zipcode"
                        variant="filled"
                    />
                </Stack>

                <Stack direction="row" spacing={2}>
                    <Controller
                        name="playerLocation.countryId"
                        render={({ field: { onChange, value, ...props } }) => (
                            <Autocomplete<NetworkModelsCountryRef, false, false, false>
                                {...props}
                                autoSelect={false}
                                blurOnSelect
                                fullWidth
                                getOptionLabel={(option) => option.name || ''}
                                isOptionEqualToValue={(option, _value) => option.id === _value.id}
                                label={trans('common.form.input.country')}
                                loading={isLoading || countries.loading}
                                name="countryId"
                                onChange={(_, _value) => onChange(_value?.id || null)}
                                options={countryOptions}
                                placeholder="Select value"
                                ref={null}
                                variant="filled"
                                value={countryOptions.find((item) => item.id === value) || null}
                            />
                        )}
                    />
                    <Controller
                        name="playerLocation.timezoneId"
                        render={({ field: { onChange, value, ...props } }) => (
                            <Autocomplete<NetworkModelsTimeZoneRef, false, false, false>
                                {...props}
                                autoSelect={false}
                                blurOnSelect
                                fullWidth
                                getOptionLabel={(option) => option.daylightName || ''}
                                isOptionEqualToValue={(option, _value) => option.id === _value.id}
                                label={trans('common.form.input.timezone')}
                                loading={isLoading || timezones.loading}
                                name="timezoneId"
                                onChange={(_, _value) => onChange(_value?.id || null)}
                                options={timezoneOptions}
                                placeholder="Select value"
                                ref={null}
                                variant="filled"
                                value={timezoneOptions.find((item) => item.id === value) || null}
                            />
                        )}
                    />
                </Stack>
            </Stack>
        </Box>
    );
};

export default PlayerSettingsDetailsForm;
