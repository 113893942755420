import { FieldArrayWithId } from 'react-hook-form';
import { NcastContractsEntitiesConstraintTypes } from '@zetadisplay/engage-api-client';
import { ConfigModelsGroupPersistModel } from '@zetadisplay/engage-api-client/models/config-models-group-persist-model';

export const isCanBe = (constraintType: NcastContractsEntitiesConstraintTypes) => {
    return constraintType === NcastContractsEntitiesConstraintTypes.CAN_BE;
};

export const isCanBeAny = (constraintType: NcastContractsEntitiesConstraintTypes) => {
    return constraintType === NcastContractsEntitiesConstraintTypes.CAN_BE_ANY;
};

export const isCanNotBe = (constraintType: NcastContractsEntitiesConstraintTypes) => {
    return constraintType === NcastContractsEntitiesConstraintTypes.CAN_NOT_BE;
};

/**
 * Determine default constraint for new attribute
 *
 *  - If there is any attribute with "can be any" constraint, default constraint is "can not be"
 *  - Otherwise default constraint is "can be"
 */
export const determineDefaultConstraint = (
    attributeId: number,
    attributes: FieldArrayWithId<ConfigModelsGroupPersistModel, 'attributesConstraints', 'key'>[] = []
) => {
    const selectedAttributes = attributes.filter((value) => value.attributeId === attributeId);
    if (selectedAttributes.some((value) => isCanBeAny(value.constraintType))) {
        return NcastContractsEntitiesConstraintTypes.CAN_NOT_BE;
    }

    return NcastContractsEntitiesConstraintTypes.CAN_BE;
};

export const determinePossibleConstraintsOptions = (
    attributeId: number,
    attributeIndex: number,
    attributes: FieldArrayWithId<ConfigModelsGroupPersistModel, 'attributesConstraints', 'key'>[] = []
) => {
    let constraints = [
        NcastContractsEntitiesConstraintTypes.CAN_BE,
        NcastContractsEntitiesConstraintTypes.CAN_BE_ANY,
        NcastContractsEntitiesConstraintTypes.CAN_NOT_BE,
    ];

    // To be able to use "must match" there can not be "can be any" of the same attributeConstraint
    if (
        attributes.some(
            (value, idx) =>
                idx !== attributeIndex &&
                value.attributeId === attributeId &&
                value.constraintType === NcastContractsEntitiesConstraintTypes.CAN_BE_ANY
        )
    ) {
        constraints = constraints.filter((value) => value !== NcastContractsEntitiesConstraintTypes.CAN_BE);
    }

    // If there is must match already, you can't add CAN_BE_ANY or
    // if CAN_BE_ANY is already
    if (
        attributes.some(
            (value, idx) =>
                idx !== attributeIndex &&
                value.attributeId === attributeId &&
                (value.constraintType === NcastContractsEntitiesConstraintTypes.CAN_BE ||
                    value.constraintType === NcastContractsEntitiesConstraintTypes.CAN_BE_ANY)
        )
    ) {
        constraints = constraints.filter((value) => value !== NcastContractsEntitiesConstraintTypes.CAN_BE_ANY);
    }

    // If there is CAN_NOT_BE already, you can't add MUST MATCH
    if (
        attributes.some(
            (value, idx) =>
                idx !== attributeIndex &&
                value.attributeId === attributeId &&
                value.constraintType === NcastContractsEntitiesConstraintTypes.CAN_NOT_BE
        )
    ) {
        constraints = constraints.filter((value) => value !== NcastContractsEntitiesConstraintTypes.CAN_BE);
    }

    // To be able to "exclude" something there must be an existing "can be any" of the same attributeConstraint
    if (
        attributes.length &&
        !attributes.some(
            (value, idx) =>
                idx !== attributeIndex &&
                value.attributeId === attributeId &&
                value.constraintType === NcastContractsEntitiesConstraintTypes.CAN_BE_ANY
        )
    ) {
        constraints = constraints.filter((value) => value !== NcastContractsEntitiesConstraintTypes.CAN_NOT_BE);
    }

    return constraints;
};
