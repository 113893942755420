import { useCallback } from 'react';
import NiceModal, { NiceModalHocProps } from '@ebay/nice-modal-react';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Icon } from '@zetadisplay/zeta-ui-components';

import ConfirmPrompt from 'src/components/Modals/ConfirmPrompt';
import { LabelValuePlayerType, LabelValueType } from 'src/views/LabelSetupView';

export type RemoveLabelValuePlayerPromptProps = {
    labelValue: LabelValueType;
    player: LabelValuePlayerType;
} & NiceModalHocProps;

const RemoveLabelValuePlayerPrompt = NiceModal.create<RemoveLabelValuePlayerPromptProps>(({ labelValue, player }) => {
    const t = useTranslation();
    const submitAction = useCallback(async () => player, [player]);

    return (
        <ConfirmPrompt
            description={t.trans('engage.modal.players.labels.setup.remove-player.description', [
                player.friendlyName,
                labelValue.value,
            ])}
            title={{
                icon: <Icon type="PLAYER_GROUP" />,
                label: 'engage.modal.players.labels.setup.remove-player.title',
            }}
            submit={{
                label: 'common.action.remove',
                name: 'remove',
            }}
            submitAction={submitAction}
        />
    );
});

export default RemoveLabelValuePlayerPrompt;
