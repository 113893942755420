import { FieldArrayWithId, UseFieldArrayRemove, UseFieldArrayUpdate } from 'react-hook-form';

import { PlaylistSetupFormValues } from './createPlaylistFormValues';

const removeContentFromPlaylist =
    (
        fields: FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>[],
        remove: UseFieldArrayRemove,
        update: UseFieldArrayUpdate<PlaylistSetupFormValues, 'playlistMediaCollection'>
    ) =>
    (items: string[]) => {
        const toRemove = items
            .map((id) => fields.findIndex((field) => field.id === id && field.state === 'added'))
            .filter((value) => value !== -1);

        // Note that the filter is important to first check if there are existing items to be marked as removed.
        // If this filtering is left out you could end up with one or more elements having value -1 as index and state 'removed'.
        // This will cause the actual form (that is indirectly coupled by the remove and update methods) to be in an isDirty state, while there are no changes to be saved.
        // So for example by adding media to a playlist and then removing it again, the form will not be dirty.
        const toMarkAsRemoved: [
            number,
            FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>
        ][] = items
            .filter((id) => fields.findIndex((field) => field.id === id && field.state !== 'added') !== -1)
            .map((id) => {
                const index = fields.findIndex((field) => field.id === id && field.state !== 'added');

                return [index, { ...fields[index], state: 'removed' }];
            });

        remove(toRemove);
        toMarkAsRemoved.forEach(([index, item]) => {
            update(index, item);
        });
    };

export default removeContentFromPlaylist;
