import { useCallback } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { LibraryModelsFolder } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity, isPlaylistFolder } from '@zetadisplay/engage-components/models';
import { useUserInformation } from '@zetadisplay/engage-components/modules/auth';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ActionGroup } from '@zetadisplay/zeta-ui-components';

import DeletePlaylistFolderPrompt, {
    RemoveFolderPromptProps,
} from 'src/components/Modals/Playlist/PlaylistFolder/DeletePlaylistFolderPrompt';
import UpdatePlaylistFolderForm, {
    UpdateMediaFolderFormProps,
} from 'src/components/Modals/Playlist/PlaylistFolder/UpdatePlaylistFolderForm';
import { emitOnPlaylistFolderDeleted } from 'src/views/PlaylistView/Events/onPlaylistFolderDeletedEvent';
import { emitOnPlaylistFolderUpdated } from 'src/views/PlaylistView/Events/onPlaylistFolderUpdatedEvent';

import MovePlaylistItemSidekick, {
    MovePlaylistItemsSidekickProps,
} from '../../../components/Sidekicks/Playlist/MovePlaylistItemSidekick';

const usePlaylistViewListFolderActions = (): ActionGroup<DiscriminatedEntity<LibraryModelsFolder>>[] => {
    const t = useTranslation();
    const { hasRole } = useUserInformation();

    const handleDeletePlaylistFolder = useCallback((folder: DiscriminatedEntity<LibraryModelsFolder>) => {
        NiceModal.show<DiscriminatedEntity<LibraryModelsFolder>, RemoveFolderPromptProps>(DeletePlaylistFolderPrompt, {
            folder,
        }).then(emitOnPlaylistFolderDeleted);
    }, []);

    const handleMovePlaylistFolder = useCallback((folder: DiscriminatedEntity<LibraryModelsFolder>) => {
        NiceModal.show<DiscriminatedEntity<LibraryModelsFolder>[], MovePlaylistItemsSidekickProps>(
            MovePlaylistItemSidekick,
            {
                subjects: [folder],
            }
        ).then((folders) => folders.forEach((f) => emitOnPlaylistFolderDeleted(f)));
    }, []);

    const handleEditPlaylistFolder = useCallback((folder: DiscriminatedEntity<LibraryModelsFolder>) => {
        NiceModal.show<DiscriminatedEntity<LibraryModelsFolder>, UpdateMediaFolderFormProps>(UpdatePlaylistFolderForm, {
            folder,
        }).then(emitOnPlaylistFolderUpdated);
    }, []);

    return [
        {
            actions: [
                {
                    handler: handleEditPlaylistFolder,
                    isDisabled: () => !hasRole(['ENGAGE_PLAYLIST_FOLDER_EDIT']),
                    isVisible: isPlaylistFolder,
                    label: t.trans('common.action.edit'),
                    name: 'edit',
                },
                {
                    handler: handleMovePlaylistFolder,
                    isDisabled: () => !hasRole(['ENGAGE_PLAYLIST_FOLDER_CREATE']),
                    isVisible: isPlaylistFolder,
                    label: t.trans('common.action.move'),
                    name: 'move',
                },
                {
                    handler: handleDeletePlaylistFolder,
                    isDisabled: () => !hasRole(['ENGAGE_PLAYLIST_FOLDER_DELETE']),
                    isVisible: isPlaylistFolder,
                    label: t.trans('common.action.delete'),
                    name: 'delete',
                },
            ],
            name: 'playlist-list-actions',
        },
    ];
};

export default usePlaylistViewListFolderActions;
