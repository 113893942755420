import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { ViewHeader } from '@zetadisplay/engage-components';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import withDefaultLayout from 'src/utils/Layout/withDefaultLayout';

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingTop: 24,
        paddingLeft: 24,
        paddingRight: 24,
        position: 'relative',
        width: 'auto',
        [theme.breakpoints.up('md')]: {
            margin: '0 auto',
            paddingTop: 40,
            width: '70%',
        },
    },
    paragraph: {
        marginBottom: 20,
    },
    actions: {
        textAlign: 'center',
    },
}));

type DisplayErrorProps = {
    title: string;
    description: string;
};

const DisplayError: React.FC<DisplayErrorProps> = ({ title, description }) => {
    const { classes } = useStyles();
    const t = useTranslation();
    const navigate = useNavigate();

    return (
        <main className={classes.root}>
            <ViewHeader title={title} />
            <Typography className={classes.paragraph}>{t.trans(description)}</Typography>
            <div className={classes.actions}>
                <Button kind="secondaryAction" name="button-back" onClick={() => navigate(-1)}>
                    {t.trans('common.action.back')}
                </Button>
                <Button kind="primaryAction" name="button-dashboard" onClick={() => navigate('/')}>
                    {t.trans('common.action.continue')}
                </Button>
            </div>
        </main>
    );
};

export default withDefaultLayout(DisplayError);
