import { useCallback } from 'react';
import NiceModal, { NiceModalHocProps } from '@ebay/nice-modal-react';
import { createDiscriminatedEntity, EntityDiscriminators } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Icon } from '@zetadisplay/zeta-ui-components';

import ConfirmPrompt from 'src/components/Modals/ConfirmPrompt';
import { LabelValueState, LabelValueType } from 'src/views/LabelSetupView';

export type RemoveLabelValuePromptProps = {
    labelValue: LabelValueType;
} & NiceModalHocProps;

const RemoveLabelValuePrompt = NiceModal.create<RemoveLabelValuePromptProps>(({ labelValue }) => {
    const api = useApi();
    const t = useTranslation();
    const { workspace } = useWorkspace();

    // This submitAction will check and fetch players for labelValue unless they have been already fetched previously by expanding labelValue
    const submitAction = useCallback(async () => {
        if (labelValue.state === LabelValueState.NEW || labelValue.players.length !== 0) {
            return labelValue;
        }

        const players = await api.players
            .findByAttribute({
                attributeid: labelValue.labelId,
                attributevalueid: labelValue.id,
                workspaceid: workspace.id,
            })
            .then((response) =>
                response.data.items.map((player) => createDiscriminatedEntity(EntityDiscriminators.Player, player))
            );

        return { ...labelValue, players };
    }, [api, labelValue, workspace.id]);

    return (
        <ConfirmPrompt
            description={t.trans('engage.modal.players.labels.setup.remove-label-value.description', [
                labelValue.value,
            ])}
            title={{
                icon: <Icon type="VALUE" />,
                label: 'engage.modal.players.labels.setup.remove-label-value.title',
            }}
            submit={{
                label: 'common.action.remove',
                name: 'remove',
            }}
            submitAction={submitAction}
        />
    );
});

export default RemoveLabelValuePrompt;
