import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import { PublishingCampaignSchedulingCampaignInfoWithMediaSchedules } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity, isCampaign } from '@zetadisplay/engage-components/models';
import { useUserInformation } from '@zetadisplay/engage-components/modules/auth';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ActionGroup } from '@zetadisplay/zeta-ui-components';

import CopyPlaylistPrompt, {
    RemoveFolderPromptProps,
} from 'src/components/Modals/Playlist/Playlist/CopyPlaylistPrompt';
import DeletePlaylistPrompt from 'src/components/Modals/Playlist/Playlist/DeletePlaylistPrompt';
import { emitOnPlaylistCopied } from 'src/views/PlaylistView/Events/onPlaylistCopiedEvent';
import { emitOnPlaylistDeleted } from 'src/views/PlaylistView/Events/onPlaylistDeletedEvent';

import MovePlaylistItemSidekick, {
    MovePlaylistItemsSidekickProps,
} from '../../../components/Sidekicks/Playlist/MovePlaylistItemSidekick';

const usePlaylistViewListPlaylistActions = (): ActionGroup<
    DiscriminatedEntity<PublishingCampaignSchedulingCampaignInfoWithMediaSchedules>
>[] => {
    const history = useNavigate();
    const location = useLocation();
    const t = useTranslation();
    const { hasRole } = useUserInformation();

    const handleCopyPlaylist = useCallback(
        (playlist: DiscriminatedEntity<PublishingCampaignSchedulingCampaignInfoWithMediaSchedules>) => {
            NiceModal.show<
                DiscriminatedEntity<PublishingCampaignSchedulingCampaignInfoWithMediaSchedules>,
                RemoveFolderPromptProps
            >(CopyPlaylistPrompt, { playlist }).then(emitOnPlaylistCopied);
        },
        []
    );

    const handleMovePlaylist = (
        playlist: DiscriminatedEntity<PublishingCampaignSchedulingCampaignInfoWithMediaSchedules>
    ) => {
        NiceModal.show<
            DiscriminatedEntity<PublishingCampaignSchedulingCampaignInfoWithMediaSchedules>[],
            MovePlaylistItemsSidekickProps
        >(MovePlaylistItemSidekick, {
            subjects: [playlist],
        }).then((response) => response.forEach((p) => emitOnPlaylistDeleted(p)));
    };

    const handleDeletePlaylist = useCallback(
        (playlist: DiscriminatedEntity<PublishingCampaignSchedulingCampaignInfoWithMediaSchedules>) => {
            NiceModal.show<
                DiscriminatedEntity<PublishingCampaignSchedulingCampaignInfoWithMediaSchedules>,
                RemoveFolderPromptProps
            >(DeletePlaylistPrompt, { playlist }).then(emitOnPlaylistDeleted);
        },
        []
    );

    const handleEditPlaylist = useCallback(
        (playlist: DiscriminatedEntity<PublishingCampaignSchedulingCampaignInfoWithMediaSchedules>) => {
            history(`/playlists/edit?playlistId=${playlist.id}&returnUrl=${location.pathname}`);
        },
        [history, location.pathname]
    );

    return [
        {
            actions: [
                {
                    handler: handleEditPlaylist,
                    isDisabled: () => !hasRole(['ENGAGE_PLAYLIST_EDIT']),
                    isVisible: isCampaign,
                    label: t.trans('common.action.edit'),
                    name: 'edit',
                },
                {
                    handler: handleCopyPlaylist,
                    isDisabled: () => !hasRole(['ENGAGE_PLAYLIST_CREATE']),
                    isVisible: isCampaign,
                    label: t.trans('common.action.copy'),
                    name: 'copy',
                },
                {
                    handler: handleMovePlaylist,
                    isDisabled: () => !hasRole(['ENGAGE_PLAYLIST_CREATE']),
                    isVisible: isCampaign,
                    label: t.trans('common.action.move'),
                    name: 'move',
                },
                {
                    handler: handleDeletePlaylist,
                    isDisabled: () => !hasRole(['ENGAGE_PLAYLIST_DELETE']),
                    isVisible: isCampaign,
                    label: t.trans('common.action.delete'),
                    name: 'delete',
                },
            ],
            name: 'playlist-list-actions',
        },
    ];
};

export default usePlaylistViewListPlaylistActions;
