import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { createDefaultButtons, Modal } from '@zetadisplay/engage-components/modules/modal/components';
import { Schedule } from '@zetadisplay/zeta-ui-components';

import SchedulingForm from 'src/components/Forms/SchedulingForm';

export type ScheduleMediaFileFields = {
    schedules: Schedule[];
};

export type ScheduleMediaFileFormProps = {
    mediaSchedules: Schedule[];
} & NiceModalHocProps;

const SchedulePlaylistMediaForm = NiceModal.create<ScheduleMediaFileFormProps>(({ mediaSchedules }) => {
    const methods = useForm<ScheduleMediaFileFields>();
    const modal = useModal();

    const onSubmit = useCallback(
        async (formValues: ScheduleMediaFileFields) => {
            modal.resolve(formValues);
            modal.hide();
        },
        [modal]
    );

    return (
        <FormProvider {...methods}>
            <Modal
                actions={{
                    buttons: createDefaultButtons({
                        cancel: {
                            disabled: methods.formState.isSubmitting,
                            onClick: modal.hide,
                        },
                        submit: {
                            busy: methods.formState.isSubmitting,
                            disabled: methods.formState.isSubmitting,
                            label: 'common.action.continue',
                            name: 'continue',
                            onClick: methods.handleSubmit(onSubmit),
                        },
                    }),
                }}
                dark
                title={{ label: 'engage.modal.schedule.media.title' }}
            >
                <SchedulingForm mediaSchedules={mediaSchedules} />
            </Modal>
        </FormProvider>
    );
});

export default SchedulePlaylistMediaForm;
