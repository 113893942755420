import React from 'react';
import { IconButton } from '@mui/material';
import { ConfigModelsAttributeDetails } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button, Icon } from '@zetadisplay/zeta-ui-components';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

const { ORANGE } = themeOptions.colors;

const useStyles = makeStyles()(() => ({
    delete: {
        marginLeft: '0 !important',
    },
}));

type Props = {
    item: DiscriminatedEntity<ConfigModelsAttributeDetails>;
    onDeleteLabel: (arg: DiscriminatedEntity<ConfigModelsAttributeDetails>) => void;
    onSelectLabel: (arg: DiscriminatedEntity<ConfigModelsAttributeDetails>) => void;
};

const LabelSetupLibraryItemActions = ({ item, onDeleteLabel, onSelectLabel }: Props) => {
    const { classes } = useStyles();
    const t = useTranslation();

    return (
        <div data-testid={`label-setup-library-item-actions-${item.name}`}>
            <Button onClick={() => onSelectLabel(item)} label={t.trans('common.action.edit').toUpperCase()} />
            <IconButton
                className={classes.delete}
                data-testid="delete-label"
                onClick={() => onDeleteLabel(item)}
                size="small"
            >
                <Icon type="DELETE" size={16} color={ORANGE} />
            </IconButton>
        </div>
    );
};

export default LabelSetupLibraryItemActions;
