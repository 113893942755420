import React from 'react';
import { Typography } from '@mui/material';
import { LibraryModelsTemplateRelations } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()((theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
    },
    text: {
        color: theme.palette.background.disabledText,
        display: 'inline-block',
        fontSize: '11px',
        lineHeight: '13px',
        '&:first-letter': {
            textTransform: 'uppercase',
        },
        '&:not(:last-of-type)::after': {
            content: '" • "',
            marginRight: 4,
        },
    },
}));

type TemplateLibraryListItemSubtitleProps = {
    item: DiscriminatedEntity<LibraryModelsTemplateRelations>;
};

const TemplateLibraryListItemSubtitle = ({ item }: TemplateLibraryListItemSubtitleProps) => {
    const { classes, cx } = useStyles();

    return (
        <div className={cx(classes.root)}>
            <Typography
                variant="subtitle1"
                className={cx(classes.text)}
                data-testid={`template-subtitle-modified-${item.id}`}
            >
                {item.description && item.description.length > 0 ? item.description : 'N/A'}
            </Typography>
        </div>
    );
};

export default TemplateLibraryListItemSubtitle;
