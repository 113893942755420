import {
    ConfigModelsGroupInfo,
    PublishingCampaignSchedulingCampaignInfoWithMediaSchedules,
    PublishingModelsCampaignCampaignPriorityEnum,
    PublishingModelsCampaignCampaignRules,
    PublishingModelsCampaignCampaignStatus,
} from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { PlaylistMediaType, PlaylistSetupModifiedMediaType } from '@zetadisplay/engage-components/modules/playlist';
import { TreeNode } from '@zetadisplay/engage-components/utils/tree-builder';

export type ExtendedPlaylistMediaType = PlaylistMediaType & {
    fieldArrayId?: string;
    state?: 'added' | 'modified' | 'removed';
};

export type PlaylistSetupFormValues = Pick<
    PublishingCampaignSchedulingCampaignInfoWithMediaSchedules,
    'folderId' | 'name' | 'mixingMode' | 'priority' | 'schedules' | 'status'
> & {
    groups: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfo>, number>[];
    layoutId: number | undefined;
    layoutZoneId: number | undefined;
    playlistMediaCollection: Partial<ExtendedPlaylistMediaType>[];
    modifiedMedia: Map<string, PlaylistSetupModifiedMediaType>;
};

const createPlaylistFormValues = (values: Partial<PlaylistSetupFormValues> = {}): PlaylistSetupFormValues => {
    return {
        folderId: values.folderId || undefined,
        groups: values.groups || [],
        layoutId: values.layoutId || undefined,
        layoutZoneId: values.layoutZoneId || undefined,
        name: values.name || '',
        playlistMediaCollection: values.playlistMediaCollection || [],
        mixingMode: values.mixingMode || PublishingModelsCampaignCampaignRules.Grouped,
        modifiedMedia: new Map(),
        priority: values.priority || PublishingModelsCampaignCampaignPriorityEnum.Normal,
        schedules: values.schedules || [],
        status: values.status !== undefined ? values.status : PublishingModelsCampaignCampaignStatus.Published,
    };
};

export default createPlaylistFormValues;
