import React, { useCallback, useMemo } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { Typography } from '@mui/material';
import { ItemsView, SkeletonItemsView } from '@zetadisplay/engage-components';
import { ViewMode } from '@zetadisplay/engage-components/modules/options';
import { getIconType } from '@zetadisplay/engage-components/modules/view/utils';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ItemProps } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import RemovePowerSchedulePrompt, {
    RemovePowerSchedulePromptProps,
} from 'src/components/Modals/PowerScheduleSetup/RemovePowerSchedulePrompt';
import { PrimaryPowerScheduleType } from 'src/views/PowerScheduleSetupView';
import usePowerSchedules from 'src/views/PowerScheduleSetupView/Components/PowerScheduleLibrary/Hooks/usePowerSchedules';
import PrimaryPowerScheduleLibraryItemActions from 'src/views/PowerScheduleSetupView/Components/PowerScheduleLibrary/PrimaryPowerScheduleLibraryItemActions';
import { useOnPowerScheduleCreatedListener } from 'src/views/PowerScheduleSetupView/Events/onPowerScheduleCreatedEvent';

const useStyles = makeStyles()(() => ({
    info: {
        fontSize: 12,
        padding: 12,
    },
}));

type PowerScheduleLibraryPrimaryListProps = {
    onSelectPowerSchedule: (arg: PrimaryPowerScheduleType) => void;
};

const PowerScheduleLibraryPrimaryList = ({ onSelectPowerSchedule }: PowerScheduleLibraryPrimaryListProps) => {
    const { classes } = useStyles();
    const { data, getNextResultPage, isLoading, removeResult, setResult, total } = usePowerSchedules();
    const t = useTranslation();

    // When PowerSchedule is created or edited, update existing or inject new one
    useOnPowerScheduleCreatedListener((powerSchedule) => setResult(powerSchedule));

    const renderPrimaryAction = useCallback(
        (item: PrimaryPowerScheduleType) => {
            return (
                <PrimaryPowerScheduleLibraryItemActions
                    onDeletePowerSchedule={() => {
                        NiceModal.show<PrimaryPowerScheduleType, RemovePowerSchedulePromptProps>(
                            RemovePowerSchedulePrompt,
                            {
                                powerSchedule: item,
                            }
                        ).then(removeResult);
                    }}
                    onSelectPowerSchedule={onSelectPowerSchedule}
                    powerSchedule={item}
                />
            );
        },
        [onSelectPowerSchedule, removeResult]
    );

    const itemProps: ItemProps<PrimaryPowerScheduleType> = useMemo(
        () => ({
            clickable: false,
            getItemIconType: getIconType,
            inViewThreshold: 0.2,
            renderPrimaryAction,
            showThumbnail: false,
            type: 'compact',
        }),
        [renderPrimaryAction]
    );

    if (isLoading && data.length === 0) {
        return <SkeletonItemsView items={8} primaryAction textCells={1} textCellWidth="70%" viewMode={ViewMode.ROWS} />;
    }

    if (data.length === 0) {
        return (
            <Typography align="center" className={classes.info}>
                {t.trans('engage.library.content.no_content')}
            </Typography>
        );
    }

    return (
        <ItemsView
            infiniteScrolling={!total || total > data.length}
            isLoadingMore={isLoading && data.length > 0}
            items={data as PrimaryPowerScheduleType[]} // TODO: This following is a hack and should be removed in future when we have similar responses
            ItemProps={itemProps}
            onInfiniteScroll={getNextResultPage}
            viewMode={ViewMode.ROWS}
        />
    );
};

export default PowerScheduleLibraryPrimaryList;
