import useIsFeatureEnabled, { AvailableFeatureType } from 'src/hooks/useIsFeatureEnabled';

export type FeatureFlagProps = React.PropsWithChildren<{ feature: AvailableFeatureType }>;

const FeatureFlag = ({ children, feature }: FeatureFlagProps) => {
    const isFeatureEnabled = useIsFeatureEnabled();

    if (isFeatureEnabled(feature)) {
        // This is weird, what would be the alternative?
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <>{children}</>;
    }

    return null;
};

export default FeatureFlag;
