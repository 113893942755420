import React from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { ConfigModelsUpdateAttribute } from '@zetadisplay/engage-api-client';
import { TextInput } from '@zetadisplay/engage-components';
import { whitespace } from '@zetadisplay/engage-components/utils/form/validators';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { LabelSetupFormData } from 'src/views/LabelSetupView';

const useStyles = makeStyles()(() => ({
    gridItem: {
        padding: '8px 0', // padding used instead grid container spacing due to StyledScrollers behaving poorly wrong with negative margins it adds
    },
}));

type Props = {
    defaultValues?: ConfigModelsUpdateAttribute;
};

const LabelForm = ({ defaultValues = { name: '', description: '' } }: Props) => {
    const { formState } = useFormContext<LabelSetupFormData>();
    const { classes } = useStyles();

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className={classes.gridItem}>
                <TextInput
                    defaultLabel="common.form.input.name"
                    defaultValue={defaultValues.name}
                    error={formState.errors.name}
                    name="name"
                    rules={{
                        required: 'common.validation.required',
                        validate: { noWhitespaces: whitespace },
                    }}
                />
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
                <TextInput
                    defaultLabel="common.form.input.description"
                    defaultValue={defaultValues.description}
                    error={formState.errors.description}
                    minRows={4}
                    maxRows={10}
                    multiline
                    name="description"
                />
            </Grid>
        </Grid>
    );
};

export default LabelForm;
