import React from 'react';
import { Alert, AlertTitle, Typography } from '@mui/material';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()((theme) => ({
    information: {
        marginTop: 24,
    },
    informationText: {
        color: theme.palette.text.primary,
    },
}));

const EditMediaFileInformation = () => {
    const t = useTranslation();
    const { classes, cx } = useStyles();

    return (
        <div className={cx(classes.information)}>
            <Alert severity="info" variant="filled">
                <AlertTitle>Info</AlertTitle>
                <Typography className={cx(classes.informationText)}>
                    {t.trans('engage.playlist.setup.content.edit.information')}
                </Typography>
            </Alert>
        </div>
    );
};

export default EditMediaFileInformation;
