// TODO: Figure this one out
/* eslint-disable react/no-unstable-nested-components */

import React from 'react';
import { Person } from '@styled-icons/material';
import { DropDownMenu, DropdownMenuItem } from '@zetadisplay/engage-components';
import { themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

const { BLACK } = themeOptions.colors;

type Props = {
    menuItems: DropdownMenuItem[];
};

const UserMenu = ({ menuItems }: Props) => {
    return (
        <div id="userMenu">
            <DropDownMenu options={menuItems} menuTrigger={() => <Person size={24} color={BLACK} />} />
        </div>
    );
};

export default UserMenu;
