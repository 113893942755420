import React from 'react';
import { useOidc } from '@axa-fr/react-oidc';
import { AuthInfo } from '@zetadisplay/engage-components/modules/auth';
import { Theme } from '@zetadisplay/zeta-ui-components';

import { DEFAULT_OIDC_CONFIGURATION_NAME } from 'src/config/appConfig';

const SessionLostComponent = () => {
    const { login } = useOidc(DEFAULT_OIDC_CONFIGURATION_NAME);

    return (
        <Theme>
            <AuthInfo action={() => login()} actionText="common.action.login" text="engage.auth.session.lost" />
        </Theme>
    );
};

export default SessionLostComponent;
