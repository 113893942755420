import { FieldArrayWithId, UseFieldArrayUpdate } from 'react-hook-form';

import { OnPlaylistContentScheduledEventPayload } from '../sidekick/playlist-preview-sidekick/events/on-playlist-content-scheduled-event';
import { PlaylistSetupFormValues } from './createPlaylistFormValues';
import hasAlwaysScheduledSchedule from './has-always-scheduled-schedule';

const updatePlaylistContentSchedules =
    (
        fields: FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>[],
        update: UseFieldArrayUpdate<PlaylistSetupFormValues, 'playlistMediaCollection'>
    ) =>
    ({ item, data }: OnPlaylistContentScheduledEventPayload) => {
        const items = Array.isArray(item) ? item : [item];

        for (const playlistMediaItem of items) {
            const index = fields.findIndex((field) => field.fieldArrayId === playlistMediaItem.fieldArrayId);

            update(index, {
                ...playlistMediaItem,
                mediaSchedules: !hasAlwaysScheduledSchedule(data.schedules) ? data.schedules : [],
                state: 'modified',
            });
        }
    };

export default updatePlaylistContentSchedules;
