import { ApiInterface } from '@zetadisplay/engage-components/modules/api';
import { NotifyFunctionType } from '@zetadisplay/zeta-ui-components/hooks/use-notify';
import pLimit from 'p-limit';

import handlePendingPromise, { PendingPromiseMessages } from 'src/utils/HandlePendingPromise';
import { LabelValuePlayerState, LabelValueState, LabelValueType } from 'src/views/LabelSetupView';
import updatePlayerChannels from 'src/views/LabelSetupView/Utils/updatePlayerChannels';

const limit = pLimit(1);

const createPlayerRelationPromises = (api: ApiInterface, attributeValues: LabelValueType[], workspaceId: string) => {
    const addedPlayers = attributeValues.flatMap((value) => ({
        attributeValue: value,
        players: value.players.filter((player) => player.state === LabelValuePlayerState.NEW),
    }));

    const deletedPlayers = attributeValues.flatMap((value) => ({
        attributeValue: value,
        players:
            value.state === LabelValueState.DELETED
                ? value.players
                : value.players.filter((player) => player.state === LabelValuePlayerState.DELETED),
    }));

    return Promise.all(
        [...deletedPlayers, ...addedPlayers].flatMap(({ attributeValue, players }) =>
            players.map((player) => {
                return limit(() => {
                    return api.players
                        .getPlayerRelations({ playerGuid: player.guid, workspaceid: workspaceId })
                        .then((playerRelations) =>
                            api.players.updatePlayerRelations({
                                body: {
                                    ...playerRelations.data,
                                    channels: updatePlayerChannels(
                                        attributeValue,
                                        player,
                                        playerRelations.data.channels
                                    ),
                                },
                                playerGuid: player.guid,
                                workspaceid: workspaceId,
                            })
                        );
                });
            })
        )
    );
};

const updatePlayerRelations = async (
    api: ApiInterface,
    data: LabelValueType[],
    workspaceId: string,
    notify: NotifyFunctionType
) => {
    const pendingValues = data.filter((value) => {
        return value.state === LabelValueState.DELETED || value.players.some((player) => player.state !== undefined);
    });

    if (pendingValues.length === 0) {
        return [];
    }

    const hasMultipleValues = pendingValues.length > 1;
    const messages: PendingPromiseMessages = {
        pending: {
            message: 'engage.notification.edit.label.value.player.info',
            params: [hasMultipleValues ? pendingValues.length.toString() : pendingValues[0].value],
            plural: hasMultipleValues,
        },
        success: {
            message: 'engage.notification.edit.label.value.player.success',
            params: [hasMultipleValues ? pendingValues.length.toString() : pendingValues[0].value],
            plural: hasMultipleValues,
        },
    };

    return handlePendingPromise(createPlayerRelationPromises(api, pendingValues, workspaceId), messages, notify);
};

export default updatePlayerRelations;
