import React, { useCallback, useContext, useMemo } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { ConfigModelsAttributeDetails, NetworkModelsPlayersPlayerRelations } from '@zetadisplay/engage-api-client';
import { BottomsUp, BottomsUpProps } from '@zetadisplay/engage-components/modules/sidekick/components';

import PlayerSettingsChannelContext from './PlayerSettingsChannelContext';
import PlayerSettingsChannelLabelsAppendOverlay from './PlayerSettingsChannelLabels/PlayerSettingsChannelLabelsAppendOverlay';
import PlayerSettingsChannelLabelsDetails from './PlayerSettingsChannelLabels/PlayerSettingsChannelLabelsDetails';

type ChannelAttributePath = `channels.${number}.attributes`;

const PlayerSettingsChannelLabels = () => {
    const { currentChannelIndex } = useContext(PlayerSettingsChannelContext);

    const watchedFields = useWatch<NetworkModelsPlayersPlayerRelations, ChannelAttributePath>({
        name: `channels.${currentChannelIndex}.attributes`,
    });

    const { append, fields, remove } = useFieldArray<NetworkModelsPlayersPlayerRelations, ChannelAttributePath, 'key'>({
        name: `channels.${currentChannelIndex}.attributes`,
        keyName: 'key',
    });

    const attributes = useMemo(
        () =>
            fields.map((field, index) => {
                if (watchedFields?.[index]) {
                    return { ...field, ...watchedFields[index] };
                }

                return field;
            }),
        [fields, watchedFields]
    );

    const handleLabelAdd = useCallback((attribute: ConfigModelsAttributeDetails) => append({ attribute }), [append]);

    const bottomsUpProps = useMemo(
        (): BottomsUpProps => ({
            secondaryContent: (
                <PlayerSettingsChannelLabelsAppendOverlay onLabelAdd={handleLabelAdd} selectedAttributes={attributes} />
            ),
            mainContent: <PlayerSettingsChannelLabelsDetails attributes={attributes} onRemove={remove} />,
            title: 'engage.players.settings.labels.overlay.title',
            width: 700,
        }),
        [attributes, handleLabelAdd, remove]
    );

    return <BottomsUp {...bottomsUpProps} />;
};

export default PlayerSettingsChannelLabels;
