import { useAsyncAbortable } from 'react-async-hook';
import {
    NetworkModelsPlayerInformation,
    PublishingCampaignSchedulingCampaignMedia,
    PublishingModelsPlaylistPlaylist,
    PublishingModelsPlaylistPlaylistChannel,
} from '@zetadisplay/engage-api-client';
import {
    createDiscriminatedEntity,
    DiscriminatedEntity,
    EntityDiscriminators,
} from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { createInternalMediaUuid } from '@zetadisplay/engage-components/utils/media';
import convertDateToUTC from '@zetadisplay/engage-components/utils/scheduling/convert-date-to-utc';

import { PlaylistItem } from 'src/views/PlaylistView/Components/PlaylistItemDetails';

export type PlayerPlaylistType = {
    channels?: Array<DiscriminatedEntity<PublishingModelsPlaylistPlaylistChannel>> | null;
    campaigns?: Array<DiscriminatedEntity<PlaylistItem>> | null;
};

const playerPlaylistMapper = (playlist: PublishingModelsPlaylistPlaylist): PlayerPlaylistType => ({
    channels: playlist.channels?.map((channel) => createDiscriminatedEntity(EntityDiscriminators.Channel, channel)),
    campaigns: playlist.campaigns?.map((campaign) =>
        createDiscriminatedEntity(EntityDiscriminators.Campaign, {
            ...campaign,
            campaignMedia: campaign.media?.map(
                (mediaId, index) =>
                    ({
                        id: createInternalMediaUuid(mediaId, index),
                        mediaId,
                    } as PublishingCampaignSchedulingCampaignMedia)
            ),
        })
    ),
});

const usePlayerPlaylist = (player: DiscriminatedEntity<NetworkModelsPlayerInformation>, date: Date) => {
    const api = useApi();
    const { workspace } = useWorkspace();

    return useAsyncAbortable(
        async (signal) => {
            return api.campaigns
                .getPlaylistByPlayer(
                    {
                        playerid: player.id,
                        workspaceid: workspace.id,
                        dateTime: convertDateToUTC(date)?.toISOString(),
                    },
                    { signal }
                )
                .then((response) => response.data)
                .then(playerPlaylistMapper);
        },
        [api.campaigns, date, player.id, workspace.id]
    );
};

export default usePlayerPlaylist;
