import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import AttributeConstraintValue from 'src/modules/groups/setup/components/GroupSetupSettingsForm/AttributesConstraints/AttributeConstraintValue';
import { useOnAttributeConstraintAddedListener } from 'src/modules/groups/setup/events/onAttributeConstraintAddedEvent';
import useAttributesConstraintsArray from 'src/modules/groups/setup/hooks/useAttributesConstraintsArray';
import { determineDefaultConstraint } from 'src/views/PlayersView/Utils/Constraints';

const useStyles = makeStyles()((theme) => ({
    root: {
        borderTop: `1px ${theme.palette.background.disabled} solid`,
        overflowY: 'auto',
        padding: '0 4px',
        width: '100%',
    },
    empty_list: {
        margin: '12px 0',
    },
}));

const AttributesConstraints = () => {
    const { classes, cx } = useStyles();
    const t = useTranslation();

    const [attributesConstraints, appendAttributeConstraint, removeAttributeConstraint] =
        useAttributesConstraintsArray();

    useOnAttributeConstraintAddedListener((attribute) => {
        appendAttributeConstraint({
            attributeId: attribute.id,
            attributeValueId: undefined,
            constraintType: determineDefaultConstraint(attribute.id, attributesConstraints),
        });
    });

    return (
        <div className={cx(classes.root)} data-testid="labels-and-values" role="list">
            {attributesConstraints.length === 0 && (
                <Typography className={cx(classes.empty_list)}>
                    {t.trans('engage.players.setup.labels_and_values.empty_list')}
                </Typography>
            )}

            {attributesConstraints.map((attributeConstraint, index: number) => (
                <AttributeConstraintValue
                    attributeConstraint={attributeConstraint}
                    attributesConstraints={attributesConstraints}
                    index={index}
                    key={attributeConstraint.key || index}
                    onRemoveLabel={removeAttributeConstraint}
                />
            ))}
        </div>
    );
};

export default AttributesConstraints;
