import React, { PropsWithChildren } from 'react';
import { DefaultLayout } from '@zetadisplay/engage-components/layouts';
import { DefaultLayoutContainerProps } from '@zetadisplay/engage-components/layouts/components/default-layout-container';

import ProductFruitsWrapper from '../ProductFruits/ProductFruitsWrapper';

const defaultLayoutContainerProps: DefaultLayoutContainerProps = {
    useViewModeProvider: true,
};

const withDarkLayout = <P,>(
    Component: React.ComponentType<PropsWithChildren<P>>,
    LayoutContainerProps: DefaultLayoutContainerProps = defaultLayoutContainerProps
): React.FC<PropsWithChildren<P>> => {
    return function DefaultLayoutWrapper(rest) {
        return (
            <ProductFruitsWrapper>
                <DefaultLayout dark LayoutContainerProps={LayoutContainerProps}>
                    <Component {...rest} />
                </DefaultLayout>
            </ProductFruitsWrapper>
        );
    };
};

export default withDarkLayout;
