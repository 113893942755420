import React, { useEffect, useMemo } from 'react';
import { Controller, FieldArrayWithId, useFormContext } from 'react-hook-form';
import { Grid, IconButton, Skeleton, Typography } from '@mui/material';
import { ConfigModelsAttributeConstraintRef, ConfigModelsAttributeValueBasic } from '@zetadisplay/engage-api-client';
import { ConfigModelsGroupPersistModel } from '@zetadisplay/engage-api-client/models/config-models-group-persist-model';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useAttributeWithValues } from '@zetadisplay/engage-components/modules/players/hooks';
import { Autocomplete, Icon } from '@zetadisplay/zeta-ui-components';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

import AttributeConstraintSelect from 'src/modules/groups/setup/components/GroupSetupSettingsForm/AttributesConstraints/AttributeConstraintSelect';
import { isCanBeAny, isCanNotBe } from 'src/views/PlayersView/Utils/Constraints';

const { ORANGE, DARKGRAY: DISABLED } = themeOptions.colors;

const useStyles = makeStyles()((theme) => ({
    root: {
        alignItems: 'center',
        borderBottom: `1px ${theme.palette.background.disabled} solid`,
        height: 50,
    },
    constraintsValuesActions: {
        display: 'flex',
        alignItems: 'center',
    },
    constraints: {
        marginRight: 10,
    },
    actions: {
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'flex-end',
    },
}));

const isSameLabel = (a: ConfigModelsAttributeConstraintRef, b: ConfigModelsAttributeConstraintRef) => {
    return a.attributeId === b.attributeId;
};

type AttributeConstraintValueProps = {
    attributeConstraint: ConfigModelsAttributeConstraintRef;
    attributesConstraints: FieldArrayWithId<ConfigModelsGroupPersistModel, 'attributesConstraints', 'key'>[];
    index: number;
    onRemoveLabel: (arg: number) => void;
};

const AttributeConstraintValue = ({
    attributeConstraint,
    attributesConstraints,
    index,
    onRemoveLabel,
}: AttributeConstraintValueProps) => {
    const { classes, cx } = useStyles();
    const { setValue } = useFormContext<ConfigModelsGroupPersistModel>();

    const { result: attribute, loading } = useAttributeWithValues(attributeConstraint.attributeId);
    const attributeValues = attribute?.attributeValues || [];
    // Attribute value is disabled when attributeConstraint has "can be any" constraint or when there are no attribute values
    const attributeValueDisabled = isCanBeAny(attributeConstraint.constraintType) || attribute === undefined;

    /**
     * Removing the attributeConstraint is disabled in cases there is "exclude" and this item is "can be any"
     */
    const removeLabelDisabled = useMemo(() => {
        return (
            isCanBeAny(attributeConstraint.constraintType) &&
            attributesConstraints.filter(
                (value) => isSameLabel(value, attributeConstraint) && isCanNotBe(value.constraintType)
            ).length > 0
        );
    }, [attributeConstraint, attributesConstraints]);

    // Reset attributeValueId when constraint type changes to NcastContractsEntitiesConstraintTypes.CAN_BE_ANY
    useEffect(() => {
        if (!isCanBeAny(attributeConstraint.constraintType) || !attributeConstraint.attributeValueId) {
            return;
        }

        setValue(`attributesConstraints.${index}.attributeValueId`, undefined);
    });

    const renderAttributeName = () => {
        if (attribute === undefined || loading) {
            return <Skeleton variant="text" width={120} />;
        }

        return (
            <Typography variant="body1" noWrap>
                {attribute.name}
            </Typography>
        );
    };

    return (
        <Grid container className={cx(classes.root)} role="listitem">
            <Grid item xs={12} md={5}>
                {renderAttributeName()}
            </Grid>
            <Grid item xs={12} md={7}>
                <div className={cx(classes.constraintsValuesActions)}>
                    <div className={cx(classes.constraints)}>
                        <AttributeConstraintSelect
                            attributeConstraint={attributeConstraint}
                            attributesConstraints={attributesConstraints}
                            index={index}
                        />
                    </div>
                    <div className={cx(classes.actions)}>
                        <Controller
                            name={`attributesConstraints.${index}.attributeValueId`}
                            render={({ field: { onChange, value, ...props } }) => (
                                <Autocomplete<DiscriminatedEntity<ConfigModelsAttributeValueBasic>, false, false, false>
                                    {...props}
                                    autoSelect={false}
                                    blurOnSelect
                                    disabled={attributeValueDisabled}
                                    fullWidth
                                    getOptionLabel={(option) => option?.value}
                                    isOptionEqualToValue={(option, _value) => option.id === _value.id}
                                    loading={loading}
                                    name="attributeValueId"
                                    onChange={(event, _value) => onChange(_value?.id || null)}
                                    options={attributeValues}
                                    placeholder="Select value"
                                    ref={null}
                                    required={!isCanBeAny(attributeConstraint.constraintType)}
                                    variant="standard"
                                    value={attributeValues.find((item) => item?.id === value) || null}
                                />
                            )}
                            rules={{
                                required:
                                    (!isCanBeAny(attributeConstraint.constraintType) && 'common.validation.required') ||
                                    false,
                            }}
                        />
                        <IconButton
                            disabled={removeLabelDisabled}
                            onClick={() => onRemoveLabel(index)}
                            data-testid="delete"
                            size="small"
                        >
                            <Icon type="DELETE" size={16} color={!removeLabelDisabled ? ORANGE : DISABLED} />
                        </IconButton>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

export default AttributeConstraintValue;
