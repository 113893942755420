import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { Divider, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Icon } from '@zetadisplay/zeta-ui-components';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

import RemoveLabelValuePlayerPrompt, {
    RemoveLabelValuePlayerPromptProps,
} from 'src/components/Modals/LabelSetup/RemoveLabelValuePlayerPrompt';
import Tag from 'src/components/Tag';
import { LabelValuePlayerState, LabelValuePlayerType, LabelValueType } from 'src/views/LabelSetupView';

const { ORANGE, DARKGRAY } = themeOptions.colors;

const useStyles = makeStyles()(() => ({
    player: {
        padding: '2px 0 0',
    },
    playerTitle: {
        fontSize: 12,
    },
    playerSubtitle: {
        fontSize: 11,
    },
    actionsContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'end',
    },
    actions: {
        paddingLeft: 6,
    },
}));

type Props = {
    labelValue: LabelValueType;
    onRemoveLabelValuePlayer: (player: LabelValuePlayerType) => void;
    onRestoreLabelValuePlayer: (player: LabelValuePlayerType) => void;
    player: LabelValuePlayerType;
    usedPlayersByAttributeValues: LabelValuePlayerType[];
};

const LabelSetupValuePlayer = ({
    labelValue,
    onRemoveLabelValuePlayer,
    onRestoreLabelValuePlayer,
    player,
    usedPlayersByAttributeValues,
}: Props) => {
    const { classes } = useStyles();
    const { trans } = useTranslation();
    const canEnablePlayer = usedPlayersByAttributeValues.find((p) => p.id === player.id) === undefined;

    const showRemovePlayerPrompt = () => {
        NiceModal.show<LabelValuePlayerType, RemoveLabelValuePlayerPromptProps>(RemoveLabelValuePlayerPrompt, {
            player,
            labelValue,
        }).then(onRemoveLabelValuePlayer);
    };

    const renderStateTag = () => {
        if (player.state === undefined) {
            return null;
        }

        return player.state === LabelValuePlayerState.DELETED ? (
            <Tag type="RED" text={trans('engage.misc.deleted')} />
        ) : (
            <Tag type="DEFAULT" text={trans('engage.misc.new')} />
        );
    };

    const renderActionButton = () => {
        if (player.state !== LabelValuePlayerState.DELETED) {
            return (
                <IconButton data-testid={`remove-player-${player.id}`} onClick={showRemovePlayerPrompt} size="small">
                    <Icon type="DELETE" size={16} color={ORANGE} />
                </IconButton>
            );
        }

        return (
            <IconButton
                data-testid={`enable-player-${player.id}`}
                disabled={!canEnablePlayer}
                onClick={() => onRestoreLabelValuePlayer(player)}
                size="small"
            >
                <Icon type="DELETE_RESTORE" size={16} color={canEnablePlayer ? ORANGE : DARKGRAY} />
            </IconButton>
        );
    };

    return (
        <div className={classes.player} data-testid={`label-setup-value-player-${player.name}`}>
            <Grid container alignItems="center" alignContent="center">
                <Grid item xs={8}>
                    <Typography variant="h5" className={classes.playerTitle}>
                        {player.friendlyName}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.playerSubtitle}>
                        <span>#{player.id}</span> <span>{player.name}</span>
                    </Typography>
                </Grid>
                <Grid item xs={4} className={classes.actionsContainer}>
                    {renderStateTag()}
                    <div className={classes.actions}>{renderActionButton()}</div>
                </Grid>
            </Grid>
            <Divider />
        </div>
    );
};

export default LabelSetupValuePlayer;
