import { getFoldersByParentId } from '@zetadisplay/engage-components/utils/media';

import { MoveMediaTargetType } from '../Library/MoveLibraryItemSidekick';

const getInitialFolders = (
    currentNode: MoveMediaTargetType | undefined,
    targets: Map<string, MoveMediaTargetType> | undefined = new Map()
) => {
    if (currentNode === undefined) {
        return [...targets.values()];
    }

    return [...getFoldersByParentId(currentNode.id, targets).values()];
};

export const getFoldersByParentWithChildrenAsArray = (collection: MoveMediaTargetType[]): MoveMediaTargetType[] => {
    return collection.flatMap((folder) => {
        if (!folder.children.size) {
            return folder;
        }

        return [folder, ...getFoldersByParentWithChildrenAsArray([...folder.children.values()])];
    });
};

export const getFilteredFolders = (
    currentNode: MoveMediaTargetType | undefined,
    navigatedNode: MoveMediaTargetType | undefined,
    targets: Map<string, MoveMediaTargetType> | undefined
) => {
    const initialFolders = getInitialFolders(navigatedNode, targets);
    if (currentNode && !initialFolders.some((folder) => folder.id === currentNode.id)) {
        return [{ ...currentNode, name: './' }, ...initialFolders];
    }

    return initialFolders;
};
