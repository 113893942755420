import React from 'react';
import { Typography } from '@mui/material';
import { ConfigModelsGroupInfoWithChildren } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { TreeNode } from '@zetadisplay/engage-components/utils/tree-builder';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()((theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
    },
    separator: {
        color: theme.palette.background.disabledText,
        display: 'inline-block',
        padding: '0 4px',
    },
    text: {
        color: theme.palette.background.disabledText,
        display: 'inline-block',
        '&:first-letter': {
            textTransform: 'uppercase',
        },
    },
}));

type Props = {
    item:
        | DiscriminatedEntity<ConfigModelsGroupInfoWithChildren>
        | TreeNode<DiscriminatedEntity<ConfigModelsGroupInfoWithChildren>, number>;
};

const GroupItemSubtitle = ({ item }: Props) => {
    const { classes, cx } = useStyles();
    const t = useTranslation();

    return (
        <div className={cx(classes.root)} data-testid={`group-list-item-subtitle-${item.id}`}>
            {item.isShared && (
                <Typography variant="subtitle1" noWrap className={cx(classes.text)}>
                    {t.trans('engage.model.common.shared')}
                </Typography>
            )}
        </div>
    );
};

export default GroupItemSubtitle;
