import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ConfigModelsGroupInfo } from '@zetadisplay/engage-api-client';
import {
    buildUuidNamePathNode,
    getCurrentRouteNodeFromPath,
    idNamePathNodeRegex,
    isRouteNode,
} from '@zetadisplay/engage-components/utils/route';

const useCurrentGroup = (group: ConfigModelsGroupInfo | undefined = undefined, usePath = true): number | undefined => {
    const location = useLocation();

    const getCurrentGroupId = useCallback(() => {
        const identifier =
            group?.id ||
            (usePath && getCurrentRouteNodeFromPath(location.pathname, idNamePathNodeRegex, buildUuidNamePathNode)) ||
            undefined;

        if (isRouteNode(identifier)) {
            return typeof identifier.id === 'string' ? parseInt(identifier.id, 10) : identifier.id;
        }

        return identifier;
    }, [group?.id, location.pathname, usePath]);

    return useMemo((): number | undefined => getCurrentGroupId(), [getCurrentGroupId]);
};

export default useCurrentGroup;
