import React, { useCallback } from 'react';
import { Divider, Tab, Tabs } from '@mui/material';
import { FilterFields, keywordFilter } from '@zetadisplay/engage-components/modules/search';
import { emitOnSearch, SearchEventScope } from '@zetadisplay/engage-components/modules/search/events';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Search } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { PrimaryPowerScheduleType, SpecialPowerScheduleType } from 'src/views/PowerScheduleSetupView';
import PowerScheduleLibraryPrimaryList from 'src/views/PowerScheduleSetupView/Components/PowerScheduleLibrary/PowerScheduleLibraryPrimaryList';

import PowerScheduleLibrarySpecialList from './PowerScheduleLibrary/PowerScheduleLibrarySpecialList';

const useStyles = makeStyles()(() => ({
    container: {
        height: 'calc(100% - 104px)',
        flexGrow: 1,
        marginBottom: 10,
    },
    divider: {
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        marginTop: 6,
    },
    info: {
        fontSize: 12,
        padding: 12,
    },
    listHeader: {
        marginBottom: 4,
    },
    root: {
        height: '100%',
        paddingTop: 2,
    },
    tabs: {
        marginBottom: 6,
    },
    title: {
        margin: '12px 0',
    },
}));

type PowerScheduleLibraryProps = {
    onSelectPowerSchedule: (arg: PrimaryPowerScheduleType) => void;
    onSelectSpecialSchedule: (arg: SpecialPowerScheduleType) => void;
    onTabSelect: (tab: number) => void;
    currentTab: number;
};

const PowerScheduleLibrary = ({
    currentTab,
    onTabSelect,
    onSelectPowerSchedule,
    onSelectSpecialSchedule,
}: PowerScheduleLibraryProps) => {
    const { classes } = useStyles();
    const t = useTranslation();

    const handleTabClick = useCallback(
        (tab: number) => {
            onTabSelect(tab); // Communicate the selected tab back to the parent component
        },
        [onTabSelect]
    );

    const renderLibraryList = useCallback(() => {
        if (currentTab === 0) {
            return <PowerScheduleLibraryPrimaryList onSelectPowerSchedule={onSelectPowerSchedule} />;
        }

        return <PowerScheduleLibrarySpecialList onSelectPowerSchedule={onSelectSpecialSchedule} />;
    }, [currentTab, onSelectPowerSchedule, onSelectSpecialSchedule]);

    return (
        <div className={classes.root} data-testid="power-schedule-library">
            <Tabs
                centered
                className={classes.tabs}
                onChange={(_, value) => handleTabClick(value)}
                value={currentTab}
                variant="fullWidth"
            >
                <Tab label={t.trans('engage.players.setup.power_schedule.library.primary')} data-testid="primary-tab" />
                <Tab label={t.trans('engage.players.setup.power_schedule.library.special')} data-testid="special-tab" />
            </Tabs>
            <Search
                filters={{ [FilterFields.KEYWORD]: keywordFilter }}
                fullWidth
                onChangeCallback={(state) => emitOnSearch({ scope: SearchEventScope.POWER_SCHEDULE, value: state })}
            />
            <Divider className={classes.divider} />
            <div className={classes.container}>{renderLibraryList()}</div>
        </div>
    );
};

export default PowerScheduleLibrary;
