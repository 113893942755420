import React from 'react';
import { Control, FieldValues, UseFormSetValue, useFormState } from 'react-hook-form';
import { FieldPath } from 'react-hook-form/dist/types';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { useTheme } from '@mui/material/styles';
import { ZetaTheme } from '@zetadisplay/zeta-ui-components/utils/theme';

export type UseEditableContentProps<T extends FieldValues, N extends FieldPath<T> = FieldPath<T>> = {
    control: Control<T>;
    defaultHeight: number;
    name: N;
    rules?: Omit<RegisterOptions<T, N>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    setValue: UseFormSetValue<T>;
};

const useEditableContent = <T extends FieldValues, N extends FieldPath<T> = FieldPath<T>>({
    control,
    defaultHeight,
    name,
    rules,
    setValue,
}: UseEditableContentProps<T, N>) => {
    const { register } = control;
    const { errors } = useFormState({ control });
    const theme: ZetaTheme = useTheme();

    const error = errors[name as string];

    const errorSx = error && {
        '::before': {
            backgroundColor: theme.palette.background.paper,
            content: `"${error.message}"`,
            color: theme.palette.error.main,
            cursor: 'text',
            height: `${defaultHeight - 4}px`,
            fontSize: '0.75rem',
            fontWeight: 500,
            opacity: 0.95,
            paddingLeft: '4px',
            position: 'absolute',
            textTransform: 'uppercase',
            bottom: 2,
            left: 2,
            top: 2,
            right: 2,
            '&:hover': {
                backgroundColor: `${theme.palette.background.disabled}99`,
            },
        },
    };

    return {
        ...register(name, rules),
        contentEditable: true,
        'data-testid': `editable-${name}`,
        onInput: (e: React.ChangeEvent<HTMLDivElement>) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setValue(name, e.target.innerHTML.replace('&nbsp;', ' ').trim(), {
                shouldDirty: true,
                shouldValidate: true,
            });
        },
        sx: {
            position: 'relative',
            textTransform: 'none',
            ...errorSx,
            '&:hover': {
                backgroundColor: `${theme.palette.background.disabled}99`,
            },
            '&:focus': {
                backgroundColor: 'transparent',
            },
        },
        suppressContentEditableWarning: true,
    };
};

export default useEditableContent;
