import { PublishingModelsCampaignCampaignStatus, SharedModelsLayoutRelations } from '@zetadisplay/engage-api-client';
import { ApiInterface } from '@zetadisplay/engage-components/modules/api';

import { getDefaultLayout } from '../../../views/PlaylistSetupView';
import createPlaylistFormValues from './createPlaylistFormValues';
import fetchPlaylistFormData from './fetchPlaylistFormData';

const getFormDefaultValues = ({
    api,
    defaultLayoutId,
    folderId,
    playlistId,
    workspaceId,
    workspaceLayouts,
}: {
    api: ApiInterface;
    defaultLayoutId?: number | null;
    folderId?: string;
    playlistId?: number;
    workspaceId: string;
    workspaceLayouts?: SharedModelsLayoutRelations[];
}) => {
    if (playlistId) {
        return fetchPlaylistFormData(playlistId, workspaceId, api);
    }

    return createPlaylistFormValues({
        folderId,
        status: PublishingModelsCampaignCampaignStatus.Published,
        layoutZoneId: getDefaultLayout(workspaceLayouts, defaultLayoutId)?.zones[0].id,
    });
};

export default getFormDefaultValues;
