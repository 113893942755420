import { useMemo } from 'react';
import { Box } from '@mui/material';
import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

import usePlaylistLayout from 'src/views/PlaylistView/Hooks/usePlaylistLayout';

const { ERROR, SUCCESS, WARNING } = themeOptions.colors;

type Props = {
    layoutZoneId?: number;
    media: DiscriminatedEntity<LibraryModelsMedia>;
};

export const RATIO_VALID = 'valid';
export const RATIO_UNKNOWN = 'unknown';
export const RATIO_INVALID = 'invalid';

const BOX_SIZE = '10px';
const BOX_DISTANCE = '2px';

const getAspectRatio = (width: number | undefined | null = 0, height: number | undefined | null = 0) => {
    if (width && height) {
        return width / height;
    }

    return 0;
};

const getRatioColor = (ratio: string) => {
    if (ratio === RATIO_VALID) {
        return SUCCESS;
    }

    if (ratio === RATIO_INVALID) {
        return ERROR;
    }

    return WARNING;
};

const PlaylistSetupContentListItemAspectRatio = ({ media, layoutZoneId }: Props) => {
    const { layout, zone } = usePlaylistLayout(layoutZoneId);

    const layoutAspectRatio = getAspectRatio(
        (layout && zone && layout.width * zone.width) || 0,
        (layout && zone && layout.height * zone.height) || 0
    );
    const imageAspectRatio = getAspectRatio(media?.width, media?.height);

    const ratio = useMemo(() => {
        if (layoutAspectRatio === imageAspectRatio) {
            return RATIO_VALID;
        }

        if (layoutAspectRatio > 0 && imageAspectRatio > 0 && layoutAspectRatio !== imageAspectRatio) {
            return RATIO_INVALID;
        }

        return RATIO_UNKNOWN;
    }, [imageAspectRatio, layoutAspectRatio]);

    if (ratio === RATIO_VALID) {
        return null;
    }

    const border = `2px solid ${getRatioColor(ratio)}`;

    return (
        <Box
            data-testid="aspect-ratio"
            sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                opacity: 1,
                zIndex: 1,
            }}
        >
            <Box data-testid={ratio} sx={{ border, height: '100%', width: '100%', position: 'relative' }}>
                <Box
                    sx={{
                        borderLeft: border,
                        borderTop: border,
                        position: 'absolute',
                        height: BOX_SIZE,
                        width: BOX_SIZE,
                        left: BOX_DISTANCE,
                        top: BOX_DISTANCE,
                    }}
                />

                <Box
                    sx={{
                        borderRight: border,
                        borderBottom: border,
                        position: 'absolute',
                        height: BOX_SIZE,
                        width: BOX_SIZE,
                        right: BOX_DISTANCE,
                        bottom: BOX_DISTANCE,
                    }}
                />
            </Box>
        </Box>
    );
};

export default PlaylistSetupContentListItemAspectRatio;
