import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useModal } from '@ebay/nice-modal-react';
import { Collapse, IconButton, Stack, Typography } from '@mui/material';
import {
    ConfigModelsWorkspaceBasic,
    PublishingCampaignSchedulingCampaignInfoWithMediaSchedules,
} from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useUserInformation } from '@zetadisplay/engage-components/modules/auth';
import {
    createScheduleFromEngageSchedule,
    PlaylistMediaMenu,
    PlaylistMediaType,
} from '@zetadisplay/engage-components/modules/playlist';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { formatDuration } from '@zetadisplay/engage-components/utils/media';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Expander, Icon, Tag, Theme } from '@zetadisplay/zeta-ui-components';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';
import queryString from 'query-string';

import PlayerPlaylistDetails from 'src/views/PlayersView/Components/PlayerItemDetails/PlayerPlaylistDetails';

import useResolvePlaylistMediaCollection from '../../../../hooks/useResolvePlaylistMediaCollection';

const { BLACK, DARKGRAY, LIGHTGRAY, WHITE } = themeOptions.colors;

const useStyles = makeStyles()(() => ({
    noContent: {
        alignItems: 'center',
        backgroundColor: BLACK,
        display: 'flex',
        height: 104,
        justifyContent: 'center',
        marginBottom: 18,
        padding: '4px 0',
        width: '100%',
    },
    title: {
        fontSize: 14,
        fontWeight: 'bold',
        padding: 5,
        color: WHITE,
    },
    stackContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        border: '1px solid',
        borderRadius: '8px',
        borderColor: LIGHTGRAY,
        backgroundColor: BLACK,
    },
    stackItems: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'flex-end',
    },
    stackTags: {
        padding: 5,
    },
}));

// Inject workspace that we get from PublishingModelsPlaylistPlaylistCampaignInfo
export type PlaylistItem = PublishingCampaignSchedulingCampaignInfoWithMediaSchedules & {
    workspace?: ConfigModelsWorkspaceBasic;
};

type Props = {
    item: DiscriminatedEntity<PlaylistItem>;
};

const PlayerItemDetails = ({ item }: Props) => {
    const { classes } = useStyles();
    const t = useTranslation();
    const modal = useModal();
    const { hasRole } = useUserInformation();
    const { workspace } = useWorkspace();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [expand, setExpand] = useState<boolean>(false);

    const playlistMediaCollection = useMemo(
        () =>
            item.campaignMedia?.map(
                (media) =>
                    ({
                        ...media,
                        mediaSchedules: media.mediaSchedules?.map(createScheduleFromEngageSchedule) || [],
                    } as Partial<PlaylistMediaType>)
            ) || [],
        [item.campaignMedia]
    );

    const [resolvedMedia] = useResolvePlaylistMediaCollection(playlistMediaCollection);

    const renderDurationTag = (detailSubject: string, detailValue: ReactNode) => (
        <span className={classes.stackTags}>
            <Tag type="BLACK" sx={{ color: WHITE, backgroundColor: BLACK }} text={`${detailSubject}: ${detailValue}`} />
        </span>
    );

    const handleExpandClick = (state: boolean) => {
        setExpand(state);
    };

    const isSharedPlaylist = useCallback(
        (playlist: DiscriminatedEntity<PlaylistItem>) => {
            return playlist.workspace !== undefined && workspace.id !== playlist.workspace?.id;
        },
        [workspace.id]
    );

    const isDisabled = useCallback(
        (playlist: DiscriminatedEntity<PlaylistItem>) => {
            return !hasRole(['ENGAGE_PLAYLIST_EDIT']) || isSharedPlaylist(playlist);
        },
        [hasRole, isSharedPlaylist]
    );

    const handleEdit = () => {
        navigate({
            pathname: '/playlists/edit',
            search: queryString.stringify({ playlistId: item.id, returnUrl: pathname }),
        });

        modal.hide();
    };

    return (
        <Stack className={classes.stackContainer} data-testid={`playlist-stack-${item.id}`}>
            <Stack direction="row" spacing={1} sx={{ padding: '3px', alignItems: 'baseline' }}>
                <Expander onClick={handleExpandClick} expanded={expand} />
                <Stack direction="row" sx={{ width: '100%' }}>
                    <Typography className={classes.title} variant="h5">
                        {item.name}
                    </Typography>
                    <div className={classes.stackItems}>
                        <span className={classes.stackTags}>
                            <Tag type="ORANGE" text={`${playlistMediaCollection.length} items`} />
                        </span>
                        {renderDurationTag(
                            t.trans('engage.playlist.details.duration'),
                            formatDuration(
                                resolvedMedia.reduce(
                                    (duration, mediaFile) => duration + (mediaFile.model?.duration || 0),
                                    0
                                )
                            )
                        )}
                        <IconButton
                            data-testid="edit-label-value"
                            disabled={isDisabled(item)}
                            onClick={handleEdit}
                            size="small"
                        >
                            <Icon type="SETTINGS" size={18} color={isDisabled(item) ? DARKGRAY : WHITE} />
                        </IconButton>
                    </div>
                </Stack>
            </Stack>
            <Collapse
                in={expand}
                sx={{ width: '100%', padding: '0px 32px' }}
                data-testid={`playlist-stack-expand-collapsed-${item.id}`}
            >
                <PlayerPlaylistDetails item={item} />
            </Collapse>

            {item.campaignMedia && item.campaignMedia.length > 0 && (
                <Theme nested>
                    <PlaylistMediaMenu mediaCollection={resolvedMedia} isDisableRenderPreview />
                </Theme>
            )}
            {item.campaignMedia && item.campaignMedia.length === 0 && (
                <Theme nested>
                    <div className={classes.noContent}>
                        <Typography>{t.trans('engage.players.details.playlist.no_content')}</Typography>
                    </div>
                </Theme>
            )}
        </Stack>
    );
};

export default PlayerItemDetails;
