import React, { useCallback, useMemo } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { Divider, Grid, Typography } from '@mui/material';
import { ConfigModelsAttributeDetails } from '@zetadisplay/engage-api-client';
import { ItemsView, SkeletonItemsView } from '@zetadisplay/engage-components';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { ViewMode } from '@zetadisplay/engage-components/modules/options';
import { UseAttributesReturnType } from '@zetadisplay/engage-components/modules/players/hooks';
import { FilterFields, keywordFilter } from '@zetadisplay/engage-components/modules/search';
import { emitOnSearch, SearchEventScope } from '@zetadisplay/engage-components/modules/search/events';
import { getIconType } from '@zetadisplay/engage-components/modules/view/utils';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ItemProps, Search } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import RemoveLabelPrompt, { RemoveLabelPromptProps } from 'src/components/Modals/LabelSetup/RemoveLabelPrompt';
import LabelSetupLibraryItemActions from 'src/views/LabelSetupView/Components/LabelSetupLibrary/LabelSetupLibraryItemActions';

const useStyles = makeStyles()(() => ({
    root: {
        height: '100%',
        paddingTop: 2,
    },
    listHeader: {
        marginBottom: 4,
    },
    divider: {
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
    title: {
        margin: '12px 0',
    },
    container: {
        height: 'calc(100% - 104px)',
        flexGrow: 1,
        marginBottom: 10,
    },
    info: {
        fontSize: 12,
        padding: 12,
    },
}));

type Props = {
    labels: UseAttributesReturnType;
    onRemoveLabel: (label: DiscriminatedEntity<ConfigModelsAttributeDetails>) => void;
    onSelectLabel: (labelId: number) => void;
};

const LabelSetupLibrary = ({ labels, onRemoveLabel, onSelectLabel }: Props) => {
    const { classes } = useStyles();
    const t = useTranslation();

    const showRemoveLabelPrompt = useCallback(
        (label: DiscriminatedEntity<ConfigModelsAttributeDetails>) => {
            NiceModal.show<DiscriminatedEntity<ConfigModelsAttributeDetails>, RemoveLabelPromptProps>(
                RemoveLabelPrompt,
                {
                    label,
                }
            ).then(onRemoveLabel);
        },
        [onRemoveLabel]
    );

    const renderPrimaryAction = useCallback(
        (item: DiscriminatedEntity<ConfigModelsAttributeDetails>) => {
            return (
                <LabelSetupLibraryItemActions
                    item={item}
                    onDeleteLabel={showRemoveLabelPrompt}
                    onSelectLabel={(label) => onSelectLabel(label.id)}
                />
            );
        },
        [onSelectLabel, showRemoveLabelPrompt]
    );

    const itemProps: ItemProps<DiscriminatedEntity<ConfigModelsAttributeDetails>> = useMemo(
        () => ({
            getItemIconType: getIconType,
            inViewThreshold: 0.2,
            renderPrimaryAction,
            showThumbnail: false,
            type: 'compact',
        }),
        [renderPrimaryAction]
    );

    const renderList = () => {
        if (labels.isLoading && labels.data.length === 0) {
            return (
                <SkeletonItemsView items={8} primaryAction textCells={1} textCellWidth="70%" viewMode={ViewMode.ROWS} />
            );
        }

        if (labels.data.length === 0) {
            return (
                <Typography align="center" className={classes.info}>
                    {t.trans('engage.library.content.no_content')}
                </Typography>
            );
        }

        return (
            <ItemsView
                infiniteScrolling={!labels.total || labels.total > labels.data.length}
                isLoadingMore={labels.isLoading && labels.data.length > 0}
                items={labels.data}
                ItemProps={itemProps}
                onInfiniteScroll={labels.getNextResultPage}
                viewMode={ViewMode.ROWS}
            />
        );
    };

    return (
        <div className={classes.root} data-testid="label-setup-library-list">
            <Typography className={classes.title} variant="h5">
                {t.trans('engage.players.labels.setup.library.title')}
            </Typography>
            <Grid container spacing={2} className={classes.listHeader}>
                <Grid item xs={12}>
                    <Search
                        filters={{ [FilterFields.KEYWORD]: keywordFilter }}
                        fullWidth
                        onChangeCallback={(state) => emitOnSearch({ scope: SearchEventScope.LABEL, value: state })}
                    />
                </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <div className={classes.container}>{renderList()}</div>
        </div>
    );
};

export default LabelSetupLibrary;
