import React from 'react';
import { DefaultLayout } from '@zetadisplay/engage-components/layouts';
import { DefaultLayoutContainerProps } from '@zetadisplay/engage-components/layouts/components/default-layout-container';

import Header from 'src/layouts/Components/Header';

import ProductFruitsWrapper from '../ProductFruits/ProductFruitsWrapper';

const defaultLayoutContainerProps: DefaultLayoutContainerProps = {
    renderHeader: () => <Header />,
    useViewModeProvider: true,
};

const withDefaultLayout = <P,>(
    Component: React.ComponentType<P>,
    LayoutContainerProps: DefaultLayoutContainerProps = defaultLayoutContainerProps
): React.FC<P & DefaultLayoutContainerProps> => {
    return function DefaultLayoutWrapper({ renderHeader, useViewModeProvider, ...rest }) {
        return (
            <ProductFruitsWrapper>
                <DefaultLayout
                    LayoutContainerProps={{
                        renderHeader: renderHeader || LayoutContainerProps.renderHeader,
                        useViewModeProvider: useViewModeProvider || LayoutContainerProps.useViewModeProvider,
                    }}
                >
                    <Component {...(rest as unknown as P & JSX.IntrinsicAttributes)} />
                </DefaultLayout>
            </ProductFruitsWrapper>
        );
    };
};

export default withDefaultLayout;
