import { handleResponseError } from '@zetadisplay/engage-components/utils/response';
import { AxiosError } from 'axios';

const getErrorMessage = (error: unknown) => {
    if (error instanceof AxiosError) {
        return handleResponseError(error);
    }

    throw error;
};

export default getErrorMessage;
