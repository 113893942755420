import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { LabelValuePlayerState, LabelValueState, LabelValueType } from 'src/views/LabelSetupView';
import LabelSetupValue from 'src/views/LabelSetupView/Components/LabelSetupMainForm/LabelSetupValues/LabelSetupValue';

const useStyles = makeStyles()(() => ({
    loader: {
        padding: 10,
        width: '100%',
    },
    values: {
        width: '100%',
    },
}));

type Props = {
    onRemoveLabelValue: (labelValue: LabelValueType) => void;
    values: LabelValueType[];
};

const getDefaultSelectedLabelValueIndex = (id: string | undefined, values: LabelValueType[]) => {
    const selectedLabelValueIndex = values.findIndex((value) => value.id === Number(id));
    return selectedLabelValueIndex !== -1 ? selectedLabelValueIndex : undefined;
};

const LabelSetupValues = ({ onRemoveLabelValue, values }: Props) => {
    const { classes } = useStyles();
    const { attributeValueId } = useParams<'attributeValueId'>();

    // Selected state, default state will be parsed from params
    const [selectedLabelValueIndex, setSelectedLabelValueIndex] = useState(
        getDefaultSelectedLabelValueIndex(attributeValueId, values)
    );

    const removedPlayersFromAttributeValues = useMemo(
        () =>
            values.flatMap((value) => {
                return value.state === LabelValueState.DELETED
                    ? value.players
                    : value.players.filter((player) => player.state === LabelValuePlayerState.DELETED);
            }),
        [values]
    );

    const usedPlayersByAttributeValues = useMemo(
        () =>
            values
                .filter((value) => value.state !== LabelValueState.DELETED)
                .flatMap((value) => {
                    return value.players.filter((player) => player.state !== LabelValuePlayerState.DELETED);
                }),
        [values]
    );

    return (
        <div className={classes.values}>
            {values.map((value, index: number) => {
                return (
                    <Grid key={value.id} item xs={12}>
                        <LabelSetupValue
                            expanded={value.state !== LabelValueState.DELETED && selectedLabelValueIndex === index}
                            index={index}
                            labelValue={value}
                            onExpandLabelValue={(labelValueIndex: number) =>
                                setSelectedLabelValueIndex((prevState) =>
                                    prevState === labelValueIndex ? undefined : labelValueIndex
                                )
                            }
                            onRemoveLabelValue={onRemoveLabelValue}
                            removedPlayersFromAttributeValues={removedPlayersFromAttributeValues}
                            usedPlayersByAttributeValues={usedPlayersByAttributeValues}
                        />
                    </Grid>
                );
            })}
        </div>
    );
};

export default LabelSetupValues;
