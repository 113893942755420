import React, { useContext } from 'react';
import { useFormState } from 'react-hook-form';
import { Box, Stack, Typography } from '@mui/material';
import { SwitchInput, TextInput } from '@zetadisplay/engage-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import PlayerSettingsChannelContext from './PlayerSettingsChannelContext';

const useStyles = makeStyles()(() => ({
    audioBox: {
        display: 'flex',
        padding: '10px 0px 10px',
        justifyContent: 'space-between',
    },
}));

const PlayerSettingsChannelSettings = () => {
    const { classes } = useStyles();
    const { currentChannelIndex } = useContext(PlayerSettingsChannelContext);
    const { isLoading } = useFormState();

    return (
        <Box>
            <Stack direction="row" spacing={1} sx={{ paddingBottom: '5px', paddingTop: '5px' }}>
                <TextInput
                    defaultLabel="common.form.input.width"
                    loading={isLoading}
                    name={`channels.${currentChannelIndex}.width`}
                    variant="filled"
                />
                <TextInput
                    defaultLabel="common.form.input.height"
                    loading={isLoading}
                    name={`channels.${currentChannelIndex}.height`}
                    variant="filled"
                />
            </Stack>

            <Box className={classes.audioBox}>
                <div>
                    <Typography variant="h6">Audio</Typography>
                    <Typography variant="caption">Having this setting off will mute the channel</Typography>
                </div>
                <SwitchInput name={`channels.${currentChannelIndex}.audio`} />
            </Box>
        </Box>
    );
};

export default PlayerSettingsChannelSettings;
