import React from 'react';
import { Skeleton, Stack } from '@mui/material';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()(() => ({
    details: {
        marginBottom: 10,
    },
    media: {
        marginBottom: -12,
        marginTop: -30,
    },
    divider: {
        marginTop: 6,
        marginBottom: 6,
    },
}));

const PlayerItemDetailsPlaylistSkeleton = () => {
    const { classes } = useStyles();

    return (
        <>
            <Stack direction="row" spacing={1} sx={{ padding: '3px', justifyContent: 'space-between' }}>
                <Skeleton width={200} height={30} />
                <Skeleton width={200} height={30} />
            </Stack>
            <Skeleton className={classes.media} width="100%" height={160} />
        </>
    );
};

export default PlayerItemDetailsPlaylistSkeleton;
