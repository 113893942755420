import React, { useState } from 'react';
import { Button, Drawer } from '@mui/material';
import { Menu } from '@styled-icons/material';
import { DropdownMenuItem } from '@zetadisplay/engage-components';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import Navigation from 'src/layouts/Components/Navigation';
import WorkspaceSelection from 'src/layouts/Components/WorkspaceSelection';
import useNavigation from 'src/layouts/Hooks/useNavigation';

const useStyles = makeStyles()((theme) => ({
    hamburger: {
        height: 24,
        minWidth: 24,
        padding: 0,
        marginLeft: 6,
    },
    drawerContent: {
        padding: '24px 48px',
    },
    secondaryLinkBold: {
        color: '#1D1D1D',
        display: 'block',
        fontSize: 14,
        fontWeight: 600,
        marginBottom: 18,
    },
    'secondaryLink--disabled': {
        color: theme.palette.text.disabled,
        display: 'block',
        pointerEvents: 'none',
    },
}));

type Props = {
    includeWorkspace?: boolean;
    secondaryMenuItems: DropdownMenuItem[];
};

const MobileNavigation = ({ includeWorkspace = true, secondaryMenuItems }: Props) => {
    const { classes, cx } = useStyles();
    const t = useTranslation();

    const [open, toggleOpen] = useState<boolean>(false);
    const navigationItems = useNavigation(() => toggleOpen(false));

    const handleSecondary = (item: DropdownMenuItem) => {
        if (item?.callback) {
            item.callback();
        }
    };

    return (
        <>
            <Button
                className={cx(classes.hamburger)}
                aria-label="Menu"
                onClick={() => toggleOpen(!open)}
                variant="text"
            >
                <Menu size={26} />
            </Button>

            <Drawer anchor="right" open={open} onClose={() => toggleOpen(false)} data-testid="mobile-navigation-drawer">
                <div className={cx(classes.drawerContent)}>
                    {includeWorkspace && <WorkspaceSelection />}

                    <Navigation items={navigationItems} />

                    {secondaryMenuItems.map((item) => (
                        <Button
                            key={item.name}
                            className={cx(
                                item.disabled ? classes['secondaryLink--disabled'] : classes.secondaryLinkBold
                            )}
                            onClick={() => handleSecondary(item)}
                            variant="text"
                        >
                            {t.trans(item.label)}
                        </Button>
                    ))}
                </div>
            </Drawer>
        </>
    );
};

export default MobileNavigation;
