import { useApi } from '@zetadisplay/engage-components/modules/api';

import { createGroupFormValues } from 'src/views/GroupSetupView';

const fetchGroupFormData = async (groupId: number, workspaceId: string, api: ReturnType<typeof useApi>) => {
    const result = await api.publishGroups
        .getGroup({
            groupid: groupId,
            workspaceid: workspaceId,
        })
        .then((response) => response.data);

    return createGroupFormValues({
        attributesConstraints: result.attributesConstraints || [],
        description: result.description || '',
        name: result.name || '',
        parent: result.parent || null,
    });
};

export default fetchGroupFormData;
