import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from '@zetadisplay/zeta-localization';

import PlayerSettingsChannelGroups from './PlayerSettingsChannelGroups';
import PlayerSettingsChannelLabels from './PlayerSettingsChannelLabels';
import PlayerSettingsChannelSettings from './PlayerSettingsChannelSettings';

const PlayerSettingsChannelForm = () => {
    const { trans } = useTranslation();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, padding: '10px 0' }}>
            <Typography variant="h6">{trans('engage.players.settings.channel.title')}</Typography>
            <PlayerSettingsChannelSettings />
            <Typography variant="h6">{trans('engage.players.settings.groups.title')}</Typography>
            <PlayerSettingsChannelGroups />
            <Typography variant="h6">{trans('engage.players.settings.labels.title')}</Typography>
            <PlayerSettingsChannelLabels />
        </Box>
    );
};

export default PlayerSettingsChannelForm;
