import { useCallback, useMemo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { PublishingCampaignSchedulingCampaignInfoWithMediaSchedules } from '@zetadisplay/engage-api-client';
import { usePendingPromise } from '@zetadisplay/engage-components/hooks';
import { createDiscriminatedEntity, DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { EntityDiscriminators } from '@zetadisplay/engage-components/models/entity-discriminator';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { Sidekick, SidekickProps } from '@zetadisplay/engage-components/modules/sidekick/components';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useNotify } from '@zetadisplay/zeta-ui-components/hooks';

import SidekickBack from 'src/components/Sidekicks/SideKickBack';

import ConfirmUnsavedChangesPrompt, {
    ConfirmUnsavedChangesPromptProps,
} from '../../../../components/Modals/ConfirmUnsavedChangesPrompt';
import SidekickClose from '../../../../components/Sidekicks/SideKickClose';
import { useOnPlaylistMediaRemovedListener } from '../../../../views/PlaylistSetupView/Events/onPlaylistMediaRemovedEvent';
import PlaylistItemDetailsInformation from '../../../../views/PlaylistView/Components/PlaylistItemDetails/PlaylistItemDetailsInformation';
import { emitOnPlaylistUpdated } from '../../../../views/PlaylistView/Events/onPlaylistUpdatedEvent';
import playlistCreateOrUpdatePromiseFactory from '../../actions/playlistCreateOrUpdatePromiseFactory';
import appendContentOnPlaylist from '../../utils/append-content-on-playlist';
import createButtonEvents from '../../utils/create-button-events';
import createMessagesCallbackFactory from '../../utils/create-messages-callback-factory';
import { PlaylistSetupFormValues } from '../../utils/createPlaylistFormValues';
import getFormDefaultValues from '../../utils/get-form-default-values';
import movePlaylistContentItemOnSort from '../../utils/move-playlist-content-item-on-sort';
import removeContentFromPlaylist from '../../utils/remove-content-from-playlist';
import undoRemoveContentFromPlaylist from '../../utils/undo-remove-content-from-playlist';
import updatePlaylistContentSchedules from '../../utils/update-playlist-content-schedules';
import updatePlaylistModifiedMedia from '../../utils/update-playlist-modified-media';
import { useOnPlaylistContentAddedListener } from './events/on-playlist-content-added-event';
import { useOnPlaylistContentEditedListener } from './events/on-playlist-content-edited-event';
import { useOnPlaylistContentItemDragEndListener } from './events/on-playlist-content-item-drag-end-event';
import { useOnPlaylistContentRemoveUndoListener } from './events/on-playlist-content-remove-undo-event';
import { useOnPlaylistContentScheduledListener } from './events/on-playlist-content-scheduled-event';
import PlaylistPreviewContent from './playlist-preview-content';
import PlaylistPreviewHeader from './playlist-preview-header';

export type PlaylistPreviewSidekickProps = {
    onClosePlaylist?: (id: number) => void;
    playlist: DiscriminatedEntity<PublishingCampaignSchedulingCampaignInfoWithMediaSchedules>;
} & NiceModalHocProps;

const playlistItemDetailsInformationSx = {
    marginBottom: '20px',
    marginTop: '10px',
};

const PlaylistPreviewSidekick = NiceModal.create<PlaylistPreviewSidekickProps>(({ playlist, onClosePlaylist }) => {
    const api = useApi();
    const location = useLocation();
    const modal = useModal();
    const notify = useNotify();
    const { workspace, workspaceLayouts, workspaceSettings } = useWorkspace();

    const state = location.state as { currentFolderId?: string };

    const methods = useForm<PlaylistSetupFormValues>({
        defaultValues: async () => {
            return getFormDefaultValues({
                api,
                defaultLayoutId: workspaceSettings?.defaultLayout,
                folderId: state?.currentFolderId,
                playlistId: playlist.id,
                workspaceId: workspace.id,
                workspaceLayouts,
            });
        },
    });

    const { control, formState, getValues, handleSubmit, setValue } = methods;
    const { isSubmitting, isLoading, isDirty } = formState;
    const { fields, append, move, remove, update } = useFieldArray({
        control,
        name: 'playlistMediaCollection',
        keyName: 'fieldArrayId',
    });

    useOnPlaylistContentItemDragEndListener(movePlaylistContentItemOnSort(fields, move));
    useOnPlaylistContentAddedListener(appendContentOnPlaylist(append, api, workspace.id, notify));
    useOnPlaylistMediaRemovedListener(removeContentFromPlaylist(fields, remove, update));
    useOnPlaylistContentRemoveUndoListener(undoRemoveContentFromPlaylist(fields, update));
    useOnPlaylistContentScheduledListener(updatePlaylistContentSchedules(fields, update));
    useOnPlaylistContentEditedListener(updatePlaylistModifiedMedia(fields, update, getValues, setValue));

    const handleOnClose = useCallback(
        (onClose: () => void) => {
            if (onClosePlaylist) {
                onClosePlaylist(playlist.id);
            }

            onClose();
        },
        [onClosePlaylist, playlist.id]
    );

    const onSidekickClose = useCallback(
        (onClose: () => void) => {
            if (!isDirty) {
                handleOnClose(onClose);
                return;
            }
            NiceModal.show<void, ConfirmUnsavedChangesPromptProps>(ConfirmUnsavedChangesPrompt, {}).then(() => {
                handleOnClose(onClose);
            });
        },
        [handleOnClose, isDirty]
    );

    const onSidekickSaved = useCallback((onClose: () => void) => {
        onClose();
    }, []);

    const submitAction = usePendingPromise(
        playlistCreateOrUpdatePromiseFactory({
            api,
            playlistId: playlist.id,
            workspaceid: workspace.id,
        }),
        createMessagesCallbackFactory(true),
        createButtonEvents
    );

    const onSubmit = useCallback(
        async (data: PlaylistSetupFormValues) => {
            const response = await submitAction(data);
            if (response === undefined) {
                return;
            }

            emitOnPlaylistUpdated(createDiscriminatedEntity(EntityDiscriminators.Campaign, response.data));
            onSidekickSaved(modal.hide);
        },
        [modal.hide, onSidekickSaved, submitAction]
    );

    const sidekickHeader = useMemo(
        (): SidekickProps['header'] => ({
            renderHeader: () => (
                <PlaylistPreviewHeader
                    control={control}
                    isSubmitBusy={isSubmitting}
                    isSubmitDisabled={isLoading || !isDirty}
                    onSubmitClick={handleSubmit(onSubmit)}
                    playlist={playlist}
                    setValue={setValue}
                />
            ),
            renderClose: (onClose) =>
                onClosePlaylist ? (
                    <SidekickBack onClose={() => onSidekickClose(onClose)} />
                ) : (
                    <SidekickClose onClose={() => onSidekickClose(onClose)} />
                ),
        }),
        [
            control,
            isSubmitting,
            isLoading,
            isDirty,
            handleSubmit,
            onSubmit,
            playlist,
            setValue,
            onClosePlaylist,
            onSidekickClose,
        ]
    );

    return (
        <Sidekick
            dark={false}
            disableBackdropClick={isDirty}
            drawerWidth={700}
            header={sidekickHeader}
            name="playlist-preview"
        >
            <FormProvider {...methods}>
                <PlaylistItemDetailsInformation
                    item={playlist}
                    showName={false}
                    showStatus
                    showTitle
                    sx={playlistItemDetailsInformationSx}
                />

                <PlaylistPreviewContent
                    layoutZoneId={playlist.layoutZoneId ?? 0}
                    loading={isLoading}
                    playlistMediaCollection={fields}
                />
            </FormProvider>
        </Sidekick>
    );
});

export default PlaylistPreviewSidekick;
