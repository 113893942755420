import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography } from '@mui/material';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { NavigationItem } from 'src/layouts/Hooks/useNavigation';

const useStyles = makeStyles()((theme) => ({
    activeLink: {
        color: theme.palette.text.primary,
    },
    disabledLink: {
        color: 'rgba(0, 0, 0, 0.26) !important',
        cursor: 'not-allowed',
    },
    link: {
        color: '#1D1D1D',
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '18px',
        letterSpacing: '0.47px',
        textTransform: 'uppercase',
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 36,
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'inline',
            marginLeft: 24,
            marginRight: 14,
            marginBottom: 0,
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: 40,
            marginRight: 0,
        },
    },
    root: {
        flexGrow: 1,
    },
}));

type Props = {
    items: NavigationItem[];
};

const Navigation = ({ items }: Props) => {
    const { classes, cx } = useStyles();

    return (
        <div className={cx(classes.root)}>
            {items.map((item) =>
                item.disabled ? (
                    <Typography className={cx(classes.link, classes.disabledLink)} key={item.label}>
                        {item.label}
                    </Typography>
                ) : (
                    <Link
                        key={item.label}
                        className={cx(classes.link)}
                        to={item.href}
                        component={RouterLink}
                        onClick={() => item.onNavigate && item.onNavigate()}
                    >
                        {item.label}
                    </Link>
                )
            )}
        </div>
    );
};

export default Navigation;
