import { useAsyncAbortable } from 'react-async-hook';
import { NetworkModelsPlayerInformation } from '@zetadisplay/engage-api-client';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';

import { getPlayerOnlineState, PlayerLocation } from '../Components/NetworkMap';

const usePlayerLocations = (players: NetworkModelsPlayerInformation[]) => {
    const api = useApi();
    const { workspace } = useWorkspace();

    return useAsyncAbortable(
        async (signal) => {
            const response = await api.players.getPlayerLocationsByWorkspace({ workspaceid: workspace.id }, { signal });
            return response.data
                .map((location) => {
                    const player = players.find((p) => p.locationId === location.id);

                    if (player === undefined) {
                        return undefined;
                    }

                    if (
                        location.lat === undefined ||
                        location.lat === null ||
                        location.lng === undefined ||
                        location.lng === null
                    ) {
                        return undefined;
                    }

                    return {
                        id: location.id,
                        lat: location.lat,
                        lng: location.lng,
                        name: player.friendlyName,
                        status: getPlayerOnlineState(player.onlineState),
                    };
                })
                .filter((location): location is PlayerLocation => location !== undefined);
        },
        [api, players, workspace.id]
    );
};

export default usePlayerLocations;
