import React from 'react';
import { Stack } from '@mui/material';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import PrimaryPowerScheduleForm from './PowerScheduleForm/PrimaryPowerScheduleForm';
import SpecialPowerScheduleForm from './PowerScheduleForm/SpecialPowerScheduleForm';

const useStyles = makeStyles()(() => ({
    container: {
        height: '100%',
        padding: '10px 4px 0px 0px',
        width: '100%',
    },
}));

type PowerScheduleFormProps = {
    currentTab: number;
};

const PowerScheduleForm = ({ currentTab }: PowerScheduleFormProps) => {
    const { classes } = useStyles();

    return (
        <Stack className={classes.container} spacing={1}>
            {currentTab === 0 && <PrimaryPowerScheduleForm />}
            {currentTab !== 0 && <SpecialPowerScheduleForm />}
        </Stack>
    );
};

export default PowerScheduleForm;
