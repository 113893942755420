import React, { useMemo } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { PublishingCampaignSchedulingCampaignInfoWithMediaSchedules } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import useEditableContent, { UseEditableContentProps } from '../../../../hooks/use-editable-content';
import { PlaylistSetupFormValues } from '../../utils/createPlaylistFormValues';

// import EditableContent from '../../../../components/Inputs/editable-content/editable-content';

const useStyles = makeStyles()(() => ({
    name: {
        fontSize: '24px',
        maxWidth: '505px',
        padding: '0 4px',
        whiteSpace: 'initial',
    },
    status: {
        marginLeft: 'auto',
        marginTop: 4,
        display: 'flex',
        alignItems: 'center',
    },
}));

type PlaylistPreviewHeaderProps = {
    control: Control<PlaylistSetupFormValues>;
    isSubmitBusy: boolean;
    isSubmitDisabled: boolean;
    onSubmitClick: (e?: React.BaseSyntheticEvent) => Promise<void>;
    playlist: DiscriminatedEntity<PublishingCampaignSchedulingCampaignInfoWithMediaSchedules>;
    setValue: UseFormSetValue<PlaylistSetupFormValues>;
};

const PlaylistPreviewHeader = ({
    control,
    isSubmitBusy,
    isSubmitDisabled,
    onSubmitClick,
    playlist,
    setValue,
}: PlaylistPreviewHeaderProps) => {
    const { classes } = useStyles();
    const { trans } = useTranslation();

    const editableNameProps: UseEditableContentProps<PlaylistSetupFormValues, 'name'> = useMemo(
        () => ({
            control,
            defaultHeight: 36,
            name: 'name',
            rules: {
                required: trans('common.validation.required'),
                validate: {
                    maxLength: (value: string) => (value.length <= 50 ? true : trans('common.validation.max.length')),
                },
            },
            setValue,
        }),
        [control, setValue, trans]
    );

    const editableName = useEditableContent<PlaylistSetupFormValues, 'name'>(editableNameProps);

    return (
        <Box sx={{ alignItems: 'center', display: 'flex', width: '100%' }}>
            <Box sx={{ flexGrow: 2, marginRight: 2 }}>
                <Typography className={classes.name} noWrap variant="h1" {...editableName}>
                    {playlist.name}
                </Typography>
            </Box>

            <Box sx={{ flexShrink: 1 }}>
                <Button
                    busy={isSubmitBusy}
                    disabled={isSubmitDisabled}
                    kind="primaryAction"
                    label={trans('common.action.update')}
                    name="update"
                    onClick={onSubmitClick}
                    sx={{ borderRadius: '8px' }}
                />
            </Box>
        </Box>
    );
};

export default PlaylistPreviewHeader;
