import { NetworkModelsLifelineConnectionStatusEnum } from '@zetadisplay/engage-api-client';

export const statusColors = {
    [NetworkModelsLifelineConnectionStatusEnum.ONLINE]: '#51ED6D',
    [NetworkModelsLifelineConnectionStatusEnum.OFFLINE]: '#ED5175',
    [NetworkModelsLifelineConnectionStatusEnum.IDLE]: '#FF8700',
    [NetworkModelsLifelineConnectionStatusEnum.NEW]: '#5E51ED',
};

export const statusStrings = {
    [NetworkModelsLifelineConnectionStatusEnum.ONLINE]: 'engage.model.player.state.online',
    [NetworkModelsLifelineConnectionStatusEnum.OFFLINE]: 'engage.model.player.state.offline',
    [NetworkModelsLifelineConnectionStatusEnum.IDLE]: 'engage.model.player.state.idle',
    [NetworkModelsLifelineConnectionStatusEnum.NEW]: 'engage.model.player.state.new',
};

export const statuses = [
    { key: NetworkModelsLifelineConnectionStatusEnum.ONLINE, literal: 'ONLINE' },
    { key: NetworkModelsLifelineConnectionStatusEnum.OFFLINE, literal: 'OFFLINE' },
    { key: NetworkModelsLifelineConnectionStatusEnum.IDLE, literal: 'IDLE' },
    { key: NetworkModelsLifelineConnectionStatusEnum.NEW, literal: 'NEW' },
];
