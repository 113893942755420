import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Chip, Typography } from '@mui/material';
import { NetworkModelsPlayersPlayerRelations } from '@zetadisplay/engage-api-client';
import { useTranslation } from '@zetadisplay/zeta-localization';

import PlayerSettingsChannelContext from './PlayerSettingsChannelContext';

const PlayerSettingsChannelGroups = () => {
    const { trans } = useTranslation();

    const { currentChannelIndex } = useContext(PlayerSettingsChannelContext);
    const { getValues } = useFormContext<NetworkModelsPlayersPlayerRelations>();

    const groups = getValues(`channels.${currentChannelIndex}.groups`);
    if (groups === null || groups === undefined || groups.length === 0) {
        return <Typography variant="body1">{trans('engage.players.settings.groups.empty')}</Typography>;
    }

    return (
        <Box sx={{ paddingBottom: '5px', paddingTop: '5px' }}>
            {groups.map((group) => (
                <Chip data-testid={`channel-group-${group.id}`} key={group.id} label={group.name} variant="outlined" />
            ))}
        </Box>
    );
};

export default PlayerSettingsChannelGroups;
