import { useMemo } from 'react';
import { PublishingModelsCampaignCampaignPriorityEnum } from '@zetadisplay/engage-api-client';
import { useTranslation } from '@zetadisplay/zeta-localization';

const usePlaylistPriorityOptions = () => {
    const t = useTranslation();

    return useMemo(() => {
        return [
            {
                id: PublishingModelsCampaignCampaignPriorityEnum.Highest,
                name: t.trans('engage.playlist.setup.priority.option.highest'),
            },
            {
                id: PublishingModelsCampaignCampaignPriorityEnum.High,
                name: t.trans('engage.playlist.setup.priority.option.high'),
            },
            {
                id: PublishingModelsCampaignCampaignPriorityEnum.Normal,
                name: t.trans('engage.playlist.setup.priority.option.normal'),
            },
            {
                id: PublishingModelsCampaignCampaignPriorityEnum.Low,
                name: t.trans('engage.playlist.setup.priority.option.low'),
            },
            {
                id: PublishingModelsCampaignCampaignPriorityEnum.Lowest,
                name: t.trans('engage.playlist.setup.priority.option.lowest'),
            },
        ];
    }, [t]);
};

export default usePlaylistPriorityOptions;
