import React, { useMemo } from 'react';
import { Control } from 'react-hook-form';
import { Typography } from '@mui/material';
import { HiddenInput } from '@zetadisplay/engage-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import usePlaylistLayout from 'src/views/PlaylistView/Hooks/usePlaylistLayout';

const useStyles = makeStyles()(() => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
    },
}));

type Props = {
    control?: Control;
    layoutZoneId?: number;
};

const PlaylistLayoutInformation = ({ control, layoutZoneId = undefined }: Props) => {
    const { classes, cx } = useStyles();

    const { layout, zone } = usePlaylistLayout(layoutZoneId);

    const information = useMemo(() => {
        if (!layout || !zone) {
            return '-';
        }

        return `${layout?.name} / ${zone?.name}`;
    }, [layout, zone]);

    return (
        <div className={cx(classes.root)}>
            <HiddenInput control={control} defaultValue={layoutZoneId || ''} name="layoutZoneId" />
            <Typography data-testid="information">{information}</Typography>
        </div>
    );
};

export default PlaylistLayoutInformation;
