import { alpha } from '@mui/material';
import { NetworkModelsLifelineConnectionStatusEnum } from '@zetadisplay/engage-api-client';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

import { statusColors, statusStrings } from 'src/constants/Players/PlayerStatus';

const { DARKGRAY } = themeOptions.colors;

const getPulseAnimation = (state: NetworkModelsLifelineConnectionStatusEnum) => ({
    '0%': {
        boxShadow: `0 0 0 0px ${alpha(statusColors[state], 0.2)}`,
    },
    '100%': {
        boxShadow: `0 0 0 8px ${alpha(statusColors[state], 0)}`,
    },
});

const useStyles = makeStyles()(() => ({
    indicator: {
        borderRadius: '50%',
        display: 'inline-block',
        height: 8,
        width: 8,
        margin: 4,
        verticalAlign: 'sub',
    },
    'indicator--idle': {
        animation: '$pulse-idle 2s infinite',
        backgroundColor: statusColors[NetworkModelsLifelineConnectionStatusEnum.IDLE],
    },
    'indicator--new': {
        animation: '$pulse-new 2s infinite',
        backgroundColor: statusColors[NetworkModelsLifelineConnectionStatusEnum.NEW],
    },
    'indicator--offline': {
        animation: '$pulse-offline 2s infinite',
        backgroundColor: statusColors[NetworkModelsLifelineConnectionStatusEnum.OFFLINE],
    },
    'indicator--online': {
        animation: '$pulse-online 2s infinite',
        backgroundColor: statusColors[NetworkModelsLifelineConnectionStatusEnum.ONLINE],
    },
    root: {
        marginRight: 12,
    },
    status: {
        fontSize: 14,
        color: DARKGRAY,
        lineHeight: '18px',
        letterSpacing: '0.47px',
        minWidth: '105px',
    },
    text: {
        display: 'inline-block',
        margin: '0 0 0 4px',
    },
    '@keyframes pulse-idle': getPulseAnimation(NetworkModelsLifelineConnectionStatusEnum.IDLE),
    '@keyframes pulse-new': getPulseAnimation(NetworkModelsLifelineConnectionStatusEnum.NEW),
    '@keyframes pulse-offline': getPulseAnimation(NetworkModelsLifelineConnectionStatusEnum.OFFLINE),
    '@keyframes pulse-online': getPulseAnimation(NetworkModelsLifelineConnectionStatusEnum.ONLINE),
}));

type Props = {
    status: NetworkModelsLifelineConnectionStatusEnum;
};

const PlayerItemStatus = ({ status }: Props) => {
    const { classes, cx } = useStyles();
    const t = useTranslation();

    const indicatorClasses = cx({
        [classes.indicator]: true,
        [classes['indicator--idle']]: status === NetworkModelsLifelineConnectionStatusEnum.IDLE,
        [classes['indicator--new']]: status === NetworkModelsLifelineConnectionStatusEnum.NEW,
        [classes['indicator--offline']]: status === NetworkModelsLifelineConnectionStatusEnum.OFFLINE,
        [classes['indicator--online']]: status === NetworkModelsLifelineConnectionStatusEnum.ONLINE,
    });

    return (
        <div className={classes.root}>
            <div className={classes.status}>
                <div className={indicatorClasses} />
                <p className={classes.text}>{t.trans(statusStrings[status])}</p>
            </div>
        </div>
    );
};

export default PlayerItemStatus;
