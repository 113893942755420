import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Link, Toolbar } from '@mui/material';
import { DropdownMenuItem } from '@zetadisplay/engage-components';
import { Logo } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import MobileNavigation from 'src/layouts/Components/MobileHeader/MobileNavigation';

const useStyles = makeStyles()(() => ({
    toolbar: {
        position: 'relative',
    },
    logo: {
        display: 'block',
        height: 28,
    },
    rightCol: {
        textAlign: 'right',
    },
}));

type Props = {
    includeWorkspace?: boolean; // The only purpose for this to be optional is to bypass unit testing issues not able to solve
    secondaryMenuItems: DropdownMenuItem[];
};

const MobileHeader = ({ includeWorkspace = true, secondaryMenuItems }: Props) => {
    const { classes, cx } = useStyles();

    return (
        <Toolbar data-testid="toolbar">
            <Grid container spacing={0}>
                <Grid item xs={4}>
                    <Link to="/" component={RouterLink}>
                        <Logo className={cx(classes.logo)} />
                    </Link>
                </Grid>

                <Grid item xs={8} className={cx(classes.rightCol)}>
                    <MobileNavigation includeWorkspace={includeWorkspace} secondaryMenuItems={secondaryMenuItems} />
                </Grid>
            </Grid>
        </Toolbar>
    );
};

export default MobileHeader;
