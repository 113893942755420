import React from 'react';
import { Box } from '@mui/material';
import { ConfigModelsAttributeDetails, ConfigModelsAttributeValueRelations } from '@zetadisplay/engage-api-client';
import { useAttributes } from '@zetadisplay/engage-components/modules/players/hooks';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import PlayerSettingsChannelLabelsAppendLabel from './PlayerSettingsChannelLabelsAppendLabel';
import PlayerSettingsChannelLabelsAppendLabelSkeleton from './PlayerSettingsChannelLabelsAppendLabelSkeleton';

type PlayerSettingsChannelLabelsAppendOverlayProps = {
    onLabelAdd: (attribute: ConfigModelsAttributeDetails) => void;
    selectedAttributes: (ConfigModelsAttributeValueRelations & { key: string })[];
};

const useStyles = makeStyles()(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '375px',
        overflow: 'auto',
    },
}));

const PlayerSettingsChannelLabelsAppendOverlay: React.FC<PlayerSettingsChannelLabelsAppendOverlayProps> = ({
    onLabelAdd,
    selectedAttributes,
}) => {
    const { classes } = useStyles();
    const attributes = useAttributes({ key: 'PlayerSettings' });

    // Render skeleton just in case if attributes are still loading if user opens the overlay too fast
    if (attributes.isLoading) {
        return (
            <Box className={classes.container}>
                {Array.from({ length: 6 }, (_, num) => (
                    <PlayerSettingsChannelLabelsAppendLabelSkeleton key={num} />
                ))}
            </Box>
        );
    }

    return (
        <Box className={classes.container}>
            {attributes.data.map((attribute) => (
                <PlayerSettingsChannelLabelsAppendLabel
                    key={attribute.id}
                    attribute={attribute}
                    disabled={Boolean(selectedAttributes.find((a) => a.attribute.id === attribute.id))}
                    onClick={() => onLabelAdd(attribute)}
                />
            ))}
        </Box>
    );
};

export default PlayerSettingsChannelLabelsAppendOverlay;
