import React from 'react';
import { Typography } from '@mui/material';
import { SharedModelsLayoutRelations, SharedModelsLayoutZoneInfo } from '@zetadisplay/engage-api-client';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

const { DARKGRAY, ORANGE } = themeOptions.colors;

const useStyles = makeStyles()(() => ({
    root: {
        alignItems: 'center',
        border: '1px solid transparent',
        display: 'flex',
        flexDirection: 'column',
        padding: 4,
    },
    'root--selected': {
        borderColor: ORANGE,
    },
    layout: {
        backgroundColor: DARKGRAY,
        position: 'relative',
    },
    zone: {
        backgroundColor: ORANGE,
        position: 'absolute',
    },
    name: {
        fontSize: 11,
    },
    'name--selected': {
        color: ORANGE,
        fontWeight: themeOptions.BOLD,
    },
}));

export type PlaylistSetupLayoutZoneProps = {
    layout: SharedModelsLayoutRelations;
    onChange: (layoutZoneId: number) => void;
    selected: boolean;
    zone: SharedModelsLayoutZoneInfo;
};

const PlaylistSetupLayoutZone = ({ layout, onChange, selected, zone }: PlaylistSetupLayoutZoneProps) => {
    const { classes, cx } = useStyles();

    const scale = Math.min((layout.height * 10) / 45, (layout.width * 10) / 80);

    const canvasHeight = (layout.height * 10) / scale;
    const canvasWidth = (layout.width * 10) / scale;

    const zoneHeight = (canvasHeight / 100) * zone.height;
    const zoneWidth = (canvasWidth / 100) * zone.width;

    const zoneY = (canvasHeight / 100) * zone.y;
    const zoneX = (canvasWidth / 100) * zone.x;

    const rootClasses = cx({
        [classes.root]: true,
        [classes['root--selected']]: selected,
    });

    const nameClasses = cx({
        [classes.name]: true,
        [classes['name--selected']]: selected,
    });

    const handleSelect = () => {
        onChange(zone.id);
    };

    return (
        <div
            className={rootClasses}
            onClick={handleSelect}
            onKeyDown={handleSelect}
            role="button"
            tabIndex={0}
            data-testid={`layoutZone-${zone.id}`}
        >
            <div className={cx(classes.layout)} style={{ width: canvasWidth, height: canvasHeight }}>
                <div
                    className={cx(classes.zone)}
                    style={{ width: zoneWidth, height: zoneHeight, top: zoneY, left: zoneX }}
                />
            </div>

            <Typography className={nameClasses}>{zone.name}</Typography>
        </div>
    );
};

export default PlaylistSetupLayoutZone;
