import { createEvent } from 'react-event-hook';
import { FieldArrayWithId } from 'react-hook-form';

import { ScheduleMediaFileFields } from '../../../../../components/Modals/PlaylistSetup/SchedulePlaylistMediaForm';
import { PlaylistSetupFormValues } from '../../../utils/createPlaylistFormValues';

type Item = FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>;

export type OnPlaylistContentScheduledEventPayload = {
    item: Item | Item[];
    data: ScheduleMediaFileFields;
};

export const { useOnPlaylistContentScheduledListener, emitOnPlaylistContentScheduled } =
    createEvent('onPlaylistContentScheduled')<OnPlaylistContentScheduledEventPayload>();
