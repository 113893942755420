import React, { memo, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserInformation } from '@zetadisplay/engage-components/modules/auth';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Icon } from '@zetadisplay/zeta-ui-components';

import ViewActions from 'src/components/ViewActions';
import { PLAYERS_BASE_PATH } from 'src/constants/Paths';
import useCurrentGroup from 'src/hooks/useCurrentGroup';
import useGroup from 'src/hooks/useGroup';

const PlayersViewActions = () => {
    const currentGroupId = useCurrentGroup();
    const group = useGroup(currentGroupId);
    const history = useNavigate();
    const location = useLocation();
    const t = useTranslation();
    const { hasRole } = useUserInformation();

    const actions = useMemo(
        () => [
            {
                disabled: !hasRole(['ENGAGE_POWERSCHEDULE_CREATE', 'ENGAGE_POWERSCHEDULE_EDIT']),
                icon: <Icon color="#fff" size={22} type="OPENING_HOURS" />,
                name: t.trans('engage.players.setup.power_schedule.title'),
                onClick: () => history(`${PLAYERS_BASE_PATH}/power/settings`),
            },
            {
                disabled: !hasRole(['ENGAGE_LABEL_MANAGEMENT', 'ENGAGE_PLAYER_EDIT']),
                icon: <Icon color="#fff" size={22} type="LABELS" />,
                name: t.trans('engage.players.labels.setup.title'),
                onClick: () => history(`${PLAYERS_BASE_PATH}/labels/settings`),
            },
            {
                disabled: !hasRole(['ENGAGE_LABEL_MANAGEMENT']) || group.result?.isShared === true,
                icon: <Icon type="DISTRIBUTION_GROUP" color="#fff" />,
                name: t.trans('engage.action.create.group'),
                onClick: () =>
                    history(`${PLAYERS_BASE_PATH}/group/create?returnUrl=${location.pathname}`, {
                        state: { currentGroupId },
                    }),
            },
        ],
        [currentGroupId, group.result?.isShared, hasRole, history, location.pathname, t]
    );

    return <ViewActions actions={actions} label="players-actions" />;
};

export default memo(PlayersViewActions);
