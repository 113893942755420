import React from 'react';
import { useFormState } from 'react-hook-form';
import NiceModal from '@ebay/nice-modal-react';
import { IconButton, Stack } from '@mui/material';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SwitchInput } from '@zetadisplay/engage-components';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Icon } from '@zetadisplay/zeta-ui-components';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

import RemoveSpecialScheduleValuePrompt, {
    RemoveSpecialScheduleValuePromptProps,
} from 'src/components/Modals/PowerScheduleSetup/RemoveSpecialScheduleValuePrompt';
import { SpecialDayData } from 'src/views/PowerScheduleSetupView';

const useStyles = makeStyles()(() => ({
    container: {
        alignItems: 'baseline',
        padding: '5px 10px',
    },
    timePicker: {
        maxWidth: '186px',
    },
}));

const { ORANGE } = themeOptions.colors;

export type SpecialPowerScheduleFormSpecialDayProps = {
    index: number;
    onDateChange: (date: Date | null) => void;
    onEndTimeChange: (date: Date | null) => void;
    onRemoveSpecialDay: (specialDay: SpecialDayData) => void;
    onStartTimeChange: (date: Date | null) => void;
    specialDay: SpecialDayData;
};

const SpecialPowerScheduleFormSpecialDay = ({
    index,
    onDateChange,
    onEndTimeChange,
    onStartTimeChange,
    onRemoveSpecialDay,
    specialDay,
}: SpecialPowerScheduleFormSpecialDayProps) => {
    const { classes } = useStyles();
    const t = useTranslation();
    const { errors } = useFormState();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore suppress ts error here because setValue doesn't work well with generated strings as value resolves to never
    const specialDayDateError = errors?.specialPowerSchedule?.specialDays?.[0]?.date;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore suppress ts error here because setValue doesn't work well with generated strings as value resolves to never
    const specialDayEndTimeError = errors?.specialPowerSchedule?.specialDays?.[0]?.endTime;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore suppress ts error here because setValue doesn't work well with generated strings as value resolves to never
    const specialDayStartTimeError = errors?.specialPowerSchedule?.specialDays?.[0]?.startTime;

    const showRemoveSpecialScheduleValuePrompt = () => {
        NiceModal.show<SpecialDayData, RemoveSpecialScheduleValuePromptProps>(RemoveSpecialScheduleValuePrompt, {
            specialDay,
        }).then(onRemoveSpecialDay);
    };

    return (
        <Stack className={classes.container} data-testid="special-day-row" direction="row" spacing={1}>
            <SwitchInput name={`specialPowerSchedule.specialDays.${index}.enabled`} />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    format="dd.MM.yyyy"
                    label={t.trans(specialDayDateError?.message || 'common.scheduling.dates.start_date')}
                    onChange={onDateChange}
                    slotProps={{
                        textField: {
                            className: classes.timePicker,
                            InputLabelProps: { shrink: true },
                            error: !!specialDayDateError,
                        },
                    }}
                    value={specialDay.date}
                />
                <TimePicker
                    ampm={false}
                    disabled={!specialDay.enabled}
                    label={t.trans(specialDayStartTimeError?.message || 'common.scheduling.times.start_time')}
                    onChange={onStartTimeChange}
                    slotProps={{
                        textField: {
                            className: classes.timePicker,
                            InputLabelProps: { shrink: true },
                            error: !!specialDayStartTimeError,
                        },
                    }}
                    value={specialDay.startTime}
                />
                <TimePicker
                    ampm={false}
                    disabled={!specialDay.enabled}
                    label={t.trans(specialDayEndTimeError?.message || 'common.scheduling.times.end_time')}
                    onChange={onEndTimeChange}
                    slotProps={{
                        textField: {
                            className: classes.timePicker,
                            InputLabelProps: { shrink: true },
                            error: !!specialDayEndTimeError,
                        },
                    }}
                    value={specialDay.endTime}
                />
                <IconButton
                    data-testid="remove-special-day-value"
                    onClick={showRemoveSpecialScheduleValuePrompt}
                    size="small"
                >
                    <Icon type="DELETE" size={16} color={ORANGE} />
                </IconButton>
            </LocalizationProvider>
        </Stack>
    );
};

export default SpecialPowerScheduleFormSpecialDay;
