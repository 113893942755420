import * as z from 'zod';

import PrimaryPowerScheduleSchema from './PrimaryPowerScheduleSchema';
import SpecialPowerScheduleSchema from './SpecialPowerScheduleSchema';

const PowerScheduleFormDataSchema = z.object({
    primaryPowerSchedule: PrimaryPowerScheduleSchema,
    specialPowerSchedule: SpecialPowerScheduleSchema,
});

// Omit one structure from validation based on key
const validateCurrentFormSchema = (key: 'primaryPowerSchedule' | 'specialPowerSchedule') => {
    return PowerScheduleFormDataSchema.omit({ [key]: true });
};

export default validateCurrentFormSchema;
