import { useUserInformation } from '@zetadisplay/engage-components/modules/auth';
import { useTranslation } from '@zetadisplay/zeta-localization';

export type NavigationItem = {
    disabled?: boolean;
    href: string;
    label: string;
    onNavigate?: () => void;
};

type onNavigateCallback = undefined | (() => void);

const useNavigation = (onNavigate: onNavigateCallback = undefined): NavigationItem[] => {
    const t = useTranslation();
    const { hasRole } = useUserInformation();

    return [
        {
            disabled: !hasRole('ENGAGE_LIBRARY_VIEW'),
            href: '/library',
            label: t.trans('engage.navigation.library'),
            onNavigate,
        },
        {
            disabled: !hasRole('ENGAGE_PLAYLIST_VIEW'),
            href: '/playlists',
            label: t.trans('engage.navigation.playlist'),
            onNavigate,
        },
        {
            disabled: !hasRole('ENGAGE_PLAYER_VIEW'),
            href: '/players',
            label: t.trans('engage.navigation.players'),
            onNavigate,
        },
    ];
};

export default useNavigation;
