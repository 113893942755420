import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import {
    ConfigModelsWorkspaceBasic,
    PublishingCampaignSchedulingCampaignInfoWithMediaSchedules,
} from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { createScheduleFromEngageSchedule, PlaylistMediaType } from '@zetadisplay/engage-components/modules/playlist';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ActionGroup, Theme } from '@zetadisplay/zeta-ui-components';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

import PlaylistItemDetailsContent from 'src/views/PlaylistView/Components/PlaylistItemDetails/PlaylistItemDetailsContent';
import PlaylistItemDetailsInformation from 'src/views/PlaylistView/Components/PlaylistItemDetails/PlaylistItemDetailsInformation';

const useStyles = makeStyles()(() => ({
    body: {
        padding: '0 36px 12px',
    },
    'body--embedded': {
        padding: 0,
    },
    noContent: {
        alignItems: 'center',
        backgroundColor: themeOptions.colors.BLACK,
        display: 'flex',
        height: 104,
        justifyContent: 'center',
        marginBottom: 18,
        padding: '4px 0',
        width: '100%',
    },
}));

// Inject workspace that we get from PublishingModelsPlaylistPlaylistCampaignInfo
export type PlaylistItem = PublishingCampaignSchedulingCampaignInfoWithMediaSchedules & {
    workspace?: ConfigModelsWorkspaceBasic;
};

type Props = {
    actions?: ActionGroup<DiscriminatedEntity<PlaylistItem>>[];
    embedded?: boolean;
    item: DiscriminatedEntity<PlaylistItem>;
    showName?: boolean;
    showTitle?: boolean;
};

const PlaylistItemDetails = ({ actions, embedded = false, item, showName = false, showTitle = true }: Props) => {
    const { classes, cx } = useStyles();
    const t = useTranslation();

    const bodyClasses = cx({
        [classes.body]: true,
        [classes['body--embedded']]: embedded,
    });

    const playlistMediaCollection = useMemo(
        () =>
            item.campaignMedia?.map(
                (media) =>
                    ({
                        ...media,
                        mediaSchedules: media.mediaSchedules?.map(createScheduleFromEngageSchedule) || [],
                    } as Partial<PlaylistMediaType>)
            ) || [],
        [item.campaignMedia]
    );

    return (
        <div className={bodyClasses} data-testid="playlist-item-details">
            <PlaylistItemDetailsInformation actions={actions} item={item} showName={showName} showTitle={showTitle} />

            {item.campaignMedia && item.campaignMedia.length > 0 && (
                <PlaylistItemDetailsContent playlistMediaCollection={playlistMediaCollection} />
            )}

            {item.campaignMedia && item.campaignMedia.length === 0 && (
                <Theme dark nested>
                    <div className={cx(classes.noContent)}>
                        <Typography>{t.trans('engage.players.details.playlist.no_content')}</Typography>
                    </div>
                </Theme>
            )}
        </div>
    );
};

export default PlaylistItemDetails;
