import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import DialogContentText from '@mui/material/DialogContentText';
import { createDefaultButtons, Modal } from '@zetadisplay/engage-components/modules/modal/components';
// TODO Uncomment for Jira item EN-3800
// import { useTranslation } from '@zetadisplay/zeta-localization';
import { Icon } from '@zetadisplay/zeta-ui-components';

export type ConfirmUnsavedChangesPromptProps = NiceModalHocProps;

const ConfirmUnsavedChangesPrompt = NiceModal.create<ConfirmUnsavedChangesPromptProps>(() => {
    const methods = useForm({
        defaultValues: {},
    });
    const modal = useModal();
    // TODO Uncomment and rewrite line with data-testid="confirm-undo" for Jira item EN-3800
    // const t = useTranslation();

    const onSubmit = useCallback(async () => {
        modal.resolve();
        await modal.hide();
    }, [modal]);

    // TODO Translate these terms for Jira item EN-3800
    // engage.misc.undo_saved_changes
    const translationTermEngageMiscUndoSavedChanges = 'Undo changes'; // Label should use term in a string value
    // engage.modal.undo_saved_changes.title
    const translationTermEngageModalUndoSavedChangesTitle = 'Close without saving?'; // Label should use term in a string value
    // engage.modal.undo_saved_changes.confirmation
    const translationTermEngageModalUndoSavedChangesConfirmation = 'Are you sure you want to close without saving?'; // Should be translated with t.trans(..

    return (
        <FormProvider {...methods}>
            <Modal
                actions={{
                    buttons: createDefaultButtons({
                        cancel: { onClick: modal.hide },
                        submit: {
                            busy: methods.formState.isSubmitting,
                            disabled: methods.formState.isSubmitting,
                            label: translationTermEngageMiscUndoSavedChanges,
                            name: 'undo',
                            onClick: methods.handleSubmit(onSubmit),
                        },
                    }),
                }}
                dark
                title={{
                    icon: <Icon type="WARNING" />,
                    label: translationTermEngageModalUndoSavedChangesTitle,
                }}
            >
                <DialogContentText data-testid="confirm-undo" id="alert-dialog-description">
                    {translationTermEngageModalUndoSavedChangesConfirmation}
                </DialogContentText>
            </Modal>
        </FormProvider>
    );
});

export default ConfirmUnsavedChangesPrompt;
