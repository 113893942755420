import { EndpointsRootNetworkModelsPowerSchedulePowerScheduleInfo } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';

import createPowerScheduleSchedule, {
    PowerScheduleSchedule,
} from 'src/views/PowerScheduleSetupView/Utils/createPowerScheduleSchedule';
import {
    getPowerScheduleCapitalWeekdayShort,
    PowerScheduleWeekdayLong,
} from 'src/views/PowerScheduleSetupView/Utils/powerScheduleWeekday';

export type PowerScheduleSchedules = {
    [key in PowerScheduleWeekdayLong]: PowerScheduleSchedule;
};

const createPowerScheduleSchedules = (
    powerSchedule?: DiscriminatedEntity<EndpointsRootNetworkModelsPowerSchedulePowerScheduleInfo>
) => {
    const weekdayKeys = Object.keys(PowerScheduleWeekdayLong) as (keyof typeof PowerScheduleWeekdayLong)[];

    return weekdayKeys.reduce((schedules, weekday) => {
        const shortName = getPowerScheduleCapitalWeekdayShort(weekday);
        const startTime = powerSchedule?.[`startTime${shortName}`] || null;
        const endTime = powerSchedule?.[`endTime${shortName}`] || null;

        return {
            ...schedules,
            [PowerScheduleWeekdayLong[weekday]]: createPowerScheduleSchedule(
                Boolean(endTime && startTime),
                endTime,
                startTime
            ),
        };
    }, {} as PowerScheduleSchedules);
};

export default createPowerScheduleSchedules;
