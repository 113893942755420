import React from 'react';
import { useWatch } from 'react-hook-form';
import { Typography } from '@mui/material';
import { ConfigModelsGroupPersistModel } from '@zetadisplay/engage-api-client/models/config-models-group-persist-model';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Accordion } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import AttributesConstraintsPlayers from 'src/modules/groups/setup/components/GroupSetupTargeting/AttributesConstraintsPlayers';

const useStyles = makeStyles()(() => ({
    root: {
        height: '100%',
        width: '100%',
    },
}));

const GroupSetupTargeting = () => {
    const { classes, cx } = useStyles();
    const t = useTranslation();

    const attributesConstraints = useWatch<ConfigModelsGroupPersistModel, 'attributesConstraints'>({
        defaultValue: [],
        name: 'attributesConstraints',
    });

    return (
        <div className={cx(classes.root)}>
            <Accordion
                defaultExpanded
                expandable
                name="group-targeting"
                title={t.trans('engage.players.group.information.title')}
            >
                {(attributesConstraints || []).length > 0 && <AttributesConstraintsPlayers />}
                {(attributesConstraints || []).length === 0 && (
                    <Typography>{t.trans('engage.players.group.information.targeting.assign_labels_first')}</Typography>
                )}
            </Accordion>
        </div>
    );
};

export default GroupSetupTargeting;
