import React from 'react';
import { ArrowBackSharp } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

export type SideKickBackProps = {
    onClose: () => void;
};

const SidekickBack: React.FC<SideKickBackProps> = ({ onClose }) => {
    return (
        <Box sx={{ paddingRight: '10px' }}>
            <IconButton
                onClick={onClose}
                sx={{
                    '&:hover': {
                        backgroundColor: themeOptions.colors.CONTENT_DARK_HOVER,
                    },
                    backgroundColor: themeOptions.colors.CONTENT_DARK,
                }}
            >
                <ArrowBackSharp sx={{ color: themeOptions.colors.WHITE, fontSize: 16 }} />
            </IconButton>
        </Box>
    );
};

export default SidekickBack;
