import React from 'react';
import { Typography } from '@mui/material';
import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()(() => ({
    libraryMediaName: {
        fontSize: '24px',
        lineHeight: '24px',
        whiteSpace: 'initial',
    },
}));

export type LibraryMediaDetailsHeaderProps = {
    libraryMedia: DiscriminatedEntity<LibraryModelsMedia>;
};

const LibraryMediaDetailsHeader = ({ libraryMedia }: LibraryMediaDetailsHeaderProps) => {
    const { classes } = useStyles();

    return (
        <Typography className={classes.libraryMediaName} variant="h1" title={libraryMedia.name}>
            {libraryMedia.name}
        </Typography>
    );
};

export default LibraryMediaDetailsHeader;
