import { LibraryModelsMediaItemTypes } from '@zetadisplay/engage-api-client';
import { isMediaFolder } from '@zetadisplay/engage-components/models';
import { ApiInterface } from '@zetadisplay/engage-components/modules/api';

import { LibraryItemsType } from 'src/views/LibraryView';

const getLibraryItemType = (item: LibraryItemsType) => {
    if (isMediaFolder(item)) {
        return LibraryModelsMediaItemTypes.Folder;
    }

    return LibraryModelsMediaItemTypes.File;
};

const mediaMovePromiseFactory = ({ api, workspaceid }: { api: ApiInterface; workspaceid: string }) => {
    return async (subjects: LibraryItemsType[], targetFolderId: string) => {
        return api.media.moveMedia({
            body: {
                moveItems: subjects.map((subject) => ({
                    id: subject.id,
                    itemType: getLibraryItemType(subject),
                })),
                targetFolderId,
                overwrite: false,
            },
            workspaceid,
        });
    };
};

export default mediaMovePromiseFactory;
