import { FieldArrayWithId, UseFieldArrayUpdate, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import update from 'immutability-helper';

import { OnPlaylistContentEditedEventPayload } from '../sidekick/playlist-preview-sidekick/events/on-playlist-content-edited-event';
import { PlaylistSetupFormValues } from './createPlaylistFormValues';

const updatePlaylistModifiedMedia =
    (
        fields: FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>[],
        fieldArrayUpdate: UseFieldArrayUpdate<PlaylistSetupFormValues, 'playlistMediaCollection'>,
        getValues: UseFormGetValues<PlaylistSetupFormValues>,
        setValue: UseFormSetValue<PlaylistSetupFormValues>
    ) =>
    ({ item, data }: OnPlaylistContentEditedEventPayload) => {
        if (
            typeof item.mediaId !== 'string' ||
            !item.model ||
            (data.duration === item.model.duration && data.name === item.model.name)
        ) {
            return;
        }

        // Array indices will contain each index that matches the mediaId, resulting in one or more items.
        // If the mediaId is undefined it will match the fieldArrayId, always resulting in one item.
        const indices = fields
            .map((field, index) =>
                (item.mediaId && field.mediaId === item.mediaId) || field.fieldArrayId === item.fieldArrayId
                    ? index
                    : -1
            )
            .filter((index) => index > -1);

        indices.forEach((index) => {
            fieldArrayUpdate(index, {
                ...fields[index],
                state: 'modified',
            });
        });

        const modifiedMedia = getValues('modifiedMedia');
        setValue('modifiedMedia', update(modifiedMedia, { $add: [[item.mediaId, { data, object: item.model }]] }));
    };

export default updatePlaylistModifiedMedia;
