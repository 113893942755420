import { useCallback } from 'react';
import NiceModal, { NiceModalHocProps } from '@ebay/nice-modal-react';
import { EndpointsRootNetworkModelsPowerSchedulePowerScheduleInfo } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';

import ConfirmPrompt from 'src/components/Modals/ConfirmPrompt';

export type RemovePowerSchedulePromptProps = {
    powerSchedule: DiscriminatedEntity<EndpointsRootNetworkModelsPowerSchedulePowerScheduleInfo>;
} & NiceModalHocProps;

const RemovePowerSchedulePrompt = NiceModal.create<RemovePowerSchedulePromptProps>(({ powerSchedule }) => {
    const api = useApi();
    const t = useTranslation();
    const { workspace } = useWorkspace();

    const submitAction = useCallback(async () => {
        return api.powerSchedules
            .deletePowerSchedule({
                powerscheduleid: powerSchedule.id,
                workspaceid: workspace.id,
            })
            .then(() => powerSchedule);
    }, [api, powerSchedule, workspace.id]);

    return (
        <ConfirmPrompt
            description={t.trans('engage.modal.delete.content', [powerSchedule.name])}
            submit={{ label: 'common.action.delete', name: 'delete' }}
            submitAction={submitAction}
        />
    );
});

export default RemovePowerSchedulePrompt;
