import { useCallback } from 'react';
import { FieldArrayWithId } from 'react-hook-form';
import NiceModal, { NiceModalHocProps } from '@ebay/nice-modal-react';
import { useTranslation } from '@zetadisplay/zeta-localization';

import ConfirmPrompt from '../../../../components/Modals/ConfirmPrompt';
import { PlaylistSetupFormValues } from '../../utils/createPlaylistFormValues';

export type PlaylistPreviewConfirmBulkRemovePromptProps = {
    items: FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>[];
} & NiceModalHocProps;

const PlaylistPreviewConfirmBulkRemovePrompt = NiceModal.create(
    ({ items }: PlaylistPreviewConfirmBulkRemovePromptProps) => {
        const { trans } = useTranslation();

        const submitActions = useCallback(() => Promise.resolve(items), [items]);

        return (
            <ConfirmPrompt
                description={trans('engage.modal.playlist.remove.content.bulk', [`${items.length}`], items.length > 1)}
                submit={{ label: 'common.action.remove', name: 'remove' }}
                submitAction={submitActions}
                title={{ label: 'engage.modal.bulk.remove.title' }}
            />
        );
    }
);

export default PlaylistPreviewConfirmBulkRemovePrompt;
