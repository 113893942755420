import { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldArrayWithId } from 'react-hook-form';
import { dequal } from 'dequal';

import { PlaylistSetupFormValues } from '../../../utils/createPlaylistFormValues';

const useSelectableItems = <
    T extends FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>
>(
    availableItems: T[]
) => {
    const [selectedItems, setSelectedItems] = useState<Record<string, T>>({});

    /**
     * When available items change, we want to check previously selected items
     * have not gone away (for example single item was deleted using normal action).
     */
    useEffect(() => {
        if (Object.values(selectedItems).length > 0) {
            const nextSelectedItems = { ...selectedItems };
            Object.values(selectedItems).forEach((item) => {
                if (
                    !availableItems.find((elem) => elem.fieldArrayId === item.fieldArrayId) &&
                    nextSelectedItems[item.fieldArrayId]
                ) {
                    delete nextSelectedItems[item.fieldArrayId];
                }
            });

            if (!dequal(nextSelectedItems, selectedItems)) {
                setSelectedItems(nextSelectedItems);
            }
        }
    }, [availableItems, selectedItems]);

    const selectItem = useCallback(
        (item: T) => {
            const nextSelectedItems = { ...selectedItems };
            if (nextSelectedItems[item.fieldArrayId]) {
                delete nextSelectedItems[item.fieldArrayId];
            } else {
                nextSelectedItems[item.fieldArrayId] = item;
            }

            setSelectedItems(nextSelectedItems);
        },
        [selectedItems]
    );

    const selectAllItems = useCallback((items: T[], selectAll: boolean) => {
        setSelectedItems(selectAll ? items.reduce((next, item) => ({ ...next, [item.fieldArrayId]: item }), {}) : {});
    }, []);

    return useMemo(
        () => ({
            selectAllItems,
            selectItem,
            selectedItems,
        }),
        [selectAllItems, selectItem, selectedItems]
    );
};

export default useSelectableItems;
