import React, { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import NiceModal from '@ebay/nice-modal-react';
import { IconButton, Typography } from '@mui/material';
import { TextInput } from '@zetadisplay/engage-components';
import { whitespace } from '@zetadisplay/engage-components/utils/form/validators';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Expander, Icon } from '@zetadisplay/zeta-ui-components';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

import RemoveLabelValuePrompt, {
    RemoveLabelValuePromptProps,
} from 'src/components/Modals/LabelSetup/RemoveLabelValuePrompt';
import Tag from 'src/components/Tag';
import {
    LabelSetupFormData,
    LabelValuePlayerState,
    LabelValuePlayerType,
    LabelValueState,
    LabelValueType,
} from 'src/views/LabelSetupView';
import LabelSetupValuePlayers from 'src/views/LabelSetupView/Components/LabelSetupMainForm/LabelSetupValues/LabelSetupValue/LabelSetupValuePlayers';

const { ORANGE, DARKGRAY } = themeOptions.colors;

const useStyles = makeStyles()((theme) => ({
    root: {
        borderBottom: `1px solid ${theme.palette.background.disabled}66`,
        padding: '2px 0 2px',
    },
    contentContainer: {
        margin: '0 16px',
    },
    content: {
        backgroundColor: theme.palette.background.contentSecondary,
        display: 'block',
        marginBottom: 8,
        padding: '8px 20px 12px',
    },
    header: {
        alignItems: 'center',
        display: 'flex',
        height: 50,
    },
    info: {
        marginRight: 6,
    },
    actions: {
        alignItems: 'center',
        display: 'inline-flex',
        justifyContent: 'right',
    },
    value: {
        display: 'inline-flex',
        flexGrow: 1,
        marginRight: 8,
    },
    subtitle: {
        fontSize: 12,
        lineHeight: '30px',
        marginLeft: 20,
    },
}));

type Props = {
    expanded: boolean;
    index: number;
    labelValue: LabelValueType;
    onExpandLabelValue: (labelValueIndex: number) => void;
    onRemoveLabelValue: (labelValue: LabelValueType) => void;
    removedPlayersFromAttributeValues: LabelValuePlayerType[];
    usedPlayersByAttributeValues: LabelValuePlayerType[];
};

const LabelSetupValue = ({
    expanded,
    index,
    labelValue,
    onExpandLabelValue,
    onRemoveLabelValue,
    removedPlayersFromAttributeValues,
    usedPlayersByAttributeValues,
}: Props) => {
    const { classes } = useStyles();
    const { formState, setValue, getValues } = useFormContext<LabelSetupFormData>();
    const { trans } = useTranslation();
    const error = formState.errors.values?.[index]?.value;

    const [isEditing, setIsEditing] = useState(labelValue.state === LabelValueState.NEW || error !== undefined);
    const isDeleted = labelValue.state === LabelValueState.DELETED;
    const isDisabledEditButton = isDeleted || error !== undefined;

    const validateUniqueValue = useCallback(
        (inputValue: string) => {
            const values = getValues('values') || [];
            const existing = values.find((v) => v.value === inputValue);
            if (existing && existing.id !== labelValue.id) {
                return 'engage.validation.unique';
            }

            return true;
        },
        [getValues, labelValue.id]
    );

    const onAddLabelValuePlayers = useCallback(
        (players: LabelValuePlayerType[]) => {
            setValue(`values.${index}.players`, players);
        },
        [index, setValue]
    );

    const onLoadLabelValuePlayers = useCallback(
        (players: LabelValuePlayerType[]) => {
            setValue(`values.${index}.players`, players);
        },
        [index, setValue]
    );

    const onRemoveLabelValuePlayer = useCallback(
        (player: LabelValuePlayerType) => {
            if (player.state === LabelValuePlayerState.NEW) {
                return setValue(
                    `values.${index}.players`,
                    labelValue.players.filter((currentPlayer) => currentPlayer.id !== player.id)
                );
            }

            const playerIndex = labelValue.players.findIndex((p) => p.id === player.id);
            return setValue(`values.${index}.players.${playerIndex}.state`, LabelValuePlayerState.DELETED);
        },
        [index, labelValue.players, setValue]
    );

    const onRestoreLabelValuePlayer = useCallback(
        (player: LabelValuePlayerType) => {
            const playerIndex = labelValue.players.findIndex((p) => p.id === player.id);

            setValue(`values.${index}.players.${playerIndex}.state`, undefined);
        },
        [index, labelValue.players, setValue]
    );

    const renderLabelValue = () => {
        if (isEditing && !formState.isSubmitting) {
            return (
                <TextInput
                    defaultLabel="common.form.input.value"
                    defaultValue={labelValue.value}
                    error={error}
                    name={`values.${index}.value`}
                    rules={{
                        required: 'common.validation.required',
                        validate: {
                            validateUniqueValue,
                            whitespace,
                        },
                    }}
                />
            );
        }

        return <Typography>{labelValue.value}</Typography>;
    };

    const showRemoveLabelValuePrompt = () => {
        NiceModal.show<LabelValueType, RemoveLabelValuePromptProps>(RemoveLabelValuePrompt, { labelValue }).then(
            onRemoveLabelValue
        );
    };

    return (
        <div className={classes.root} data-testid={`label-setup-value-${labelValue.id}`}>
            <div className={classes.header}>
                <Expander disabled={isDeleted} expanded={expanded} onClick={() => onExpandLabelValue(index)} />
                <div className={classes.value}>{renderLabelValue()}</div>
                <div className={classes.info}>
                    {isDeleted && <Tag type="RED" text={trans('engage.misc.deleted')} />}
                </div>
                <div className={classes.actions}>
                    <IconButton
                        data-testid="edit-label-value"
                        disabled={isDisabledEditButton}
                        onClick={() => setIsEditing(!isEditing)}
                        size="small"
                    >
                        <Icon type="EDIT" size={16} color={isDisabledEditButton ? DARKGRAY : ORANGE} />
                    </IconButton>
                    <IconButton
                        data-testid="remove-label-value"
                        disabled={isDeleted}
                        onClick={showRemoveLabelValuePrompt}
                        size="small"
                    >
                        <Icon type="DELETE" size={16} color={isDeleted ? DARKGRAY : ORANGE} />
                    </IconButton>
                </div>
            </div>
            <div className={classes.contentContainer}>
                {expanded && (
                    <LabelSetupValuePlayers
                        labelValue={labelValue}
                        onAddLabelValuePlayers={onAddLabelValuePlayers}
                        onLoadLabelValuePlayers={onLoadLabelValuePlayers}
                        onRemoveLabelValuePlayer={onRemoveLabelValuePlayer}
                        onRestoreLabelValuePlayer={onRestoreLabelValuePlayer}
                        removedPlayersFromAttributeValues={removedPlayersFromAttributeValues}
                        usedPlayersByAttributeValues={usedPlayersByAttributeValues}
                    />
                )}
            </div>
        </div>
    );
};

export default LabelSetupValue;
