import { createEvent } from 'react-event-hook';
import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { PlaylistMediaType } from '@zetadisplay/engage-components/modules/playlist';

export type PlaylistMediaAddedEventPayload = {
    playlistMedia: PlaylistMediaType;
    data: DiscriminatedEntity<LibraryModelsMedia>;
};

export const { useOnPlaylistMediaEditedListener, emitOnPlaylistMediaEdited } =
    createEvent('onPlaylistMediaEdited')<PlaylistMediaAddedEventPayload>();
