import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { usePendingPromise } from '@zetadisplay/engage-components/hooks';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button } from '@zetadisplay/zeta-ui-components';

import playlistCreateOrUpdatePromiseFactory from 'src/modules/playlist/actions/playlistCreateOrUpdatePromiseFactory';
import { PlaylistSetupFormValues } from 'src/modules/playlist/utils/createPlaylistFormValues';

import createButtonEvents from '../../../modules/playlist/utils/create-button-events';
import createMessagesCallbackFactory from '../../../modules/playlist/utils/create-messages-callback-factory';

export type PlaylistSetupActionsProps = {
    onCancel: () => void;
    playlistId?: number;
};

const PlaylistSetupActions = ({ onCancel, playlistId = undefined }: PlaylistSetupActionsProps) => {
    const api = useApi();
    const { formState, handleSubmit } = useFormContext<PlaylistSetupFormValues>();
    const { isSubmitting, isValid } = formState;
    const t = useTranslation();
    const { workspace } = useWorkspace();

    const submitAction = usePendingPromise(
        playlistCreateOrUpdatePromiseFactory({
            api,
            playlistId,
            workspaceid: workspace.id,
        }),
        createMessagesCallbackFactory(playlistId !== undefined),
        createButtonEvents
    );

    const onSubmit = useCallback(
        async (data: PlaylistSetupFormValues) => {
            const response = await submitAction(data);
            if (response === undefined) {
                return;
            }

            onCancel();
        },
        [onCancel, submitAction]
    );

    return (
        <div>
            <Button kind="secondaryAction" label={t.trans('common.action.cancel')} onClick={onCancel} />
            <Button
                busy={isSubmitting}
                disabled={isSubmitting || !isValid}
                kind="primaryAction"
                label={t.trans('common.action.save')}
                onClick={handleSubmit(onSubmit)}
            />
        </div>
    );
};

export default PlaylistSetupActions;
