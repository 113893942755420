import React from 'react';
import { Typography } from '@mui/material';
import { ConfigModelsGroupInfo } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { TreeNode } from '@zetadisplay/engage-components/utils/tree-builder';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button, Icon } from '@zetadisplay/zeta-ui-components';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

const { ORANGE } = themeOptions.colors;

const useStyles = makeStyles()(() => ({
    root: {
        marginBottom: 12,
        width: '100%',
    },
    group: {
        display: 'flex',
        height: 22,
        lineHeight: '22px',
        marginBottom: 4,
    },
    groupTitle: {
        display: 'inline-flex',
        flexGrow: 1,
    },
    groupRemove: {
        display: 'inline-flex',
        padding: 5,
    },
}));

type Props = {
    groups: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfo>, number>[];
    onSelectGroups: (arg: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfo>, number>[]) => void;
};

const TargetingGroupsList = ({ groups, onSelectGroups }: Props) => {
    const { classes, cx } = useStyles();
    const t = useTranslation();

    const handleRemoveGroup = (arg: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfo>, number>) => {
        onSelectGroups(groups.filter((group) => group.id !== arg.id));
    };

    return (
        <div className={cx(classes.root)}>
            <Typography variant="h6">{t.trans('engage.players.targeting.groups.title')}</Typography>
            {groups.length === 0 && <Typography>{t.trans('engage.players.targeting.no_selected_groups')}</Typography>}
            {groups?.map((group) => (
                <div key={group.id} className={cx(classes.group)} data-testid={`group-${group.name}`}>
                    <Typography className={cx(classes.groupTitle)}>{group.name}</Typography>
                    <Button
                        className={cx(classes.groupRemove)}
                        onClick={() => handleRemoveGroup(group)}
                        startIcon={<Icon color={ORANGE} type="DELETE" size={14} />}
                    />
                </div>
            ))}
        </div>
    );
};

export default TargetingGroupsList;
