import NiceModal, { NiceModalHocProps } from '@ebay/nice-modal-react';
import { ConfigModelsGroupInfoWithChildren } from '@zetadisplay/engage-api-client';
import { usePendingPromise } from '@zetadisplay/engage-components/hooks';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { createButtonClickEvent } from '@zetadisplay/zeta-ui-components/utils/data-layer';

import ConfirmPrompt, { ConfirmPromptActions } from 'src/components/Modals/ConfirmPrompt';

export type DeleteGroupPromptProps = {
    group: DiscriminatedEntity<ConfigModelsGroupInfoWithChildren>;
} & NiceModalHocProps;

const DeleteGroupPrompt = NiceModal.create<DeleteGroupPromptProps>(({ group }) => {
    const api = useApi();
    const t = useTranslation();
    const { workspace } = useWorkspace();

    const submitAction = usePendingPromise(
        async () => {
            return api.publishGroups
                .deleteGroup({
                    groupid: group.id,
                    workspaceid: workspace.id,
                })
                .then(() => group);
        },
        { success: { message: 'engage.notification.delete.group.success', params: [group.name] } },
        createButtonClickEvent('Editing option', 'Delete')
    );

    return (
        <ConfirmPrompt
            description={t.trans('engage.modal.delete.content', [group.name])}
            submit={{ label: 'common.action.delete', name: 'delete' }}
            submitAction={submitAction}
            title={{ label: 'engage.modal.delete.title', labelParams: [group.name] }}
            validate={{ value: ConfirmPromptActions.DELETE, label: 'common.validation.validate.action' }}
        />
    );
});

export default DeleteGroupPrompt;
