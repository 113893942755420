import { useCallback } from 'react';
import { useSearchFilters } from '@zetadisplay/engage-components/modules/search';
import { SearchEventScope } from '@zetadisplay/engage-components/modules/search/events';

import { MAX_ITEMS_PER_PAGE } from 'src/config/appConfig';
import { useOnPlayerSettingsUpdateListener } from 'src/events/player/onPlayerSettingsUpdateEvent';
import useCurrentGroup from 'src/hooks/useCurrentGroup';
import { useOnGroupCreatedListener } from 'src/views/PlayersView/Events/onGroupCreated';
import { useOnGroupDeletedListener } from 'src/views/PlayersView/Events/onGroupDeleted';
import { useOnGroupUpdatedListener } from 'src/views/PlayersView/Events/onGroupUpdated';
import usePaginatedGroups from 'src/views/PlayersView/Hooks/usePaginatedGroups';
import usePaginatedPlayers from 'src/views/PlayersView/Hooks/usePaginatedPlayers';

const canFetchPlayers = (groups: ReturnType<typeof usePaginatedGroups>) => {
    if (groups.isLoading) {
        return false;
    }

    const total = groups.total || 0;
    return groups.data.length === total;
};

const getTotalCount = (totalGroups: number | undefined, totalPlayers: number | undefined) => {
    if (totalGroups !== undefined && totalPlayers !== undefined) {
        return totalGroups + totalPlayers;
    }

    return totalGroups || totalPlayers;
};

const usePlayersContent = (showPlayersOnly = false) => {
    const currentGroupId = useCurrentGroup();
    const [searchFilters, searchFiltersKey] = useSearchFilters([SearchEventScope.GROUP, SearchEventScope.PLAYER]);

    const groups = usePaginatedGroups({
        currentGroupId,
        enabled: !showPlayersOnly,
        pageSize: MAX_ITEMS_PER_PAGE,
        searchFilters,
        searchFiltersKey,
    });

    const players = usePaginatedPlayers({
        currentGroupId,
        enabled: canFetchPlayers(groups),
        pageSize: MAX_ITEMS_PER_PAGE,
        searchFilters,
        searchFiltersKey,
        showPlayersOnly,
    });

    // Subscribe to events
    useOnGroupCreatedListener(groups.setResult);
    useOnGroupDeletedListener(groups.removeResult);
    useOnGroupUpdatedListener(groups.setResult);
    useOnPlayerSettingsUpdateListener(players.setResult);

    const handleGetNextResultPage = useCallback(() => {
        if (canFetchPlayers(groups)) {
            players.getNextResultPage();
        } else {
            groups.getNextResultPage();
        }
    }, [groups, players]);

    return {
        groups,
        getNextResultPage: handleGetNextResultPage,
        players,
        total: getTotalCount(groups.total, players.total) || 0,
    };
};

export default usePlayersContent;
