import React from 'react';
import { useFormState } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import { ConfigModelsGroupPersistModel } from '@zetadisplay/engage-api-client/models/config-models-group-persist-model';
import { TextInput } from '@zetadisplay/engage-components';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ComponentLoader } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import AttributesConstraints from 'src/modules/groups/setup/components/GroupSetupSettingsForm/AttributesConstraints';

const useStyles = makeStyles()(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    },
}));

const GroupSetupSettingsForm = () => {
    const { classes } = useStyles();
    const t = useTranslation();
    const { errors, isLoading } = useFormState<ConfigModelsGroupPersistModel>();

    if (isLoading) {
        return <ComponentLoader />;
    }

    return (
        <div className={classes.root}>
            <Typography sx={{ margin: '12px 0' }} variant="h5">
                {t.trans('engage.players.group.setup.title')}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextInput
                        name="name"
                        defaultLabel="common.form.input.name"
                        error={errors?.name}
                        required
                        rules={{
                            required: 'common.validation.required',
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        multiline
                        name="description"
                        defaultLabel="common.form.input.description"
                        error={errors?.description}
                    />
                </Grid>
            </Grid>
            <Typography sx={{ margin: '12px 0' }} variant="h5">
                {t.trans('engage.players.setup.labels_and_values.title')}
            </Typography>
            <AttributesConstraints />
        </div>
    );
};

export default GroupSetupSettingsForm;
