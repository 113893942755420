import { FieldArrayWithId, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';

import { PlaylistSetupFormValues } from '../../utils/createPlaylistFormValues';
import useSelectPlaylistItems from './hooks/use-select-playlist-items';
import PlaylistPreviewContentHeader from './playlist-preview-content-header';
import PlaylistPreviewContentList from './playlist-preview-content-list';

type Props = {
    layoutZoneId?: number;
    loading: boolean;

    playlistMediaCollection: FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>[];
};

const PlaylistPreviewContent = ({ layoutZoneId, loading, playlistMediaCollection }: Props) => {
    const { watch } = useFormContext<PlaylistSetupFormValues>();
    const modifiedMedia = watch('modifiedMedia');

    const { selectAllItems, selectItem, selectedItems } = useSelectPlaylistItems(playlistMediaCollection);

    return (
        <Box data-testid="playlist-preview-content" sx={{ flexGrow: 1 }}>
            <PlaylistPreviewContentHeader
                loading={loading}
                modifiedMedia={modifiedMedia}
                playlistMediaCollection={playlistMediaCollection}
                selectAllItems={selectAllItems}
                selectedItems={selectedItems}
            />

            <PlaylistPreviewContentList
                layoutZoneId={layoutZoneId}
                loading={loading}
                modifiedMedia={modifiedMedia}
                playlistMediaCollection={playlistMediaCollection}
                selectItem={selectItem}
                selectedItems={selectedItems}
            />
        </Box>
    );
};

export default PlaylistPreviewContent;
