import { useCallback } from 'react';
import NiceModal, { NiceModalHocProps } from '@ebay/nice-modal-react';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Icon } from '@zetadisplay/zeta-ui-components';

import ConfirmPrompt from 'src/components/Modals/ConfirmPrompt';

import { PowerScheduleAssignedPlayerType } from '../../../views/PowerScheduleSetupView';

export type RemovePowerSchedulePlayerPromptProps = {
    player: PowerScheduleAssignedPlayerType;
} & NiceModalHocProps;

const RemovePowerSchedulePlayerPrompt = NiceModal.create<RemovePowerSchedulePlayerPromptProps>(({ player }) => {
    const t = useTranslation();
    const submitAction = useCallback(async () => player, [player]);

    return (
        <ConfirmPrompt
            description={t.trans('engage.modal.power_schedule.remove-player.description', [player.friendlyName])}
            title={{
                icon: <Icon type="PLAYER_GROUP" />,
                label: 'engage.modal.power_schedule.remove-player.title',
            }}
            submit={{
                label: 'common.action.remove',
                name: 'remove',
            }}
            submitAction={submitAction}
        />
    );
});

export default RemovePowerSchedulePlayerPrompt;
