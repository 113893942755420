import NiceModal, { NiceModalHocProps } from '@ebay/nice-modal-react';
import { PublishingCampaignSchedulingCampaignInfoWithMediaSchedules } from '@zetadisplay/engage-api-client';
import { usePendingPromise } from '@zetadisplay/engage-components/hooks';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { createButtonClickEvent } from '@zetadisplay/zeta-ui-components/utils/data-layer';

import ConfirmPrompt, { ConfirmPromptActions } from 'src/components/Modals/ConfirmPrompt';

export type RemoveFolderPromptProps = {
    playlist: DiscriminatedEntity<PublishingCampaignSchedulingCampaignInfoWithMediaSchedules>;
} & NiceModalHocProps;

const DeletePlaylistPrompt = NiceModal.create<RemoveFolderPromptProps>(({ playlist }) => {
    const api = useApi();
    const t = useTranslation();
    const { workspace } = useWorkspace();

    const submitAction = usePendingPromise(
        async () => {
            return api.playlists
                .deleteCampaign({
                    campaignid: playlist.id,
                    workspaceid: workspace.id,
                })
                .then(() => playlist);
        },
        { success: { message: 'engage.notification.delete.playlist.success', params: [playlist.name] } },
        createButtonClickEvent('Editing option', 'Delete')
    );

    return (
        <ConfirmPrompt
            description={t.trans('engage.modal.delete.content', [playlist.name])}
            submit={{ label: 'common.action.delete', name: 'delete' }}
            submitAction={submitAction}
            title={{ label: 'engage.modal.delete.title', labelParams: [playlist.name] }}
            // TODO: Update label to 'common.validation.validate.action' after R18 upgrade
            validate={{ value: ConfirmPromptActions.DELETE, label: 'Action confirmation' }}
        />
    );
});

export default DeletePlaylistPrompt;
