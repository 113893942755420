import React, { useCallback } from 'react';
import { Grid, Link, Typography } from '@mui/material';
import { HelpWithCircle } from '@styled-icons/entypo';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button } from '@zetadisplay/zeta-ui-components';
import { createCustomDataLayerEvent, pushToDataLayer } from '@zetadisplay/zeta-ui-components/utils/data-layer';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

const { LIGHTGRAY } = themeOptions.colors;

const useStyles = makeStyles()(() => ({
    helpTitle: {
        fontSize: 17,
        letterSpacing: '0.57px',
        lineHeight: '22px',
        marginBottom: 4,
        textTransform: 'uppercase',
    },
    helpBody: {
        marginBottom: 20,
    },
    iconContainer: {
        alignItems: 'center',
        backgroundColor: LIGHTGRAY,
        borderRadius: '50%',
        display: 'flex',
        height: 94,
        margin: '0 auto 14px',
        justifyContent: 'center',
        width: 94,
    },
    action: {
        marginBottom: 20,
        textAlign: 'center',
    },
}));

const Support = () => {
    const { classes, cx } = useStyles();
    const t = useTranslation();

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLSpanElement, MouseEvent> | React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
            pushToDataLayer(createCustomDataLayerEvent('Support', 'Open Helpcenter'));
            Object.assign(document.createElement('a'), {
                target: '_blank',
                href: 'https://zetadisplay.com/engage-knowledge-base/',
            }).click();
        },
        []
    );

    return (
        <section id="support" data-testid="support">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h2">{t.trans('engage.dashboard.support.title')}</Typography>
                </Grid>

                <Grid item xs={12}>
                    <div className={cx(classes.iconContainer)}>
                        <HelpWithCircle size={48} color="#fff" />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h3" className={cx(classes.helpTitle)}>
                        {t.trans('engage.dashboard.support.subtitle')}
                    </Typography>

                    <Typography variant="body2" className={cx(classes.helpBody)}>
                        {t.trans('engage.dashboard.support.information')}
                    </Typography>
                </Grid>

                <Grid item xs={12} className={cx(classes.action)}>
                    <Link
                        data-testid="link"
                        href="https://zetadisplay.com/engage-knowledge-base/"
                        onClick={handleClick}
                        target="_blank"
                    >
                        <Button
                            kind="primaryAction"
                            fullWidth={false}
                            label={t.trans('engage.dashboard.support.action')}
                        />
                    </Link>
                </Grid>
            </Grid>
        </section>
    );
};

export default Support;
