import { useCallback, useEffect, useState } from 'react';

type UseSidekickTargetSelectionReturn<T> = {
    addSelectedTarget: (target: T) => void;
    removeSelectedTarget: () => void;
    selectedTarget: T | undefined;
};

const useSidekickTargetSelection = <T>(
    getTargetById: (id: string, targets: Map<string, T>) => T | undefined,
    targets: Map<string, T>
): UseSidekickTargetSelectionReturn<T> => {
    const [selectedTarget, setSelectedTarget] = useState<T | undefined>(undefined);
    const addSelectedTarget = useCallback((target: T) => setSelectedTarget(target), []);
    const removeSelectedTarget = useCallback(() => setSelectedTarget(undefined), []);

    // Current selection should be reset to initial state once targets change
    useEffect(() => {
        setSelectedTarget(undefined);
    }, [targets]);

    return { addSelectedTarget, removeSelectedTarget, selectedTarget };
};

export default useSidekickTargetSelection;
