import React from 'react';
import { RouteNode } from '@zetadisplay/engage-components/utils/route/route';
import { Icon } from '@zetadisplay/zeta-ui-components';

const createRootNode = (id: string, name: string, href: string): RouteNode => {
    return {
        id,
        href,
        icon: <Icon type="HOME" size={22} />,
        name,
    };
};

export default createRootNode;
