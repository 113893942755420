import { ConfigModelsAttributeValueRelations, SharedModelsChannelAttributeValue } from '@zetadisplay/engage-api-client';

import { LabelValuePlayerState, LabelValuePlayerType, LabelValueState, LabelValueType } from 'src/views/LabelSetupView';
import createAttributeValuePair from 'src/views/LabelSetupView/Utils/createAttributeValuePair';

const createChannelAttributes = (
    attributeValue: LabelValueType,
    player: LabelValuePlayerType,
    attributes?: ConfigModelsAttributeValueRelations[] | null
): SharedModelsChannelAttributeValue[] => {
    if (attributes === undefined || attributes === null) {
        return [createAttributeValuePair(attributeValue.labelId, attributeValue.id)];
    }

    const existingAttributes = attributes.map((existingAttribute) => ({
        attributeId: existingAttribute.attribute.id,
        attributeValueId: existingAttribute.attributeValue?.id as number,
    }));

    if (attributeValue.state === LabelValueState.DELETED || player.state === LabelValuePlayerState.DELETED) {
        return existingAttributes.filter(
            (existing) =>
                existing.attributeId !== attributeValue.labelId && existing.attributeValueId !== attributeValue.id
        );
    }

    return [...existingAttributes, createAttributeValuePair(attributeValue.labelId, attributeValue.id)];
};

export default createChannelAttributes;
