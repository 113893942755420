import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import NiceModal from '@ebay/nice-modal-react';
import { Box, Stack, Typography } from '@mui/material';
import {
    ConfigModelsGroupInfoWithChildren,
    NetworkModelsLifelineConnectionStatusEnum,
    NetworkModelsPlayerInformation,
} from '@zetadisplay/engage-api-client';
import {
    createDiscriminatedEntity,
    DiscriminatedEntity,
    EntityDiscriminators,
} from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { TreeNode } from '@zetadisplay/engage-components/utils/tree-builder';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button } from '@zetadisplay/zeta-ui-components';

import GroupSelectionModal, { GroupSelectionModalProps } from 'src/components/Modals/Group/GroupSelectionModal';
import PlayerTargets from 'src/components/Players/PlayerTargets';
import {
    AssignedPowerScheduleType,
    PowerScheduleAssignedPlayerType,
    PowerScheduleFormData,
} from 'src/views/PowerScheduleSetupView';

import { PowerScheduleAssignedPlayerState } from '../Utils/powerScheduleFormEnums';
import SpecialPowerScheduleTargets from './SpecialPowerScheduleTargets';

type PowerScheduleTargetsProps = {
    currentTab: number;
};
const PowerScheduleTargets = ({ currentTab }: PowerScheduleTargetsProps) => {
    const api = useApi();
    const { getValues, setValue, watch } = useFormContext<PowerScheduleFormData>();
    const t = useTranslation();
    const { workspace } = useWorkspace();

    // Callback to resolve players based on groups
    const resolveSelectedGroups = useCallback(
        async (groups?: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfoWithChildren>, number>[]) => {
            if (groups === undefined || groups.length === 0) {
                return [];
            }

            const players = await api.players
                .getPlayersByGroups({ ids: groups.map((group) => group.id), workspaceid: workspace.id })
                .then((response) => response.data.items);

            return players
                .filter((player) => player.onlineState !== NetworkModelsLifelineConnectionStatusEnum.NEW)
                .map((player) => createDiscriminatedEntity(EntityDiscriminators.Player, player));
        },
        [api.players, workspace.id]
    );

    const onRemovePowerSchedulePlayer = useCallback(
        (player: PowerScheduleAssignedPlayerType) => {
            const players = getValues('primaryPowerSchedule.assignedPlayers') || [];
            if (player.state === PowerScheduleAssignedPlayerState.NEW) {
                return setValue(
                    `primaryPowerSchedule.assignedPlayers`,
                    players.filter((p) => p.id !== player.id)
                );
            }

            const playerIndex = players.findIndex((p) => p.id === player.id);
            return setValue(
                `primaryPowerSchedule.assignedPlayers.${playerIndex}.state`,
                PowerScheduleAssignedPlayerState.DELETED
            );
        },
        [getValues, setValue]
    );

    const onRestorePowerSchedulePlayer = useCallback(
        (player: PowerScheduleAssignedPlayerType) => {
            const players = getValues('primaryPowerSchedule.assignedPlayers') || [];
            const playerIndex = players.findIndex((p) => p.id === player.id);

            setValue(`primaryPowerSchedule.assignedPlayers.${playerIndex}.state`, undefined);
        },
        [getValues, setValue]
    );

    const onRemoveAssignedPowerSchedule = useCallback(
        (assignedPowerSchedule: AssignedPowerScheduleType) => {
            const powerSchedules = getValues('specialPowerSchedule.powerSchedules') || [];
            if (assignedPowerSchedule.state === PowerScheduleAssignedPlayerState.NEW) {
                return setValue(
                    'specialPowerSchedule.powerSchedules',
                    powerSchedules.filter((p) => p.id !== assignedPowerSchedule.id)
                );
            }

            const powerScheduleIndex = powerSchedules.findIndex((p) => p.id === assignedPowerSchedule.id);
            return setValue(
                `specialPowerSchedule.powerSchedules.${powerScheduleIndex}.state`,
                PowerScheduleAssignedPlayerState.DELETED
            );
        },
        [getValues, setValue]
    );

    const onRestoreAssignedPowerSchedule = useCallback(
        (assignedPowerSchedule: AssignedPowerScheduleType) => {
            const powerSchedules = getValues('specialPowerSchedule.powerSchedules') || [];
            const powerScheduleIndex = powerSchedules.findIndex((p) => p.id === assignedPowerSchedule.id);

            setValue(`specialPowerSchedule.powerSchedules.${powerScheduleIndex}.state`, undefined);
        },
        [getValues, setValue]
    );

    const renderPlayerTargets = () => {
        const players = watch('primaryPowerSchedule.assignedPlayers') || [];
        if (players.length === 0) {
            return (
                <Typography align="center">
                    {t.trans('engage.players.setup.power_schedule.targeting.no_targets')}
                </Typography>
            );
        }

        return (
            <PlayerTargets
                onRemovePowerSchedulePlayer={onRemovePowerSchedulePlayer}
                onRestorePowerSchedulePlayer={onRestorePowerSchedulePlayer}
                players={players}
            />
        );
    };

    const renderPowerScheduleTargets = () => {
        const powerSchedules = watch('specialPowerSchedule.powerSchedules') || [];
        if (powerSchedules.length === 0) {
            return <Typography align="center">{t.trans('engage.power_schedule.assigning.no_assigns')}</Typography>;
        }

        return (
            <SpecialPowerScheduleTargets
                onRemoveAssignedPowerSchedule={onRemoveAssignedPowerSchedule}
                onRestoreAssignedPowerSchedule={onRestoreAssignedPowerSchedule}
                powerSchedules={powerSchedules}
            />
        );
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            {currentTab === 0 && (
                <>
                    <Stack
                        direction="row"
                        spacing={3}
                        sx={{ alignItems: 'center', justifyContent: 'space-between', overflow: 'hidden' }}
                    >
                        <Typography noWrap variant="h5">
                            {t.trans('engage.players.setup.power_schedule.targeting.title')}
                        </Typography>
                        <Button
                            name="select-groups"
                            onClick={() =>
                                NiceModal.show<
                                    DiscriminatedEntity<NetworkModelsPlayerInformation>[],
                                    GroupSelectionModalProps
                                >(GroupSelectionModal, {
                                    resolveSelectedGroups,
                                }).then((players) => setValue('primaryPowerSchedule.assignedPlayers', players))
                            }
                        >
                            + Select groups
                        </Button>
                    </Stack>
                    {renderPlayerTargets()}
                </>
            )}
            {currentTab !== 0 && (
                <>
                    <Stack
                        direction="row"
                        spacing={3}
                        sx={{ alignItems: 'center', justifyContent: 'space-between', overflow: 'hidden' }}
                    >
                        <Typography noWrap variant="h5">
                            {t.trans('engage.power_schedule.assigned.title')}
                        </Typography>
                    </Stack>
                    {renderPowerScheduleTargets()}
                </>
            )}
        </Box>
    );
};

export default PowerScheduleTargets;
