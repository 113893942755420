import React from 'react';
import { getIconType } from '@zetadisplay/engage-components/modules/view/utils';
import { ItemList } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { AssignedPowerScheduleType } from '../../PowerScheduleSetupView';
import AssignedPowerScheduleAction from './AssignedPowerScheduleAction';

const useStyles = makeStyles()(() => ({
    powerSchedulesNew: {
        height: '100%',
    },
}));

type Props = {
    onRemoveAssignedPowerSchedule: (assignedPowerSchedule: AssignedPowerScheduleType) => void;
    onRestoreAssignedPowerSchedule: (assignedPowerSchedule: AssignedPowerScheduleType) => void;
    powerSchedules: AssignedPowerScheduleType[];
};

const SpecialPowerScheduleTargets: React.FC<Props> = ({
    onRemoveAssignedPowerSchedule,
    onRestoreAssignedPowerSchedule,
    powerSchedules,
}) => {
    const { classes } = useStyles();

    return (
        <div className={classes.powerSchedulesNew} data-testid="assigned-powerSchedules-list">
            <ItemList
                initialItemCount={0}
                items={powerSchedules || []}
                ItemProps={{
                    clickable: false,
                    getItemIconType: getIconType,
                    showThumbnail: false,
                    type: 'compact',
                    renderTitle: (item) => item.name,
                    renderPrimaryAction: (item) => (
                        <AssignedPowerScheduleAction
                            onRemovePowerSchedule={onRemoveAssignedPowerSchedule}
                            onRestorePowerSchedule={onRestoreAssignedPowerSchedule}
                            powerSchedule={item}
                        />
                    ),
                }}
                overscan={0}
            />
        </div>
    );
};

export default SpecialPowerScheduleTargets;
