const unregisterServiceWorker = () => {
    if (navigator.serviceWorker === undefined) {
        return;
    }

    // eslint-disable-next-line compat/compat
    navigator.serviceWorker.ready.then((registration) => {
        if (registration.active && registration.active.scriptURL.includes('/OidcServiceWorker.js')) {
            registration.unregister();
        }
    });
};

export default unregisterServiceWorker;
