import React from 'react';
import { Box } from '@mui/material';
import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useApiConfiguration } from '@zetadisplay/engage-components/modules/api';
import { getThumbnailUrl } from '@zetadisplay/engage-components/utils/media';
import { Thumbnail } from '@zetadisplay/zeta-ui-components';
import { DefaultNotFoundImage } from '@zetadisplay/zeta-ui-components/assets';

import { renderPreview } from '../../../../views/PlaylistSetupView/Components/PlaylistSetupMainForm/PlaylistSetupContentList/PlaylistSetupContentListItem';
import PlaylistSetupContentListItemAspectRatio from '../../../../views/PlaylistSetupView/Components/PlaylistSetupMainForm/PlaylistSetupContentList/PlaylistSetupContentListItem/PlaylistSetupContentListItemAspectRatio';

type Props = {
    item: DiscriminatedEntity<LibraryModelsMedia>;
    layoutZoneId?: number;
};

const PlaylistPreviewContentItemThumbnail = ({ item, layoutZoneId }: Props) => {
    const apiConfig = useApiConfiguration();

    const thumbnailUrl = getThumbnailUrl(item, false, apiConfig.basePath);

    const isPortrait = (item.width && item.height && item.width < item.height) || false;

    return (
        <Box
            component="span"
            data-testid={(thumbnailUrl && 'has-thumb') || 'has-default-not-found-image'}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                minHeight: 38,
                marginRight: 2,
                width: 70,
            }}
        >
            <Box
                data-testid={(isPortrait && 'is-portrait') || 'is-landscape'}
                sx={{ position: 'relative', width: isPortrait ? 40 : 70 }}
            >
                {renderPreview(item, apiConfig.basePath)}

                <Thumbnail alt={item.name} size={isPortrait ? 40 : 70} source={thumbnailUrl || DefaultNotFoundImage} />

                <PlaylistSetupContentListItemAspectRatio media={item} layoutZoneId={layoutZoneId} />
            </Box>
        </Box>
    );
};

export default PlaylistPreviewContentItemThumbnail;
