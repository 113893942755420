import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { IconButton } from '@mui/material';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Icon } from '@zetadisplay/zeta-ui-components';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

import RemoveAssignedPowerSchedulePrompt, {
    RemoveAssignedPowerSchedulePromptProps,
} from 'src/components/Modals/PowerScheduleSetup/RemoveAssignedPowerSchedulePrompt';
import Tag from 'src/components/Tag';
import { AssignedPowerScheduleType } from 'src/views/PowerScheduleSetupView';
import { PowerScheduleAssignedPlayerState } from 'src/views/PowerScheduleSetupView/Utils/powerScheduleFormEnums';

const { ORANGE, DARKGRAY } = themeOptions.colors;

const useStyles = makeStyles()(() => ({
    actions: {
        paddingLeft: 6,
    },
    tags: {
        display: 'flex',
    },
}));

type Props = {
    onRemovePowerSchedule: (powerSchedule: AssignedPowerScheduleType) => void;
    onRestorePowerSchedule: (powerSchedule: AssignedPowerScheduleType) => void;
    powerSchedule: AssignedPowerScheduleType;
};

const AssignedPowerScheduleAction = ({ powerSchedule, onRestorePowerSchedule, onRemovePowerSchedule }: Props) => {
    const { classes } = useStyles();
    const { trans } = useTranslation();

    const showRemovePowerSchedulePrompt = () => {
        NiceModal.show<AssignedPowerScheduleType, RemoveAssignedPowerSchedulePromptProps>(
            RemoveAssignedPowerSchedulePrompt,
            {
                powerSchedule,
            }
        ).then(onRemovePowerSchedule);
    };

    const renderStateTag = () => {
        if (powerSchedule.state === undefined) {
            return null;
        }

        return powerSchedule.state === PowerScheduleAssignedPlayerState.DELETED ? (
            <Tag type="RED" text={trans('engage.misc.removed')} />
        ) : (
            <Tag type="DEFAULT" text={trans('engage.misc.new')} />
        );
    };

    const renderActionButton = () => {
        if (powerSchedule.state !== PowerScheduleAssignedPlayerState.DELETED) {
            return (
                <IconButton
                    data-testid={`remove-power-schedule-${powerSchedule.id}`}
                    onClick={showRemovePowerSchedulePrompt}
                    size="small"
                >
                    <Icon type="DELETE" size={16} color={ORANGE} />
                </IconButton>
            );
        }

        return (
            <IconButton
                data-testid={`enable-power-schedule-${powerSchedule.id}`}
                onClick={() => onRestorePowerSchedule(powerSchedule)}
                size="small"
            >
                <Icon type="DELETE_RESTORE" size={16} color={DARKGRAY} />
            </IconButton>
        );
    };

    return (
        <div className={classes.tags} data-testid={`power-schedule-assigned-item-actions-${powerSchedule.name}`}>
            {renderStateTag()}
            <div className={classes.actions}>{renderActionButton()}</div>
        </div>
    );
};

export default AssignedPowerScheduleAction;
