import {
    PublishingModelsCampaignCampaignPriorityEnum,
    PublishingModelsCampaignCampaignRules,
} from '@zetadisplay/engage-api-client';

export const getPlaybackModeTranslation = (mixingMode?: PublishingModelsCampaignCampaignRules) => {
    const translations = {
        [PublishingModelsCampaignCampaignRules.Shuffled]: 'engage.playlist.setup.mode.option.shuffled',
        [PublishingModelsCampaignCampaignRules.Grouped]: 'engage.playlist.setup.mode.option.grouped',
        [PublishingModelsCampaignCampaignRules.Exclusive]: 'engage.playlist.setup.mode.option.exclusive',
        [PublishingModelsCampaignCampaignRules.OnDemand]: 'engage.playlist.setup.mode.option.on-demand',
    };

    if (mixingMode === undefined) {
        return 'engage.playlist.setup.mode.option.unknown';
    }

    return translations[mixingMode];
};

export const getPlaybackPriorityTranslation = (playlistPriority: PublishingModelsCampaignCampaignPriorityEnum) => {
    const translations = {
        [PublishingModelsCampaignCampaignPriorityEnum.Highest]: 'engage.playlist.setup.priority.option.highest',
        [PublishingModelsCampaignCampaignPriorityEnum.High]: 'engage.playlist.setup.priority.option.high',
        [PublishingModelsCampaignCampaignPriorityEnum.Normal]: 'engage.playlist.setup.priority.option.normal',
        [PublishingModelsCampaignCampaignPriorityEnum.Low]: 'engage.playlist.setup.priority.option.low',
        [PublishingModelsCampaignCampaignPriorityEnum.Lowest]: 'engage.playlist.setup.priority.option.lowest',
    };

    return translations[playlistPriority];
};
