import React, { useCallback } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button } from '@zetadisplay/zeta-ui-components';

import { PLAYERS_BASE_PATH } from 'src/constants/Paths';
import {
    PowerScheduleFormData,
    PrimaryPowerScheduleFormData,
    SpecialPowerScheduleFormData,
} from 'src/views/PowerScheduleSetupView';
import { emitOnPowerScheduleCreated } from 'src/views/PowerScheduleSetupView/Events/onPowerScheduleCreatedEvent';
import usePowerScheduleSubmitAction from 'src/views/PowerScheduleSetupView/Hooks/usePowerScheduleSubmitAction';

import { emitOnSpecialScheduleCreated } from '../Events/onSpecialScheduleCreatedEvent';
import useSpecialScheduleSubmitAction from '../Hooks/useSpecialScheduleSubmitAction';

type PowerScheduleFormActionsProps = {
    currentTab: number;
};

const PowerScheduleFormActions = ({ currentTab }: PowerScheduleFormActionsProps) => {
    const navigate = useNavigate();
    const powerScheduleSubmitAction = usePowerScheduleSubmitAction(emitOnPowerScheduleCreated);
    const specialScheduleSubmitAction = useSpecialScheduleSubmitAction(emitOnSpecialScheduleCreated);
    const t = useTranslation();

    const { handleSubmit, getValues, reset } = useFormContext<PowerScheduleFormData>();
    const { isSubmitting } = useFormState<PowerScheduleFormData>();
    const isExistingPrimaryPowerSchedule = getValues('primaryPowerSchedule.id');
    const specialScheduleId = getValues('specialPowerSchedule.id');
    const isExistingSpecialSchedule = specialScheduleId > 0;
    const isExistingPowerSchedule = isExistingPrimaryPowerSchedule || isExistingSpecialSchedule;

    const shouldResetForm = useCallback(() => {
        if (currentTab !== 0 && isExistingPrimaryPowerSchedule) {
            return false;
        }

        if (currentTab === 0 && isExistingPrimaryPowerSchedule) {
            return true;
        }

        if (currentTab !== 0 && isExistingSpecialSchedule) {
            return true;
        }

        return currentTab === 0 && isExistingSpecialSchedule;
    }, [currentTab, isExistingPrimaryPowerSchedule, isExistingSpecialSchedule]);

    const onSubmit = useCallback(
        async (data: PowerScheduleFormData) => {
            if (currentTab === 0) {
                // Handle submit for PrimaryPowerScheduleFormData
                await powerScheduleSubmitAction(data.primaryPowerSchedule as PrimaryPowerScheduleFormData);
            } else {
                // Handle submit for SpecialPowerScheduleFormData
                await specialScheduleSubmitAction(data.specialPowerSchedule as SpecialPowerScheduleFormData);
            }

            if (shouldResetForm()) {
                reset();
            }
        },
        [currentTab, powerScheduleSubmitAction, reset, shouldResetForm, specialScheduleSubmitAction]
    );

    const getButtonLabel = useCallback(() => {
        if (currentTab === 0) {
            return isExistingPrimaryPowerSchedule ? t.trans('common.action.save') : t.trans('common.action.create');
        }

        return isExistingSpecialSchedule ? t.trans('common.action.save') : t.trans('common.action.create');
    }, [currentTab, isExistingPrimaryPowerSchedule, isExistingSpecialSchedule, t]);

    return (
        <div>
            <Button
                kind="secondaryAction"
                label={t.trans('common.action.cancel')}
                onClick={() => navigate(PLAYERS_BASE_PATH)}
            />
            <Button
                kind="secondaryAction"
                disabled={isSubmitting}
                label={t.trans('common.action.clear')}
                onClick={() => reset()}
            />
            <Button
                busy={isSubmitting}
                disabled={isSubmitting}
                kind="primaryAction"
                label={getButtonLabel()}
                name={isExistingPowerSchedule ? 'save' : 'create'}
                onClick={handleSubmit(onSubmit)}
            />
        </div>
    );
};

export default PowerScheduleFormActions;
