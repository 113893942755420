import React, { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { Box } from '@mui/material';
import {
    NetworkModelsPlayerInformation,
    NetworkModelsPlayersPlayerLocation,
    NetworkModelsPlayersPlayerRelations,
    NetworkModelsPlayerTypeEnum,
} from '@zetadisplay/engage-api-client';
import {
    createDiscriminatedEntity,
    DiscriminatedEntity,
    EntityDiscriminators,
} from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { Sidekick, SidekickProps } from '@zetadisplay/engage-components/modules/sidekick/components';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { handleResponseError } from '@zetadisplay/engage-components/utils/response';
import { useNotify } from '@zetadisplay/zeta-ui-components/hooks';
import { AxiosError } from 'axios';

import SidekickBack from 'src/components/Sidekicks/SideKickBack';

import SidekickClose from '../../../components/Sidekicks/SideKickClose';
import PlayerSettingsChannels from './PlayerSettingsSidekick/components/PlayerSettingsChannels';
import PlayerSettingsDetailsForm from './PlayerSettingsSidekick/components/PlayerSettingsDetailsForm';
import PlayerSettingsHeader from './PlayerSettingsSidekick/components/PlayerSettingsHeader';
import useUpdatePlayerSettings from './PlayerSettingsSidekick/hooks/useUpdatePlayerSettings';

const createDefaultLocation = (): NetworkModelsPlayersPlayerLocation => ({
    address: '',
    city: '',
    zipcode: '',
});

const mapPlayerRelations = (player: NetworkModelsPlayersPlayerRelations): NetworkModelsPlayersPlayerRelations => {
    return {
        ...player,
        orientation: player.orientation ?? 0,
        playerLocation: player.playerLocation ?? createDefaultLocation(),
    };
};

export type PlayerSettingsSidekickProps = {
    openDetailsCallback?: (id: string) => void;
    player: DiscriminatedEntity<NetworkModelsPlayerInformation>;
} & NiceModalHocProps;

const PlayerSettingsSidekick = NiceModal.create<PlayerSettingsSidekickProps>(({ openDetailsCallback, player }) => {
    const api = useApi();
    const modal = useModal();
    const notify = useNotify();
    const { workspace } = useWorkspace();

    const updatePlayerSettings = useUpdatePlayerSettings(player);

    const methods = useForm({
        defaultValues: async () => {
            return api.players
                .getPlayerRelations({ playerGuid: player.guid, workspaceid: workspace.id })
                .then((response) => mapPlayerRelations(response.data));
        },
    });

    const isSubmitDisabled = useMemo(() => {
        return (
            player.workspaceId !== workspace.id ||
            methods.formState.isLoading ||
            methods.formState.isSubmitting ||
            !methods.formState.isValid
        );
    }, [
        methods.formState.isLoading,
        methods.formState.isSubmitting,
        methods.formState.isValid,
        player.workspaceId,
        workspace.id,
    ]);

    const onHandleSubmitClick = useCallback(
        async (data: NetworkModelsPlayersPlayerRelations) => {
            return updatePlayerSettings(data)
                .then((response) => {
                    notify({
                        message: 'engage.notification.edit.player.success',
                        params: [player.friendlyName],
                        variant: 'success',
                    });

                    modal.resolve(createDiscriminatedEntity(EntityDiscriminators.Player, response.data));
                    modal.hide();

                    if (openDetailsCallback) {
                        openDetailsCallback(player.guid);
                    }
                })
                .catch((error) => {
                    if (!(error instanceof AxiosError)) {
                        throw error;
                    }

                    notify({
                        message: handleResponseError(error),
                        variant: 'error',
                    });
                });
        },
        [modal, notify, openDetailsCallback, player.friendlyName, player.guid, updatePlayerSettings]
    );

    const onCloseCallback = useCallback(
        (onClose: () => void) => {
            if (openDetailsCallback) {
                openDetailsCallback(player.guid);
            }
            onClose();
        },
        [openDetailsCallback, player.guid]
    );

    const sidekickHeader = useMemo(
        (): SidekickProps['header'] => ({
            renderHeader: () => (
                <PlayerSettingsHeader
                    isSubmitBusy={methods.formState.isSubmitting}
                    isSubmitDisabled={isSubmitDisabled}
                    onSubmitClick={methods.handleSubmit(onHandleSubmitClick)}
                />
            ),
            renderClose: (onClose) =>
                openDetailsCallback ? (
                    <SidekickBack onClose={() => onCloseCallback(onClose)} />
                ) : (
                    <SidekickClose onClose={onClose} />
                ),
        }),
        [isSubmitDisabled, methods, onCloseCallback, onHandleSubmitClick, openDetailsCallback]
    );

    return (
        <Sidekick header={sidekickHeader} dark={false} drawerWidth={700}>
            <FormProvider {...methods}>
                <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, padding: '15px 0' }}>
                    <PlayerSettingsDetailsForm
                        supportsOrientation={[
                            NetworkModelsPlayerTypeEnum.LGWebOS,
                            NetworkModelsPlayerTypeEnum.SamsungSSSP,
                        ].includes(player.type)}
                    />

                    <PlayerSettingsChannels />
                </Box>
            </FormProvider>
        </Sidekick>
    );
});

export default PlayerSettingsSidekick;
