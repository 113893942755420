import createDateFromPlayingTime from '@zetadisplay/engage-components/utils/scheduling/create-date-from-playing-time';

export type PowerScheduleSchedule = {
    enabled: boolean;
    endTime: Date | null;
    startTime: Date | null;
};

const createPowerScheduleSchedule = (
    enabled: boolean,
    endTime: string | null,
    startTime: string | null
): PowerScheduleSchedule => ({
    enabled,
    endTime: createDateFromPlayingTime(endTime ?? ''),
    startTime: createDateFromPlayingTime(startTime ?? ''),
});

export default createPowerScheduleSchedule;
