import React, { useCallback } from 'react';
import { FieldArrayWithId } from 'react-hook-form';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import {
    defaultCancelButton,
    defaultSubmitButton,
    Modal,
} from '@zetadisplay/engage-components/modules/modal/components';
import { PlaylistMediaType } from '@zetadisplay/engage-components/modules/playlist';
import { useTranslation } from '@zetadisplay/zeta-localization';

import { PlaylistSetupFormValues } from '../../../modules/playlist/utils/createPlaylistFormValues';

export type RemovePlaylistMediaProps = {
    playlistMedia:
        | PlaylistMediaType
        | FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>;
    copies: string[];
} & NiceModalHocProps;

const RemovePlaylistMediaPrompt = NiceModal.create<RemovePlaylistMediaProps>(({ playlistMedia, copies }) => {
    const modal = useModal();
    const t = useTranslation();

    const onSubmit = useCallback(
        async (playlistIdCollection: string[]) => {
            modal.resolve(playlistIdCollection);
            modal.hide();
        },
        [modal]
    );

    return (
        <Modal
            actions={{
                buttons: [
                    { ...defaultCancelButton, onClick: modal.hide },
                    {
                        ...defaultSubmitButton,
                        disabled: copies.length < 2,
                        label: 'engage.action.remove_all_copies',
                        name: 'remove_all',
                        onClick: () => onSubmit(copies),
                    },
                    {
                        ...defaultSubmitButton,
                        disabled: playlistMedia.id === undefined,
                        label: 'common.action.remove',
                        name: 'remove',
                        onClick: () => playlistMedia.id !== undefined && onSubmit([playlistMedia.id]),
                    },
                ],
            }}
            dark
            title={{
                label: 'engage.modal.playlist.remove.title',
                labelParams: [(playlistMedia.model && playlistMedia.model.name) || ''],
            }}
        >
            {t.trans('engage.modal.playlist.remove.content', [
                (playlistMedia.model && playlistMedia.model.name) || 'undefined',
            ])}
        </Modal>
    );
});

export default RemovePlaylistMediaPrompt;
