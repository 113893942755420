import React from 'react';
import { getIconType } from '@zetadisplay/engage-components/modules/view/utils';
import { ItemList } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import PlayerTargetsPrimaryAction from 'src/components/Players/PlayerTargets/PlayerTargetsPrimaryAction';
import PlayerTargetsSubtitle from 'src/components/Players/PlayerTargets/PlayerTargetsSubtitle';

import { PowerScheduleAssignedPlayerType } from '../../views/PowerScheduleSetupView';

const COMPACT_HEIGHT = 54;
const MAX_VISIBLE_PLAYERS = 20;

type UseStylesProps = {
    playerCount: number;
};

const useStyles = makeStyles<UseStylesProps>()((_, { playerCount }) => ({
    players: {
        height: playerCount * COMPACT_HEIGHT,
        maxHeight: MAX_VISIBLE_PLAYERS * COMPACT_HEIGHT,
        width: '100%',
    },
    playersNew: {
        height: '100%',
    },
}));

type Props = {
    onRemovePowerSchedulePlayer: (player: PowerScheduleAssignedPlayerType) => void;
    onRestorePowerSchedulePlayer: (player: PowerScheduleAssignedPlayerType) => void;
    players: PowerScheduleAssignedPlayerType[];
};

const PlayerTargets: React.FC<Props> = ({ onRemovePowerSchedulePlayer, onRestorePowerSchedulePlayer, players }) => {
    const { classes } = useStyles({ playerCount: players.length });

    return (
        <div className={classes.playersNew}>
            <ItemList
                initialItemCount={0}
                items={players || []}
                ItemProps={{
                    clickable: false,
                    getItemIconType: getIconType,
                    showThumbnail: false,
                    type: 'compact',
                    renderPrimaryAction: (item) => (
                        <PlayerTargetsPrimaryAction
                            onRemovePowerSchedulePlayer={onRemovePowerSchedulePlayer}
                            onRestorePowerSchedulePlayer={onRestorePowerSchedulePlayer}
                            player={item}
                        />
                    ),
                    renderSubtitle: (item) => (
                        <PlayerTargetsSubtitle showShare={false} showType={false} player={item} />
                    ),
                    renderTitle: (item) => item.friendlyName,
                }}
                overscan={0}
            />
        </div>
    );
};

export default PlayerTargets;
