import React, { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import Grid from '@mui/material/Grid';
import { UserModelsAddUpdateUserInfoDetails, UserModelsUser } from '@zetadisplay/engage-api-client';
import { CheckboxInput, EmailInput, SelectInput, TextInput } from '@zetadisplay/engage-components';
import { usePendingPromise } from '@zetadisplay/engage-components/hooks';
import { isSupportedLocale } from '@zetadisplay/engage-components/locales';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useUserLanguages } from '@zetadisplay/engage-components/modules/auth';
import { createDefaultButtons, Modal } from '@zetadisplay/engage-components/modules/modal/components';
import { whitespace } from '@zetadisplay/engage-components/utils/form/validators';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ComponentLoader, Icon } from '@zetadisplay/zeta-ui-components';

export type ProfileModalProps = {
    user: UserModelsUser | undefined;
} & NiceModalHocProps;

const ProfileModal = NiceModal.create<ProfileModalProps>(({ user }) => {
    const api = useApi();
    const methods = useForm<UserModelsAddUpdateUserInfoDetails>();
    const modal = useModal();
    const t = useTranslation();

    // Fetch additional data
    const { userLanguages } = useUserLanguages();
    const availableLanguages = useMemo(
        () => userLanguages?.filter((language) => isSupportedLocale(language.code)) || [],
        [userLanguages]
    );
    const { errors, isSubmitting } = methods.formState;
    const hasErrors = Object.keys(errors).length !== 0;

    const submitAction = usePendingPromise(
        async (formValues: UserModelsAddUpdateUserInfoDetails) => {
            return api.users
                .updateUserInfo({
                    body: formValues,
                })
                .then((response) => response.data);
        },
        { success: 'engage.notification.edit.profile.success' }
    );

    const onSubmit = useCallback(
        async (formValues: UserModelsAddUpdateUserInfoDetails) => {
            const response = await submitAction(formValues);

            modal.resolve(response);
            modal.hide();
        },
        [modal, submitAction]
    );

    const renderProfileForm = useCallback(() => {
        if (user === undefined || user.profile === undefined || userLanguages === undefined) {
            return <ComponentLoader />;
        }

        return (
            <form autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} data-testid="first-name-container">
                        <TextInput
                            defaultLabel="common.form.input.first_name"
                            defaultValue={user.profile.firstName}
                            error={errors.firstName}
                            name="firstName"
                            rules={{
                                required: 'common.validation.required',
                                validate: { whitespace },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} data-testid="last-name-container">
                        <TextInput
                            defaultLabel="common.form.input.last_name"
                            defaultValue={user.profile.lastName}
                            error={errors.lastName}
                            name="lastName"
                            rules={{
                                required: 'common.validation.required',
                                validate: { whitespace },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <EmailInput
                            defaultLabel="common.form.input.username"
                            defaultValue={user.profile.userName}
                            error={errors.userName}
                            name="userName"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <CheckboxInput
                            defaultValue={user.profile.notifyCampaignStatus}
                            label={t.trans('engage.profile.notification.email.playlist.status_change')}
                            name="notifyCampaignStatus"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <CheckboxInput
                            defaultValue={user.profile.notifyEmptyPlaylist}
                            label={t.trans('engage.profile.notification.email.schedule.empty')}
                            name="notifyEmptyPlaylist"
                        />
                    </Grid>
                    {availableLanguages.length > 0 && (
                        <Grid item xs={12} sm={12}>
                            <SelectInput
                                defaultValue={user.profile.languageId}
                                label={t.trans('engage.profile.language')}
                                name="languageId"
                                options={availableLanguages}
                            />
                        </Grid>
                    )}
                </Grid>
            </form>
        );
    }, [
        availableLanguages,
        errors.firstName,
        errors.lastName,
        errors.userName,
        methods,
        onSubmit,
        t,
        user,
        userLanguages,
    ]);

    return (
        <FormProvider {...methods}>
            <Modal
                actions={{
                    buttons: createDefaultButtons({
                        cancel: { onClick: modal.hide },
                        submit: {
                            busy: isSubmitting,
                            disabled: isSubmitting || hasErrors,
                            onClick: methods.handleSubmit(onSubmit),
                        },
                    }),
                }}
                dark
                title={{
                    icon: <Icon type="USER" />,
                    label: 'engage.profile.title',
                }}
            >
                {renderProfileForm()}
            </Modal>
        </FormProvider>
    );
});

export default ProfileModal;
