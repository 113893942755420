import { Box, Typography } from '@mui/material';
import { ConfigModelsAttributeBasic } from '@zetadisplay/engage-api-client';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button } from '@zetadisplay/zeta-ui-components';
import { themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

type PlayerSettingsChannelLabelsAppendLabelProps = {
    attribute: ConfigModelsAttributeBasic;
    disabled: boolean;
    onClick: () => void;
};

const PlayerSettingsChannelLabelsAppendLabel: React.FC<PlayerSettingsChannelLabelsAppendLabelProps> = ({
    attribute,
    disabled,
    onClick,
}) => {
    const { trans } = useTranslation();

    return (
        <Box
            data-testid={`label-${attribute.id}`}
            sx={{
                alignItems: 'center',
                backgroundColor: themeOptions.colors.WHITE,
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'space-between',
                margin: '10px 0 0 0',
                padding: '8px 16px',
            }}
        >
            <Typography>{attribute.name}</Typography>
            <Button
                disabled={disabled}
                kind="primaryAction"
                label={trans('common.action.add')}
                onClick={onClick}
                sx={{ borderRadius: '8px' }}
            />
        </Box>
    );
};

export default PlayerSettingsChannelLabelsAppendLabel;
