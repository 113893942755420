import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { SwitchInput } from '@zetadisplay/engage-components';
import {
    createDiscriminatedEntity,
    DiscriminatedEntity,
    EntityDiscriminators,
} from '@zetadisplay/engage-components/models';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ComponentLoader } from '@zetadisplay/zeta-ui-components';

import { DeleteMediaFileFields } from 'src/components/Modals/Library/MediaFile/DeleteMediaFilePrompt';
import useMediaRelations from 'src/components/Modals/Library/MediaFile/Hooks/useMediaRelations';
import PlaylistSelection from 'src/components/PlaylistSelection';

type RemoveMediaFileFormProps = {
    media: DiscriminatedEntity<LibraryModelsMedia>;
};

const DeleteMediaFileForm = ({ media }: RemoveMediaFileFormProps) => {
    const { getValues, setValue, watch } = useFormContext<DeleteMediaFileFields>();
    const t = useTranslation();

    const [isSwitchDisabled, setSwitchDisabled] = useState(false);

    // Fetch playlists if media has any
    const relations = useMediaRelations(media.id);
    const values = watch();

    const renderPlaylistSelect = useCallback(() => {
        return (
            <Box>
                <Controller
                    defaultValue={relations.result?.campaigns?.map((playlist) => playlist.id) || []}
                    name="selectedPlaylists"
                    render={({ field: { onChange } }) => (
                        <PlaylistSelection
                            onChange={onChange}
                            playlists={
                                relations.result?.campaigns?.map((playlist) =>
                                    createDiscriminatedEntity(EntityDiscriminators.Campaign, {
                                        ...playlist,
                                        description: '',
                                    })
                                ) || []
                            }
                            showApplyToEveryPlaylist={false}
                        />
                    )}
                />
            </Box>
        );
    }, [relations.result?.campaigns]);

    /**
     * If playlists were found, initialize what we want to delete with all usages
     */
    useEffect(() => {
        if (
            relations.loading ||
            relations.result === undefined ||
            !relations.result.campaigns ||
            getValues('selectedPlaylists')?.length !== 0
        ) {
            return;
        }

        setValue(
            'selectedPlaylists',
            relations.result.campaigns.map((playlist) => playlist.id)
        );
    }, [getValues, relations.loading, relations.result, setValue]);

    /**
     * Logic to handle switch state
     */
    useEffect(() => {
        if (relations.loading || relations.result === undefined || !relations.result.campaigns) {
            return;
        }

        if (relations.result.campaigns.length > 0) {
            const { selectedPlaylists } = values;
            if (
                selectedPlaylists &&
                selectedPlaylists.length < relations.result.campaigns.length &&
                !isSwitchDisabled
            ) {
                setSwitchDisabled(true);
                setValue('deleteSourceMedia', false);
            } else if (isSwitchDisabled && selectedPlaylists?.length === relations.result.campaigns?.length) {
                setSwitchDisabled(false);
            }
        }
    }, [isSwitchDisabled, relations.loading, relations.result, setValue, values]);

    if (relations.loading) {
        return <ComponentLoader />;
    }

    return (
        <Box>
            {renderPlaylistSelect()}
            <SwitchInput
                disabled={isSwitchDisabled}
                label={t.trans('engage.modal.delete.content.delete.source')}
                name="deleteSourceMedia"
            />
        </Box>
    );
};

export default DeleteMediaFileForm;
