import React, { useCallback } from 'react';
import { Controller, FieldArrayWithId, FormProvider, useForm } from 'react-hook-form';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { createDefaultButtons, Modal } from '@zetadisplay/engage-components/modules/modal/components';
import { Scheduling } from '@zetadisplay/zeta-ui-components';
import {
    MODE_NAME_ALWAYS,
    MODE_NAME_SCHEDULED,
} from '@zetadisplay/zeta-ui-components/components/scheduling/components/modes';

import { ScheduleMultipleMediaFields } from '../../../../components/Modals/Library/Multiple/ScheduleMultipleMediaFilesModal';
import { PlaylistSetupFormValues } from '../../utils/createPlaylistFormValues';

export type ScheduleMultiplePlaylistItemsModalProps = {
    items: FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>[];
} & NiceModalHocProps;

const ScheduleMultiplePlaylistItemsModal = NiceModal.create<ScheduleMultiplePlaylistItemsModalProps>(({ items }) => {
    const methods = useForm<ScheduleMultipleMediaFields>();
    const modal = useModal();

    const onSubmit = useCallback(
        async (formValues: ScheduleMultipleMediaFields) => {
            modal.resolve({ data: formValues, item: items });
            return modal.hide();
        },
        [items, modal]
    );

    return (
        <FormProvider {...methods}>
            <Modal
                actions={{
                    buttons: createDefaultButtons({
                        cancel: {
                            disabled: methods.formState.isSubmitting,
                            onClick: modal.hide,
                        },
                        submit: {
                            busy: methods.formState.isSubmitting,
                            disabled: methods.formState.isSubmitting,
                            label: 'common.action.continue',
                            onClick: methods.handleSubmit(onSubmit),
                        },
                    }),
                }}
                dark
                title={{ label: 'engage.modal.schedule.media.title' }}
            >
                <Controller
                    defaultValue={[]}
                    name="schedules"
                    render={({ field: { onChange } }) => (
                        <Scheduling
                            allowedModes={[MODE_NAME_ALWAYS, MODE_NAME_SCHEDULED]}
                            initialSchedules={[]}
                            multiple
                            onChange={onChange}
                            showPlayingTimes
                            showWeekdays
                            useDateTime
                        />
                    )}
                />
            </Modal>
        </FormProvider>
    );
});

export default ScheduleMultiplePlaylistItemsModal;
