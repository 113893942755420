import { ConfigModelsAttributeDetails } from '@zetadisplay/engage-api-client';
import {
    createDiscriminatedEntity,
    DiscriminatedEntity,
    EntityDiscriminators,
} from '@zetadisplay/engage-components/models';
import { ApiInterface } from '@zetadisplay/engage-components/modules/api';
import { NotifyFunctionType } from '@zetadisplay/zeta-ui-components/hooks/use-notify';

import handlePendingPromise, { PendingPromiseMessages } from 'src/utils/HandlePendingPromise';
import { LabelSetupFormData } from 'src/views/LabelSetupView';

const createOrUpdateAttributePromise = (
    api: ApiInterface,
    attribute: DiscriminatedEntity<ConfigModelsAttributeDetails> | undefined,
    data: LabelSetupFormData,
    workspaceId: string
) => {
    if (attribute === undefined) {
        return api.attributes
            .createAttribute({
                body: { name: data.name, description: data.description },
                workspaceid: workspaceId,
            })
            .then((response) => createDiscriminatedEntity(EntityDiscriminators.Attribute, response.data));
    }

    if (attribute.name !== data.name || attribute?.description !== data.description) {
        return api.attributes
            .updateAttribute({
                attributeid: attribute.id,
                body: { name: data.name, description: data.description },
                workspaceid: workspaceId,
            })
            .then((response) => createDiscriminatedEntity(EntityDiscriminators.Attribute, response.data));
    }

    return new Promise<DiscriminatedEntity<ConfigModelsAttributeDetails>>((resolve) => {
        resolve(attribute);
    });
};

const createOrUpdateAttribute = (
    api: ApiInterface,
    attribute: DiscriminatedEntity<ConfigModelsAttributeDetails> | undefined,
    data: LabelSetupFormData,
    workspaceId: string,
    notify: NotifyFunctionType
) => {
    const isExistingAttribute = attribute !== undefined;
    const messages: PendingPromiseMessages = {
        pending: {
            message: isExistingAttribute
                ? 'engage.notification.edit.label.info'
                : 'engage.notification.create.label.info',
            params: [data.name],
        },
        success: {
            message: isExistingAttribute
                ? 'engage.notification.edit.label.success'
                : 'engage.notification.create.label.success',
            params: [data.name],
        },
    };

    return handlePendingPromise(createOrUpdateAttributePromise(api, attribute, data, workspaceId), messages, notify);
};

export default createOrUpdateAttribute;
