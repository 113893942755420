import env from '@beam-australia/react-env';
import NiceModal from '@ebay/nice-modal-react';
import { LibraryModelsMedia, LibraryModelsMediaActions } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity, isMediaFile } from '@zetadisplay/engage-components/models';
import { useUserInformation } from '@zetadisplay/engage-components/modules/auth';
import { EditMediaFileFields } from '@zetadisplay/engage-components/modules/library';
import { PlaylistMediaType } from '@zetadisplay/engage-components/modules/playlist';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { createTemplateUrl } from '@zetadisplay/engage-components/utils/media';
import { useTranslation } from '@zetadisplay/zeta-localization';

import EditMediaFileForm, { UpdateMediaFileFormProps } from 'src/components/Modals/PlaylistSetup/EditMediaFileForm';
import RemovePlaylistMediaPrompt, {
    RemovePlaylistMediaProps,
} from 'src/components/Modals/PlaylistSetup/RemovePlaylistMediaPrompt';
import SchedulePlaylistMediaForm, {
    ScheduleMediaFileFields,
    ScheduleMediaFileFormProps,
} from 'src/components/Modals/PlaylistSetup/SchedulePlaylistMediaForm';
import { isVisibleForZetaCastTemplate } from 'src/views/LibraryView/Utils/TemplateUtils';
import { emitOnPlaylistMediaEdited } from 'src/views/PlaylistSetupView/Events/onPlaylistMediaEditedEvent';
import { emitOnPlaylistMediaRemoved } from 'src/views/PlaylistSetupView/Events/onPlaylistMediaRemovedEvent';
import { emitOnPlaylistMediaScheduled } from 'src/views/PlaylistSetupView/Events/onPlaylistMediaScheduledEvent';

const usePlaylistSetupContentListItemActions = <T extends PlaylistMediaType>(
    copies: string[],
    playlistMedia: T,
    modifiedData: undefined | EditMediaFileFields
) => {
    const { hasRole } = useUserInformation();
    const t = useTranslation();
    const { workspace } = useWorkspace();

    const handleEditPlaylistMedia = (item: T) => {
        NiceModal.show<DiscriminatedEntity<LibraryModelsMedia>, UpdateMediaFileFormProps>(EditMediaFileForm, {
            media: { ...item.model, ...modifiedData },
        }).then((data) => emitOnPlaylistMediaEdited({ playlistMedia, data }));
    };

    const handleSchedulePlaylistMedia = (item: T) => {
        NiceModal.show<ScheduleMediaFileFields, ScheduleMediaFileFormProps>(SchedulePlaylistMediaForm, {
            mediaSchedules: item.mediaSchedules,
        }).then((data) => emitOnPlaylistMediaScheduled({ playlistMedia, data }));
    };

    const handleRemovePlaylistMedia = (item: T) => {
        NiceModal.show<string[], RemovePlaylistMediaProps>(RemovePlaylistMediaPrompt, {
            playlistMedia: item,
            copies,
        }).then(emitOnPlaylistMediaRemoved);
    };

    const isVisibleForMediaFile = () => {
        return isMediaFile(playlistMedia.model) && !isVisibleForZetaCastTemplate(playlistMedia.model);
    };

    return [
        {
            actions: [
                {
                    handler: (item: T) => {
                        window.open(createTemplateUrl(item.model, workspace, env('CONTENT_EDITOR_DOMAIN')), '_blank');
                    },
                    isDisabled: (item: T) => !(item.model.actionFlags & 2) || !item.model.templateId,
                    // Hide the action temporarily as CE + BE still has those mutating id's issue
                    // isVisible: isVisibleForZetaCastTemplate,
                    isVisible: () => false,
                    label: t.trans('engage.templates.content-editor.open'),
                    name: 'open-in-editor',
                },
                {
                    handler: handleEditPlaylistMedia,
                    isVisible: isVisibleForMediaFile,
                    isDisabled: () => {
                        return (
                            !hasRole(['ENGAGE_LIBRARY_CONTENT_EDIT']) ||
                            !(playlistMedia.model.actionFlags & LibraryModelsMediaActions.Edit)
                        );
                    },
                    label: t.trans('common.action.edit'),
                    name: 'edit',
                },
                {
                    handler: handleSchedulePlaylistMedia,
                    label: t.trans('engage.action.schedule'),
                    name: 'schedule',
                },
                {
                    handler: handleRemovePlaylistMedia,
                    label: t.trans('common.action.remove'),
                    name: 'remove-item',
                },
            ],
            name: 'playlist-setup-content-list-actions',
        },
    ];
};

export default usePlaylistSetupContentListItemActions;
