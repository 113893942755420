import React, { useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { PlaylistMediaItemScheduling } from '@zetadisplay/engage-components/modules/playlist';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Schedule, ScheduleToString } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()(() => ({
    root: {
        paddingTop: 10,
    },
    schedule: {
        alignItems: 'center',
        display: 'flex',
        margin: '4px 0',
    },
    title: {
        marginBottom: 2,
    },
}));

type ExpandedDetailsSchedulingProps = {
    schedules: Schedule[];
};

const LibraryMediaDetailsScheduling: React.FC<ExpandedDetailsSchedulingProps> = ({ schedules }) => {
    const { classes } = useStyles();
    const { trans } = useTranslation();

    const renderSchedules = useCallback(() => {
        if (schedules.length === 0) {
            return <Typography>{trans('common.scheduling.list.no_schedules')}</Typography>;
        }

        return schedules.map((schedule) => (
            <Box className={classes.schedule} key={`${schedule.id}-${schedule.mode}`}>
                <PlaylistMediaItemScheduling schedules={[schedule]} />
                <ScheduleToString key={schedule.id} schedule={schedule} />
            </Box>
        ));
    }, [classes.schedule, schedules, trans]);

    return (
        <Box className={classes.root}>
            <Typography className={classes.title} variant="h5">
                {trans('engage.library.media.scheduling.title')}
            </Typography>
            {renderSchedules()}
        </Box>
    );
};

export default LibraryMediaDetailsScheduling;
