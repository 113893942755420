import {
    ConfigModelsGroupInfo,
    PublishingCampaignSchedulingCampaignInfoWithMediaSchedules,
} from '@zetadisplay/engage-api-client';
import { PublishingCampaignSchedulingCampaignMedia } from '@zetadisplay/engage-api-client/models/publishing-campaign-scheduling-campaign-media';
import {
    createDiscriminatedEntity,
    DiscriminatedEntity,
    EntityDiscriminators,
} from '@zetadisplay/engage-components/models';
import { ApiInterface } from '@zetadisplay/engage-components/modules/api';
import { mediaFileMapper } from '@zetadisplay/engage-components/modules/library/utils';
import { createScheduleFromEngageSchedule, PlaylistMediaType } from '@zetadisplay/engage-components/modules/playlist';
import { TreeBuilder } from '@zetadisplay/engage-components/utils/tree-builder';
import pLimit from 'p-limit';

import createPlaylistFormValues from 'src/modules/playlist/utils/createPlaylistFormValues';

import getPlaylistMediaById from '../api/get-playlist-media';

const limit = pLimit(4);

const fetchPublishingGroups = async (
    publishGroups: PublishingCampaignSchedulingCampaignInfoWithMediaSchedules['publishGroups'],
    workspaceId: string,
    api: ApiInterface
) => {
    if (publishGroups === null || publishGroups === undefined || publishGroups.length === 0) {
        return [];
    }

    const groups = await Promise.all(
        publishGroups.map((group) =>
            limit(async () => {
                return api.publishGroups
                    .getGroup({
                        groupid: group,
                        workspaceid: workspaceId,
                    })
                    .then((response) => response.data)
                    .catch(() => undefined);
            })
        )
    );

    return [
        ...TreeBuilder<DiscriminatedEntity<ConfigModelsGroupInfo>, ConfigModelsGroupInfo, number>(
            groups.filter((group): group is ConfigModelsGroupInfo => group !== undefined),
            (group) => createDiscriminatedEntity(EntityDiscriminators.Group, group),
            undefined,
            undefined
        ).values(),
    ];
};

const fetchPlaylistMedia = async (
    campaignMedia: PublishingCampaignSchedulingCampaignMedia[] | null | undefined,
    workspaceId: string,
    api: ApiInterface
): Promise<Partial<PlaylistMediaType>[]> => {
    if (!campaignMedia || campaignMedia.length === 0) {
        return [];
    }

    return Promise.all(
        campaignMedia.map((media) => {
            if (media.mediaId) {
                return limit(async () => {
                    return getPlaylistMediaById(media.mediaId as string, api, workspaceId)
                        .then((response) => ({
                            ...media,
                            model: mediaFileMapper(response.data),
                            mediaSchedules: media.mediaSchedules?.map(createScheduleFromEngageSchedule) || [],
                        }))
                        .catch(() => ({
                            ...media,
                            model: undefined,
                            mediaSchedules: media.mediaSchedules?.map(createScheduleFromEngageSchedule) || [],
                        }));
                });
            }

            return Promise.resolve({
                ...media,
                model: undefined,
                mediaSchedules: media.mediaSchedules?.map(createScheduleFromEngageSchedule) || [],
            });
        })
    );
};

const fetchPlaylistFormData = async (playlistId: number, workspaceId: string, api: ApiInterface) => {
    const playlist = await api.playlists.getCampaignById({
        campaignid: playlistId,
        workspaceid: workspaceId,
    });

    const groups = await fetchPublishingGroups(playlist.data.publishGroups, workspaceId, api);

    const playlistMediaCollection = await fetchPlaylistMedia(playlist.data.campaignMedia, workspaceId, api);

    return createPlaylistFormValues({
        folderId: playlist.data.folderId,
        groups,
        layoutZoneId: playlist.data.layoutZoneId ?? undefined,
        name: playlist.data.name,
        playlistMediaCollection: playlistMediaCollection || [],
        mixingMode: playlist.data.mixingMode,
        priority: playlist.data.priority,
        schedules: playlist.data.schedules,
        status: playlist.data.status,
    });
};

export default fetchPlaylistFormData;
