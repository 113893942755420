import React, { useCallback } from 'react';
import { FieldArrayWithId } from 'react-hook-form';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, ListItem, Typography } from '@mui/material';
import { DragHandle } from '@styled-icons/material-sharp/DragHandle';
import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { EditMediaFileFields } from '@zetadisplay/engage-components/modules/library';
import { PlaylistMediaItemScheduling } from '@zetadisplay/engage-components/modules/playlist';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Checkbox, ContextMenu } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { PlaylistSetupFormValues } from '../../utils/createPlaylistFormValues';
import usePlaylistContentListItemActions from './hooks/use-playlist-content-list-item-actions';
import PlaylistPreviewContentItemInformation from './playlist-preview-content-item-information';
import PlaylistPreviewContentItemState from './playlist-preview-content-item-state';
import PlaylistPreviewContentItemThumbnail from './playlist-preview-content-item-thumbnail';

const useStyles = makeStyles()((theme) => ({
    root: {
        borderBottom: (theme.palette.dark && '1px solid rgba(255, 255, 255, 0.12)') || '1px solid rgba(0, 0, 0, 0.12)',
        borderTop: (theme.palette.dark && '1px solid rgba(255, 255, 255, 0.12)') || '1px solid rgba(0, 0, 0, 0.12)',
        height: 65,
        padding: '8px 8px 8px 0',
        marginTop: -1,
    },
    drag: {
        color: theme.palette.text.disabled,
        cursor: 'grab',
        marginRight: '4px',
        opacity: 0.35,
        '&:hover': {
            opacity: 1,
        },
        '&:active': {
            cursor: 'grabbing',
            opacity: 1,
        },
    },
    dragIcon: {
        outline: 'none',
    },
}));

type Props = {
    copies: string[];
    item: FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>;
    layoutZoneId?: number;
    modifiedData?: EditMediaFileFields;
    selectItem: (item: FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>) => void;
    selected: boolean;
};

const PlaylistPreviewContentItem = ({ copies, item, layoutZoneId, modifiedData, selected, selectItem }: Props) => {
    const { classes } = useStyles();
    const { trans } = useTranslation();

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: `${item.fieldArrayId}`,
    });

    const actions = usePlaylistContentListItemActions(copies, modifiedData);

    const renderContent = useCallback(() => {
        if (!item.model) {
            return <Typography>{trans('engage.notification.something.went.wrong')}</Typography>;
        }

        return (
            <>
                <Checkbox
                    checked={selected}
                    disabled={item.state === 'removed'}
                    onChange={() => selectItem(item)}
                    size="medium"
                />

                <PlaylistPreviewContentItemThumbnail item={item.model} layoutZoneId={layoutZoneId} />

                <PlaylistPreviewContentItemInformation
                    item={(modifiedData as DiscriminatedEntity<LibraryModelsMedia> | undefined) || item.model}
                />

                <PlaylistPreviewContentItemState item={item} />

                {item.mediaSchedules && (
                    <Box sx={{ display: 'flex', mr: 1 }}>
                        <PlaylistMediaItemScheduling iconSize={20} schedules={item.mediaSchedules} />
                    </Box>
                )}

                <Box component="span" className={classes.drag}>
                    <DragHandle
                        className={classes.dragIcon}
                        size={24}
                        {...attributes}
                        {...listeners}
                        data-testid="drag-handle"
                    />
                </Box>

                <ContextMenu<FieldArrayWithId<PlaylistSetupFormValues, 'playlistMediaCollection', 'fieldArrayId'>>
                    actions={actions}
                    item={item}
                />
            </>
        );
    }, [
        item,
        selected,
        layoutZoneId,
        modifiedData,
        classes.drag,
        classes.dragIcon,
        attributes,
        listeners,
        actions,
        trans,
        selectItem,
    ]);

    return (
        <ListItem
            ref={setNodeRef}
            className={classes.root}
            data-testid={`playlist-preview-content-item-${item.model?.name}`}
            style={{
                transform: CSS.Transform.toString(transform),
                transition,
            }}
        >
            {renderContent()}
        </ListItem>
    );
};

export default PlaylistPreviewContentItem;
