import { useCallback } from 'react';
import { UseFormGetValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { ConfigModelsGroupInfo, PublishingModelsCampaignCampaignStatus } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { PlaylistMediaType } from '@zetadisplay/engage-components/modules/playlist';
import { TreeNode } from '@zetadisplay/engage-components/utils/tree-builder';
import { Schedule } from '@zetadisplay/zeta-ui-components';
import { createButtonClickEvent, pushToDataLayer } from '@zetadisplay/zeta-ui-components/utils/data-layer';
import { dequal } from 'dequal';
import update from 'immutability-helper';

import convertScheduleToEngage from 'src/modules/playlist/utils/convert-schedule-to-engage';
import { PlaylistSetupFormValues } from 'src/modules/playlist/utils/createPlaylistFormValues';
import { useOnPlaylistMediaAddedListener } from 'src/views/PlaylistSetupView/Events/onPlaylistMediaAddedEvent';
import { useOnPlaylistMediaDraggedListener } from 'src/views/PlaylistSetupView/Events/onPlaylistMediaDraggedEvent';
import { useOnPlaylistMediaEditedListener } from 'src/views/PlaylistSetupView/Events/onPlaylistMediaEditedEvent';
import { useOnPlaylistMediaRemovedListener } from 'src/views/PlaylistSetupView/Events/onPlaylistMediaRemovedEvent';
import { useOnPlaylistMediaScheduledListener } from 'src/views/PlaylistSetupView/Events/onPlaylistMediaScheduledEvent';
import { useOnPlaylistScheduledListener } from 'src/views/PlaylistSetupView/Events/onPlaylistScheduledEvent';
import { useOnPlaylistTargetedListener } from 'src/views/PlaylistSetupView/Events/onPlaylistTargetedEvent';
import { createPlaylistMedia } from 'src/views/PlaylistView/Utils/withMediaResolver';

import hasAlwaysScheduledSchedule from '../../../modules/playlist/utils/has-always-scheduled-schedule';

const usePlaylistSetupEvents = (
    getValues: UseFormGetValues<PlaylistSetupFormValues>,
    setValue: UseFormSetValue<PlaylistSetupFormValues>,
    watch: UseFormWatch<PlaylistSetupFormValues>
) => {
    const playlistMediaCollection = watch('playlistMediaCollection');
    const getCurrentPlaylistMediaCollection = useCallback(() => {
        return getValues('playlistMediaCollection') as Partial<PlaylistMediaType>[];
    }, [getValues]);

    useOnPlaylistMediaAddedListener((playlistMedia) => {
        setValue('playlistMediaCollection', [...getValues('playlistMediaCollection'), playlistMedia]);

        pushToDataLayer(createButtonClickEvent('Playlist Settings', 'Add Content'));
    });

    useOnPlaylistMediaDraggedListener(({ dragIndex, hoverIndex, newItem }) => {
        const dragCard = playlistMediaCollection[dragIndex] || (newItem && createPlaylistMedia(newItem.model));
        if (dragCard === undefined) {
            return;
        }

        const updatedPlaylistMediaCollection = update(playlistMediaCollection, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragCard],
            ],
        });

        setValue('playlistMediaCollection', updatedPlaylistMediaCollection);
    });

    useOnPlaylistMediaEditedListener(({ playlistMedia, data }) => {
        if (
            typeof playlistMedia.mediaId !== 'string' ||
            (data.duration === playlistMedia.model.duration && data.name === playlistMedia.model.name)
        ) {
            return;
        }

        const modifiedMedia = getValues('modifiedMedia');

        setValue(
            'modifiedMedia',
            update(modifiedMedia, { $add: [[playlistMedia.mediaId, { data, object: playlistMedia.model }]] })
        );
    });

    useOnPlaylistMediaRemovedListener((mediaIdCollection: string[]) => {
        const currentPlaylistMediaCollection = getCurrentPlaylistMediaCollection();
        const filteredPlaylistMediaCollection = currentPlaylistMediaCollection.filter(
            (media) => mediaIdCollection.find((id) => id === media.id) === undefined
        );

        setValue('playlistMediaCollection', filteredPlaylistMediaCollection);
    });

    useOnPlaylistMediaScheduledListener(({ playlistMedia, data }) => {
        const currentPlaylistMediaCollection = getCurrentPlaylistMediaCollection();
        const mediaIndex = currentPlaylistMediaCollection.findIndex((m) => m.id === playlistMedia.id);
        if (mediaIndex === -1) {
            return;
        }

        setValue(
            'playlistMediaCollection',
            update(currentPlaylistMediaCollection, {
                [mediaIndex]: {
                    mediaSchedules: {
                        $set: !hasAlwaysScheduledSchedule(data.schedules) ? data.schedules : [],
                    },
                },
            })
        );
    });

    useOnPlaylistScheduledListener((schedules: Schedule[]) => {
        const existingSchedules = getValues('schedules');
        const convertedSchedules = schedules
            .filter((schedule) => schedule.mode !== 0)
            .map((schedule) => convertScheduleToEngage(schedule, existingSchedules));

        setValue('schedules', !hasAlwaysScheduledSchedule(schedules) ? convertedSchedules : []);
        setValue(
            'status',
            schedules.some((schedule) => schedule.mode === 0)
                ? PublishingModelsCampaignCampaignStatus.New
                : PublishingModelsCampaignCampaignStatus.Published
        );
    });

    useOnPlaylistTargetedListener((groups: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfo>, number>[]) => {
        if (!dequal(groups, getValues('groups'))) {
            pushToDataLayer(createButtonClickEvent('Playlist Settings', 'Add Group'));
        }

        setValue('groups', groups);
    });
};

export default usePlaylistSetupEvents;
