import { useCallback } from 'react';
import NiceModal, { NiceModalHocProps } from '@ebay/nice-modal-react';
import { isMediaFolder } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { createButtonClickEvent } from '@zetadisplay/zeta-ui-components/utils/data-layer';
import pLimit from 'p-limit';

import ConfirmPrompt, { ConfirmPromptActions } from 'src/components/Modals/ConfirmPrompt';
import { LibraryItemsType } from 'src/views/LibraryView';

const limit = pLimit(1);

export type DeleteMultipleItemsConfirmPromptProps = {
    items: LibraryItemsType[];
} & NiceModalHocProps;

const DeleteMultipleItemsConfirmPrompt = NiceModal.create<DeleteMultipleItemsConfirmPromptProps>(({ items }) => {
    const api = useApi();
    const t = useTranslation();
    const { workspace } = useWorkspace();

    const submitAction = useCallback(() => {
        return Promise.all(
            items.map((item) =>
                limit(() => {
                    if (isMediaFolder(item)) {
                        return api.folders.deleteFolder({
                            folderid: item.id,
                            forceDelete: true,
                            workspaceid: workspace.id,
                        });
                    }

                    return api.media.deleteMedia({
                        mediaid: item.id,
                        force: true,
                        workspaceid: workspace.id,
                    });
                })
            )
        ).then(() => items);
    }, [api.folders, api.media, items, workspace.id]);

    return (
        <ConfirmPrompt
            description={t.trans('engage.modal.bulk.delete.content', [])}
            submit={{ label: 'common.action.delete', name: 'delete' }}
            submitAction={submitAction}
            submitActionDataLayerEvent={createButtonClickEvent('Editing option', 'Bulk delete')}
            submitActionMessages={{ success: 'engage.notification.bulk.delete.success' }}
            title={{ label: 'engage.modal.bulk.delete.title', labelParams: [] }}
            // TODO: Update label to 'common.validation.validate.action' after R18 upgrade
            validate={{ value: ConfirmPromptActions.DELETE, label: 'Action confirmation' }}
        />
    );
});

export default DeleteMultipleItemsConfirmPrompt;
