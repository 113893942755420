import { PublishingModelsCampaignCampaignStatus } from '@zetadisplay/engage-api-client';

export const statuses = [
    {
        key: PublishingModelsCampaignCampaignStatus.New,
        label: 'engage.playlist.status.disabled',
        literal: 'New',
    },
    {
        key: PublishingModelsCampaignCampaignStatus.Promoted,
        label: 'engage.playlist.status.promoted',
        literal: 'Promoted',
    },
    {
        key: PublishingModelsCampaignCampaignStatus.Published,
        label: 'engage.playlist.status.published',
        literal: 'Published',
    },
    {
        key: PublishingModelsCampaignCampaignStatus.Expired,
        label: 'engage.playlist.status.expired',
        literal: 'Expired',
    },
    {
        key: PublishingModelsCampaignCampaignStatus.Reused,
        label: 'engage.playlist.status.reused',
        literal: 'Reused',
    },
    {
        key: PublishingModelsCampaignCampaignStatus.Rejected,
        label: 'engage.playlist.status.rejected',
        literal: 'Rejected',
    },
    {
        key: PublishingModelsCampaignCampaignStatus.Deleted,
        label: 'engage.playlist.status.deleted',
        literal: 'Deleted',
    },
];

export const statusColors = {
    [PublishingModelsCampaignCampaignStatus.Published]: '#51ED6D',
    [PublishingModelsCampaignCampaignStatus.New]: '#5E51ED',
    [PublishingModelsCampaignCampaignStatus.Expired]: '#ED5175',
    [PublishingModelsCampaignCampaignStatus.Reused]: '#FF8700',
    [PublishingModelsCampaignCampaignStatus.Rejected]: '#FF8700',
    [PublishingModelsCampaignCampaignStatus.Deleted]: '#FF8700',
    [PublishingModelsCampaignCampaignStatus.Promoted]: '#FF8700',
};

/**
 * In engage only statuses that are available to use are Disabled (New), Published and Expired
 */
export const isEngagePlaylistStatus = (status: PublishingModelsCampaignCampaignStatus) => {
    return [
        PublishingModelsCampaignCampaignStatus.New,
        PublishingModelsCampaignCampaignStatus.Published,
        PublishingModelsCampaignCampaignStatus.Expired,
    ].includes(status);
};
