import { useAsyncAbortable, UseAsyncReturn } from 'react-async-hook';
import { LibraryModelsFolder } from '@zetadisplay/engage-api-client';
import {
    createDiscriminatedEntity,
    DiscriminatedEntity,
    EntityDiscriminators,
} from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { TreeBuilder } from '@zetadisplay/engage-components/utils/tree-builder';

import { MoveMediaTargetType } from 'src/components/Sidekicks/Playlist/MovePlaylistItemSidekick';

type UseSidekickTargetsReturn = {
    rootNode: UseAsyncReturn<MoveMediaTargetType | undefined>;
    refresh: () => Promise<Map<string, MoveMediaTargetType>>;
    targets: UseAsyncReturn<Map<string, MoveMediaTargetType>>;
};

const useSidekickTargets = (): UseSidekickTargetsReturn => {
    const api = useApi();
    const { workspace, workspaceSettings } = useWorkspace();

    const targets = useAsyncAbortable(
        async (signal) => {
            return api.folders
                .getCampaignFolders(
                    {
                        parentId: workspaceSettings?.campaignRootFolder,
                        recursive: true,
                        workspaceid: workspace.id,
                    },
                    { signal }
                )
                .then((response) =>
                    TreeBuilder<DiscriminatedEntity<LibraryModelsFolder>, LibraryModelsFolder>(
                        response.data.items,
                        (node) => createDiscriminatedEntity(EntityDiscriminators.PlaylistFolder, node)
                    )
                );
        },
        [api.folders, workspace.id, workspaceSettings?.campaignRootFolder]
    );

    const rootNode = useAsyncAbortable(
        async (signal) => {
            if (!workspaceSettings?.campaignRootFolder) {
                return undefined;
            }

            return api.folders
                .getCampaignFolder(
                    { folderid: workspaceSettings.campaignRootFolder, workspaceid: workspace.id },
                    { signal }
                )
                .then((response) => createDiscriminatedEntity(EntityDiscriminators.PlaylistFolder, response.data))
                .then((response): MoveMediaTargetType => ({ ...response, children: new Map() }));
        },
        [api, workspace.id, workspaceSettings?.campaignRootFolder]
    );

    return { rootNode, refresh: targets.execute, targets };
};

export default useSidekickTargets;
