import React, { useCallback } from 'react';
import NiceModal, { NiceModalHocProps } from '@ebay/nice-modal-react';
import { ConfigModelsAttributeDetails } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';

import ConfirmPrompt from 'src/components/Modals/ConfirmPrompt';

export type RemoveLabelPromptProps = {
    label: DiscriminatedEntity<ConfigModelsAttributeDetails>;
} & NiceModalHocProps;

const RemoveLabelPrompt = NiceModal.create<RemoveLabelPromptProps>(({ label }) => {
    const api = useApi();
    const t = useTranslation();
    const { workspace } = useWorkspace();
    const submitAction = useCallback(async () => {
        return api.attributes
            .deleteAttribute({
                attributeid: label.id,
                deleteValues: true,
                workspaceid: workspace.id,
            })
            .then(() => label);
    }, [api, label, workspace.id]);
    return (
        <ConfirmPrompt
            description={t.trans('engage.modal.delete.content', [label.name])}
            submit={{ label: 'common.action.delete', name: 'delete' }}
            submitAction={submitAction}
        />
    );
});
export default RemoveLabelPrompt;
